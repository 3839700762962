import { path } from "Legacy/utils";
import Sites from "./Sites";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: path.SITES,
    key: "sites",
    component: Sites,
  },
];
