import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import "./TemplatesPage.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import { templateService } from "Legacy/services";
import { path, sortOrder, usePageDataReducer } from "Legacy/utils";
import { EmptyState, LayoutWithPreviewPane, PageHeader, PageLoader, Pager, TemplatesCard } from "Legacy/components";
import { convertSearchDataApiToUi, convertSearchDataUiToApi } from "Legacy/services/TemplateService";
import { TemplatesFilters, TemplatesPagePreview } from "./components";

const computePagination = pagination => ({
  offset: pagination.pageSize * (pagination.pageNumber - 1),
  limit: pagination.pageSize,
});

const loadEntities = async criteria => {
  const result = templateService.listLocal({ ...criteria, pagination: computePagination(criteria.pagination) });
  return { list: result.templates, totalCount: result.totalTemplates };
};

const PARAMETER_SEARCH = "search";

const defaultCriteria = {
  filters: [],
  sort: { sortBy: "id", sortOrder: sortOrder.DESC },
  pagination: { pageSize: 9, pageNumber: 1 },
};

export default function TemplatesPage() {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [pageState, dispatch, loadPageState] = usePageDataReducer("list", defaultCriteria);
  const [previewDemos, setPreviewDemos] = useState();
  const getTemplates = useCallback(
    async (criteria, forceFetch = false) => {
      if (forceFetch) {
        await templateService.clearCache();
      }
      const criteriaForUrl = convertSearchDataUiToApi(criteria);
      const route = `${path.TEMPLATES}?${PARAMETER_SEARCH}=${encodeURI(JSON.stringify(criteriaForUrl))}`;
      history.replace(route);
      await loadPageState(dispatch, criteria, () => loadEntities(criteria));
    },
    [history, dispatch, loadPageState]
  );
  const setFilters = useCallback(
    async criteria => {
      await loadPageState(dispatch, criteria);
    },
    [dispatch, loadPageState]
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search).get(PARAMETER_SEARCH);
    let criteria;
    if (search !== null) {
      let jsonSearch;
      try {
        jsonSearch = JSON.parse(search);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Could not parse the search query '${search}'`, error);
        return;
      }
      criteria = convertSearchDataApiToUi(jsonSearch, []);
    } else {
      criteria = {
        sort: defaultCriteria.sort,
        filters: defaultCriteria.filters,
        pagination: defaultCriteria.pagination,
      };
    }
    // noinspection JSIgnoredPromiseFromCall
    getTemplates(
      {
        sort: criteria.sorting,
        filters: criteria.filters,
        pagination: criteria.pagination,
      },
      false
    );
  }, [location.search, getTemplates]);

  const handleLoadPreview = template => {
    setPreviewDemos(template.demos);
  };

  const onSearch = () => {
    // Reset pageNumber to 1 too
    // noinspection JSIgnoredPromiseFromCall
    getTemplates(
      {
        sort: pageState.sort,
        filters: pageState.filters,
        pagination: { ...pageState.pagination, pageNumber: 1 },
      },
      false
    );
  };
  const paginationChanged = value => {
    // noinspection JSIgnoredPromiseFromCall
    getTemplates({ sort: pageState.sort, filters: pageState.filters, pagination: value }, false);
  };

  const onChanged = value => {
    if (value.length === 0) {
      // We reset the state when there is no filter
      // noinspection JSIgnoredPromiseFromCall
      getTemplates(
        { sort: pageState.sort, filters: value, pagination: { ...pageState.pagination, pageNumber: 1 } },
        false
      );
    } else {
      // We update filters
      // noinspection JSIgnoredPromiseFromCall
      setFilters({ sort: pageState.sort, filters: value, pagination: pageState.pagination });
    }
  };

  const onCleared = () => {
    // noinspection JSIgnoredPromiseFromCall
    getTemplates({ sort: pageState.sort, filters: [], pagination: { ...pageState.pagination, pageNumber: 1 } }, false);
  };

  const renderEmptyState = () => {
    // First the errors, then the 0 results state
    if (pageState.loadingError) {
      return (
        <EmptyState
          title={t("templates.list.loadingError")}
          subtitle={pageState.loadingError}
          action={{ label: t("actions.reload"), disabled: pageState.loading, onClick: getTemplates }}
        />
      );
    }
    if (pageState.totalCount === 0) {
      return (
        <EmptyState
          title={t("templates.list.noFilteredDataTitle")}
          subtitle={t("templates.list.noFilteredDataSubtitle")}
        />
      );
    }
    return <></>;
  };

  const renderPageBody = () => (
    <>
      <div className="content-wrap">
        {pageState.list.map(template => (
          <TemplatesCard key={template.id} template={template} loadPreview={() => handleLoadPreview(template)} />
        ))}
      </div>
      {renderEmptyState()}
    </>
  );

  return (
    <LayoutWithPreviewPane
      previewPane={<TemplatesPagePreview demos={previewDemos} onClose={() => setPreviewDemos(undefined)} />}
    >
      <div className="templates-page">
        <PageHeader title={t("templates.list.title")}>
          <PageLoader inline active={pageState.loading} />
          <Button
            icon={<RefreshLineIcon />}
            iconPosition="iconOnly"
            onClick={() =>
              getTemplates(
                {
                  sort: pageState.sort,
                  filters: pageState.filters,
                  pagination: pageState.pagination,
                },
                true
              )
            }
            type="secondary"
          />
        </PageHeader>
        <div className="filters-container">
          <TemplatesFilters
            filtersValue={pageState.filters}
            onChange={onChanged}
            onSearch={onSearch}
            onCleared={onCleared}
          />
        </div>
        {renderPageBody()}
        <hr />
        {pageState.pagination && (
          <Pager
            total={pageState.totalCount}
            pageNumber={pageState.pagination.pageNumber}
            pageSize={pageState.pagination.pageSize}
            onPagerChange={(pageSize, pageNumber) => paginationChanged({ pageSize, pageNumber })}
          />
        )}
      </div>
    </LayoutWithPreviewPane>
  );
}
