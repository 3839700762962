const equalComparator = a => b => a === b;

const containsComparator = a => b => b.indexOf(a) > -1;

const containsIgnoreCaseComparator = a => b => b.toLowerCase().indexOf(a.toLowerCase()) > -1;

const comparators = {
  equals: equalComparator,
  contains: containsComparator,
  containsIgnoreCase: containsIgnoreCaseComparator,
};

export default comparators;
