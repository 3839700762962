import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import { Alert, Button, ModalClosableFooter, Space, Typography } from "@ogury/design-system";
import { Site } from "@ogury/motionly-ws-api/ws";
import { GuardedModal } from "Legacy/components";
import { siteService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function SiteBulkPublishModal({ open, sites, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const publish = async () => {
    setWorking(true);
    const publishPromises = [];
    try {
      sitesToPublish.forEach(site => {
        publishPromises.push(siteService.publish(site.id));
      });
      await Promise.all(publishPromises);

      onSuccess();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  const sitesToPublish = useMemo(() => sites?.filter(site => site.state !== Site.StateEnum.Published), [sites]);

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("sites.bulkPublishModal.title")}
      footer={
        <ModalClosableFooter
          cancelButtonText={t("actions.cancel")}
          actions={
            <Button
              onClick={publish}
              type="primary"
              loading={working}
              disabled={working || sitesToPublish.length === 0}
            >
              {t("actions.publish")}
            </Button>
          }
        />
      }
      canBeClosed={() => (working === true ? false : undefined)}
      onClose={onClose}
    >
      {sitesToPublish.length > 0 ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert type="warning" closable={false} showIcon description={t("sites.bulkPublishModal.warning")} />

          {sitesToPublish.map(site => (
            <Typography.P2Strong>{site.name}</Typography.P2Strong>
          ))}
        </Space>
      ) : (
        <Alert type="warning" closable={false} showIcon description={t("sites.bulkPublishModal.emptySelection")} />
      )}
    </GuardedModal>
  );
}

SiteBulkPublishModal.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
