import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./RenameViewModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { ViewType } from "Legacy/propTypes";
import { Form, FormControl, GuardedModal } from "../../../../..";

const inputsWidth = "100%";

export default function RenameViewModal({
  open,
  currentView,
  onCancel = () => {},
  callAction = () => {},
  onActionPerformed = () => {},
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onRename = async formData => {
    setWorking(true);
    try {
      const view = await callAction(currentView.id, formData.name.trim());
      setWorking(false);
      notificationService.notifySuccess(t("viewManagement.renameSuccess"));
      onActionPerformed(view);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: { name: currentView.name },
    fields: { name: [{ name: validators.IS_REQUIRED }] },
    onSubmit: onRename,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const nameFieldProps = getFieldProps("name");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Small}
      title={t("viewManagement.renameModalTitle")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              loading={working}
              disabled={nameFieldProps.value === currentView.name}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.rename")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        nameFieldProps.value !== currentView.name ? t("components.guardedModal.closeQuestion") : undefined
      }
      onClose={onCancel}
    >
      <Typography.P2Regular as="p">{t("viewManagement.createModalExplanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <FormControl
          label={t("fields.name")}
          placeholder={t("fields.namePlaceholder")}
          width={inputsWidth}
          required
          {...nameFieldProps}
        />
      </Form>
    </GuardedModal>
  );
}

RenameViewModal.propTypes = {
  currentView: ViewType,
  onCancel: PropTypes.func,
  callAction: PropTypes.func,
  onActionPerformed: PropTypes.func,
  open: PropTypes.bool,
};
