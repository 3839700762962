import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Drawer,
  DrawerClosableFooter,
  DrawerHeader,
  Form,
  FormControlLabel,
  Input,
  Loader,
  Space,
  Typography,
} from "@ogury/design-system";
import { useNotificationService } from "Legacy/utils";
import { customerService, experienceService } from "Legacy/services";
import { customAntURLValidator, isValidHttpsUrl } from "Legacy/utils/string";
export default function SiteExternalTrackingDrawer({ open, site, onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [isRequesting, setIsRequesting] = useState(false);
  const [experiences, setExperiences] = useState();
  const [isMultiCTA, setIsMultiCTA] = useState(false);
  const [formData, setFormData] = useState();
  const [validLinksByKey, setValidLinksByKey] = useState();
  const [isFormTouched, setIsFormTouched] = useState();
  const [applyToAllForm] = Form.useForm();
  const [form] = Form.useForm();

  const applyToAllValue = Form.useWatch("applyToAllValue", applyToAllForm);

  const customerId = customerService.getCurrentCustomerSummaryId();

  async function load() {
    try {
      setIsRequesting(true);

      const experienceResponse = await experienceService.getExperiencesSummaryList(site.experienceIds, {
        hydrateTags: true,
      });

      setExperiences(experienceResponse);

      const data = {};
      for (const experience of experienceResponse) {
        data[experience.id] = "";
        const response = await experienceService.getTracking(experience.id);
        if (response?.clicks && response.clicks.length > 0) {
          if (response.clicks.length > 1) {
            setIsMultiCTA(true);
          }
          data[experience.id] = response.clicks[0].url;
        }
      }

      setFormData(data);
      form.setFieldsValue(data);
      computeLinksAvailability(data);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsRequesting(false);
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, site, customerId]);

  useEffect(() => {
    if (!open) {
      applyToAllForm.resetFields(undefined);
      form.resetFields(undefined);
      setIsFormTouched(false);
      setValidLinksByKey(undefined);
      setIsMultiCTA(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  async function handleFormFinish(values) {
    setIsRequesting(true);
    try {
      const promises = [];

      for (const experienceId in values) {
        const url = values[experienceId]?.trim();
        promises.push(experienceService.setDefaultTrackingClickURL(experienceId, url));
      }

      await Promise.all(promises);
      notificationService.notifySuccess(t("sites.externalTrackingModal.success"));
      onClose();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsRequesting(false);
    }
  }

  function handleOnValuesChange() {
    setIsFormTouched(true);
    computeLinksAvailability();
  }

  // Listens for individual input changes, and checks if the "check" link is clickable, according to the validity of the URL.
  function computeLinksAvailability(data) {
    const fieldsValues = data || form.getFieldsValue();

    function addKeyToSet(prev, key) {
      const updatedItems = new Set(prev);
      updatedItems.add(key);
      return Array.from(updatedItems);
    }

    function removeKeyFromArray(prev, key) {
      return prev?.filter(item => item !== key);
    }

    Object.keys(fieldsValues).forEach(key => {
      if (isValidHttpsUrl(fieldsValues[key])) {
        setValidLinksByKey(prev => addKeyToSet(prev, key));
      } else {
        setValidLinksByKey(prev => removeKeyFromArray(prev, key));
      }
    });
  }
  function renderFormControls() {
    function renderLabel(experienceId) {
      const experienceName = experiences?.find(experience => experience.id?.toString() === experienceId?.toString())
        ?.name;
      return (
        <FormControlLabel
          label={experiences?.length > 1 ? experienceName : t("sites.externalTrackingModal.urlInputLabel")}
        />
      );
    }

    function renderLink(key) {
      const isValid = validLinksByKey?.includes(key);

      function linkWrapper(children) {
        if (isValid) {
          return (
            <a href={form.getFieldValue(key)} target="_blank" rel="noreferrer">
              {children}
            </a>
          );
        }
        return children;
      }

      return linkWrapper(
        <Typography.TextLink2 disabled={!isValid}>{t("sites.externalTrackingModal.checkLink")}</Typography.TextLink2>
      );
    }

    return Object.keys(formData)?.map(key => {
      return (
        <React.Fragment key={key}>
          <Form.Item
            name={key}
            style={{
              display: "initial",
            }}
            label={renderLabel(key)}
            rules={[{ validator: customAntURLValidator }]}
          >
            <Input.TextArea
              width="100%"
              rows={2}
              disabled={isRequesting}
              placeholder={t("sites.externalTrackingModal.urlInputPlaceholder")}
            />
          </Form.Item>
          {renderLink(key)}
        </React.Fragment>
      );
    });
  }

  const handleOnApplyToAll = useCallback(() => {
    const values = form.getFieldsValue();

    Object.keys(values).forEach(key => {
      values[key] = applyToAllValue;
    });

    form.setFieldsValue(values);
    handleOnValuesChange();
  }, [form, applyToAllValue]);

  function canBeAppliedToall() {
    if (applyToAllValue === "") {
      return true;
    }
    if (isValidHttpsUrl(applyToAllValue)) {
      return true;
    }
  }
  function renderContent() {
    if (!experiences?.length && !isRequesting) {
      return <Typography.P2Regular>{t("sites.externalTrackingModal.noExperiences")}</Typography.P2Regular>;
    }
    if (isMultiCTA) {
      return <Typography.P2Regular>{t("sites.externalTrackingModal.multiCTA")}</Typography.P2Regular>;
    }
    return (
      <div>
        <Space direction="vertical" size="xs" style={{ width: "100%" }}>
          <Typography.P2Strong> {site?.name}</Typography.P2Strong>
          {experiences?.length > 1 && (
            <>
              <Form form={applyToAllForm} initialValues={{ applyToAllValue: "" }}>
                <FormControlLabel label={t("sites.externalTrackingModal.urlInputLabel")} />
                <Form.Item
                  disabled
                  name={"applyToAllValue"}
                  rules={[{ validator: customAntURLValidator }]}
                  style={{ margin: 0 }}
                >
                  <Input width="100%" placeholder={t("sites.externalTrackingModal.urlInputPlaceholder")} />
                </Form.Item>
              </Form>

              <Button disabled={!canBeAppliedToall()} type="secondary" size="small" onClick={handleOnApplyToAll}>
                {t("sites.externalTrackingModal.applyToAll")}
              </Button>
            </>
          )}
        </Space>
        <Divider />

        {formData && !isRequesting && experiences?.length > 0 ? (
          <Form form={form} onFinish={handleFormFinish} onValuesChange={handleOnValuesChange}>
            <Space direction="vertical" size="s" style={{ width: "100%" }}>
              {renderFormControls()}
            </Space>
          </Form>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
  return (
    <Drawer
      open={open}
      header={<DrawerHeader title={t("sites.externalTrackingModal.title")} />}
      footer={
        <DrawerClosableFooter
          actions={
            experiences?.length > 0 && (
              <Button
                submit
                type="primary"
                loading={isRequesting}
                disabled={!isFormTouched}
                onClick={() => {
                  form.submit();
                }}
              >
                {t("actions.save")}
              </Button>
            )
          }
        />
      }
      onClose={onClose}
      canBeClosed={() => (isFormTouched ? t("components.guardedModal.closeQuestion") : undefined)}
    >
      {renderContent()}
    </Drawer>
  );
}

SiteExternalTrackingDrawer.propTypes = {
  site: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
