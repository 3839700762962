import React from "react";
import * as PropTypes from "prop-types";

import "./LayoutWithLeftNav.scss";
import { NavigableSidebar } from "../../index";

export default function LayoutWithLeftNav({ links, currentPath, children, withDetailHeader = false }) {
  return (
    <div className="layout-with-left-nav">
      <div className="left-sidebar-container">
        <div className={`left-sidebar${withDetailHeader ? " with-header" : ""}`}>
          <NavigableSidebar links={links} currentPath={currentPath} />
        </div>
      </div>
      <div className="layout-viewport">{children}</div>
    </div>
  );
}

LayoutWithLeftNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  currentPath: PropTypes.string,
  children: PropTypes.node,
  withDetailHeader: PropTypes.bool,
};
