import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { ExperienceThumbnail, Form, FormControl, GuardedModal, SpacedContainer, SpacedUnits } from "Legacy/components";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { experienceService, helpers } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "100%";

export default function ExperienceDuplicateModal({
  experience,
  experienceName,
  experienceComment,
  onClose,
  onSuccess,
  open,
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async formData => {
    try {
      setWorking(true);
      const newExperience = await experienceService.duplicate(experience.id, {
        name: formData.name.trim(),
        comment: formData.comment,
      });
      notificationService.notifySuccess(t("experiences.duplicateModal.success"));
      onSuccess(newExperience);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.duplicateModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => ({ name: `${experienceName}-copy`, comment: experienceComment }), [
    experienceName,
    experienceComment,
  ]);

  const nameMaxLength = helpers.constants.experienceNameLengthMaximum;
  const commentMaxLength = helpers.constants.commentLengthMaximum;

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_MAX_LENGTH,
          length: nameMaxLength,
          message: t("fields.formValidationErrors.name", { length: nameMaxLength }),
        },
      ],
      comment: [
        {
          name: validators.IS_MAX_LENGTH,
          length: commentMaxLength,
          message: t("fields.formValidationErrors.comment", { length: commentMaxLength }),
        },
      ],
    },
    onSubmit,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const nameFieldProps = getFieldProps("name");
  const commentFieldProps = getFieldProps("comment");

  const renderForm = () => (
    <Form {...getFormProps()}>
      <fieldset disabled={working}>
        <FormControl
          id="name"
          type="text"
          label={t("fields.name")}
          placeholder={t("fields.namePlaceholder")}
          width={inputsWidth}
          required
          {...nameFieldProps}
        />
        <FormControl
          id="comment"
          type="textarea"
          label={t("fields.comment")}
          placeholder={t("fields.commentPlaceholder")}
          width={inputsWidth}
          {...commentFieldProps}
        />
      </fieldset>
    </Form>
  );

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("experiences.duplicateModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.duplicate")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        nameFieldProps.value !== initialFormValue.name || commentFieldProps.value !== initialFormValue.comment
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onClose}
      locked={working}
    >
      <Typography.P2Regular as="p">{t("experiences.duplicateModal.explanation")}</Typography.P2Regular>
      <SpacedContainer gap={SpacedUnits.Small}>
        <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
        {renderForm()}
      </SpacedContainer>
    </GuardedModal>
  );
}

ExperienceDuplicateModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  experienceName: PropTypes.string,
  experienceComment: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
