import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { mdiFileReplaceOutline } from "@mdi/js";
import "./ExperiencePublishOverButton.scss";
import { Button } from "@ogury/design-system";
import Icon from "@mdi/react";
import { experiencePermissions } from "Legacy/services";
import { ExperiencePublishOverModal } from "./components";

export default function ExperiencePublishOverButton({ experience, disabled, onExperienceReplaced }) {
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const onClickPublish = () => {
    setShowModal(true);
  };

  const onPublishOverSuccess = _experience => {
    onExperienceReplaced(_experience);
  };

  return (
    <>
      <Button
        iconPosition="left"
        icon={<Icon path={mdiFileReplaceOutline} />}
        type="secondary"
        disabled={disabled}
        onClick={onClickPublish}
      >
        {t("actions.publishOver")}
      </Button>
      <ExperiencePublishOverModal
        open={showModal && experiencePermissions.canPublishOver(experience)}
        experience={experience}
        onClose={() => setShowModal(false)}
        onSuccess={onPublishOverSuccess}
      />
    </>
  );
}

ExperiencePublishOverButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onExperienceReplaced: PropTypes.func.isRequired,
};
