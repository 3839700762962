import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FrontalEntity } from "@ogury/motionly-ws-api/ws";
import { useHistory } from "react-router-dom";

import { Input } from "@ogury/design-system";
import { backofficeService } from "Legacy/services";
import { path, useNotificationService } from "Legacy/utils";

const ENTER_KEY_CODE = 13;

export default function NavbarSearch() {
  const [search, setSearch] = useState();
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();

  const handleOnSearch = async event => {
    if (event.which === ENTER_KEY_CODE) {
      try {
        const frontalEntity = await backofficeService.getFrontalEntity(search);
        if (frontalEntity?.kind === FrontalEntity.KindEnum.Site.toLocaleLowerCase()) {
          history.push(`${path.SITES}/${search}`);
        } else if (frontalEntity?.kind === FrontalEntity.KindEnum.Experience.toLocaleLowerCase()) {
          history.push(`${path.EXPERIENCES}/${search}`);
        }
        setSearch("");
      } catch (error) {
        notificationService.notifyError(error, t("messages.errorTitle"));
      }
    }
  };

  return (
    <Input.Search
      value={search}
      onChange={value => setSearch(value)}
      placeholder={t("actions.experienceOrSiteId")}
      style={{ width: 250 }}
      onKeyPress={handleOnSearch}
    />
  );
}
