const get = (urlSearchParams, key) => {
  const value = urlSearchParams.get(key);
  return value === null ? undefined : value;
};

class OverridingManager {
  search = undefined;

  getParameters() {
    if (this.search === undefined) {
      this.search = new URLSearchParams(window.location.search);
    }
    return this.search;
  }

  getServerBaseUrl() {
    return get(this.getParameters(), "serverBaseUrl");
  }

  getFormBaseUrl() {
    return get(this.getParameters(), "formBaseUrl");
  }

  getStudioBaseUrl() {
    return get(this.getParameters(), "studioBaseUrl");
  }

  getSimulatedEnvironment() {
    return get(this.getParameters(), "simulatedEnvironment");
  }

  isLegacy() {
    return get(this.getParameters(), "isLegacy");
  }
}

// We define a singleton
const instance = new OverridingManager();

export default instance;
