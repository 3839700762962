import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";

export default function ChangeEmailConfirmationModal({ onCancel, onConfirm }) {
  const [t] = useTranslation();

  return (
    <Modal
      title={t("users.email.changeEmailConfirmationModal.title")}
      footer={
        <ModalClosableFooter
          actions={<Button onClick={onConfirm}>{t("users.email.changeEmailConfirmationModal.action")}</Button>}
        />
      }
      onCancel={onCancel}
    >
      <p className="normal-text">{t("users.email.changeEmailConfirmationModal.description")}</p>
    </Modal>
  );
}

ChangeEmailConfirmationModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
