import React from "react";
import { Input, InputNumber, Select, Switch } from "@ogury/design-system";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";

import { Checkbox, ColorInput, FileInput, SearchInput } from "..";
import "./FormControl.scss";
import { Tooltip } from "../..";

export const controlTypes = {
  NUMBER: "number",
  TEXT: "text",
  PASSWORD: "password",
  SELECT: "select",
  MULTISELECT: "multiselect",
  CHECKBOX: "checkbox",
  COLOR: "color",
  FILE: "file",
  TEXTAREA: "textarea",
  SEARCH: "search",
  TOGGLE: "toggle",
};

export default function FormControl(props) {
  const {
    type = controlTypes.TEXT,
    label,
    tooltipText,
    required = false,
    width = "auto",
    value,
    children,
    error,
    withPadding = true,
    ...rest
  } = props;
  const [t] = useTranslation();

  const formComponent = inputType => {
    if (children) {
      return children(props);
    }

    switch (inputType) {
      case controlTypes.NUMBER:
        return <InputNumber {...props} status={rest.validity === "invalid" ? "error" : rest.validity} />; // use until FormControl will be replaced with DS form control
      case controlTypes.TEXT:
        return <Input {...props} status={rest.validity === "invalid" ? "error" : rest.validity} />; // use until FormControl will be replaced with DS form control
      case controlTypes.PASSWORD:
        return <Input.Password {...props} status={rest.validity === "invalid" ? "error" : rest.validity} />; // use until FormControl will be replaced with DS form control
      case controlTypes.SELECT:
        return (
          <Select width="100%" {...props} {...rest} status={rest.validity === "invalid" ? "error" : rest.validity} />
        ); // use until FormControl will be replaced with DS form control
      case controlTypes.MULTISELECT:
        return (
          <Select
            width="100%"
            multiple
            {...props}
            {...rest}
            status={rest.validity === "invalid" ? "error" : rest.validity}
          /> // use until FormControl will be replaced with DS form control
        );
      case controlTypes.COLOR:
        return <ColorInput {...props} />;
      case controlTypes.CHECKBOX:
        return <Checkbox checked={value} {...props} />;
      case controlTypes.TOGGLE:
        return <Switch checked={value} {...props} />;
      case controlTypes.TEXTAREA:
        return <Input.TextArea {...props} status={rest.validity === "invalid" ? "error" : rest.validity} />; // use until FormControl will be replaced with DS form control
      case controlTypes.SEARCH:
        return <SearchInput {...props} />;
      case controlTypes.FILE:
        return (
          <div style={{ width }}>
            <FileInput {...props} />
          </div>
        );
      default:
        throw new Error(`Unknown form type : ${inputType}`);
    }
  };

  const isCheckbox = type === controlTypes.CHECKBOX;
  const hasLabel = isCheckbox === false && (label !== undefined || tooltipText !== undefined);
  const isCheckboxWithTooltip = isCheckbox === true && tooltipText !== undefined;
  // noinspection NestedConditionalExpressionJS
  const wrapperClassName =
    // eslint-disable-next-line no-nested-ternary
    hasLabel === true ? "form-control-field" : isCheckboxWithTooltip === true ? "form-control-checkbox" : undefined;

  return (
    <div className={`form-control-container${withPadding === true ? " form-control-padding" : ""}`}>
      {hasLabel === true && (
        <label className="form-control-label">
          {label}
          {tooltipText !== undefined && (
            <span className="form-control-tooltip-container">
              <Tooltip shortHtmlContent={tooltipText}>
                <Icon path={mdiInformation} size={0.6} />
              </Tooltip>
            </span>
          )}
          {isCheckbox === true || required === true ? (
            <></>
          ) : (
            <span className="optional-field">{t("form.optional")}</span>
          )}
        </label>
      )}
      <div className={wrapperClassName} style={{ width }}>
        {isCheckboxWithTooltip === true && (
          <span className="form-control-tooltip-container">
            <Tooltip shortHtmlContent={tooltipText}>
              <Icon path={mdiInformation} size={0.6} />
            </Tooltip>
          </span>
        )}
        {formComponent(type)}
      </div>
      {error && (
        <div className="form-control-error" style={{ width }}>
          {error}
        </div>
      )}
    </div>
  );
}

FormControl.propTypes = {
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  width: PropTypes.string,
  withPadding: PropTypes.bool,
  children: PropTypes.func,
  error: PropTypes.string,
};
