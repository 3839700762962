import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@ogury/design-system";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import { path, useAuth, useNotificationService } from "Legacy/utils";
import { authenticationService } from "Legacy/services";
import { AuthFormContainer, CredentialsForm, PublicLayout } from "../../components";
import { JoinForm } from "./components";

export default function JoinPage() {
  const auth = useAuth();
  const notificationService = useNotificationService();
  const [t] = useTranslation();

  const { encodedEmail, customerId } = useParams();

  const initialFormValue = {
    email: decodeURIComponent(encodedEmail),
    password: "",
  };

  const [working, setWorking] = useState(false);

  // Set login  or sign up mode
  const [isLogin, setIsLogin] = useState(false);

  const [authCredentials, setAuthCredentials] = useState();

  const onEmailAuth = async (formData, source) => {
    setAuthCredentials({ formData, source });
  };

  async function onGoogleAuth(googleResponse, source) {
    setAuthCredentials({
      formData: {
        ...googleResponse,
        email: initialFormValue.email,
      },
      source,
    });
  }

  const onInvitationAccepted = async ({ name }) => {
    setWorking(true);
    try {
      const authenticateResults = await authenticationService.acceptInvitation(customerId, name, authCredentials);
      await auth.authenticateAndLogin(undefined, undefined, authenticateResults);
      notificationService.notifySuccess(t("auth.join.success"));
      setWorking(false);
      path.set("/", true);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
      setWorking(false);
    }
  };

  const handleOnClickGoBack = () => {
    setAuthCredentials(undefined);
    setWorking(false);
    // TODO destroy google token
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      setIsLogin(!isLogin);
    }
  };

  const renderBody = () => {
    if (authCredentials) {
      return (
        <>
          <div className="auth-form-header">
            <div className="back-button">
              <Button iconPosition="iconOnly" icon={<ArrowLeftSLineIcon />} onClick={handleOnClickGoBack} />
            </div>
            <h1>Choose your name </h1>
          </div>
          <JoinForm onInvitationAccepted={onInvitationAccepted} working={working} />
        </>
      );
    }
    return (
      <>
        <div className="auth-form-header">
          <h1>{t("auth.join.title")}</h1>
        </div>
        <CredentialsForm
          actionLabel={!isLogin ? t("auth.signup.title") : ""}
          emailIsReadOnly
          initialFormValue={initialFormValue}
          forgottenPasswordLink={isLogin}
          working={working}
          onEmailAuth={onEmailAuth}
          onGoogleAuth={onGoogleAuth}
        />
      </>
    );
  };

  return (
    <PublicLayout>
      <div className="auth-page">
        <div className="auth-page-header">
          {isLogin ? t("auth.login.dontHaveAnAccount") : t("auth.signup.alreadyHaveAnAccount")}
          <span
            role="presentation"
            onKeyPress={handleKeyPress}
            onClick={() => setIsLogin(!isLogin)}
            className="typo-link"
          >
            {isLogin ? t("auth.signup.title") : t("auth.login.title")}
          </span>
        </div>
        <div className="auth-form">
          <AuthFormContainer>{renderBody()}</AuthFormContainer>
        </div>
      </div>
    </PublicLayout>
  );
}
