import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Attribute, ExperienceThumbnail, GuardedModal, SpacedContainer, SpacedUnits } from "Legacy/components";
import { experienceService, siteService } from "Legacy/services";
import { path, useNotificationService } from "Legacy/utils";
import { DESTINATION_DETACH_FROM_SITES } from "Legacy/utils/constants";
import { DestinationAndAdUnitSelector } from "Legacy/app/sites/components/SiteExperienceDuplicateModal/components";
import { AD_UNIT_PREFIXES } from "Legacy/components/AddExperienceDrawer/AdUnitPrefixes";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function SiteExperienceDuplicateModal({ open, experience, site, onClose, onSuccess }) {
  const initialDestination = {
    site: undefined,
    adUnit: undefined,
    isValid: false,
  };
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [destination, setDestination] = useState(initialDestination);
  const history = useHistory();

  async function onDuplicate() {
    try {
      setWorking(true);

      const { site, adUnit } = destination;
      if (experience.units[0]?.technicalId === adUnit) {
        // No need to open the builder, so the process is done here in order to not closing the modal.
        const newExperience = await experienceService.duplicate(experience.id, {
          name: experience.name,
        });
        if (site.id === DESTINATION_DETACH_FROM_SITES) {
          notificationService.notifySuccess(t("sites.experienceDuplicateModal.success"));
          return history.push(`${path.EXPERIENCES}`);
        }
        await siteService.attachExperience(site, newExperience.id);
        notificationService.notifySuccess(t("sites.experienceDuplicateModal.success"));
        history.push(`${path.SITES}/${site.id}`);
        onClose();
      } else {
        const prefix = AD_UNIT_PREFIXES[adUnit] ? `${AD_UNIT_PREFIXES[adUnit]}_` : "";
        const prefixMatch = Object.values(AD_UNIT_PREFIXES).filter(prefix => experience.name.startsWith(prefix));
        const experienceNameWithoutPrefix = experience.name?.split("_");
        experienceNameWithoutPrefix.shift();

        const newName =
          prefixMatch.length > 0 ? `${prefix}${experienceNameWithoutPrefix.join()}` : `${prefix}${experience.name}`;

        onSuccess({ ...experience, name: newName }, destination);
        onClose();
      }
    } catch (error) {
      notificationService.notifyError(error, t("sites.experienceDuplicateModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  }

  function handleOnChangeDestination(destinationSite, destinationAdUnit, isValid) {
    setDestination({ site: destinationSite, adUnit: destinationAdUnit, isValid });
  }

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("sites.experienceDuplicateModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" loading={working} disabled={!destination.isValid} onClick={onDuplicate}>
              {t("actions.duplicate")}
            </Button>
          }
        />
      }
      canBeClosed={() => (destination !== initialDestination ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("sites.experienceDuplicateModal.explanation")}</Typography.P2Regular>
      <Attribute name={t("sites.experienceDuplicateModal.oldExperience")} />
      <SpacedContainer gap={SpacedUnits.Small}>
        <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
        <DestinationAndAdUnitSelector
          onChange={handleOnChangeDestination}
          sourceSite={site}
          sourceExperience={experience}
        />
      </SpacedContainer>
    </GuardedModal>
  );
}

SiteExperienceDuplicateModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  site: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
