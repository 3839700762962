import React, { useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./FileInput.scss";

// TODO implement multiple selection handler

export default function FileInput({ accept = "*", disabled = false, onChange = () => {} }) {
  const [t] = useTranslation();
  const fileInput = useRef();
  const [dragOver, setDragOver] = useState(false);
  // TODO: handle the "message" to report an error
  const [message] = useState();

  const readBuffer = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = event => {
        resolve(event.target.result);
      };
      reader.readAsArrayBuffer(file);
    });

  const fileChangedHandler = file => {
    const reader = new FileReader();
    reader.onerror = error => {
      // eslint-disable-next-line no-console
      console.error(`Could not read the file with name '${file.name}'`, error);
    };
    reader.onloadend = () => {
      readBuffer(file).then(buffer => {
        onChange({ selectedFile: file, selectedFilePreview: reader.result, buffer });
      });
    };
    reader.readAsDataURL(file);
  };
  const inputId = "fileInput";

  return (
    <div
      onDragOver={event => {
        event.preventDefault();
        if (message !== undefined) {
          return;
        }
        setDragOver(true);
      }}
      onDragLeave={() => {
        if (message !== undefined) {
          return;
        }
        setDragOver(false);
      }}
      onDrop={async event => {
        event.preventDefault();
        if (message !== undefined) {
          return;
        }
        setDragOver(false);
        const acceptFile = file => {
          if (accept === "*") {
            return true;
          }
          const { type } = file;
          const acceptTokens = typeof accept === "string" ? [accept] : accept;
          // eslint-disable-next-line no-plusplus
          for (let tokenIndex = 0; tokenIndex < acceptTokens.length; tokenIndex++) {
            const acceptToken = acceptTokens[tokenIndex];
            if (type === acceptToken || acceptToken.indexOf(type) !== -1) {
              return true;
            }
            const index = acceptToken.indexOf("/*");
            if (index !== -1 && type.startsWith(acceptToken.substring(0, index)) === true) {
              return true;
            }
          }
          return false;
        };
        let file;
        if (event.dataTransfer.items !== undefined) {
          // We use the "items" interface to access the files
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < event.dataTransfer.items.length; index++) {
            // If a dropped item is not a file, we reject it
            if (event.dataTransfer.items[index].kind === "file") {
              const aFile = event.dataTransfer.items[index].getAsFile();
              if (acceptFile(aFile) === true) {
                file = aFile;
                break;
              }
            }
          }
        } else {
          // We use the "files" interface to access the files
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < event.dataTransfer.files.length; index++) {
            const aFile = event.dataTransfer.files[index];
            if (acceptFile(aFile) === true) {
              file = aFile;
              break;
            }
          }
        }

        if (file !== undefined) {
          fileChangedHandler(file);
        }
      }}
    >
      <input
        type="file"
        id={inputId}
        ref={fileInput}
        onChange={event => {
          if (event.target.files.length >= 1) {
            fileChangedHandler(event.target.files[0]);
          }
          // This forces the native component to reset the selected file, in case the same file is selected once again later on
          fileInput.current.value = "";
        }}
        disabled={disabled}
        accept={accept}
      />
      <label htmlFor={inputId} className={dragOver === true ? "drag-over" : undefined}>
        {t("components.fileInput.choose")}
      </label>
    </div>
  );
}

FileInput.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
