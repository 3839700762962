import React from "react";
import { useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "Legacy/components";
import { UserProfilePage } from "./pages";

export default function Users() {
  const { url } = useRouteMatch();

  return (
    <div>
      <ProtectedRoute path={`${url}`}>
        <UserProfilePage />
      </ProtectedRoute>
    </div>
  );
}
