import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./CreateCustomerModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { useAuth, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { customerService, userService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const initialFormValue = {
  customerName: "",
  customerId: "",
};
const inputWidth = "100%";

export default function CreateCustomerModal({ open, onCancel }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const auth = useAuth();
  const [working, setWorking] = useState(false);
  const [showConfirmationData, setShowConfirmationData] = useState();

  const onSubmitForm = formValue => {
    setShowConfirmationData(formValue);
  };

  const onCreateCustomer = async formValue => {
    setWorking(true);
    try {
      const freshCustomer = await userService.createCustomer(
        auth.currentUser.id,
        formValue.customerId,
        formValue.customerName
      );
      const freshUser = await userService.get(auth.currentUser.id);
      customerService.updateCachedCustomerData(freshCustomer);
      userService.updateCachedUserData(freshUser);
      customerService.changeCustomer(freshCustomer.id, "/");
    } catch (error) {
      notificationService.notifyError(error, t("navbar.customer.createCustomerModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };
  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      customerName: [{ name: validators.IS_REQUIRED }],
      customerId: [{ name: validators.IS_REQUIRED }, { name: validators.IS_PUBLIC_ID }],
    },
    onSubmit: onSubmitForm,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const customerNameFieldProps = getFieldProps("customerName");
  const customerIdFieldProps = getFieldProps("customerId");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderConfirmation = formValue => (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("navbar.customer.createCustomerModal.title")}
      footer={
        <ModalClosableFooter
          cancelButtonText={t("navbar.customer.createCustomerModal.confirmation.cancel")}
          actions={
            <Button onClick={() => onCreateCustomer(formValue)} loading={working} disabled={working}>
              {t("customers.modal.creationConfirm")}
            </Button>
          }
        />
      }
      onClose={() => setShowConfirmationData(undefined)}
    >
      <div className="create-customer-confirmation">
        <h3 className="form-recap-intro">{t("navbar.customer.createCustomerModal.confirmation.description")}</h3>
        <div className="form-recap-label">{t("fields.customerName")}</div>
        <div className="form-recap-description">{customerNameFieldProps.value}</div>
        <div className="form-recap-label">{t("navbar.customer.createCustomerModal.form.customerId")}</div>
        <div className="form-recap-description">
          {customerIdFieldProps.value}
          <div className="small-text ">{t("customers.modal.creationWarning")}</div>
        </div>
      </div>
    </GuardedModal>
  );

  const renderForm = () => (
    <GuardedModal
      width={ModalWidths.Medium}
      open={open}
      title={t("navbar.customer.createCustomerModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button type="primary" onClick={() => getFormProps().onSubmit()}>
              {t("navbar.customer.createCustomerModal.form.action")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        customerNameFieldProps.value !== initialFormValue.customerName ||
        customerIdFieldProps.value !== initialFormValue.customerId
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onCancel}
    >
      <Form {...getFormProps()}>
        <Typography.P2Regular as="p">{t("navbar.customer.createCustomerModal.explanation")}</Typography.P2Regular>
        <FormControl
          id="customerName"
          label={t("fields.customerName")}
          placeholder={t("fields.customerNamePlaceholder")}
          required
          {...customerNameFieldProps}
          width={inputWidth}
        />
        <FormControl
          id="customerId"
          label={t("fields.customerId")}
          placeholder={t("fields.customerIdPlaceholder")}
          required
          {...customerIdFieldProps}
          width={inputWidth}
        />
      </Form>
    </GuardedModal>
  );

  return !showConfirmationData ? renderForm() : renderConfirmation(showConfirmationData);
}

CreateCustomerModal.propTypes = {
  onCancel: PropTypes.func,
  open: PropTypes.bool,
};
