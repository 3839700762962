import React from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import Logo from "Legacy/assets/img/Logo.white.svg";

import "./PublicLayout.scss";

export default function PublicLayout({ children }) {
  const [t] = useTranslation();

  return (
    <div className="public-layout">
      <div className="public-layout-left-pane">
        <img id="logo" src={Logo} alt="Ogury logo" />
        <div className="left-pane-footer">
          <div>
            © {t("app.companyName")}, 2016-{new Date().getUTCFullYear()}
          </div>
          <div>v2</div>
        </div>
      </div>
      <div className="public-layout-right-pane">{children}</div>
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
