import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperienceEmbedButton.scss";
import { Button, Dropdown, Tooltip } from "@ogury/design-system";
import CodeLineIcon from "remixicon-react/CodeLineIcon";
import { computeValueLabels, Environment, experiencePermissions } from "Legacy/services";
import { ExperienceEmbedModal } from "./components";

export default function ExperienceEmbedButton({ experience }) {
  const [t] = useTranslation();
  const [environment, setEnvironment] = useState();

  const EnvironmentEnum = {};
  [Environment.Web, Environment.ECommerce].map(anEnvironment => {
    if (experiencePermissions.canEmbed(experience, anEnvironment) === true) {
      EnvironmentEnum[anEnvironment] = anEnvironment;
    }
    return undefined;
  });
  const environmentOptions = computeValueLabels(EnvironmentEnum, true, value => t(`experiences.embed.${value}`));

  const openEmbedModal = mode => {
    setEnvironment(mode);
  };

  const menuItems = environmentOptions.map(({ key, value, label }) => ({
    key: value,
    onClick: () => openEmbedModal(key),
    label,
  }));

  const embedIcon = (
    <Tooltip title={t("actions.integrate")} placement="top">
      <Button
        iconPosition="iconOnly"
        icon={<CodeLineIcon />}
        type="secondary"
        disabled={Object.keys(EnvironmentEnum).length <= 0}
      />
    </Tooltip>
  );

  return (
    <>
      <Dropdown trigger="click" menu={{ items: menuItems }}>
        {embedIcon}
      </Dropdown>
      <ExperienceEmbedModal
        open={!!environment}
        experience={experience}
        environment={environment}
        onCancel={() => setEnvironment(null)}
      />
    </>
  );
}

ExperienceEmbedButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
};
