import { path } from "Legacy/utils";
import Dashboard from "./Dashboard";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: path.DASHBOARD,
    key: "dashboard",
    component: Dashboard,
  },
];
