import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import Chat4LineIcon from "remixicon-react/Chat4LineIcon";
import ArticleLineIcon from "remixicon-react/ArticleLineIcon";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";

import "./NavbarUserMenu.scss";
import { Dropdown } from "@ogury/design-system";
import { ImageWithFallback } from "Legacy/components";
import { cache, userService } from "Legacy/services";
import { disableFeature, path, supportEmailAddress, useAuth, useNotificationService } from "Legacy/utils";
import sampleAvatar from "Legacy/assets/img/sampleAvatar.png";
import { LogoutConfirmationModal } from "./components";

export default function NavbarUserMenu() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const location = useLocation();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const onReload = async () => {
    await cache.reload(t, history, false, notificationService);
  };
  const openDocumentation = () => {};
  const openSupport = () => {
    window.location.href = `mailto:${supportEmailAddress}?subject=${t(
      "navbar.support.subject"
    )}&body=${t("navbar.support.body", { email: userService.getCurrentUserEmail() })}`;
  };
  const onLogout = async () => {
    await auth.logout();
  };

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          selectedKeys: [location.pathname.startsWith(path.USERS) && "profile"],
          items: [
            {
              key: "profile",
              label: (
                <Link to={path.USERS}>
                  {t("navbar.user.profile")}
                  <span className="navbar-user-menu-role">
                    ({userService.getRoleForCustomer(auth.currentCustomerSummary.id)})
                  </span>
                </Link>
              ),
            },
            {
              type: "divider",
            },
            disableFeature && {
              key: "documentation",
              onClick: openDocumentation,
              icon: <ArticleLineIcon size={16} />,
              label: t("navbar.user.documentation"),
            },
            disableFeature && {
              type: "divider",
            },
            disableFeature && {
              key: "reload",
              onClick: onReload,
              icon: <RefreshLineIcon size={16} />,
              label: t("navbar.user.reload"),
            },
            {
              key: "support",
              onClick: openSupport,
              icon: <Chat4LineIcon size={16} />,
              label: t("navbar.user.support"),
            },
            {
              type: "divider",
            },
            {
              key: "logout",
              danger: true,
              onClick: () => setShowLogoutModal(true),
              label: t("navbar.user.logout"),
            },
          ],
        }}
      >
        <ImageWithFallback
          src={auth.currentUser.avatarUrl}
          className="navbar-user-menu-avatar"
          alt="avatar"
          fallbackSrc={sampleAvatar}
        />
      </Dropdown>
      <LogoutConfirmationModal open={showLogoutModal} onConfirm={onLogout} onCancel={() => setShowLogoutModal(false)} />
    </>
  );
}
