import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { experienceService, siteService } from "Legacy/services";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

import "./UploadExperienceBundleModal.scss";
import UnitService from "Legacy/services/UnitService";

const inputsWidth = "400px";

export default function UploadExperienceBundleModal({ open, onClose, attachToSite, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [adunitOptions, setAdunitOptions] = useState([]);

  const formConfig = {
    initialValue: {
      file: null,
      adunit: null,
    },
    fields: {
      file: [{ name: validators.IS_REQUIRED }],
      adunit: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: async formValue => {
      setWorking(true);
      try {
        const experience = await experienceService.upload(formValue.file?.buffer, [formValue.adunit]);
        if (attachToSite) {
          try {
            await siteService.attachExperience(attachToSite, experience.id);
          } catch (error) {
            // Rollback to experience deletion if the attachment fails.
            await experienceService.deleteExperience(experience.id);
            notificationService.notifyError(error);
          }
        }
        onSuccess(experience.id);
      } catch (error) {
        notificationService.notifyError(error);
      } finally {
        setWorking(false);
      }
    },
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const fileFieldProps = getFieldProps("file");
  const adunitFieldProps = getFieldProps("adunit");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  useEffect(() => {
    if (open) {
      (async () => {
        const units = await UnitService.list();
        setAdunitOptions(units.map(unit => ({ value: unit.technicalId, label: unit.name })));
      })();
    }
  }, [open]);
  return (
    <GuardedModal
      width={ModalWidths.Medium}
      open={open}
      title={t("navbar.uploadExperienceBundleModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              disabled={!fileFieldProps.value || !adunitFieldProps.value}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.upload")}
            </Button>
          }
        />
      }
      canBeClosed={() => (fileFieldProps.value !== null ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("navbar.uploadExperienceBundleModal.explanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <FormControl
          type="select"
          label=""
          id="adunit"
          width={inputsWidth}
          disabled={working}
          options={adunitOptions}
          placeholder={t("experiences.properties.adUnit")}
          required
          {...adunitFieldProps}
        />
        <FormControl
          type="file"
          label=""
          id="inputsFile"
          width={inputsWidth}
          disabled={working}
          required
          {...fileFieldProps}
        />
        {fileFieldProps.value && <div>{fileFieldProps.value?.selectedFile?.name} </div>}
      </Form>
    </GuardedModal>
  );
}

UploadExperienceBundleModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClose: PropTypes.func,
  attachToSite: PropTypes.object,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
