import * as PropTypes from "prop-types";

export const FilterType = PropTypes.shape({
  field: PropTypes.string,
  value: PropTypes.string,
});

export const SortingType = PropTypes.shape({
  field: PropTypes.string,
  ascending: PropTypes.bool,
});

export const CriteriaType = PropTypes.shape({
  filters: PropTypes.arrayOf(FilterType),
  sorting: SortingType,
});

export const ViewType = PropTypes.shape({
  name: PropTypes.string,
  data: CriteriaType,
  creationDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  updateDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  ownerId: PropTypes.string,
  customerId: PropTypes.string,
  id: PropTypes.string,
});
