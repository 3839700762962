import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./TemplateDetailHeader.scss";
import { Button, Dropdown } from "@ogury/design-system";
import AddLineIcon from "remixicon-react/AddLineIcon";
import More2FillIcon from "remixicon-react/More2FillIcon";
import CodeLineIcon from "remixicon-react/CodeLineIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";
import { experiencePermissions, templatePermissions } from "Legacy/services";
import { Builder, ButtonWithDropdown, DetailPageHeader } from "Legacy/components";
import { TemplateCreateDynamicModal, TemplateCreateFromRevisionModal } from "Legacy/app/templates/components";
import { path } from "Legacy/utils";

export default function TemplateDetailHeader({ template }) {
  const [t] = useTranslation();
  const [showCreateDynamicModal, setShowCreateDynamicModal] = useState(false);
  const [showBuilder, setShowBuilder] = useState(false);
  const [showBuilderWithTemplateRevision, setShowBuilderWithTemplateRevision] = useState();
  const [showCreateFromRevisionModal, setShowCreateFromRevisionModal] = useState(false);

  const onCreateSucceed = () => {
    setShowCreateDynamicModal(false);
  };
  const handleCreateFromRevision = () => {
    setShowCreateFromRevisionModal(true);
  };

  const disabled = experiencePermissions.canCreate() === false;
  return (
    <DetailPageHeader backPath={path.TEMPLATES} loading={false}>
      <ButtonWithDropdown
        button={
          <Button icon={<AddLineIcon />} disabled={disabled} onClick={() => setShowBuilder(true)}>
            {t("actions.create")}
          </Button>
        }
        dropdown={
          <Dropdown
            trigger="click"
            menu={{
              items: [
                {
                  key: "dynamic",
                  icon: <CodeLineIcon size={16} />,
                  disabled,
                  onClick: () => setShowCreateDynamicModal(true),
                  label: t("templates.card.dynamic"),
                },
                templatePermissions.canCreateWithBuilderFromRevision() && {
                  key: "revision",
                  icon: <TimeLineIcon size={16} />,
                  onClick: handleCreateFromRevision,
                  label: t("templates.card.revision"),
                },
              ],
            }}
          >
            <Button iconPosition="iconOnly" icon={<More2FillIcon />} disabled={disabled} />
          </Dropdown>
        }
      />
      <TemplateCreateDynamicModal
        open={showCreateDynamicModal}
        template={template}
        onCancel={() => setShowCreateDynamicModal(false)}
        onCreateSucceed={onCreateSucceed}
      />
      <TemplateCreateFromRevisionModal
        open={templatePermissions.canCreateWithBuilder() && showCreateFromRevisionModal}
        template={template}
        onCancel={() => setShowCreateFromRevisionModal(false)}
        onSuccess={setShowBuilderWithTemplateRevision}
      />
      {templatePermissions.canCreateWithBuilderFromRevision() && showBuilderWithTemplateRevision && (
        <Builder
          templateId={template.id}
          templateRevision={showBuilderWithTemplateRevision}
          onClose={() => setShowBuilderWithTemplateRevision(undefined)}
        />
      )}
      {showBuilder && <Builder templateId={template.id} onClose={() => setShowBuilder(false)} />}
    </DetailPageHeader>
  );
}

TemplateDetailHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object,
};
