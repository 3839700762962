import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Experience } from "@ogury/motionly-ws-api/ws";

import "./ExperienceDetailPage.scss";
import { experiencePermissions, experienceService, siteService } from "Legacy/services";
import { DetailPageWithLeftNav, EmptyState, LayoutWithPreviewPane, ProtectedRoute } from "Legacy/components";
import { path } from "Legacy/utils";
import ExperienceService from "Legacy/services/ExperienceService";
import {
  ExperienceDetailAssetsPage,
  ExperienceDetailCallbacksPage,
  ExperienceDetailGeneralPage,
  ExperienceDetailParametersPage,
  ExperienceDetailSettingsPage,
} from "..";
import { ExperienceDetailHeader, ExperiencePreview } from "./components";

const { StateEnum } = Experience;
const { Archived } = StateEnum;

export default function ExperienceDetailPage() {
  const [t] = useTranslation();
  const { experienceId } = useParams();
  const { url } = useRouteMatch();
  const rootPath = useMemo(() => {
    // remove trailing /
    if (url.endsWith("/")) {
      return url.substring(0, url.length - 1);
    }

    return url;
  }, [url]);

  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [experience, setExperience] = useState();
  const [experienceSite, setExperienceSite] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [localState, setLocalState] = useState(null);

  useEffect(() => {
    setLocalState(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    return () => setLocalState();
  }, [location.pathname]);

  const getData = useCallback(
    async updatedExperience => {
      setLoading(true);
      setErrorMessage(undefined);
      try {
        setExperience(await experienceService.get(experienceId, updatedExperience));
      } catch (error) {
        setErrorMessage(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [experienceId]
  );

  useEffect(() => {
    if (experience && ExperienceService.isInSite(experience)) {
      const fetchSite = async () => {
        const site = await siteService.getExperienceSite(experience.id);
        setExperienceSite(site);
      };

      fetchSite();
    }
  }, [experience]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getData();
  }, [getData]);

  const onExperienceUpdated = useCallback(
    event => {
      if (event?.detail && experienceId && experienceId.toString() === event.detail.experience?.id?.toString()) {
        setExperience(event.detail.experience);
      }
    },
    [experienceId]
  );

  useEffect(() => {
    document.addEventListener("experience", onExperienceUpdated);
    return () => document.removeEventListener("experience", onExperienceUpdated);
  }, [onExperienceUpdated]);

  const nestedPaths = {
    GENERAL: `${rootPath}`,
    PARAMETERS: `${rootPath}/parameters`,
    SETTINGS: `${rootPath}/settings`,
    CALLBACKS: `${rootPath}/callbacks`,
    ASSETS: `${rootPath}/assets`,
  };

  const links = [
    { id: 0, path: nestedPaths.GENERAL, label: "experiences.sidebar.general" },
    {
      id: 1,
      path: nestedPaths.PARAMETERS,
      label: "experiences.sidebar.parameters",
      hidden: experience === undefined || !experiencePermissions.parameters.canView(experience),
    },
    {
      id: 2,
      path: nestedPaths.CALLBACKS,
      label: "experiences.sidebar.callbacks",
      hidden: experience === undefined || !experiencePermissions.callbacks.canView(experience),
    },
    {
      id: 3,
      path: nestedPaths.SETTINGS,
      label: "experiences.sidebar.settings",
      hidden: experience === undefined || !experiencePermissions.settings.canView(experience),
    },
    {
      id: 4,
      path: nestedPaths.ASSETS,
      label: "experiences.sidebar.assets",
      hidden: experience === undefined || !experiencePermissions.assets.canView(experience),
    },
  ];

  const renderViewContent = () => (
    <Switch>
      <ProtectedRoute exact path={nestedPaths.GENERAL}>
        <ExperienceDetailGeneralPage
          experience={experience}
          experienceSite={experienceSite}
          reloadExperience={getData}
          showModalUpgrade={localState?.showModalUpgrade}
        />
      </ProtectedRoute>

      {experiencePermissions.parameters.canView(experience) && (
        <ProtectedRoute path={nestedPaths.PARAMETERS}>
          <ExperienceDetailParametersPage experience={experience} reloadExperience={getData} />
        </ProtectedRoute>
      )}
      {experiencePermissions.callbacks.canView(experience) && (
        <ProtectedRoute path={nestedPaths.CALLBACKS}>
          <ExperienceDetailCallbacksPage experience={experience} reloadExperience={getData} />
        </ProtectedRoute>
      )}
      {experiencePermissions.settings.canView(experience) && (
        <ProtectedRoute path={nestedPaths.SETTINGS}>
          <ExperienceDetailSettingsPage experience={experience} reloadExperience={getData} />
        </ProtectedRoute>
      )}
      {experiencePermissions.assets.canView(experience) && (
        <ProtectedRoute path={nestedPaths.ASSETS}>
          <ExperienceDetailAssetsPage experienceId={experienceId} />
        </ProtectedRoute>
      )}

      <Redirect to={{ pathname: nestedPaths.GENERAL }} />
    </Switch>
  );

  const renderPageContent = () => {
    if (errorMessage !== undefined) {
      return (
        <EmptyState
          title={t("experiences.detail.loadingError")}
          subtitle={errorMessage}
          action={{
            label: t("experiences.detail.backToList"),
            onClick: () => {
              history.push(path.EXPERIENCES);
            },
          }}
        />
      );
    }
    return (
      <LayoutWithPreviewPane
        previewPane={
          experience === undefined ? null : (
            <ExperiencePreview experienceId={experience.id} disabled={experience.state === Archived} />
          )
        }
      >
        <DetailPageWithLeftNav
          detailHeader={
            experience === undefined ? undefined : (
              <ExperienceDetailHeader
                experience={experience}
                experienceSite={experienceSite}
                onReload={getData}
                loading={loading}
                showName={location.pathname !== nestedPaths.GENERAL}
              />
            )
          }
          links={links}
          isEmpty={experience === undefined}
          withRightPadding
        >
          {experience !== undefined && renderViewContent()}
        </DetailPageWithLeftNav>
      </LayoutWithPreviewPane>
    );
  };

  return renderPageContent();
}
