import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter, Space, Typography } from "@ogury/design-system";
import { ExperienceThumbnail } from "Legacy/components";
import { experienceService, siteService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function ExperienceDeleteModal({ open, experience, siteId, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async () => {
    setWorking(true);
    try {
      if (siteId) {
        await siteService.detachExperience(siteId, experience.id);
      }
      await experienceService.deleteExperience(experience.id);
      notificationService.notifySuccess(t("experiences.deleteModal.success"));
      onSuccess();
      onClose();
    } catch (error) {
      notificationService.notifyError(error, t("experiences.deleteModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  return (
    <Modal
      open={open}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onSubmit} type="danger" loading={working}>
              {t("actions.delete")}
            </Button>
          }
        />
      }
      title={t("experiences.deleteModal.title")}
      onCancel={onClose}
    >
      <Space direction="vertical" size="m">
        <Typography.P2Regular>{t("experiences.deleteModal.explanation")}</Typography.P2Regular>
        <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
      </Space>
    </Modal>
  );
}

ExperienceDeleteModal.propTypes = {
  open: PropTypes.bool,
  experience: PropTypes.object,
  siteId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
