import React from "react";
import * as PropTypes from "prop-types";
import { Experience, Tag } from "@ogury/motionly-ws-api/ws";
import { useTranslation } from "react-i18next";
import { Empty, Table } from "@ogury/design-system";
import { experienceActions, experienceService, unitService } from "Legacy/services";
import { ExperienceThumbnail } from "Legacy/components";
import { dateFormatter } from "Legacy/utils";
import { ExperienceInlineActions, ExperienceStatusTag } from "Legacy/app/experiences/components";
import TemplateLink from "Legacy/components/TemplateLink/TemplateLink";

export default function SitesExperiencesTable({
  site,
  experiences = [],
  loading,
  onDuplicateExperience = () => {},
  onRefresh = () => {},
  onAdd = () => {},
}) {
  const [t] = useTranslation();

  const renderThumbnailNameCell = cellData => <ExperienceThumbnail experience={cellData} isFull={false} editable />;

  function renderFeatureCell(row) {
    if (row.origin === Experience.OriginEnum.FromTemplate || row.origin === Experience.OriginEnum.Dynamic) {
      const tag = row.tags.find(tag => tag.category === Tag.CategoryEnum.Template);
      return <TemplateLink tagId={tag.id} />;
    }
    return experienceService.getOriginsLabels(t)[row.origin];
  }

  function renderAdUnitCell(units) {
    const results = unitService.getUnitsNamesFromUnits(units);
    if (results) {
      return <span>{results.join(", ")}</span>;
    }

    return <></>;
  }

  const renderDateCell = date => dateFormatter.toShortDateTime(date);
  const renderStatusCell = state => <ExperienceStatusTag status={state} />;

  const renderActionCell = row => {
    return (
      <ExperienceInlineActions
        key={"site-table-menu-options-" + row.id}
        site={site}
        experience={row}
        actionsExcluded={[experienceActions.PublishOver, experienceActions.Transfer, experienceActions.ThirdPartyTag]}
        onRefresh={onRefresh}
        onExperienceDuplicatedInSite={onDuplicateExperience}
      />
    );
  };

  const dataSource = experiences.map(experience => ({
    key: experience.id,
    experience,
    owner: experience.owner,
    status: experience.state,
    adUnit: experience.units,
    ratio: experience.ratio,
    updated: experience.updateDate,
    row: experience,
  }));

  const columns = [
    {
      title: t("sites.table.name"),
      dataIndex: "experience",
      width: "30%",
      render: renderThumbnailNameCell,
    },
    {
      title: t("sites.table.status"),
      dataIndex: "status",
      width: "10%",
      render: renderStatusCell,
    },
    {
      title: t("sites.table.template"),
      dataIndex: "experience",
      width: 100,
      render: renderFeatureCell,
    },
    {
      title: t("experiences.properties.adUnit"),
      dataIndex: "adUnit",
      width: 100,
      render: renderAdUnitCell,
    },
    {
      title: t("fields.ratio"),
      dataIndex: "ratio",
      width: 80,
    },
    {
      title: t("sites.table.updated"),
      dataIndex: "updated",
      width: 120,
      render: renderDateCell,
    },

    {
      title: t("experiences.properties.actions"),
      dataIndex: "experience",
      align: "right",
      width: 150,
      render: renderActionCell,
    },
  ];

  return (
    <Table
      loading={loading}
      emptyState={
        <Empty
          title={t("sites.detail.noResultsDataTitle")}
          description={t("sites.detail.noResultsDataDescription")}
          buttonText={t("actions.add")}
          onClick={onAdd}
        />
      }
      key={JSON.stringify(columns)}
      columns={columns}
      dataSource={dataSource}
    />
  );
}

SitesExperiencesTable.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})),
  onDuplicateExperience: PropTypes.func,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  onAdd: PropTypes.func,
  site: PropTypes.object.isRequired,
};
