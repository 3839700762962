import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GuardedModal, SpacedContainer, SpacedUnits, SiteInformationForm } from "Legacy/components";
import { useNotificationService } from "Legacy/utils";
import { siteService } from "Legacy/services";
import * as PropTypes from "prop-types";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";

export default function CreateSiteModal({ open, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [formData, setFormData] = useState();

  async function handleOnCreate() {
    try {
      setWorking(true);
      const site = await siteService.create(formData.name, formData.account, formData.brand);
      onSuccess(site);
    } catch (error) {
      notificationService.notifyError(error, t("navbar.createSiteModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  }

  function renderForm() {
    return <SiteInformationForm onChange={setFormData} isValid={setSubmittable} />;
  }

  function computeCanBeClosed() {
    // t("components.guardedModal.closeQuestion")
    return true;
  }

  return (
    <GuardedModal
      open={open}
      title={t("navbar.createSiteModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button disabled={!submittable} submit type="primary" loading={working} onClick={handleOnCreate}>
              {t("actions.add")}
            </Button>
          }
        />
      }
      canBeClosed={computeCanBeClosed}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("navbar.createSiteModal.explanation")}</Typography.P2Regular>
      <SpacedContainer gap={SpacedUnits.Small}>{renderForm()}</SpacedContainer>
    </GuardedModal>
  );
}

CreateSiteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
