/**
 * Pass a custom function, will be executed against a value.
 * The function must return a string (the message if validation fails)
 * @param validator
 * @returns {*}
 */
const custom = ({ validator }) => value => validator(value);

/**
 * Required field : returns the error message if there is no value
 * @param message
 * @returns {*}
 */
const isRequired = ({ message = "form.required" }) => value => (String(value) ? "" : message);

/**
 * Checks if a string is shorter than the specified length
 * @param message
 * @param length
 * @returns {*}
 */
const isMinLength = ({ message = "form.errorMinLength", length }) => value => {
  if (value && value.length < length) {
    return message;
  }
  return "";
};

/**
 * Checks if a string is not longer than the specified limit
 * @param message
 * @param length
 * @returns {*}
 */
const isMaxLength = ({ message = "form.errorMaxLength", length }) => value => {
  if (value && value.length > length) {
    return message;
  }
  return "";
};

/**
 * Checks if a value is within a range (min and max included)
 * @param {string} message
 * @param range
 * @returns {*}
 */
const isIntInRange = ({ message, range }) => value => {
  if (value < range.MIN || value > range.MAX) {
    return message;
  }
  return "";
};

/**
 * Checks if a string value is a valid integer
 * @param {string} message
 * @param range
 * @returns {*}
 */
const isInteger = ({ message = "form.noDecimals" }) => value => {
  if (value) {
    const innerMessage = isMaxLength({ length: 32 })(value);
    if (innerMessage !== undefined) {
      return innerMessage;
    }
    // Finds other than numbers
    const regExp = /[^0-9]/g;
    if (regExp.test(value) === true) {
      return message;
    }
  }
  return "";
};

/**
 * Checks if a value is an email
 * @param {string} message
 * @param {function} t
 * @returns {*}
 */
const isEmail = ({ message = "form.emailWrongFormat", t }) => value => {
  if (value && value.length > 0) {
    const maxLength = 128;
    const innerMessage = isMaxLength({ length: maxLength })(value);
    if (innerMessage !== "") {
      return t === undefined ? message : t(innerMessage, { LENGTH: maxLength });
    }
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regExp.test(String(value).toLowerCase()) === false) {
      return message;
    }
  }
  return "";
};

/**
 * Checks if a value is a valid URL
 * @param {string} message
 * @param {function} t
 * @returns {*}
 */
const isURL = ({ message = "form.errorURL" }) => value => {
  if (value !== undefined && value !== "") {
    try {
      // eslint-disable-next-line no-new
      new URL(value);
    } catch (error) {
      return message;
    }
  }
  return "";
};

/**
 * Checks if a value is a valid public ID : small-case with no spaces nor special characters.
 * @returns {String}
 */
const isPublicId = () => value => {
  if (value && value.length > 0) {
    if (/^[a-z]([a-z0-9]+)*$/.test(value) === false) {
      return "form.publicIdWrongFormat";
    }
    if (value.length > 16) {
      return "form.publicIdWrongFormat";
    }
  }
  return "";
};

/**
 * Checks if a string matches a regexp
 * @param message
 * @param regexp
 * @returns {*}
 */
const isRegExp = ({ message = "form.errorMaxLength", regexp }) => value => {
  if (value && !value.match(regexp)) {
    return message;
  }
  return "";
};

const validators = {
  CUSTOM: custom,
  IS_REQUIRED: isRequired,
  IS_INT_IN_RANGE: isIntInRange,
  IS_MIN_LENGTH: isMinLength,
  IS_MAX_LENGTH: isMaxLength,
  IS_EMAIL: isEmail,
  IS_REGEXP: isRegExp,
  IS_PUBLIC_ID: isPublicId,
  IS_URL: isURL,
  IS_INTEGER: isInteger,
};

export { validators as default };
