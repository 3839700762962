import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperienceTagsModal.scss";
import { Button, ModalClosableFooter, Tag } from "@ogury/design-system";
import { Attribute, Form, GuardedModal, PageLoader, SpacedContainer } from "Legacy/components";
import { tagTypes, useFormValidation, useNotificationService } from "Legacy/utils";
import { experienceService, tagService } from "Legacy/services";

import { ModalWidths } from "Legacy/components/Modal/Modal";
import { TagSearchPane } from "./components";

const filterTags = (allTags, usedTags) => allTags.filter(tag => !usedTags.find(t => t.id === tag.id));

const nbRecentTags = 10;

export default function ExperienceTagsModal({ open, experienceId, tags = [], onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  // internal tags are the tags to be associated to the experience when clicking Apply
  const [internalTags, setInternalTags] = useState(tags);
  // All customer tags
  const [allAvailableTags, setAllAvailableTags] = useState([]);

  const originalTagsIds = useMemo(() => tags.map(tag => tag.id), [tags]);

  // Available tags in the bottom of the modal : internalTags are excluded, the list is truncated if not showAllTags
  const availableTags = useMemo(() => {
    const truncatedList = showAllTags ? allAvailableTags : allAvailableTags.slice(0, nbRecentTags);
    return filterTags(truncatedList, internalTags);
  }, [showAllTags, allAvailableTags, internalTags]);

  const loadTags = useCallback(
    async (firstLoad = false) => {
      setPageLoading(firstLoad);
      try {
        const result = await tagService.listCustomerTags();
        setAllAvailableTags(filterTags(result, internalTags));
        setPageLoading(false);
      } catch (error) {
        notificationService.notifyError(error, t("experiences.tagsModal.formValidationErrors.searchError"));
        setPageLoading(false);
      }
    },
    [t, internalTags]
  );

  useEffect(() => {
    if (!open) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    loadTags(true);
  }, [loadTags, open]);

  const saveTags = async () => {
    setWorking(true);
    try {
      const tagIds = internalTags.map(tag => tag.id);
      await experienceService.setTags(experienceId, tagIds);
      notificationService.notifySuccess(t("experiences.tagsModal.applySuccess"));
      setWorking(false);
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("experiences.tagsModal.formValidationErrors.submitErrorTitle"));
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: {},
    fields: {},
    onSubmit: saveTags,
  };

  const { getFormProps, resetForm } = useFormValidation(formConfig);

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const addTag = tag => {
    if (!internalTags.find(item => item.id === tag.id)) {
      setInternalTags(previous => [...previous, tag]);
      // noinspection JSIgnoredPromiseFromCall
      loadTags();
    }
  };

  const removeTag = id => {
    setInternalTags(previous => previous.filter(tag => tag.id !== id));
  };

  // Check if a tag is a new one (before save)
  const isNewTag = id => originalTagsIds.indexOf(id) < 0;

  const renderForm = () => (
    <>
      <Attribute name={t("experiences.tagsModal.customerTags")}>
        <div className="tags-list">
          {internalTags.length === 0 && <span className="no-data">{t("experiences.tagsModal.noTag")}</span>}
          {internalTags.map(tag => (
            <Tag
              key={tag.id}
              closable
              onClose={() => removeTag(tag.id)}
              color={isNewTag(tag.id) ? tagTypes.SUCCESS : tagTypes.GEEKBLUE}
            >
              {tag.name}
            </Tag>
          ))}
        </div>
      </Attribute>
      <hr />

      <TagSearchPane excludeTags={internalTags} onTagSelected={addTag} />

      <hr />
      <Attribute name={t("experiences.tagsModal.recent")}>
        <div className="tags-list">
          {availableTags.map(tag => (
            <Tag key={tag.id} onClick={() => addTag(tag)}>
              {tag.name}
            </Tag>
          ))}
        </div>

        {availableTags.length > nbRecentTags && (
          <div className="show-tags-link">
            {/* eslint-disable-next-line */}
            <a onClick={() => setShowAllTags(prev => !prev)}>
              {showAllTags
                ? t("experiences.tagsModal.hideTags")
                : t("experiences.tagsModal.showAllTags", { number: availableTags.length - nbRecentTags })}
            </a>
          </div>
        )}
      </Attribute>
    </>
  );

  const didTagsChanged = (existingTags, newTags) => {
    const existingTagIds = existingTags.map(tag => tag.id);
    const newTagIds = newTags.map(tag => tag.id);
    if (existingTagIds.length !== newTagIds.length) {
      return true;
    }
    return newTagIds.every(tagId => existingTagIds.indexOf(tagId) !== -1) === false;
  };

  return (
    <GuardedModal
      width={ModalWidths.Medium}
      open={open}
      title={t("experiences.tagsModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              onClick={() => getFormProps().onSubmit()}
              loading={working}
              disabled={didTagsChanged(internalTags, tags) === false}
            >
              {t("actions.apply")}
            </Button>
          }
        />
      }
      canBeClosed={() => {
        const initialTags = new Set(tags);
        const currentTags = new Set(internalTags);
        if (
          (initialTags.size === currentTags.size && [...initialTags].every(value => currentTags.has(value))) === false
        ) {
          return t("components.guardedModal.discardChangeQuestion");
        }
        return undefined;
      }}
      onClose={onClose}
    >
      <Form {...getFormProps()}>
        <SpacedContainer className="tags-modal-content">{pageLoading ? <PageLoader /> : renderForm()}</SpacedContainer>
      </Form>
    </GuardedModal>
  );
}

ExperienceTagsModal.propTypes = {
  experienceId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
