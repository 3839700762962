import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ProtectedRoute } from "Legacy/components";
import { AssetsPage } from "./pages";

export default function Assets() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={url} exact>
        <AssetsPage />
      </ProtectedRoute>
      <Route path={`${url}/*`}>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
