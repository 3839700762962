// TODO only for demo, remove later
const userPermission = 3;

class AuthorizationService {
  isAuthorized(minimumPermission = 0) {
    return userPermission >= minimumPermission;
  }
}

export default new AuthorizationService();
