import React from "react";
import * as PropTypes from "prop-types";

import "./ImageSelectorPreview.scss";
import { useTranslation } from "react-i18next";

const fakeImageSrc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const sizes = {
  SMALL: "small",
  LARGE: "large",
};

export default function ImageSelectorPreview({ src, size, alt, className }) {
  const [t] = useTranslation();
  const classNameSuffix = className === undefined ? "" : ` ${className}`;
  return (
    <div className={`image-selector-preview${classNameSuffix}`}>
      <div className={`container ${size === sizes.SMALL ? "small" : "large"}`}>
        <img src={src || fakeImageSrc} className="image-selector-preview" alt={alt || t("fields.previewAlternate")} />
      </div>
    </div>
  );
}

ImageSelectorPreview.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf([sizes.SMALL, sizes.LARGE]),
  alt: PropTypes.string,
  className: PropTypes.string,
};
