import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Experience } from "@ogury/motionly-ws-api/ws";
import { useTranslation } from "react-i18next";

import "./ExperiencePrivacySwitcher.scss";
import { Select } from "@ogury/design-system";
import { LoadDots } from "Legacy/components";
import { computeValueLabels, experiencePermissions, experienceService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";

const { PrivacyEnum } = Experience;
const privacyOptions = computeValueLabels(PrivacyEnum);

export default function ExperiencePrivacySwitcher({ experience, onPrivacyChanged = () => {} }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [privacy, setPrivacy] = useState(experience.privacy);

  const onChangePrivacy = async value => {
    if (experiencePermissions.canChangePrivacy(experience)) {
      setWorking(true);
      try {
        await experienceService.setPrivacy(experience.id, value);
        notificationService.notifySuccess(t("experiences.privacyModal.changePrivacySuccess"));
        setPrivacy(value);
        setWorking(false);
        onPrivacyChanged();
      } catch (error) {
        notificationService.notifyError(error);
        setWorking(false);
      }
    }
  };

  return (
    <div className="privacy-switcher">
      <Select
        value={privacy}
        options={privacyOptions}
        disabled={!experiencePermissions.canChangePrivacy(experience)}
        onChange={onChangePrivacy}
        width={200}
      />
      {working && <LoadDots />}
    </div>
  );
}

ExperiencePrivacySwitcher.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  onPrivacyChanged: PropTypes.func,
};
