import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { Modal } from "@ogury/design-system";

import { PageLoader } from "Legacy/components";
import { experienceDownloadCommands, experienceService } from "Legacy/services";

import "./ExperienceDownloaderModal.scss";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function ExperienceDownloaderModal({ open, experience, command, onCancel = () => {} }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [downloadTitle, setDownloadTitle] = useState();

  const downloadDescription = useCallback(async () => {
    setWorking(true);
    try {
      const description = await experienceService.getDescription(experience.id);
      const descriptionJson = JSON.stringify(description, null, 2);
      FileSaver.saveAs(
        new Blob([descriptionJson], { type: "application/json" }),
        `${experience.name}-description.json`
      );
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
      // In all cases, we wish to close the modal
      onCancel();
    }
  }, [experience, onCancel]);

  const downloadBundle = useCallback(async () => {
    setWorking(true);
    try {
      const contents = await experienceService.getBundle(experience.id);
      FileSaver.saveAs(new Blob([contents], { type: "application/zip" }), `${experience.name}.zip`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
      // In all cases, we wish to close the modal
      onCancel();
    }
  }, [experience, onCancel]);

  const downloadInputs = useCallback(async () => {
    setWorking(true);
    try {
      const contents = await experienceService.getInputs(experience.id);
      const contentsJson = JSON.stringify(contents, undefined, 2);
      FileSaver.saveAs(new Blob([contentsJson], { type: "application/json" }), `${experience.name}-inputs.json`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
      // In all cases, we wish to close the modal
      onCancel();
    }
  }, [experience, onCancel]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const run = async () => {
      switch (command) {
        default:
          throw new Error("Not implemented!");
        case experienceDownloadCommands.DESCRIPTION:
          setDownloadTitle("experiences.download.modal.title.description");
          await downloadDescription();
          break;
        case experienceDownloadCommands.INPUTS:
          setDownloadTitle("experiences.download.modal.title.inputs");
          await downloadInputs();
          break;
        case experienceDownloadCommands.BUNDLE:
          setDownloadTitle("experiences.download.modal.title.bundle");
          await downloadBundle();
          break;
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [command, open, downloadBundle, downloadDescription, downloadInputs]);

  // The modal is a blocking waiting screen if we do not have to display the downloadHtml form
  return (
    <Modal open={open} title={t(downloadTitle)} onCancel={onCancel} width={ModalWidths.Medium}>
      {working && <PageLoader message={t("experiences.download.modal.pleaseWait")} />}
    </Modal>
  );
}

ExperienceDownloaderModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool,
  experience: PropTypes.object,
  command: PropTypes.symbol,
  onCancel: PropTypes.func,
};
