import { locale } from "./index";

export const localeNumberFormat = (number, digits = undefined) =>
  new Intl.NumberFormat(locale, { maximumFractionDigits: digits === undefined ? undefined : digits }).format(number);
export const localePercentageFormat = (number, digits = 2) => {
  // We do not want to display the digits when the number is equal to 0 or 1
  const isEqualTo0or1 = number === 0 || number === 1;
  return new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: isEqualTo0or1 === true ? 0 : digits,
    maximumFractionDigits: isEqualTo0or1 === true ? 0 : digits,
  }).format(number);
};
