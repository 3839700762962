import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@ogury/design-system";
import React, { useState } from "react";
import { ThumbnailContainer, WithCopyButton } from "Legacy/components";
import { path, randomString } from "Legacy/utils";

import { siteService } from "Legacy/services";
import style from "./SiteThumbnail.module.scss";

export default function SiteThumbnail({ site = {}, withLink = true, editable = false }) {
  const [t] = useTranslation();
  const [siteName, setSiteName] = useState(site.name);

  function handleOnLinkClick(event) {
    if (event.target.tagName === "TEXTAREA") {
      event.preventDefault();
    }
  }

  const DetailPageLink = ({ children }) => {
    return (
      <Link onClick={handleOnLinkClick} className={style.nameAndThumbnailLink} to={`${path.SITES}/${site.id}`}>
        {children}
      </Link>
    );
  };

  async function handleOnEditName(value) {
    try {
      const { name } = await siteService.rename(site.id, value);
      setSiteName(name);
    } catch (error) {}
  }
  function renderName() {
    const content = (
      <Typography.P2Strong {...(editable && { editable: { onChange: handleOnEditName } })}>
        {siteName}
      </Typography.P2Strong>
    );

    return withLink ? <DetailPageLink>{content}</DetailPageLink> : content;
  }
  const tumbnailContainer = (
    <ThumbnailContainer key={randomString(6)} url={site.firstExperience?.thumbnailUrl} size="small" />
  );

  return (
    <div className={style.nameAndThumbnailContainer}>
      {withLink ? <DetailPageLink>{tumbnailContainer}</DetailPageLink> : tumbnailContainer}
      <div>
        {renderName()}
        <div className={style.id}>
          <span>{t("fields.id")}&nbsp;&nbsp;&nbsp;</span>
          <span>
            <WithCopyButton text={site.id} />
          </span>
        </div>
        <span className={style.id}>{t("sites.thumbnail.experiences", { COUNT: site.experienceIds?.length || 0 })}</span>
      </div>
    </div>
  );
}

SiteThumbnail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object,
  withLink: PropTypes.bool,
};
