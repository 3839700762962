// Convert filters (UI value) into Filters (API value)
import { Filter } from "@ogury/motionly-ws-api/ws";
import { selectEntry } from "Legacy/components";

const operators = {
  AND: "AND",
  OR: "OR",
};

export const uiToApi = (filters, filterToAttribute, tagsFiltersOperator) => {
  // Join filters based on tags categories : Template, UX, Industry, Favorites, Customer
  // Convert into filter values for the API
  const tempFilters = filters
    .map(filter => {
      const field = filterToAttribute(filter.filter);
      const operator = tagsFiltersOperator(filter.filter);
      // Only tagIds based filter accepts multiple values

      const value = field === Filter.FieldEnum.TagIds ? filter.values.join(",") : filter.values[0];
      return { field, value, operator };
    })
    .filter(item => item.field !== "");

  // Get all tag based filters that have an "OR" operator to merge them
  const tagBasedFilters = tempFilters.filter(
    filter => filter.field === Filter.FieldEnum.TagIds && filter.operator === operators.OR
  );

  // Merge "OR" tag filters values into one
  const allTagsValues = tagBasedFilters.map(filter => filter.value).join(",");

  // Get all other filters and add tag filter if necessary
  const apiFilters = tempFilters
    .filter(filter => filter.field !== Filter.FieldEnum.TagIds || filter.operator === operators.AND)
    .map(filter => ({ field: filter.field, value: filter.value }));

  if (allTagsValues.length > 0) {
    apiFilters.push({ field: Filter.FieldEnum.TagIds, value: allTagsValues });
  }

  return apiFilters;
};

// Convert Filters (API value) into filters (UI value)
export const apiToUi = (filters, tags, tagCategoryToFilter, attributeToFilter) => {
  const uiFilters = [];
  const tagsByCategories = {};

  // We need tags to separate tag filters by category
  tags.forEach(tag => {
    tagsByCategories[tag.id] = tag.category;
  });

  filters.forEach(filter => {
    if (filter.field === Filter.FieldEnum.TagIds) {
      const tagIds = filter.value.split(",");

      // Group tag ids by category
      const valuesByCategory = tagIds.reduce((acc, item) => {
        if (!acc[tagsByCategories[item]]) {
          acc[tagsByCategories[item]] = [];
        }
        acc[tagsByCategories[item]].push(item);
        return acc;
      }, {});

      Object.keys(valuesByCategory).forEach(category => {
        uiFilters.push({ filter: tagCategoryToFilter(category), values: valuesByCategory[category] });
      });

      tagIds.forEach(id => {
        if (!tagsByCategories[id]) {
          // eslint-disable-next-line no-console
          console.debug("The tag with id", id, "was not found in tags list");
        }
      });
    } else {
      uiFilters.push({ filter: attributeToFilter(filter.field), values: [filter.value] });
    }
  });

  return uiFilters;
};

const uiFiltersAreEqual = (filter1 = [], filter2 = []) => {
  if (filter1.length !== filter2.length) {
    return false;
  }
  return filter1.every(f1 => {
    const foundFilter2 = filter2.find(f2 => f2.filter === f1.filter);
    if (foundFilter2) {
      // Filter2 has current f1 filter. Let's check values
      if (foundFilter2.values.length !== f1.values.length) {
        return false;
      }
      return f1.values.every(value => foundFilter2.values.find(v2 => v2 === value));
    }
    return false;
  });
};

const buildOptions = (items, deduplicate = undefined) => {
  const sameNames = [];
  items.forEach(item => {
    if (sameNames[item.name] === undefined) {
      sameNames[item.name] = 1;
    } else {
      sameNames[item.name] += 1;
    }
  });
  const list = items.map(item =>
    selectEntry(item.id, item.name + (sameNames[item.name] > 1 && deduplicate ? ` ${deduplicate(item)}` : ""))
  );
  return list
    .filter((item, index, array) => array.findIndex(searchedItem => item.value === searchedItem.value) === index) // filter duplicated ids
    .sort((object1, object2) => object1.label.localeCompare(object2.label));
};

export default { uiToApi, apiToUi, uiFiltersAreEqual, buildOptions, operators };
