import React from "react";
import * as PropTypes from "prop-types";

import { RadioGroupContext } from "../RadioGroup/RadioGroupContext";
import "./Radio.scss";

export default function Radio({ value, id, children, horizontal = false, ...rest }) {
  const radioId = id || String(Math.random());

  return (
    <RadioGroupContext.Consumer>
      {({ selectedValue, onChange = () => {}, name, disabled }) => {
        const checked = selectedValue !== undefined && selectedValue === value;
        return (
          <div className={horizontal ? "horizontal-radio" : ""}>
            <input
              type="radio"
              name={name}
              aria-checked={checked}
              checked={checked}
              value={value}
              id={radioId}
              className="radio"
              onChange={() => onChange(value)}
              disabled={disabled}
              {...rest}
            />
            <label htmlFor={radioId}>{children}</label>
          </div>
        );
      }}
    </RadioGroupContext.Consumer>
  );
}

Radio.propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};
