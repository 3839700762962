import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./TemplatesDetailPage.scss";
import { templateService } from "Legacy/services";
import { LayoutWithPreviewPane, PageLoader, ProtectedRoute, DetailPageWithLeftNav } from "Legacy/components";
import { useNotificationService } from "Legacy/utils";
import { TemplatesDetailDescriptionPage, TemplatesDetailGeneralPage } from "..";
import { TemplateDetailHeader, TemplatePreview } from "./components";

export default function TemplateDetailPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const { templateId } = useParams();
  const { url } = useRouteMatch();
  const [template, setTemplate] = useState();
  const [selectedDemo, setSelectedDemo] = useState();

  const getData = useCallback(async () => {
    try {
      const result = await templateService.get(templateId, undefined, false);
      setTemplate(result);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
    }
  }, [templateId, t]);

  useEffect(() => {
    getData();
  }, [getData]);

  const nestedPaths = {
    GENERAL: `${url}/general`,
    DESCRIPTION: `${url}/description`,
    CREATIONS: `${url}/creations`,
  };

  const links = [
    { id: 0, path: nestedPaths.GENERAL, label: "templates.sidebar.general" },
    { id: 1, path: nestedPaths.DESCRIPTION, label: "templates.sidebar.description" },
    /*    { id: 2, path: nestedPaths.CREATIONS, label: "templates.sidebar.creations" }, */
  ];

  const renderViewContent = () => (
    <Switch>
      <ProtectedRoute path={nestedPaths.GENERAL}>
        <TemplatesDetailGeneralPage template={template} onDemoSelected={setSelectedDemo} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.DESCRIPTION}>
        <TemplatesDetailDescriptionPage template={template} />
      </ProtectedRoute>
      <Redirect to={{ pathname: nestedPaths.GENERAL }} />
    </Switch>
  );

  return template ? (
    <LayoutWithPreviewPane previewPane={<TemplatePreview demo={selectedDemo} onClose={() => setSelectedDemo()} />}>
      <DetailPageWithLeftNav
        detailHeader={<TemplateDetailHeader template={template} onTemplateUpdated={getData} />}
        links={links}
        isEmpty={false}
        withRightPadding
      >
        {renderViewContent()}
      </DetailPageWithLeftNav>
    </LayoutWithPreviewPane>
  ) : (
    <PageLoader />
  );
}
