import { Application, UserRole } from "@ogury/motionly-ws-api/ws";
import { owningOrganizationEmailDomain } from "Legacy/utils";
import { customerService, userPermissions, userService } from ".";

// Role helpers

const userIsPendingForCustomerId = (user, customerId) => {
  const userRole = user.roles.list.find(role => role.customerId?.toString() === customerId?.toString());
  return userRole !== undefined && userRole.pending === true;
};

const userIsCustomerOwner = (user, customerId) => {
  const userRole = user.roles.list.find(role => role.customerId?.toString() === customerId?.toString());
  // noinspection JSUnresolvedVariable
  return userRole !== undefined && userRole.role === UserRole.RoleEnum.Owner;
};

// noinspection JSUnresolvedVariable
const isOwnerOrAdminOrCreator = userRole =>
  userRole === UserRole.RoleEnum.Owner ||
  userRole === UserRole.RoleEnum.Administrator ||
  userRole === UserRole.RoleEnum.Creator;

const isSuperUser = () => userService.getCurrentUserEmail().endsWith(`@${owningOrganizationEmailDomain}`) === true;

// noinspection JSUnresolvedVariable
export default {
  // Experience ownership transfer
  isOwnerOrAdminOrCreator,
  // Experience creation
  canModifySettings: () => userPermissions.currentUserIsOwnerOrAdmin(),
  canModifyLogo: () => userPermissions.currentUserIsOwnerOrAdmin(),
  canAddNewMembers: () => userPermissions.currentUserIsOwnerOrAdmin(),
  canChangeRoles: user => {
    if (userIsPendingForCustomerId(user, customerService.getCurrentCustomerSummaryId())) {
      return false;
    }
    if (userIsCustomerOwner(user, customerService.getCurrentCustomerSummaryId())) {
      return userPermissions.currentUserIsOwner() && userService.getCurrentUserId() !== user.id;
    }
    return userPermissions.currentUserIsOwnerOrAdmin();
  },
  canRemoveUser: user => {
    if (userIsCustomerOwner(user, customerService.getCurrentCustomerSummaryId())) {
      return false;
    }
    return userPermissions.currentUserIsOwnerOrAdmin();
  },
  canTransferOwnership: user =>
    // We also make sure that the user is not pending withing the current customer
    userPermissions.currentUserIsOwnerOrAdmin() === true ||
    (user.roles.list.find(
      role => role.customerId?.toString() === customerService.getCurrentCustomerSummaryId() && role.pending !== true
    ) === true &&
      userService.getCurrentUserId() === user.id),
  canCreateCustomer: isSuperUser,
  canAccessApplications: userPermissions.currentUserIsOwner,
  canCreateApplication: userPermissions.currentUserIsOwner,
  canRenameApplication: userPermissions.currentUserIsOwner,
  canEditApplication: application =>
    userPermissions.currentUserIsAdmin() &&
    (application.kind === Application.KindEnum.Custom ||
      application.kind === Application.KindEnum.Default ||
      application.kind === Application.KindEnum.Preview),
  canEditApplicationParameters: application =>
    userPermissions.currentUserIsOwner() &&
    (application.kind === Application.KindEnum.Custom ||
      application.kind === Application.KindEnum.Default ||
      application.kind === Application.KindEnum.Preview),
  canEditApplicationAdvancedParameters: () => userPermissions.currentUserIsOwner() && isSuperUser(),
  canDeleteApplication: application =>
    userPermissions.currentUserIsOwner() && application.kind === Application.KindEnum.Custom,
};
