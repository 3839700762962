/**
 * Helper functions to convert filter values from/to api/ui
 */

import { Filter, Tag } from "@ogury/motionly-ws-api/ws";
import { Filters } from "Legacy/utils";

export const siteFilters = {
  FULLTEXT: Symbol("fulltext"),
  TEMPLATE: Symbol("template"),
  BRAND: Symbol("brand"),
  AD_UNIT: Symbol("adUnit"),
  OWNER: Symbol("owner"),
  STATUS: Symbol("status"),
  CUSTOMER_TAGS: Symbol("customerTags"),
};

const filterToAttribute = filter => {
  switch (filter) {
    case siteFilters.TEMPLATE:
    case siteFilters.BRAND:
    case siteFilters.CUSTOMER_TAGS:
      return Filter.FieldEnum.TagIds;
    case siteFilters.AD_UNIT:
      return Filter.FieldEnum.UnitTechnicalId;
    case siteFilters.STATUS:
      return Filter.FieldEnum.State;
    case siteFilters.OWNER:
      return Filter.FieldEnum.Owner;
    default:
      return "";
  }
};

const attributeToFilter = attribute => {
  switch (attribute) {
    case Filter.FieldEnum.Owner:
      return siteFilters.OWNER;
    case Filter.FieldEnum.State:
      return siteFilters.STATUS;
    default:
      return "";
  }
};

const tagCategoryToFilter = category => {
  switch (category) {
    case Tag.CategoryEnum.Customer:
      return siteFilters.CUSTOMER_TAGS;
    case Tag.CategoryEnum.Template:
      return siteFilters.TEMPLATE;
    case Tag.CategoryEnum.Brand:
      return siteFilters.BRAND;
    case Tag.CategoryEnum.Unit:
      return siteFilters.AD_UNIT;
    default:
      return "";
  }
};

const tagsFiltersOperator = filter => {
  switch (filter) {
    case siteFilters.AD_UNIT:
      return Filters.operators.AND;
    default:
      return Filters.operators.OR;
  }
};

// Convert filters (UI value) into Filters (API value)
export const uiToApi = filters => Filters.uiToApi(filters, filterToAttribute, tagsFiltersOperator);

// Convert Filters (API value) into filters (UI value)
export const apiToUi = (filters, tags) => Filters.apiToUi(filters, tags, tagCategoryToFilter, attributeToFilter);

export default { uiToApi, apiToUi };
