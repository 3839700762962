import { Sorting } from "@ogury/motionly-ws-api/ws";
import { sortOrder as sortDirection } from "Legacy/utils/sort";

export const siteSortings = {
  NAME: Symbol("name"),
  UPDATE_DATE: Symbol("updateDate"),
  OWNER_EMAIL: Symbol("owner"),
  STATE: Symbol("state"),
};

const sortingFieldToAttribute = sortingField => {
  switch (sortingField) {
    case Sorting.FieldEnum.Name:
      return siteSortings.NAME;
    case Sorting.FieldEnum.UpdateDate:
      return siteSortings.UPDATE_DATE;
    case Sorting.FieldEnum.OwnerEmail:
      return siteSortings.OWNER_EMAIL;
    default:
      // eslint-disable-next-line no-console
      console.warn(`The sorting field '${sortingField}' has no matching attribute.`);
      return sortingField;
  }
};

const attributeToSortingField = attribute => {
  switch (attribute) {
    case siteSortings.NAME:
      return Sorting.FieldEnum.Name;
    case siteSortings.UPDATE_DATE:
      return Sorting.FieldEnum.UpdateDate;
    case siteSortings.OWNER_EMAIL:
      return Sorting.FieldEnum.OwnerEmail;
    case siteSortings.STATE:
      return Sorting.FieldEnum.State;
    default:
      // eslint-disable-next-line no-console
      console.warn(`The sorting attribute '${String(attribute)}' has no matching Sorting field.`);
      return attribute;
  }
};

const uiToApi = ({ sortBy, sortOrder }) => ({
  field: attributeToSortingField(sortBy),
  ascending: sortOrder === sortDirection.ASC,
});

const apiToUi = sorting => {
  const { field, ascending } =
    sorting === undefined ? { field: attributeToSortingField[siteSortings.UPDATE_DATE], ascending: false } : sorting;
  return {
    sortBy: sortingFieldToAttribute(field),
    sortOrder: ascending ? sortDirection.ASC : sortDirection.DESC,
  };
};

export default { uiToApi, apiToUi };
