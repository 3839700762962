import React from "react";
import * as PropTypes from "prop-types";

import "./SpacedContainer.scss";

/**
 * The various space sizes.
 *
 * @type {Object<String, String>}
 */
export const SpacedUnits = {
  XXSmall: "xxsmall",
  XSmall: "xsmall",
  Small: "small",
  Medium: "medium",
  Large: "large",
  XLarge: "xlarge",
};

export default function SpacedContainer({
  className,
  horizontal = false,
  gap = SpacedUnits.Large,
  withBottom = false,
  center,
  children,
}) {
  return (
    <div
      className={`spaced-container ${gap} ${horizontal === true ? "horizontal" : "vertical"}${
        center === true ? " center" : ""
      }${withBottom === false ? "" : " bottom"}${className === undefined ? "" : ` ${className}`}`}
    >
      {children}
    </div>
  );
}

SpacedContainer.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  center: PropTypes.bool,
  gap: PropTypes.oneOf([
    SpacedUnits.XXSmall,
    SpacedUnits.XSmall,
    SpacedUnits.Small,
    SpacedUnits.Medium,
    SpacedUnits.Large,
    SpacedUnits.XLarge,
  ]),
  withBottom: PropTypes.bool,
  children: PropTypes.node,
};
