import React from "react";
import * as PropTypes from "prop-types";

import "./PageHeader.scss";
import { SpacedContainer, SpacedUnits } from "..";

// viewSwitcherComponent is used to display a transparent Select with its options, for view selection
export default function PageHeader({ additionalComponent, countText, children }) {
  return (
    <div className="page-header">
      <div className="title">
        <SpacedContainer className="left-block" horizontal gap={SpacedUnits.XLarge}>
          {additionalComponent && additionalComponent}
        </SpacedContainer>
        {children !== undefined && (
          <SpacedContainer className="full-page-header-actions" horizontal gap={SpacedUnits.XSmall}>
            {children}
          </SpacedContainer>
        )}
      </div>
      {countText !== undefined && <p className="count">{countText}</p>}
    </div>
  );
}

PageHeader.propTypes = {
  additionalComponent: PropTypes.node,
  countText: PropTypes.string,
  children: PropTypes.node,
};
