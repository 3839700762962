import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiPlayCircleOutline } from "@mdi/js";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperiencePreview.scss";
import { PageLoader, Preview, PreviewPlaceholder } from "Legacy/components";

export default function ExperiencePreview({ experienceId, disabled }) {
  const [t] = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const renderPreviewContainer = () => {
    if (loading) {
      return <PageLoader />;
    }
    return (
      <PreviewPlaceholder disabled={disabled}>
        <div
          role="presentation"
          className="preview-pane-action"
          onClick={() => {
            if (disabled === false) {
              setShowPreview(true);
            }
          }}
        >
          <Icon path={mdiPlayCircleOutline} size={3} />
          <div>{t("experiences.preview.preview")}</div>
        </div>
      </PreviewPlaceholder>
    );
  };

  return showPreview === true ? (
    <Preview experienceId={experienceId} onLoading={setLoading} onClose={() => setShowPreview(false)} closable />
  ) : (
    renderPreviewContainer()
  );
}
ExperiencePreview.propTypes = {
  experienceId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
