import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

import "./FilterBar.scss";
import { Button, Dropdown } from "@ogury/design-system";
import { Form, SpacedContainer, SpacedUnits } from "..";
import { FilterControl, ViewManager } from "./components";

const defaultFilterItemWidth = "200px";

export default function FilterBar({
  preFilter,
  filtersConfig = [],
  filtersEnum,
  defaultFilters = [],
  value = [],
  onChange,
  onCleared,
  onSearch,
  viewManagerConfig,
  searchText,
}) {
  const [t] = useTranslation();

  // Checks if the filter exists in the enum
  const filterExists = useCallback(filterSymbol => Object.values(filtersEnum).indexOf(filterSymbol) > -1, [
    filtersEnum,
  ]);

  /**
   * List of temporary filters to display in the second row of the FilterBar.
   * They don't always have a value
   */
  const [additionalFilters, setAdditionalFilters] = useState([]);

  // Keep existing additionalFilters and add needed additional filters used in value
  useEffect(() => {
    const usedFilters = value.map(v => v.filter);
    const valuedAdditionalFilters = filtersConfig.filter(
      filter =>
        // The filter exists, it is not in defaults, it is used in value
        filterExists(filter.filterKey) &&
        defaultFilters.indexOf(filter.filterKey) < 0 &&
        usedFilters.indexOf(filter.filterKey) > -1
    );

    setAdditionalFilters(previousValue => {
      // Rebuild the previousValue with fresh elements of filtersConfig
      const newValue = previousValue
        .map(filterValue => filtersConfig.find(filter => filter.filterKey === filterValue.filterKey))
        .filter(v => !!v);

      // Merge with valuedAdditionalFilters : add filters not found in previous value
      valuedAdditionalFilters.forEach(filter => {
        if (!previousValue.find(f => f.filterKey === filter.filterKey)) {
          newValue.push(filter);
        }
      });
      return newValue;
    });
  }, [defaultFilters, filterExists, filtersConfig, value]);

  const hasFilters = useMemo(() => value.length > 0 || additionalFilters.length > 0 || searchText, [
    value,
    additionalFilters,
  ]);

  /**
   * Ease access to current filters value by transforming
   * [{ key1: value1 }, { key2: value2 }] into { key1: value1, key2: value2 }
   */
  const valuesByKey = useMemo(() => value.reduce((acc, val) => ({ ...acc, [val.filter]: val.values }), {}), [value]);

  // Get always visible filters among provided keys
  const defaultFiltersConfs = useMemo(
    () =>
      filtersConfig.filter(filter => filterExists(filter.filterKey) && defaultFilters.indexOf(filter.filterKey) > -1),
    [filtersConfig, defaultFilters, filterExists]
  );

  // Filters available into 'More' dropdown
  const availableFilters = useMemo(() => {
    const filtersForDropdown = filtersConfig.filter(filter => {
      const hasFilter = filterExists(filter.filterKey);
      const isDefault = defaultFilters.indexOf(filter.filterKey) > -1;
      const isDisplayed = additionalFilters.find(f => f.filterKey === filter.filterKey);
      return hasFilter && !isDefault && !isDisplayed;
    });

    const addFilter = filterKey => {
      const filterToAdd = filtersConfig.find(f => f.filterKey === filterKey);
      // Make sure the filter is still available
      const filterAvailable = availableFilters.find(f => f.filterKey === filterKey);
      if (filterToAdd && filterAvailable) {
        setAdditionalFilters(prev => [...prev, filterToAdd]);
      }
    };

    return filtersForDropdown.map(f => ({
      label: t(f.label),
      filterKey: f.filterKey,
      onClick: () => addFilter(f.filterKey),
    }));
  }, [filtersConfig, defaultFilters, additionalFilters, filterExists, t]);

  /**
   * Create filter criteria based on the filters widget value
   * @param filters the value of the filters widget
   */
  const buildFiltersCriteria = filters =>
    // Keep only used filters and build criteria with corresponding values
    Object.keys(filtersEnum)
      .filter(k => !!filters[filtersEnum[k]])
      .map(configKey => {
        const filterName = filtersEnum[configKey];
        return { filter: filterName, values: filters[filterName] };
      });

  /**
   * Create and emit a new filter value if a criterion changes
   * The emitted data contains only filters having a value
   * @param {symbol} filterName Name of the filter as declared in the service config
   * @param {Array<string>} fieldValue the value of the filter
   */
  const setFilterValue = (filterName, fieldValue) => {
    const newValuesByKey = { ...valuesByKey, [filterName]: fieldValue };

    // Keep only keys having a non-empty value
    Object.getOwnPropertySymbols(newValuesByKey).forEach(k => {
      if (newValuesByKey[k] && newValuesByKey[k].length === 0) {
        delete newValuesByKey[k];
      }
    });

    const criteria = buildFiltersCriteria(newValuesByKey);
    onChange(criteria);
  };

  const removeFilter = filterKey => {
    const filterToRemove = additionalFilters.find(f => f.filterKey === filterKey);
    if (filterToRemove) {
      // We remove the filter, so we also clear its values
      setFilterValue(filterToRemove.filterKey, []);
      setAdditionalFilters(prev => prev.filter(p => p !== filterToRemove));
    }
  };

  const clearFilters = () => {
    setAdditionalFilters([]);
    onCleared();
  };

  const handleKeyPress = (event, filterKey) => {
    if (event.key === "Enter") {
      removeFilter(filterKey);
    }
  };

  const renderFilterControl = filterConfig => (
    <div className="filter-control" key={String(filterConfig.filterKey)}>
      <FilterControl
        width={filterConfig.width || defaultFilterItemWidth}
        value={valuesByKey[filterConfig.filterKey]}
        onChange={v => setFilterValue(filterConfig.filterKey, v)}
        {...filterConfig}
      />
    </div>
  );

  return (
    <Form className="filter-bar" onSubmit={onSearch}>
      <SpacedContainer className="filter-bar-row" horizontal gap={SpacedUnits.XSmall}>
        {preFilter}
        {defaultFiltersConfs.map(renderFilterControl)}
        <SpacedContainer className="filter-action" horizontal gap={SpacedUnits.XSmall}>
          {defaultFilters.length >= filtersConfig.length ? (
            <></>
          ) : (
            <Dropdown
              trigger="click"
              disabled={availableFilters.length === 0}
              menu={{
                items: availableFilters.map(filter => ({
                  key: String(filter.filterKey),
                  onClick: filter.onClick,
                  label: filter.label,
                })),
              }}
            >
              <Button disabled={availableFilters.length === 0} type="secondary">
                {t("actions.more")}
              </Button>
            </Dropdown>
          )}
          <Button type="secondary" submit>
            {t("actions.search")}
          </Button>
          {viewManagerConfig && <ViewManager {...viewManagerConfig} />}
          <Button type="tertiary" onClick={clearFilters} disabled={!hasFilters}>
            {t("actions.clear")}
          </Button>
        </SpacedContainer>
      </SpacedContainer>

      {additionalFilters.length > 0 && (
        <div className="filter-bar-row">
          {additionalFilters.map(filter => (
            <div key={String(filter.filterKey)} className="removable-filter">
              {renderFilterControl(filter)}
              <div
                className="remove-filter"
                role="button"
                tabIndex={0}
                onClick={() => removeFilter(filter.filterKey)}
                onKeyPress={e => handleKeyPress(e, filter.filterKey)}
                title={t("actions.remove")}
              >
                <Icon path={mdiCloseCircle} size={0.8} color="grey" />
              </div>
            </div>
          ))}
        </div>
      )}
    </Form>
  );
}

FilterBar.propTypes = {
  preFilter: PropTypes.node,
  defaultFilters: PropTypes.arrayOf(PropTypes.symbol),
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      filterKey: PropTypes.symbol,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  filtersEnum: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onCleared: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  viewManagerConfig: PropTypes.shape({
    disabled: PropTypes.bool,
    working: PropTypes.bool,
    currentView: PropTypes.shape({}),
    onClickCreate: PropTypes.func,
    onClickRename: PropTypes.func,
    onClickDelete: PropTypes.func,
  }),
};
