import React from "react";
import { useRouteMatch } from "react-router-dom";

import { ProtectedRoute } from "Legacy/components";
import { ApplicationDetailPage, ApplicationsPage } from "./pages";

export default function Applications() {
  const { url } = useRouteMatch();

  return (
    <div>
      <ProtectedRoute path={url} exact>
        <ApplicationsPage />
      </ProtectedRoute>
      <ProtectedRoute path={`${url}/:applicationId`}>
        <ApplicationDetailPage />
      </ProtectedRoute>
    </div>
  );
}
