import { Sorting } from "@ogury/motionly-ws-api/ws";
import { sortOrder as sortDirection } from "Legacy/utils/sort";

export const experienceSortings = {
  NAME: Symbol("name"),
  STATE: Symbol("state"),
  CREATION_DATE: Symbol("creationDate"),
  UPDATE_DATE: Symbol("updateDate"),
  OWNER_EMAIL: Symbol("owner"),
};

const sortingFieldToAttribute = sortingField => {
  switch (sortingField) {
    case Sorting.FieldEnum.Name:
      return experienceSortings.NAME;
    case Sorting.FieldEnum.State:
      return experienceSortings.STATE;
    case Sorting.FieldEnum.CreationDate:
      return experienceSortings.CREATION_DATE;
    case Sorting.FieldEnum.UpdateDate:
      return experienceSortings.UPDATE_DATE;
    case Sorting.FieldEnum.OwnerEmail:
      return experienceSortings.OWNER_EMAIL;
    default:
      // eslint-disable-next-line no-console
      console.warn(`The sorting field '${sortingField}' has no matching attribute.`);
      return sortingField;
  }
};

const attributeToSortingField = attribute => {
  switch (attribute) {
    case experienceSortings.NAME:
      return Sorting.FieldEnum.Name;
    case experienceSortings.STATE:
      return Sorting.FieldEnum.State;
    case experienceSortings.CREATION_DATE:
      return Sorting.FieldEnum.CreationDate;
    case experienceSortings.UPDATE_DATE:
      return Sorting.FieldEnum.UpdateDate;
    case experienceSortings.OWNER_EMAIL:
      return Sorting.FieldEnum.OwnerEmail;
    default:
      // eslint-disable-next-line no-console
      console.warn(`The sorting attribute '${String(attribute)}' has no matching Sorting field.`);
      return attribute;
  }
};

const uiToApi = ({ sortBy, sortOrder }) => ({
  field: attributeToSortingField(sortBy),
  ascending: sortOrder === sortDirection.ASC,
});

const apiToUi = sorting => {
  const { field, ascending } =
    sorting === undefined
      ? { field: attributeToSortingField[experienceSortings.UPDATE_DATE], ascending: false }
      : sorting;
  return {
    sortBy: sortingFieldToAttribute(field),
    sortOrder: ascending ? sortDirection.ASC : sortDirection.DESC,
  };
};

export default { uiToApi, apiToUi };
