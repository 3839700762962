import React from "react";
import * as PropTypes from "prop-types";
import "./AuthFormContainer.scss";

export default function AuthFormContainer({ children }) {
  return <div className="auth-form-container">{children}</div>;
}
AuthFormContainer.propTypes = {
  children: PropTypes.node,
};
