import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./MembersTable.scss";
import { User } from "@ogury/motionly-ws-api/ws";
import { Button, Dropdown, Table } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import { customerPermissions, customerService } from "Legacy/services";
import { useAuth, useNotificationService } from "Legacy/utils";
import {
  ChangeRoleModal,
  RemoveFromCustomerConfirmationModal,
  UserInfoCell,
  UserTransferOwnershipModal,
} from "./components";

const { StatusEnum } = User;

export default function MembersTable({ emptyState, customerId, members, reload }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const auth = useAuth();

  const tableRows = useMemo(
    () =>
      members.sort(
        (a, b) => (b.id.toString() === auth.currentUser.id.toString()) - (a.id === auth.currentUser.id.toString())
      ),
    [members, auth]
  );
  const [removeFromCustomerConfirmationModal, setRemoveFromCustomerConfirmationModal] = useState();
  const [changeRoleModal, setChangeRoleModal] = useState();
  const [transferOwnershipModal, setTransferOwnershipModal] = useState();

  const getUserRole = user => user.roles.list.find(obj => obj.customerId?.toString() === customerId?.toString());

  const onClickChangeRole = row => {
    setChangeRoleModal(row);
  };

  const onClickRemove = row => {
    setRemoveFromCustomerConfirmationModal(row);
  };

  const onClickTransferOwnership = row => {
    setTransferOwnershipModal(row);
  };

  const handleResendInvitation = async row => {
    try {
      await customerService.sendInvitation(customerId, row.email, getUserRole(row).role);
      notificationService.notifySuccess(t("customers.members.inviteMemberModal.success"));
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  const renderRoleCell = row => <div>{getUserRole(row)?.role || "-"}</div>;

  const renderStatusCell = row => {
    if (row.status === StatusEnum.Disabled) {
      return <div className="member-disabled">{t("customers.members.status.disabled")}</div>;
    }
    if (getUserRole(row)?.pending) {
      return (
        <div>
          <div className="member-pending">Invitation still pending</div>
          {customerPermissions.canAddNewMembers() && (
            <div role="presentation" onClick={event => handleResendInvitation(event, row)} className="typo-link">
              Resend invitation
            </div>
          )}
        </div>
      );
    }
    return <div className="member-onboarded">{t("customers.members.status.onboarded")}</div>;
  };

  const renderActionCell = row => {
    // Edit button + dropdown menu More actions
    const canChangeRoles = customerPermissions.canChangeRoles(row);
    const canRemoveUser = customerPermissions.canRemoveUser(row);
    const canTransferOwnership = customerPermissions.canTransferOwnership(row);
    const menuItems = [
      canChangeRoles && {
        key: "userRole",
        onClick: () => onClickChangeRole(row),
        label: t("customers.members.changeUserRole"),
      },
      canRemoveUser && {
        key: "removeUser",
        onClick: () => onClickRemove(row),
        label: t("customers.members.removeUser"),
      },
      canTransferOwnership && {
        key: "transfer",
        onClick: () => onClickTransferOwnership(row),
        label: t("customers.members.transferExperiences"),
      },
    ];
    return (
      <div className="action-cell">
        <Dropdown
          trigger="click"
          menu={{ items: menuItems }}
          disabled={canChangeRoles === false && canRemoveUser === false && canTransferOwnership === false}
        >
          <Button iconPosition="iconOnly" icon={<More2FillIcon />} type="secondary" />
        </Dropdown>
      </div>
    );
  };

  const columns = [
    {
      title: t("customers.properties.name"),
      key: "name",
      dataIndex: "name",
      render: (_, record) => <UserInfoCell user={record} />,
    },
    {
      title: t("customers.properties.role"),
      key: "role",
      dataIndex: "role",
      render: (_, record) => renderRoleCell(record),
    },
    {
      title: t("customers.properties.status"),
      key: "status",
      dataIndex: "status",
      render: (_, record) => renderStatusCell(record),
    },
    { id: "actions-col", key: "action", render: (_, record) => renderActionCell(record) },
  ];

  const onChangeRoleSuccess = () => {
    setChangeRoleModal(false);
    reload(true);
  };

  const onRemoveFromCustomerSuccess = () => {
    setRemoveFromCustomerConfirmationModal(false);
    reload(true);
  };

  const onUserTransferOwnershipSuccess = () => {
    setTransferOwnershipModal(false);
  };

  return (
    <div className="members-table">
      <Table columns={columns} dataSource={tableRows} emptyState={emptyState} />
      <RemoveFromCustomerConfirmationModal
        open={removeFromCustomerConfirmationModal}
        user={removeFromCustomerConfirmationModal}
        customerId={customerId}
        onClose={() => setRemoveFromCustomerConfirmationModal()}
        onSuccess={onRemoveFromCustomerSuccess}
      />
      {changeRoleModal && (
        <ChangeRoleModal
          open={changeRoleModal}
          user={changeRoleModal}
          role={getUserRole(changeRoleModal).role}
          customerId={customerId.toString()}
          onClose={() => setChangeRoleModal()}
          onSuccess={onChangeRoleSuccess}
        />
      )}
      <UserTransferOwnershipModal
        open={transferOwnershipModal}
        user={transferOwnershipModal}
        customerId={customerId}
        onClose={() => setTransferOwnershipModal()}
        onSuccess={onUserTransferOwnershipSuccess}
      />
    </div>
  );
}

MembersTable.propTypes = {
  customerId: PropTypes.string,
  // TODO add details for members object
  members: PropTypes.arrayOf(PropTypes.shape({})),
  reload: PropTypes.func.isRequired,
  emptyState: PropTypes.any,
};
