import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import "./CustomerDetailMembersPage.scss";
import { Button, Empty } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import { useNotificationService, usePageDataReducerWithFunction } from "Legacy/utils";
import { customerPermissions, customerService } from "Legacy/services";
import { PageHeader, PageLoader } from "Legacy/components";
import { InviteMemberModal, MembersTable } from "./components";

const loadEntities = async forceFetch => {
  const list = (await customerService.listCurrentCustomerUsers(forceFetch)).sort((object1, object2) => {
    // Caution, the "name" property is undefined for invitees
    if (object1.name !== undefined && object2.name !== undefined) {
      return object1.name.localeCompare(object2.name);
    }
    return object1.email.localeCompare(object2.email);
  });
  return { list, totalCount: list.length };
};

export default function CustomerDetailMembersPage({ customer }) {
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [pageState, reloadMembers] = usePageDataReducerWithFunction("list", {}, loadEntities);
  const [t] = useTranslation();
  const notificationService = useNotificationService();

  const onClickRefresh = async () => {
    try {
      await reloadMembers(true);
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    reloadMembers(false);
  }, [reloadMembers]);

  const onSendInvitationSuccess = async () => {
    setShowInviteMemberModal(false);
    await reloadMembers(true);
  };

  const renderPageBody = () => (
    <MembersTable
      customerId={customer.id.toString()}
      members={pageState.list}
      reload={reloadMembers}
      emptyState={
        pageState.errorMessage && (
          <Empty
            title={t("customers.members.loadingError")}
            description={pageState.errorMessage}
            buttonText={t("actions.reload")}
            onClick={reloadMembers}
          />
        )
      }
    />
  );
  return (
    <div className="members-page">
      <PageHeader
        title={t("customers.members.title")}
        countText={pageState.count === -1 ? "" : t("customers.members.count", { count: pageState.count })}
      >
        <PageLoader inline active={pageState.loading} />
        <Button onClick={onClickRefresh} type="secondary" iconPosition="iconOnly" icon={<RefreshLineIcon />} />
        {customerPermissions.canAddNewMembers() && (
          <Button
            onClick={() => setShowInviteMemberModal(true)}
            iconPosition="left"
            icon={<AddLineIcon />}
            type="secondary"
          >
            {t("customers.members.inviteMember")}
          </Button>
        )}
        <InviteMemberModal
          open={showInviteMemberModal}
          customer={customer}
          onClose={() => setShowInviteMemberModal(false)}
          onSendInvitationSuccess={onSendInvitationSuccess}
        />
      </PageHeader>

      {renderPageBody()}
    </div>
  );
}
CustomerDetailMembersPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customer: PropTypes.object,
};
