import React from "react";
import * as PropTypes from "prop-types";

import "./UserInfoCell.scss";
import { randomString, useAuth } from "Legacy/utils";
import { ImageWithFallback } from "Legacy/components";

import sampleAvatar from "Legacy/assets/img/sampleAvatar.png";
import User from "Legacy/assets/img/user.png";

export default function UserInfoCell({ user }) {
  const auth = useAuth();

  return (
    <div className="user-info-cell">
      <div className="avatar-img-thumb-container">
        <ImageWithFallback key={randomString(6)} src={user.avatarUrl} alt="thumbnail" fallbackSrc={sampleAvatar} />
      </div>
      <div>
        <h3 className="user-info-cell-name">{user.name}</h3>
        <div className="user-info-cell-email">{user.email}</div>
      </div>
      {user.id === auth.currentUser.id && <img className="user-info-cell-current-user" alt="Current user" src={User} />}
    </div>
  );
}

UserInfoCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};
