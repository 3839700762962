import React, { useEffect } from "react";
import * as PropTypes from "prop-types";

import "./TooltipRightPane.scss";
import { Button } from "@ogury/design-system";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import { OPENED_BODY_CLASS_NAME } from "Legacy/utils/classes";

export default function TooltipRightPane({ title, longHtmlContent, onClose }) {
  useEffect(() => {
    let fromModal = false;
    if (document.body.classList.contains(OPENED_BODY_CLASS_NAME)) {
      fromModal = true;
    } else {
      document.body.classList.add(OPENED_BODY_CLASS_NAME);
    }
    return () => !fromModal && document.body.classList.remove(OPENED_BODY_CLASS_NAME);
  }, []);

  return (
    <div className="tooltip-overlay">
      <div className="tooltip-container">
        <div className="tooltip-header">
          <div className="tooltip-title">{title || "Help"}</div>
          <Button size="small" onClick={onClose} type="tertiary" icon={<CloseLineIcon />} iconPosition="iconOnly" />
        </div>
        <div className="tooltip-content">{longHtmlContent}</div>
      </div>
    </div>
  );
}

TooltipRightPane.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  longHtmlContent: PropTypes.node,
};
