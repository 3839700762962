import React from "react";
import * as PropTypes from "prop-types";

import "./ExperienceRatio.scss";
import { localeNumberFormat } from "Legacy/utils";
import { areRatioEquals, findBestRatioItem, helpers } from "Legacy/services";

export default function ExperienceRatio({ ratio, multiline = true }) {
  const builtInRatioItem = findBestRatioItem(ratio);
  let name;
  if (builtInRatioItem !== undefined) {
    name = (areRatioEquals(ratio, builtInRatioItem.value, true) === true ? "" : "\u2248 ") + builtInRatioItem.name;
  }
  const roundedValue = localeNumberFormat(ratio, helpers.constants.ratioPrecisionLimit);
  return (
    <div className="experience-ratio">
      <span className={`number-${multiline === true ? "multi" : "single"}`}>{roundedValue}</span>
      {name !== undefined && (
        <span className="label">
          {multiline === true && <br />}
          {multiline === false && "\uFE52"}
          {name}
        </span>
      )}
    </div>
  );
}

ExperienceRatio.propTypes = {
  ratio: PropTypes.number.isRequired,
  multiline: PropTypes.bool,
};
