import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@ogury/design-system";
import FileSettingsLineIcon from "remixicon-react/FileSettingsLineIcon";
import {
  CallbacksModal,
  DetailPage,
  Form,
  FormControl,
  JavaScriptCode,
  PageHeader,
  SpacedContainer,
  SpacedUnits,
} from "Legacy/components";
import { bufferToString, useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { applicationService, customerPermissions, helpers } from "Legacy/services";

const inputsWidth = "100%";
const javaScriptCode = `function callbacks()
{

  var logPrefix = "[MTLY] > ";

  return {
    initialize: function (customerId, resolve, reject)
    {
      console.debug(logPrefix + "Initializing the application");
      resolve();
    },
    onReady: function (customerId)
    {
      console.debug(logPrefix + "The application is now ready");
    },
    placeholders: function (customerId, resolve, reject)
    {
      console.debug(logPrefix + "Computing additional placeholders");
      resolve([]);
    },
    onMetricEvent: function (experienceIds, experienceContext, event, scoreId, level)
    {
      console.debug(logPrefix + "The event '" + event + "' has been emitted");
      return true;
    },
    onAction: function (experienceIds, experienceContext, action, onActionCallbacksResult)
    {
      console.debug(logPrefix + "The action '" + action.id + "' has been triggered");
      return onActionCallbacksResult;
    }
  };

}
`;

export default function ApplicationDetailCallbacksPage({ application, reloadApplication }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [initialCallbacks, setInitialCallbacks] = useState("");
  const [callbacks, setCallbacks] = useState("");
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [callbacksModal, setCallbacksModal] = useState(false);

  const canEditApplication = customerPermissions.canEditApplication(application);

  const onSaveOrDelete = async formValue => {
    const isSave = formValue !== undefined;
    setWorking(true);
    const setter = isSave === true ? setSaving : setDeleting;
    setter(true);
    try {
      await applicationService.setCallbacks(application.id, formValue.process, isSave === true ? callbacks : undefined);
      reloadApplication();
      notificationService.notifySuccess(
        undefined,
        t(`applications.callbacksTab.${isSave === true ? "savedCallbacksSuccess" : "deletedCallbacksSuccess"}`)
      );
    } catch (error) {
      notificationService.notifyError(error, t("applications.callbacksTab.title"));
    } finally {
      setter(false);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: {
      file: null,
      process: true,
    },
    fields: {
      file: undefined,
      process: [
        {
          name: validators.IS_REQUIRED,
        },
      ],
    },
    onSubmit: onSaveOrDelete,
  };
  const { getFormProps, getFieldProps } = useFormValidation(formConfig);
  const fileFieldProps = getFieldProps("file");

  useEffect(() => {
    const getData = async () => {
      setWorking(true);
      try {
        const applicationCallbacks = await applicationService.getCallbacks(application.id);
        setInitialCallbacks(applicationCallbacks);
        setCallbacks(applicationCallbacks);
      } finally {
        setWorking(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  return (
    <>
      <DetailPage>
        <PageHeader title={t("applications.callbacksTab.title")}>
          <Button
            type="secondary"
            iconPosition="iconOnly"
            icon={<FileSettingsLineIcon />}
            tooltipText={t("applications.callbacksTab.modalTooltip")}
            onClick={() => {
              setCallbacksModal(true);
            }}
          />
        </PageHeader>
        <Form {...getFormProps()}>
          <SpacedContainer withBottom>
            <FormControl
              id="file"
              type="file"
              accept="text/javascript"
              width={inputsWidth}
              disabled={working}
              required
              {...fileFieldProps}
              onChange={value => {
                setCallbacks(bufferToString(value.buffer));
              }}
            />
            {callbacks !== undefined && callbacks !== "" && (
              <>
                <FormControl
                  id="callbacks"
                  label={t("fields.javascriptSource")}
                  width={inputsWidth}
                  disabled={working}
                  required
                >
                  {() => <JavaScriptCode code={callbacks} fileName={helpers.applicationCallbacksFileName} />}
                </FormControl>
                {callbacks !== initialCallbacks && (
                  <FormControl
                    id="process"
                    type="checkbox"
                    label={t("fields.process")}
                    tooltipText={t("fields.processTooltip")}
                    width={inputsWidth}
                    disabled={working}
                    required
                    {...getFieldProps("process")}
                  />
                )}
              </>
            )}
          </SpacedContainer>
          <SpacedContainer horizontal gap={SpacedUnits.Small}>
            {callbacks !== "" && (
              <Button
                type="danger"
                onClick={async () => {
                  await onSaveOrDelete(undefined);
                }}
                loading={deleting}
                disabled={working || canEditApplication === false}
              >
                {t("actions.delete")}
              </Button>
            )}
            <Button submit type="primary" loading={saving} disabled={callbacks === initialCallbacks}>
              {t("actions.save")}
            </Button>
          </SpacedContainer>
        </Form>
      </DetailPage>
      <CallbacksModal
        open={callbacksModal}
        title={t("applications.callbacksTab.modal.title")}
        explanation={t("applications.callbacksTab.modal.explanation")}
        fileName={helpers.applicationCallbacksFileName}
        javaScriptCode={javaScriptCode}
        onClose={() => {
          setCallbacksModal(false);
        }}
      />
    </>
  );
}

ApplicationDetailCallbacksPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object.isRequired,
  reloadApplication: PropTypes.func.isRequired,
};
