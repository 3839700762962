import { theme } from "@ogury/design-system";
import i18n from "i18next";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";
import ShieldUserLineIcon from "remixicon-react/ShieldUserLineIcon";
import ShareForwardLineIcon from "remixicon-react/ShareForwardLineIcon";
import ShareLineIcon from "remixicon-react/ShareLineIcon";
import LiveLineIcon from "remixicon-react/LiveLineIcon";
import ArrowGoForwardLineIcon from "remixicon-react/ArrowGoForwardLineIcon";
import ShieldCrossLineIcon from "remixicon-react/ShieldCrossLineIcon";
import StackshareLineIcon from "remixicon-react/StackshareLineIcon";
import ProfileLineIcon from "remixicon-react/ProfileLineIcon";
import CodeViewIcon from "remixicon-react/CodeViewIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import React from "react";
import FileTransferLineIcon from "remixicon-react/FileTransferLineIcon";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import LinksLineIcon from "remixicon-react/LinksLineIcon";
import VideoLineIcon from "remixicon-react/VideoLineIcon";
import { Environment, experiencePermissions } from "Legacy/services/index";

const experienceIconsStyle = {
  color: theme.colors.secondary.dark,
  size: theme.iconsSize.icon_s,
};

export default {
  Design: {
    key: "design",
    title: i18n.t("actions.design"),
    icon: <PencilLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.shouldSeeEditButton,
  },
  Preview: {
    key: "preview",
    title: i18n.t("actions.preview"),
    icon: <EyeLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canPreview,
  },
  Duplicate: {
    key: "duplicate",
    title: i18n.t("actions.duplicate"),
    icon: <FileCopyLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canDuplicate,
  },
  AssignAdUnit: {
    key: "assignadunit",
    title: i18n.t("actions.assignAdUnit"),
    icon: <ShieldUserLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canAssignAdUnit,
  },
  DemoPageLink: {
    key: "demopagelink",
    title: i18n.t("actions.demoPageLink"),
    icon: <ExternalLinkLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canSeeDemoPageLink,
  },
  CreativeConsoleLink: {
    key: "creativeconsolelink",
    title: i18n.t("actions.creativeConsoleLink"),
    icon: <LinksLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canSeeCreativeConsoleLink,
  },
  MoveTo: {
    key: "moveto",
    title: i18n.t("actions.moveTo"),
    icon: <ShareForwardLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canMoveTo,
  },
  Share: {
    key: "share",
    title: i18n.t("actions.share"),
    icon: <ShareLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.sharing.canShare,
  },
  Publish: {
    key: "publish",
    title: i18n.t("actions.publish"),
    icon: <LiveLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canPublish,
  },
  PublishOver: {
    key: "publishover",
    title: i18n.t("actions.publishOver"),
    icon: <ArrowGoForwardLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canPublishOver,
  },
  Transfer: {
    key: "transfer",
    title: i18n.t("actions.transferOwnership"),
    icon: <FileTransferLineIcon {...experienceIconsStyle} />, // Icon not provided
    hasPermission: experiencePermissions.canTransferOwnership,
  },
  Labels: {
    key: "labels",
    title: i18n.t("actions.manageTags"),
    icon: <ShieldCrossLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canManageTags,
  },
  TrackingAndRedirection: {
    key: "trackingandredirection",
    title: i18n.t("actions.externalTracking"),
    icon: <StackshareLineIcon {...experienceIconsStyle} />,
    hasPermission: () => true,
  },
  ThirdPartyTag: {
    key: "thirdpartytag",
    title: i18n.t("experiences.embed.thirdParty"),
    icon: <ProfileLineIcon {...experienceIconsStyle} />,
    hasPermission: experience => experiencePermissions.canEmbed(experience, Environment.ThirdParty),
  },
  VpaidURL: {
    key: "vpaidUrl",
    title: i18n.t("experiences.embed.vpaidUrl"),
    icon: <VideoLineIcon {...experienceIconsStyle} />,
    hasPermission: experience => experiencePermissions.canUseVpaidURL(experience),
  },
  HTML5Bundle: {
    key: "html5bundle",
    title: i18n.t("experiences.embed.html5bundle"),
    icon: <CodeViewIcon {...experienceIconsStyle} />,
    hasPermission: experience => experiencePermissions.canEmbed(experience, Environment.HTML5Bundle),
  },
  Delete: {
    key: "delete",
    title: i18n.t("actions.delete"),
    icon: <DeleteBinLineIcon {...experienceIconsStyle} />,
    hasPermission: experiencePermissions.canDelete,
  },
};
