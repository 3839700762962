export const copyText = text => navigator.clipboard.writeText(text);

export const copyElement = element => {
  const activeSheets = Array.prototype.slice.call(document.styleSheets).filter(sheet => sheet.disabled === false);
  document.body.appendChild(element);
  window.getSelection().removeAllRanges();
  const range = document.createRange();
  range.selectNode(element);
  window.getSelection().addRange(range);
  activeSheets.forEach(activeSheet => {
    // eslint-disable-next-line no-param-reassign
    activeSheet.disabled = true;
  });
  document.execCommand("copy");
  activeSheets.forEach(activeSheet => {
    // eslint-disable-next-line no-param-reassign
    activeSheet.disabled = false;
  });
  document.body.removeChild(element);
};
