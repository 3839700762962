import React from "react";
import { useTranslation } from "react-i18next";

import "./NotFoundPage.scss";
import { EmptyState } from "../../../../components";

export default function NotFoundPage() {
  const [t] = useTranslation();
  return (
    <div className="not-found-page-container">
      <EmptyState title={t("app.notFound.title")} subtitle={t("app.notFound.subtitle")} />
    </div>
  );
}
