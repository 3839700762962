import MotionlyApiService from "./MotionlyApiService";
import { customerService, unitService } from ".";

class UnitService {
  allEntities = [];
  loadingPromise = null;

  /**
   * Initialize function is called in motionlyservice.initialize() , at the initialization of the app.
   * The UI is not accessible until this function ends, so it means that the unit list always exists when it is called by other services.
   * @returns {Promise<*[]>}
   */
  async initialize() {
    if (this.allEntities.length === 0) {
      if (!this.loadingPromise) {
        this.loadingPromise = MotionlyApiService.unitApi().list(customerService.getCurrentCustomerSummaryId());
      }
      this.allEntities = await this.loadingPromise;
    }
    return this.allEntities;
  }

  clearCache() {
    this.allEntities = [];
    this.loadingPromise = null;
    this.initialize();
  }

  list() {
    return this.allEntities;
  }

  getUnitFromTechnicalId(technicalId) {
    const units = this.list();
    return units.find(unit => unit.technicalId === technicalId);
  }

  getUnitNameFromTechnicalId(technicalId) {
    const unit = this.getUnitFromTechnicalId(technicalId);
    if (unit) {
      return unit.name;
    }
  }

  getUnitsNamesFromUnits(units) {
    return units?.map(unit => {
      if (unit.name) {
        return unit.name;
      }
      return unitService.getUnitNameFromTechnicalId(unit.technicalId);
    });
  }

  async getUnitsForSpecificRatio(ratio) {
    const units = this.list();
    return units.filter(unit => unit.properties.some(property => property.ratio === ratio));
  }
}

export default new UnitService();
