import React from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./BackLinkButton.scss";
import { Button } from "@ogury/design-system";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";

// eslint-disable-next-line no-unused-vars
export default function BackLinkButton({ path }) {
  const history = useHistory();

  const go = () => {
    if (path) {
      history.push(path);
    } else {
      history.go(-1);
    }
  };

  return <Button type="secondary" icon={<ArrowLeftSLineIcon />} iconPosition="iconOnly" onClick={go} />;
}

BackLinkButton.propTypes = {
  path: PropTypes.string,
};
