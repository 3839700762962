import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";

import { Input } from "@ogury/design-system";
import { disableFeature, useDebouncedCallback } from "Legacy/utils";

export default function SearchInput({
  value,
  onChange = () => {},
  placeholder,
  width = "300px",
  debounceDelay = 0,
  ...rest
}) {
  const [search, setSearch] = useState(value);
  const setValueToDebounce = useDebouncedCallback(onChange, debounceDelay);
  useEffect(() => {
    setSearch(value);
  }, [value]);

  const onSearchChanged = searchValue => {
    setSearch(searchValue);
    setValueToDebounce(searchValue);
  };

  return (
    <Input.Search
      value={search}
      onChange={value => onSearchChanged(value)}
      onKeyDown={event => {
        // If we want to swallow the "Enter" key, remove the forthcoming test
        if (disableFeature === true) {
          if (event.key === "Enter") {
            // We do not want the "Enter" key to cause any effect
            event.preventDefault();
          }
        }
      }}
      placeholder={placeholder}
      style={{ width }}
      {...rest}
    />
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  width: PropTypes.string,
  debounceDelay: PropTypes.number,
};
