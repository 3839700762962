import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";

import { Form, FormControl, GuardedModal, ImageSelectorPreview } from "Legacy/components";
import { useAuth, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import "./UploadImageModal.scss";
import { userService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "400px";

export default function UploadImageModal({ open, user, onCancel }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const auth = useAuth();

  const onUpload = async formValue => {
    setWorking(true);
    try {
      const freshUser = await userService.setAvatar(user.id, formValue.image?.buffer);
      auth.updateCachedUser(freshUser);
      notificationService.notifySuccess(t("messages.saveSuccess"));
      onCancel();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: {
      image: null,
    },
    fields: {
      image: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onUpload,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const imageFieldProps = getFieldProps("image");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Large}
      title={t("users.profile.uploadImageModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" loading={working} onClick={() => getFormProps().onSubmit()}>
              {t("actions.save")}
            </Button>
          }
        />
      }
      canBeClosed={() => (imageFieldProps.value !== null ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onCancel}
    >
      <Typography.P2Regular as="p">{t("users.profile.uploadImageModal.explanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <FormControl
          type="file"
          label={t("users.profile.uploadImageModal.file")}
          id="logoFile"
          accept="image/*"
          width={inputsWidth}
          required
          {...imageFieldProps}
        />
        {imageFieldProps.value ? (
          <div className="image-preview">
            <div className="label">
              <span>{t("fields.selectedFilename")}</span>
              <span className="selected-file-name">{imageFieldProps.value?.selectedFile?.name}</span>
            </div>
            <div className="label">{t("fields.preview")}</div>
            <ImageSelectorPreview src={imageFieldProps.value?.selectedFilePreview} />
          </div>
        ) : (
          <ImageSelectorPreview />
        )}
      </Form>
    </GuardedModal>
  );
}

UploadImageModal.propTypes = {
  user: PropTypes.object,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
};
