import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";
import { applicationService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function DeleteApplicationModal({ application, onClose, onSuccess, open }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async () => {
    try {
      setWorking(true);
      await applicationService.delete(application.id);
      notificationService.notifySuccess(t("applications.deleteModal.success"));
      // We need to invoke the "setWorking" state setter before invoking the "onSuccess" callback, because the callback changes the navigation URL
      setWorking(false);
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("applications.deleteModal.errorTitle"));
      setWorking(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      open={open}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onSubmit} type="danger" loading={working}>
              {t("actions.delete")}
            </Button>
          }
        />
      }
      title={t("experiences.deleteModal.title")}
      locked={working}
    >
      {t("applications.deleteModal.explanation")}
    </Modal>
  );
}

DeleteApplicationModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
