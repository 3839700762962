import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "@ogury/design-system";
import { Form, FormControl } from "Legacy/components";
import { useFormValidation, validators } from "Legacy/utils";

const initialFormValue = {
  name: "",
};

export default function JoinForm({ working, onInvitationAccepted }) {
  const [t] = useTranslation();

  const acceptInvitation = formValue => {
    onInvitationAccepted(formValue);
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: acceptInvitation,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);
  return (
    <Form {...getFormProps()}>
      <FormControl
        id="name"
        label={t("fields.name")}
        placeholder={t("fields.namePlaceholder")}
        required
        {...getFieldProps("name")}
      />
      <div className="auth-form-actions">
        <Button type="primary" block loading={working} submit>
          {t("auth.join.action")}
        </Button>
      </div>
    </Form>
  );
}
JoinForm.propTypes = {
  working: PropTypes.bool,
  onInvitationAccepted: PropTypes.func,
};
