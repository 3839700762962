import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./CustomerDetailPage.scss";
import { customerService } from "Legacy/services";
import { LayoutWithLeftNav, PageLoader, ProtectedRoute } from "Legacy/components";
import { disableFeature, useNotificationService } from "Legacy/utils";
import { CustomerDetailLicensePage, CustomerDetailMembersPage, CustomerDetailSettingsPage } from "..";

export default function CustomerDetailPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [customer, setCustomer] = useState();

  const getData = useCallback(async () => {
    try {
      const result = await customerService.getCurrentCustomerData();
      setCustomer(result);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
    }
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  const nestedPaths = {
    SETTINGS: `${url}`,
    MEMBERS: `${url}/members`,
    LICENSE: `${url}/license`,
  };

  const links = [
    { id: 0, path: nestedPaths.SETTINGS, label: "customers.sidebar.settings" },
    { id: 1, path: nestedPaths.MEMBERS, label: "customers.sidebar.members" },
  ];
  if (disableFeature) {
    links.push({ id: 3, path: nestedPaths.LICENSE, label: "customers.sidebar.license" });
  }

  const renderViewContent = () => (
    <Switch>
      <ProtectedRoute exact path={nestedPaths.SETTINGS}>
        <CustomerDetailSettingsPage customer={customer} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.MEMBERS}>
        <CustomerDetailMembersPage customer={customer} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.LICENSE}>
        <CustomerDetailLicensePage customer={customer} />
      </ProtectedRoute>
      <Redirect to={{ pathname: nestedPaths.SETTINGS }} />
    </Switch>
  );

  return (
    <LayoutWithLeftNav links={links} currentPath={location.pathname}>
      <div>{customer ? renderViewContent() : <PageLoader />}</div>
    </LayoutWithLeftNav>
  );
}
