import React from "react";
import * as PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import "./DetailPageWithLeftNav.scss";
import { LayoutWithLeftNav, PageLoader } from "../../index";

export default function DetailPageWithLeftNav({ detailHeader, links, isEmpty, withRightPadding, children }) {
  const location = useLocation();
  if (isEmpty === true) {
    return <PageLoader />;
  }
  return (
    <div className="detail-page-with-left-nav">
      <div className={`header${withRightPadding === false ? "" : " header-margin"}`}>{detailHeader}</div>
      <div className="left-nav">
        <LayoutWithLeftNav links={links} currentPath={location.pathname} withDetailHeader>
          {children}
        </LayoutWithLeftNav>
      </div>
    </div>
  );
}

DetailPageWithLeftNav.propTypes = {
  detailHeader: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.array.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  withRightPadding: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
