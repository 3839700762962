import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter, Space, Typography } from "@ogury/design-system";
import { SiteThumbnail } from "Legacy/components";
import { siteService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function SiteDeleteModal({ open, site, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async () => {
    try {
      setWorking(true);
      await siteService.deleteSite(site.id);
      notificationService.notifySuccess(t("sites.deleteModal.success"));
      onSuccess();
      onClose();
    } catch (error) {
      notificationService.notifyError(error, t("sites.deleteModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  return (
    <Modal
      width={ModalWidths.Medium}
      open={open}
      title={t("sites.deleteModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onSubmit} type="danger" loading={working}>
              {t("actions.delete")}
            </Button>
          }
        />
      }
      onCancel={onClose}
    >
      <Space direction="vertical" size="m">
        <Typography.P2Regular>{t("sites.deleteModal.explanation")}</Typography.P2Regular>
        <SiteThumbnail site={site} withLink={false} />
      </Space>
    </Modal>
  );
}

SiteDeleteModal.propTypes = {
  open: PropTypes.bool,
  site: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
