export const mtlStarShootingOutline =
  "M23 7.5L16.53 6.96L14 1L11.47 6.96L5 7.5L9.9 11.77L8.44 18.1L14 14.74L19.56 18.1L18.09 11.77L23 7.5M16.14 12.23L16.54 13.94L15.03 13.03L14 12.41L12.97 13.03L11.46 13.94L11.85 12.23L12.13 11.05L11.22 10.26L9.88 9.1L11.64 8.95L12.84 8.85L13.31 7.74L14 6.12L14.69 7.74L15.16 8.85L16.36 8.95L18.11 9.1L16.78 10.26L15.86 11.05L16.14 12.23M1.16 12C.861 11.5 .989 10.89 1.45 10.59L4.18 8.79L5.75 10.15L2.55 12.26C2.38 12.37 2.19 12.43 2 12.43C1.68 12.43 1.36 12.27 1.16 12M1.45 20.16L7.31 16.31L7 17.76L6.66 19.13L2.55 21.84C2.38 21.95 2.19 22 2 22C1.68 22 1.36 21.84 1.16 21.55C.861 21.09 .989 20.47 1.45 20.16M7.32 11.5L8.24 12.31L7.97 13.5L2.55 17.05C2.38 17.16 2.19 17.21 2 17.21C1.68 17.21 1.36 17.06 1.16 16.76C.861 16.3 .989 15.68 1.45 15.38L7.32 11.5Z";

export const mtlAdd =
  "M11.6667 4C11.1144 4 10.6667 4.44772 10.6667 5V10.6667H5C4.44772 10.6667 4 11.1144 4 11.6667V12.3333C4 12.8856 4.44772 13.3333 5 13.3333H10.6667V19C10.6667 19.5523 11.1144 20 11.6667 20H12.3333C12.8856 20 13.3333 19.5523 13.3333 19V13.3333H19C19.5523 13.3333 20 12.8856 20 12.3333V11.6667C20 11.1144 19.5523 10.6667 19 10.6667H13.3333V5C13.3333 4.44772 12.8856 4 12.3333 4H11.6667Z";
export const mtlApp =
  "M9.65079 6.75927C10.0701 6.39985 10.1187 5.76855 9.75926 5.34923C9.39983 4.9299 8.76853 4.88134 8.34921 5.24076L1.34921 11.2408C1.12756 11.4307 1 11.7081 1 12C1 12.2919 1.12756 12.5693 1.34921 12.7593L8.34921 18.7593C8.76853 19.1187 9.39983 19.0701 9.75926 18.6508C10.1187 18.2315 10.0701 17.6002 9.65079 17.2408L3.53659 12L9.65079 6.75927ZM14.3492 17.2408C13.9299 17.6002 13.8813 18.2315 14.2407 18.6508C14.6002 19.0701 15.2315 19.1187 15.6508 18.7593L22.6508 12.7593C22.8724 12.5693 23 12.2919 23 12C23 11.7081 22.8724 11.4307 22.6508 11.2408L15.6508 5.24076C15.2315 4.88134 14.6002 4.9299 14.2407 5.34923C13.8813 5.76855 13.9299 6.39985 14.3492 6.75927L20.4634 12L14.3492 17.2408Z";
export const mtlBookmark =
  "M18 20L12 15.5556L6 20V5.77778C6 5.30628 6.18061 4.8541 6.5021 4.5207C6.82359 4.1873 7.25963 4 7.71429 4H16.2857C16.7404 4 17.1764 4.1873 17.4979 4.5207C17.8194 4.8541 18 5.30628 18 5.77778V20Z";
export const mtlBuild =
  "M6 12C5.85294 12 5.73039 11.9022 5.68137 11.7555C5.14216 9.57946 3.40196 7.84352 1.2451 7.33007C1.09804 7.30562 1 7.15892 1 7.01222C1 6.86553 1.09804 6.74328 1.2451 6.69438C3.45098 6.15648 5.14216 4.44499 5.68137 2.2445C5.70588 2.0978 5.85294 2 6 2C6.14706 2 6.29412 2.0978 6.31863 2.2445C6.83333 4.44499 8.54902 6.15648 10.7549 6.69438C10.902 6.71883 11 6.86553 11 7.01222C11 7.15892 10.902 7.28117 10.7549 7.33007C8.59804 7.84352 6.83333 9.57946 6.31863 11.7555C6.29412 11.9022 6.14706 12 6 12ZM22.7719 9.71537C20.0114 9.14611 17.8669 6.98292 17.2966 4.2277C17.2738 4.09108 17.1597 4 17 4C16.8631 4 16.749 4.09108 16.7034 4.2277C16.1331 6.98292 13.9658 9.14611 11.2281 9.71537C11.0913 9.73814 11 9.85199 11 10.0114C11 10.148 11.0913 10.2619 11.2281 10.3074C13.9886 10.8767 16.1331 13.0398 16.7034 15.7723C16.7262 15.9089 16.8403 16 17 16C17.1369 16 17.2509 15.9089 17.2966 15.7723C17.8669 13.0171 20.0342 10.8767 22.7719 10.3074C22.9087 10.2846 23 10.1708 23 10.0114C23 9.85199 22.9087 9.73814 22.7719 9.71537ZM12.8294 16.7754C10.7607 16.3026 9.13981 14.5059 8.71327 12.1891C8.69194 12.0709 8.60664 12 8.5 12C8.39337 12 8.30806 12.0709 8.28673 12.1891C7.86019 14.5059 6.23934 16.3026 4.17062 16.7754C4.06398 16.7991 4 16.8936 4 17.0118C4 17.13 4.06398 17.2246 4.17062 17.2482C6.23934 17.721 7.86019 19.5177 8.28673 21.8109C8.30806 21.9291 8.39337 22 8.5 22C8.60664 22 8.69194 21.9291 8.71327 21.8109C9.13981 19.5177 10.7607 17.721 12.8294 17.2482C12.936 17.2246 13 17.13 13 17.0118C13 16.8936 12.936 16.7991 12.8294 16.7754Z";
export const mtlClose = "M16 8L8 16M8 8L16 16";
export const mtlComment =
  "M19 14.3333C19 14.7459 18.8361 15.1416 18.5444 15.4333C18.2527 15.725 17.857 15.8889 17.4444 15.8889H8.11111L5 19V6.55556C5 6.143 5.16389 5.74733 5.45561 5.45561C5.74733 5.16389 6.143 5 6.55556 5H17.4444C17.857 5 18.2527 5.16389 18.5444 5.45561C18.8361 5.74733 19 6.143 19 6.55556V14.3333Z";
export const mtlComment1 =
  "M8 3C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V6C19 4.34315 17.6569 3 16 3H8ZM7 6C7 5.44772 7.44772 5 8 5H16C16.5523 5 17 5.44772 17 6V18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18V6ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H10ZM9 12C9 11.4477 9.44772 11 10 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H10C9.44772 13 9 12.5523 9 12ZM10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17H14C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15H10Z";
export const mtlDownload = "M12 5L12 19M12 19L19 11.5333M12 19L5 11.5333";
export const mtlEdit =
  "M16.5569 5.26457C16.7236 5.08674 16.9354 5 17.1415 5C17.245 5 17.3491 5.02164 17.4486 5.0656C17.5483 5.10964 17.6432 5.17611 17.7261 5.26457C17.8092 5.35316 17.8782 5.46178 17.9264 5.58575C17.9745 5.70978 18 5.84474 18 5.98239C18 6.12004 17.9745 6.25499 17.9264 6.37903C17.8782 6.50299 17.8092 6.61162 17.7261 6.70021L6.97046 18.1729L5.35433 18.6039L5.78868 16.7507L16.5569 5.26457ZM17.1415 3C16.3618 3 15.6282 3.33098 15.0978 3.89669L4.14659 15.578C4.02668 15.7059 3.94251 15.8631 3.90251 16.0338L3.02641 19.7718C2.94621 20.114 3.0515 20.4731 3.30374 20.7178C3.55597 20.9625 3.91813 21.0568 4.25768 20.9662L7.76209 20.0317C7.94267 19.9836 8.10614 19.8858 8.23396 19.7494L19.1852 8.06809C19.4473 7.78852 19.6521 7.46007 19.7907 7.10301C19.9294 6.74601 20 6.3654 20 5.98239C20 5.59938 19.9294 5.21877 19.7907 4.86177C19.6521 4.50471 19.4473 4.17626 19.1852 3.89669C18.923 3.61698 18.6082 3.39141 18.2569 3.2362C17.9054 3.08091 17.5262 3 17.1415 3ZM12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21H20C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19H12Z";
export const mtlEmail = "M19 5L11.3 12.7M19 5L14.1 19L11.3 12.7M19 5L5 9.9L11.3 12.7";
export const mtlGrid =
  "M6 4C4.89543 4 4 4.89543 4 6V9C4 10.1046 4.89543 11 6 11H9C10.1046 11 11 10.1046 11 9V6C11 4.89543 10.1046 4 9 4H6ZM6 6H9V9H6V6ZM15 4C13.8954 4 13 4.89543 13 6V9C13 10.1046 13.8954 11 15 11H18C19.1046 11 20 10.1046 20 9V6C20 4.89543 19.1046 4 18 4H15ZM15 6H18V9H15V6ZM4 15C4 13.8954 4.89543 13 6 13H9C10.1046 13 11 13.8954 11 15V18C11 19.1046 10.1046 20 9 20H6C4.89543 20 4 19.1046 4 18V15ZM9 15H6V18H9V15ZM15 13C13.8954 13 13 13.8954 13 15V18C13 19.1046 13.8954 20 15 20H18C19.1046 20 20 19.1046 20 18V15C20 13.8954 19.1046 13 18 13H15ZM15 15H18V18H15V15Z";

// 3 paths in the icon :/
export const mtlImage = "";

export const mtlInfo =
  "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V9ZM11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15Z";
export const mtlList = "M9.33333 6H20M9.33333 12H20M4 6H5.06667M4 12H5.06667M9.33333 18H20M4 18H5.06667";

// 2 paths in the icon :/
export const mtlLock = "";

// 4 paths in the icon :/
export const mtlMaximize = "";

export const mtlMe =
  "M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11ZM6 15.5C6 13.567 7.567 12 9.5 12H14.5C16.433 12 18 13.567 18 15.5C18 17.433 16.433 19 14.5 19H9.5C7.567 19 6 17.433 6 15.5Z";
export const mtlOptions =
  "M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z";

// 2 paths in the icon :/
export const mtlPause = "";

export const mtlPlay =
  "M8 6.97202V17.028C8 17.7948 8.85757 18.2607 9.51799 17.8433L17.5416 12.8153C18.1528 12.4368 18.1528 11.5632 17.5416 11.1749L9.51799 6.15667C8.85757 5.73929 8 6.2052 8 6.97202Z";
export const mtlPower = "M12.7778 4L5 13.6H12L11.2222 20L19 10.4H12L12.7778 4Z";
export const mtlSearch =
  "M6 11.2226C6 8.33822 8.33821 6 11.2225 6C14.1069 6 16.4451 8.33822 16.4451 11.2226C16.4451 12.623 15.8939 13.8946 14.9966 14.8325C14.9666 14.8563 14.9376 14.8821 14.9098 14.9098C14.8821 14.9376 14.8563 14.9666 14.8325 14.9966C13.8946 15.8939 12.623 16.4451 11.2225 16.4451C8.33821 16.4451 6 14.1069 6 11.2226ZM15.5737 16.9879C14.3635 17.9026 12.8564 18.4451 11.2225 18.4451C7.23364 18.4451 4 15.2115 4 11.2226C4 7.23366 7.23364 4 11.2225 4C15.2114 4 18.4451 7.23366 18.4451 11.2226C18.4451 12.8564 17.9026 14.3635 16.9879 15.5737L19.7076 18.2934C20.0981 18.6839 20.0981 19.317 19.7076 19.7076C19.317 20.0981 18.6839 20.0981 18.2933 19.7076L15.5737 16.9879Z";
export const mtlSetting =
  "M6.1573 13.5281C6.33708 14.1573 6.60674 14.7865 6.96629 15.4157L5.97753 16.4045C5.52809 16.8539 5.52809 17.1236 5.88764 17.4831L6.8764 18.4719C7.23595 18.8315 7.59551 18.8315 7.95506 18.382L9.03371 17.3034C9.57303 17.573 10.1124 17.8427 10.6517 17.9326V19.191C10.6517 19.8202 10.8315 20 11.3708 20H12.809C13.3483 20 13.5281 19.8202 13.5281 19.191V17.9326C14.0674 17.7528 14.6067 17.573 15.1461 17.3034L16.2247 18.382C16.6742 18.8315 16.9438 18.8315 17.3034 18.4719L18.2921 17.4831C18.6517 17.1236 18.6517 16.8539 18.2022 16.4045L17.1236 15.3258C17.4831 14.7865 17.7528 14.1573 17.9326 13.4382H19.2809C19.8202 13.4382 20 13.2584 20 12.6292V11.3708C20 10.7416 19.8202 10.5618 19.2809 10.5618H17.9326C17.7528 10.0225 17.573 9.48315 17.3034 8.94382L18.2921 7.95506C18.7416 7.50562 18.7416 7.23595 18.382 6.8764L17.4831 5.97753C17.1236 5.61798 16.764 5.61798 16.4045 6.06742L15.5056 6.96629C14.9663 6.60674 14.3371 6.33708 13.618 6.1573V4.80899C13.618 4.17978 13.4382 4 12.8989 4H11.4607C10.9213 4 10.7416 4.17978 10.7416 4.80899V6.06742C10.1124 6.24719 9.48315 6.51685 8.85393 6.8764L7.95506 5.97753C7.41573 5.52809 7.14607 5.52809 6.69663 5.97753L5.70787 6.96629C5.34831 7.32584 5.34831 7.59551 5.79775 8.04494L6.78652 9.03371C6.51685 9.57303 6.24719 10.1124 6.1573 10.6517H4.7191C4.17978 10.6517 4 10.8315 4 11.4607V12.7191C4 13.3483 4.17978 13.5281 4.7191 13.5281H6.1573ZM9.75281 12.0899C9.75281 10.7416 10.8315 9.75281 12.0899 9.75281C13.4382 9.75281 14.427 10.8315 14.427 12.0899C14.427 13.3483 13.3483 14.427 12.0899 14.427C10.7416 14.427 9.75281 13.4382 9.75281 12.0899Z";
export const mtlShare =
  "M15.6689 5C14.9582 5 14.335 5.60024 14.335 6.39951C14.335 6.64177 14.3923 6.86575 14.4919 7.06027C14.4988 7.0709 14.5056 7.08171 14.5122 7.09268C14.5269 7.11727 14.5405 7.14223 14.5529 7.16751C14.7957 7.55353 15.2144 7.79901 15.6689 7.79901C16.3795 7.79901 17.0027 7.19877 17.0027 6.39951C17.0027 5.60024 16.3795 5 15.6689 5ZM13.4231 8.91234C14.0118 9.46113 14.7966 9.79901 15.6689 9.79901C17.5361 9.79901 19.0027 8.25066 19.0027 6.39951C19.0027 4.54835 17.5361 3 15.6689 3C13.8017 3 12.335 4.54835 12.335 6.39951C12.335 6.6671 12.3657 6.92836 12.4238 7.17953L8.57995 9.48583C7.99117 8.93687 7.20622 8.59888 6.33383 8.59888C4.46663 8.59888 3 10.1472 3 11.9984C3 13.8495 4.46663 15.3979 6.33383 15.3979C7.20641 15.3979 7.99152 15.0597 8.58034 14.5106L12.4246 16.8137C12.366 17.066 12.335 17.3284 12.335 17.5973C12.335 19.4484 13.8017 20.9968 15.6689 20.9968C17.5361 20.9968 19.0027 19.4484 19.0027 17.5973C19.0027 15.7461 17.5361 14.1978 15.6689 14.1978C14.7979 14.1978 14.0141 14.5346 13.4256 15.082L9.57915 12.7775C9.63708 12.5266 9.66766 12.2657 9.66766 11.9984C9.66766 11.7309 9.63704 11.4698 9.57903 11.2188L13.4231 8.91234ZM7.4556 11.2397C7.46671 11.2617 7.47871 11.2835 7.49161 11.305C7.4963 11.3128 7.50108 11.3206 7.50595 11.3282C7.60854 11.5249 7.66766 11.7522 7.66766 11.9984C7.66766 12.2447 7.60849 12.4721 7.50581 12.6688C7.50088 12.6766 7.49603 12.6844 7.49128 12.6924C7.47856 12.7136 7.46672 12.7351 7.45575 12.7568C7.21387 13.1484 6.79204 13.3979 6.33383 13.3979C5.62316 13.3979 5 12.7977 5 11.9984C5 11.1991 5.62316 10.5989 6.33383 10.5989C6.79194 10.5989 7.21369 10.8483 7.4556 11.2397ZM14.335 17.5973C14.335 17.3715 14.3848 17.1616 14.4722 16.9766C14.4891 16.9532 14.5052 16.9288 14.5203 16.9036C14.5428 16.866 14.5626 16.8275 14.5797 16.7883C14.8258 16.4259 15.2307 16.1978 15.6689 16.1978C16.3795 16.1978 17.0027 16.798 17.0027 17.5973C17.0027 18.3965 16.3795 18.9968 15.6689 18.9968C14.9582 18.9968 14.335 18.3965 14.335 17.5973Z";
export const mtlTik = "M16 9L10.5 15L8 12.2727";
export const mtlUpdate =
  "M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6V4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12H18Z";
export const mtlUpload = "M12 19L12 5M12 5L5 12.4667M12 5L19 12.4667";
export const mtlUser =
  "M10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8ZM12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4ZM10 13C7.23858 13 5 15.2386 5 18H7C7 16.3431 8.34315 15 10 15H14C15.6569 15 17 16.3431 17 18H19C19 15.2386 16.7614 13 14 13H10Z";
