import MotionlyApiService from "./MotionlyApiService";
import { customerService } from ".";

class BackofficeService {
  dsplist;

  async getFrontalEntity(entityId) {
    return MotionlyApiService.backofficeApi().getFrontalEntity(customerService.getCurrentCustomerSummaryId(), entityId);
  }
  async getDspList(forceFetch = false) {
    if (this.dsplist && !forceFetch) {
      return this.dsplist;
    }
    // TODO refacto when the new motionly-ws-api is ready
    this.dsplist = await MotionlyApiService.backofficeApi().getDspList(customerService.getCurrentCustomerSummaryId());
    return this.dsplist;
  }
}

export default new BackofficeService();
