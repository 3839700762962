import { ProvideAuth } from "Legacy/utils";
import { UserSettingsProvider } from "Legacy/context/UserSettingsProvider";

// eslint-disable-next-line react/prop-types
function AppProvider({ children }) {
  return (
    <ProvideAuth>
      <UserSettingsProvider>{children} </UserSettingsProvider>
    </ProvideAuth>
  );
}

export default AppProvider;
