/*
 * Copyright (C) 2016-2019 KoppaSoft.
 *
 * The code hereby is the private full property of the KoppaSoft company, Paris, France.
 *
 * You have no right to re-use or modify it. There are no open-source, nor free license
 * attached to it!
 */

/* eslint-disable */

const scrolling = "scrolling";
const inscroll = "inscroll";
const time = "time";
const frozen = "frozen";
const SCORE_MODES = {
  scrolling: scrolling,
  inscroll: inscroll,
  time: time,
  frozen: frozen,
};

const ACTION_MODES = {
  same: "same",
  new: "new",
  sequence: "sequence",
};

function computeRatio(description) {
  return description.ratio;
}

function isScoreTimeBased(score) {
  return score.mode === time || score.mode === inscroll;
}

function computeTrackIds(description) {
  if (description?.tracks === undefined) {
    return [];
  }
  return description.tracks.map(track => track.id);
}

function computeScores(description, trackId, filter) {
  const scores = [];
  if (!description) {
    return scores;
  }

  function scanScores(theScores) {
    for (const score of theScores) {
      if (
        score.programs !== undefined &&
        (filter === undefined || filter(score) === true) &&
        score.programs.find(program => trackId === undefined || program.trackId === trackId) !== undefined
      ) {
        scores.push(score);
      }
    }
  }

  if (description.process.scores !== undefined) {
    scanScores(description.process.scores);
  }
  if (description.process.sequences !== undefined) {
    for (const sequence of description.process.sequences) {
      scanScores(sequence.scores);
    }
  }
  return scores;
}

function computeWidthPercentage(description) {
  const ratio = computeRatio(description);
  let width;
  if (ratio <= 0.5) {
    width = 15;
  } else if (ratio <= 0.6666) {
    width = 20;
  } else if (ratio <= 0.8) {
    width = 25;
  } else if (ratio <= 1) {
    width = 30;
  } else if (ratio <= 1.25) {
    width = 35;
  } else if (ratio <= 1.5) {
    width = 40;
  } else if (ratio <= 1.75) {
    width = 45;
  } else {
    width = 50;
  }
  return width;
}

function hasScore(description, scoreMode) {
  return computeScores(description).some(score => score.mode === scoreMode);
}

function hasAction(description, actionMode) {
  const actions = [...(description.process.actions ?? [])];
  if (description.process.action !== undefined) {
    actions.push(description.process.action);
  }
  return actions.some(action => action.mode === actionMode);
}

function computeFirstScore(description) {
  const scores = computeScores(description);
  return scores.length >= 1 ? scores[0] : undefined;
}

function computePrograms(description, trackId, mode) {
  const programs = [];

  function scanScores(theScores) {
    for (const score of theScores) {
      if (score.programs !== undefined) {
        for (const program of score.programs) {
          if (program.trackId === trackId && (mode === undefined || score.mode === mode)) {
            programs.push(program);
          }
        }
      }
    }
  }

  if (description.process.scores !== undefined) {
    scanScores(description.process.scores);
  }
  if (description.process.sequences !== undefined) {
    for (const sequence of description.process.sequences) {
      scanScores(sequence.scores);
    }
  }
  return programs;
}

function computeAnimationDurationInMilliseconds(description, trackId) {
  const scores = computeScores(description, trackId, undefined);
  for (const score of scores) {
    if (score.mode === "time" || score.mode === "inscroll") {
      for (const program of score.programs) {
        if (program.trackId === trackId) {
          return program.durationInMilliseconds || program.animationDurationInMilliseconds;
        }
      }
    }
  }
  return undefined;
}

function computeSprites(description, includeDisabled) {
  const sprites = [];
  if (description.tracks !== undefined) {
    for (const track of description.tracks) {
      if (track.layers !== undefined) {
        for (const layer of track.layers) {
          if (layer.sprites !== undefined) {
            for (const sprite of layer.sprites) {
              if (sprite.enabled === true || includeDisabled === true) {
                sprites.push(sprite);
              }
            }
          }
        }
      }
    }
  }
  return sprites;
}

function getAssets(description, includeDisabled) {
  const assets = [];
  if (Array.isArray(description.tracks) === true) {
    for (const track of description.tracks) {
      if (Array.isArray(track.layers) === false) {
        continue;
      }
      for (const layer of track.layers) {
        if (Array.isArray(layer.sprites) === true && layer.enabled !== false) {
          for (const sprite of layer.sprites) {
            if ((includeDisabled === true || sprite.enabled !== false) && sprite.url !== undefined) {
              assets.push({ type: "sprite", url: sprite.url });
            }
          }
        }
        if (Array.isArray(layer.texts) === true) {
          for (const text of layer.texts) {
            if (includeDisabled === true || text.enabled !== false) {
              assets.push({ type: "text", url: text.fontUrl });
            }
          }
        }
      }
    }
  }
  if (Array.isArray(description.externalAssets) === true) {
    for (const asset of description.externalAssets) {
      assets.push({ type: asset.type, url: asset.url });
    }
  }
  return assets;
}

function visit(description, visitor) {
  function handleProperties(properties) {
    const keys = Object.keys(properties);
    for (const key of keys) {
      const value = properties[key];
      if (Array.isArray(value) === true) {
        for (let index = 0; index < value.length; index++) {
          handleProperties(value[index]);
        }
      } else if (value instanceof Object) {
        handleProperties(value);
      } else {
        visitor(properties, key, value);
      }
    }
  }

  handleProperties(description);
}

export default {
  SCORE_MODES,
  ACTION_MODES,
  hasScore,
  hasAction,
  isScoreTimeBased,
  computeRatio,
  computeTrackIds,
  computeScores,
  computeWidthPercentage,
  computeFirstScore,
  computePrograms,
  computeAnimationDurationInMilliseconds,
  computeSprites,
  getAssets,
  visit,
};
