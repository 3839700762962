import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./UserProfileDetailPage.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import {
  Attribute,
  DetailPage,
  Form,
  FormControl,
  PageHeader,
  PageLoader,
  SpacedContainer,
  SpacedUnits,
  ThumbnailContainer,
} from "Legacy/components";
import { useAuth, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { userService } from "Legacy/services";
import { UploadImageModal } from "./components";

const inputsWidth = "400px";

export default function UserProfileDetailPage({ user }) {
  const [working, setWorking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const auth = useAuth();

  const initialFormValue = {
    name: user.name,
  };

  const onClickRefresh = async () => {
    setLoading(true);
    try {
      const freshUser = await userService.get(user.id);
      auth.updateCachedUser(freshUser);
      setLoading(false);
    } catch (error) {
      notificationService.notifyError(error);
      setLoading(false);
    }
  };

  const onChangeInformations = async formValue => {
    setWorking(true);
    try {
      const freshUser = await userService.update(user.id, formValue.name);
      auth.updateCachedUser(freshUser);
      notificationService.notifySuccess(t("messages.saveSuccess"));
      setWorking(false);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onChangeInformations,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  return (
    <>
      <div className="profile-page-header">
        <PageHeader title={t("users.profile.title")}>
          <PageLoader inline active={loading} />
          <Button iconPosition="iconOnly" onClick={onClickRefresh} type="secondary" icon={<RefreshLineIcon />} />
        </PageHeader>
      </div>
      <DetailPage className="users-settings-form">
        <SpacedContainer className="left-column" gap={SpacedUnits.Small}>
          <ThumbnailContainer url={auth.currentUser.avatarUrl} type="avatar" />
          <div>
            <Button type="secondary" onClick={() => setShowUploadImageModal(true)}>
              {t("users.profile.uploadAvatar")}
            </Button>
          </div>
        </SpacedContainer>

        <Form {...getFormProps()}>
          <div>
            <FormControl
              id="name"
              label={t("fields.name")}
              placeholder={t("fields.namePlaceholder")}
              required
              width={inputsWidth}
              {...getFieldProps("name")}
            />

            <hr />
            <Attribute name={t("users.profile.privateId")} value={user.id} />
            <hr />
          </div>

          <Button submit type="primary" loading={working}>
            {t("actions.saveChanges")}
          </Button>
        </Form>
      </DetailPage>

      <UploadImageModal open={showUploadImageModal} user={user} onCancel={() => setShowUploadImageModal(false)} />
    </>
  );
}

UserProfileDetailPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};
