import { path } from "Legacy/utils";
import Assets from "./Assets";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: path.ASSETS,
    key: "assets",
    component: Assets,
  },
];
