import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Drawer,
  DrawerClosableFooter,
  DrawerHeader,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Upload,
} from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { experienceService, helpers, siteService, templateService, unitService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { AD_UNIT_PREFIXES } from "./AdUnitPrefixes";

const MODE = {
  TEMPLATE: "template",
  IMPORT: "import",
};

export default function AddExperienceDrawer({ open, siteData, onClose, onSuccessTemplate, onSuccessImport }) {
  const [t] = useTranslation();

  const [mode, setMode] = useState(MODE.TEMPLATE);
  const [working, setWorking] = useState(false);
  const notificationService = useNotificationService();
  const [uploadedFileList, setUploadedFileList] = useState();
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [form] = Form.useForm();
  const selectedTemplate = Form.useWatch("template", form);
  const selectedUnit = Form.useWatch("adUnit", form);
  const creativeName = Form.useWatch("name", form);

  async function load() {
    const templatesContainer = templateService.listLocal();
    setTemplatesOptions(templatesContainer.templates.map(template => ({ label: template.name, value: template.id })));
  }

  const adUnitOptions = useMemo(() => {
    if (mode === MODE.TEMPLATE && !selectedTemplate) {
      form.setFieldValue("adUnit", undefined);
      return [];
    }
    const allUnits = unitService.list();
    const allUnitsFilteredBySiteAvailability = allUnits.filter(
      unit => !siteData.units?.some(siteUnit => siteUnit.technicalId === unit.technicalId)
    );
    if (mode === MODE.IMPORT) {
      return allUnitsFilteredBySiteAvailability.map(unit => ({ label: unit.name, value: unit.technicalId }));
    }

    const availableUnitsByTemplate = templateService.getAvailableUnits(
      selectedTemplate,
      allUnitsFilteredBySiteAvailability
    );

    const availableUnits = availableUnitsByTemplate.map(unit => ({ label: unit.name, value: unit.technicalId }));

    if (!availableUnits.some(unit => unit.value === selectedUnit)) {
      form.setFieldValue("adUnit", undefined);
    }
    return availableUnits;
  }, [siteData, selectedTemplate, mode]);

  async function handleOnAdd() {
    form
      .validateFields()
      .then(async () => {
        if (mode === MODE.TEMPLATE) {
          onSuccessTemplate(selectedTemplate, selectedUnit, creativeName);
        } else {
          setWorking(true);
          try {
            const experience = await experienceService.upload(form.getFieldValue("file").file, [selectedUnit]);

            try {
              await siteService.attachExperience(siteData, experience.id);
              notificationService.notifySuccess(t("sites.addExperienceDrawer.success"));
            } catch (error) {
              // Rollback to experience deletion if the attachment fails.
              await experienceService.deleteExperience(experience.id);
              notificationService.notifyError(error);
            }

            form.resetFields();
            setUploadedFileList();
            onSuccessImport(experience.id);
          } catch (error) {
            notificationService.notifyError(error);
          } finally {
            setWorking(false);
          }
        }
      })
      .catch(error => {
        console.warn(error);
      });
  }

  function handleOnUpload(file) {
    setUploadedFileList([{ name: file.name, uid: file.uid }]);
    return false;
  }

  function handleOnRemove() {
    setUploadedFileList();
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    const newName = selectedUnit ? `${AD_UNIT_PREFIXES[selectedUnit]}_${siteData.name}` : siteData.name;
    if (siteData.name !== newName) {
      form.setFieldValue("name", newName);
    }
  }, [selectedUnit, siteData.name, open]);

  function renderImport() {
    return (
      <Form form={form}>
        <Form.Item required name="adUnit" label={t("sites.addExperienceDrawer.adUnit")}>
          <Select placeholder={t("sites.addExperienceDrawer.placeholder")} options={adUnitOptions}></Select>
        </Form.Item>
        <Form.Item required name="file" label={t("sites.addExperienceDrawer.file")}>
          <Upload.Dragger
            onRemove={handleOnRemove}
            maxCount={1}
            beforeUpload={handleOnUpload}
            fileList={uploadedFileList}
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          />
        </Form.Item>
      </Form>
    );
  }

  function renderTemplate() {
    return (
      <Form form={form}>
        <Form.Item required name="template" label={t("sites.addExperienceDrawer.template")}>
          <Select
            showSearch
            placeholder={t("sites.addExperienceDrawer.placeholder")}
            options={templatesOptions}
          ></Select>
        </Form.Item>
        <Form.Item required name="adUnit" label={t("sites.addExperienceDrawer.adUnit")}>
          <Select placeholder={t("sites.addExperienceDrawer.placeholder")} options={adUnitOptions}></Select>
        </Form.Item>

        <Form.Item
          rules={[
            {
              pattern: new RegExp(`^.{0,${helpers.constants.experienceNameLengthMaximum}}$`),
              message: t("fields.formValidationErrors.name", { length: helpers.constants.experienceNameLengthMaximum }),
            },
          ]}
          validateTrigger="onBlur"
          required
          name="name"
          label={t("sites.addExperienceDrawer.name")}
          defaultValue={"tt"}
        >
          <Input placeholder={t("sites.addExperienceDrawer.name")} />
        </Form.Item>
      </Form>
    );
  }

  useEffect(() => {
    if (!open) {
      return;
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const canAdd = useMemo(() => {
    if (mode === MODE.TEMPLATE) {
      return selectedUnit && selectedTemplate && creativeName;
    }
    return uploadedFileList && selectedUnit;
  }, [selectedUnit, selectedTemplate, uploadedFileList, creativeName]);

  function renderMode() {
    if (mode === MODE.TEMPLATE) {
      return renderTemplate();
    }
    return renderImport();
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      maskClosable={!working}
      header={<DrawerHeader title={t("sites.addExperienceDrawer.title")} />}
      footer={
        <DrawerClosableFooter
          actions={
            <Button disabled={!canAdd || working} loading={working} onClick={handleOnAdd}>
              {t("actions.add")}
            </Button>
          }
        />
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Alert description={t("sites.addExperienceDrawer.warning")} type="info" showIcon closable={false} />
        <Radio.Group onChange={setMode} value={mode}>
          <Radio.Button value={MODE.TEMPLATE}>{t("sites.addExperienceDrawer.template")}</Radio.Button>
          <Radio.Button value={MODE.IMPORT}>{t("sites.addExperienceDrawer.import")}</Radio.Button>
        </Radio.Group>
        {renderMode()}
      </Space>
    </Drawer>
  );
}
