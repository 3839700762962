import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";
import { ModalHeights } from "Legacy/components";
import { customerService } from "Legacy/services";
import { path } from "Legacy/utils";
import CustomerThumbnail from "../../../../../../app/customers/components";

export default function ChangeCustomerConfirmationModal({ open, onCancel, customer }) {
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const location = useLocation();

  function onConfirm() {
    setWorking(true);
    let to = location.pathname;
    // experiences page, might be detail page so force url to list experiences.
    if (to.startsWith(path.EXPERIENCES)) {
      to = path.EXPERIENCES;
    }
    customerService.changeCustomer(customer.id, `/${path.ROOT_CUSTOMER_PREFIX}/${customer.id + to}`);
    // We force the page window so as to force the flush of all caches
    window.location.reload();
  }

  return (
    <Modal
      open={open}
      title={t("navbar.customer.changeCustomerConfirmationModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onConfirm} disabled={working}>
              {t("navbar.customer.changeCustomerConfirmationModal.action")}
            </Button>
          }
        />
      }
      height={ModalHeights.Small}
      onCancel={onCancel}
    >
      <p className="normal-text">{t("navbar.customer.changeCustomerConfirmationModal.description")}</p>
      <CustomerThumbnail customer={customer} />
    </Modal>
  );
}

ChangeCustomerConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  customer: PropTypes.object,
};
