import { Button, theme } from "@ogury/design-system";
import React, { useState } from "react";
import ErrorWarningLineIcon from "remixicon-react/ErrorWarningLineIcon";
import { ExternalSyncFailedModal } from "Legacy/app/experiences/components/ExperienceInlineActions/components/ExternalSyncFailed/components";

export default function ExternalSyncFailed({ experienceId, onSuccess }) {
  const [showModal, setShowModal] = useState(false);

  function handleOnSuccess(experience) {
    setShowModal(false);
    onSuccess(experience);
  }

  return (
    <>
      <Button
        type="tertiary"
        iconPosition="iconOnly"
        icon={<ErrorWarningLineIcon color={theme.colors.warning.content} />}
        onClick={() => setShowModal(true)}
      />
      <ExternalSyncFailedModal
        experienceId={experienceId}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onSuccess={handleOnSuccess}
      />
    </>
  );
}
