import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ProtectedRoute } from "Legacy/components";
import { experiencePermissions } from "Legacy/services";
import Path from "Legacy/utils/path";
import { TemplatesDetailPage, TemplatesPage } from "./pages";

export default function Templates() {
  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (!experiencePermissions.canCreateStandaloneExperience()) {
      history.push(Path.SITES);
    }
  }, []);

  return (
    <div>
      <ProtectedRoute path={url} exact>
        <TemplatesPage />
      </ProtectedRoute>
      <ProtectedRoute path={`${url}/:templateId`}>
        <TemplatesDetailPage />
      </ProtectedRoute>
    </div>
  );
}
