import React from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { Tag } from "@ogury/design-system";

import { Attribute, Avatar, DetailPage, SpacedContainer, SpacedUnits } from "Legacy/components";
import "./TemplatesDetailDescriptionPage.scss";
import { disableFeature } from "Legacy/utils";

import sampleAvatar from "Legacy/assets/img/sampleAvatar.png";

export default function TemplatesDetailDescriptionPage({ template = {} }) {
  const [t] = useTranslation();

  return (
    <DetailPage className="template-detail-description">
      <h1>{t("templates.details.description")}</h1>
      <hr />
      <SpacedContainer>
        <Attribute name={t("templates.details.summary")} value={template.comment} />
        {template.description !== undefined && (
          <Attribute name={t("templates.details.completeInfo")}>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="typo-black-light normal-text" dangerouslySetInnerHTML={{ __html: template.description }} />
          </Attribute>
        )}
        <Attribute name={t("fields.tags")}>
          <SpacedContainer horizontal gap={SpacedUnits.XSmall}>
            {template.tags?.map(tag => (
              <Tag key={`template-tag-${tag}`}>{tag}</Tag>
            ))}
          </SpacedContainer>
        </Attribute>
        {disableFeature && <Attribute name={t("templates.details.complexity")} value="High" />}
        <Attribute name={t("templates.details.creator")}>
          <div className="template-detail-description-body-creator">
            <Avatar src={sampleAvatar} /> Motionly
          </div>
        </Attribute>
      </SpacedContainer>
    </DetailPage>
  );
}

TemplatesDetailDescriptionPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object,
};
