import React from "react";
import * as PropTypes from "prop-types";

import "./ButtonWithDropdown.scss";
import { ButtonGroup } from "..";

export default function ButtonWithDropdown({ button, dropdown }) {
  return (
    <ButtonGroup>
      {button}
      {dropdown}
    </ButtonGroup>
  );
}

ButtonWithDropdown.propTypes = {
  button: PropTypes.node.isRequired,
  dropdown: PropTypes.node.isRequired,
};
