import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Collapse,
  Drawer,
  DrawerClosableFooter,
  DrawerHeader,
  FormControlLabel,
  Input,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  useMessage,
} from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";
import * as PropTypes from "prop-types";
import { copyText } from "Legacy/utils";
import { applicationService, backofficeService, experienceService, siteService, SolutionVendor } from "Legacy/services";

const oguryCallbacksApplicationName = "Callbacks";

export default function ThirdPartyTagDrawer({ open, site, experience, onClose }) {
  const { notifySuccess } = useMessage();
  const [t] = useTranslation();
  const [dspList, setDspList] = useState();
  const [applicationList, setApplicationList] = useState();
  const [dspSelected, setDspSelected] = useState();
  const [applicationSelected, setApplicationSelected] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [minified, setMinified] = useState(false);
  const [thirdPartyTag, setThirdPartyTag] = useState();

  const entityIsDraft = useMemo(
    () => (experience === undefined ? site : experience)?.state === Experience.StateEnum.Draft,
    [experience, site]
  );

  async function load() {
    setLoading(true);

    try {
      const _dspList = await backofficeService.getDspList();
      const _applicationList = await applicationService.list();
      setDspList(_dspList);
      setApplicationList(_applicationList);
      setDspSelected(_dspList?.find(dsp => dsp.value === SolutionVendor.Ogury) || _dspList[0]);
      setApplicationSelected(_applicationList.find(application => application.name === oguryCallbacksApplicationName));
    } catch (error) {
      console.warn(error);
      setError(t("experiences.embed.modal.thirdParty.errorLoadingDspOrApplication"));
    } finally {
      setLoading(false);
    }
  }

  async function loadTag() {
    setWorking(true);
    try {
      if (experience) {
        const tag = await experienceService.getThirdPartyTag(
          experience.id,
          minified,
          applicationSelected?.publicId,
          dspSelected?.value
        );
        setThirdPartyTag(tag);
      } else if (site) {
        const tag = await siteService.getThirdPartyTag(
          site.id,
          minified,
          applicationSelected?.publicId,
          dspSelected?.value
        );
        setThirdPartyTag(tag);
      }
    } catch (error) {
      console.warn(error);
      setError(t("experiences.embed.modal.thirdParty.errorLoadingThirdPartyTag"));
    } finally {
      setWorking(false);
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    load();
  }, [open]); //eslint-disable-line

  useEffect(() => {
    if (!open) {
      return;
    }

    if (dspSelected && applicationSelected) {
      loadTag();
    }
  }, [open, dspSelected, applicationSelected, minified]); //eslint-disable-line

  function handleOnClickDownload() {
    const blob = new Blob([thirdPartyTag], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${experience?.name || site?.name || "third-party-tag"}.txt`;
    link.href = url;
    link.click();
    onClose();
  }

  function renderLoading() {
    return <Spin style={{ width: "100%" }} />;
  }

  function renderThirdPartyTag() {
    if (working) {
      return <Spin style={{ width: "100%" }} />;
    }
    return <Input.TextArea autoSize width="100%" readonly required value={thirdPartyTag} />;
  }

  function renderContent() {
    return (
      <Collapse
        items={[
          {
            children: (
              <Space size="s" direction="vertical" style={{ width: "100%" }}>
                <div>
                  <FormControlLabel
                    label={t("experiences.embed.solutionVendor")}
                    hint={t("experiences.embed.solutionVendorTooltip")}
                  />
                  <Select
                    showSearch
                    width="100%"
                    disabled={experience}
                    value={dspSelected?.value}
                    options={dspList?.map(dsp => ({ value: dsp.value, label: dsp.text }))}
                    onChange={dspValue => setDspSelected(dspList?.find(dsp => dsp.value === dspValue))}
                  />
                </div>
                <div>
                  <FormControlLabel
                    label={t("experiences.embed.modal.thirdParty.application")}
                    hint={t("experiences.embed.modal.thirdParty.applicationTooltip")}
                  />
                  <Select
                    showSearch
                    width="100%"
                    disabled={true}
                    value={applicationSelected?.id}
                    options={applicationList?.map(application => ({ value: application.id, label: application.name }))}
                    onChange={applicationId =>
                      setApplicationSelected(
                        applicationList?.find(application => application.id?.toString() === applicationId?.toString())
                      )
                    }
                  />
                </div>
                <div>
                  <FormControlLabel label={t("experiences.embed.minifiedTag")} />
                  <Switch checked={minified} size="small" onChange={setMinified} />
                </div>
                {renderThirdPartyTag()}
              </Space>
            ),
            key: "tagCode",
            label: t("experiences.embed.modal.thirdParty.tagCode"),
          },
        ]}
        spacedOut
      ></Collapse>
    );
  }

  async function handleOnClickCopy() {
    await copyText(thirdPartyTag);
    onClose();
    notifySuccess(t("app.notifications.copiedToClipboard"));
  }

  function renderFooter() {
    const createButton = (actionType, handleOnClick, titleKey, confirmKey) => {
      return (
        <Button loading={working || loading} disabled={working || loading} onClick={handleOnClick}>
          {t(`actions.${actionType}`)}
        </Button>
      );
    };

    const copyButton = createButton(
      "copy",
      handleOnClickCopy,
      experience ? "experienceCopyWarning" : "siteCopyWarning",
      "copyAnyway"
    );
    const downloadButton = createButton(
      "download",
      handleOnClickDownload,
      experience ? "experienceDownloadWarning" : "siteDownloadWarning",
      "downloadAnyway"
    );

    return (
      <>
        {copyButton}
        {downloadButton}
      </>
    );
  }

  function renderExplanations() {
    return (
      <Space size="s" direction="vertical">
        <Typography.P2Regular>{t("experiences.embed.modal.thirdParty.explanation")}</Typography.P2Regular>
        {entityIsDraft && (
          <Alert
            type="warning"
            description={t(
              `experiences.embed.modal.thirdParty.${experience === undefined ? "site" : "experience"}Warning`
            )}
            closable={false}
            showIcon
          />
        )}
        <Alert
          type="info"
          description={
            <div style={{ whiteSpace: "pre-line" }}>{t(`experiences.embed.modal.thirdParty.downloadInfo`)}</div>
          }
          closable={false}
          showIcon
        />
      </Space>
    );
  }

  function renderError() {
    return <Alert closable={false} type="error" message={error} />;
  }

  return (
    <Drawer
      onClose={onClose}
      header={<DrawerHeader title={t("experiences.embed.modal.thirdPartyTagTitle")} />}
      footer={<DrawerClosableFooter actions={renderFooter()} />}
      open={open}
    >
      <Space direction="vertical">
        {renderExplanations()}
        {error && renderError()}
        {!error && loading && renderLoading()}
        {!error && !loading && dspSelected && applicationSelected && renderContent()}
      </Space>
    </Drawer>
  );
}

ThirdPartyTagDrawer.propTypes = {
  open: PropTypes.bool,
  site: PropTypes.object,
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
