import { sortOrder } from "./sort";

export const DEFAULT_PAGE_SIZE = 10;

// eslint-disable-next-line import/prefer-default-export
export const buildOptions = ({ sort, pagination } = {}) => {
  const criteria = {};
  if (sort) {
    criteria.sorting = { field: sort.sortBy, ascending: sort.sortOrder === sortOrder.ASC };
  }
  if (pagination) {
    criteria.range = { start: pagination.offset, end: pagination.offset + pagination.limit - 1 };
  }
  return { criteria: JSON.stringify(criteria) };
};
