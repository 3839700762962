import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@ogury/design-system";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import { path, useAuth, useNotificationService } from "Legacy/utils";
import { authenticationService } from "Legacy/services";
import { AuthFormContainer, CredentialsForm, PublicLayout } from "../../components";
import { SignupConfirmationModal, SignupForm } from "./components";

export default function SignupPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const auth = useAuth();
  const { state } = useLocation();

  const initialFormValue = {
    email: state?.email || "",
    password: "",
  };

  const [working, setWorking] = useState(false);
  const [showSignupConfirmationModal, setShowSignupConfirmationModal] = useState(false);
  const [authCredentials, setAuthCredentials] = useState(/** @type {{formData: object, source: string}} */ undefined);
  const [authenticationData, setAuthenticationData] = useState();
  const [inputEmailValue, setInputEmailValue] = useState(state?.email || "");

  const onEmailAuth = async (formData, source) => {
    setAuthCredentials({ formData, source });
  };

  async function onGoogleAuth(googleResponse, source) {
    setAuthCredentials({
      formData: {
        ...googleResponse,
        email: initialFormValue.email,
      },
      source,
    });
  }

  const setDataAndShowConfirmationModal = formValue => {
    setAuthenticationData(formValue);
    setShowSignupConfirmationModal(true);
  };

  const onSignup = async () => {
    setWorking(true);
    setShowSignupConfirmationModal(false);
    try {
      await authenticationService.signup(authCredentials, authenticationData);
      await auth.authenticateAndLogin(authCredentials.formData, authCredentials.source);
      notificationService.notifySuccess(t("auth.signup.success"));
      setWorking(false);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
      setWorking(false);
    }
  };

  const handleOnClickGoBack = () => {
    setAuthCredentials(undefined);
    setWorking(false);
    // TODO destroy google token
  };

  const renderBody = () => {
    if (authCredentials) {
      return (
        <>
          <div className="auth-form-header">
            <div className="back-button">
              <Button iconPosition="iconOnly" icon={<ArrowLeftSLineIcon />} onClick={handleOnClickGoBack} />
            </div>
            <h1>{t("auth.signup.createYourAccount")}</h1>
          </div>
          <SignupForm onSignup={setDataAndShowConfirmationModal} working={working} />
        </>
      );
    }
    return (
      <>
        <div className="auth-form-header">
          <h1>{t("auth.signup.title")}</h1>
        </div>

        <CredentialsForm
          initialFormValue={initialFormValue}
          working={working}
          forgottenPasswordLink={false}
          actionLabel={t("auth.signup.title")}
          onEmailAuth={onEmailAuth}
          onGoogleAuth={onGoogleAuth}
          setEmail={setInputEmailValue}
        />
      </>
    );
  };

  return (
    <PublicLayout>
      <div className="auth-page">
        <div className="auth-page-header">
          {t("auth.signup.alreadyHaveAnAccount")}
          <Link
            to={{
              pathname: path.LOGIN,
              state: {
                email: inputEmailValue,
              },
            }}
            className="typo-link"
          >
            {t("auth.login.title")}
          </Link>
        </div>
        <div className="auth-form">
          <AuthFormContainer>{renderBody()}</AuthFormContainer>
        </div>
      </div>
      <SignupConfirmationModal
        open={showSignupConfirmationModal}
        authenticationData={authenticationData}
        onCancel={() => setShowSignupConfirmationModal(false)}
        onConfirm={onSignup}
      />
    </PublicLayout>
  );
}
