import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import EmptyImagePlaceholder from "Legacy/assets/img/emptyImagePlaceholder.png";

export default function ImageWithFallback({ src, fallbackSrc = EmptyImagePlaceholder, alt, imageRef, ...rest }) {
  const [imageSource, setImageSource] = useState(fallbackSrc);

  useEffect(() => {
    setImageSource(src || fallbackSrc);
  }, [src, fallbackSrc]);

  return <img ref={imageRef} src={imageSource} alt={alt} onError={() => setImageSource(fallbackSrc)} {...rest} />;
}

ImageWithFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imageRef: PropTypes.object,
};
