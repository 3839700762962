import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { docco } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/cjs/languages/hljs/javascript";

import "./JavaScriptCode.scss";
import { Button } from "@ogury/design-system";
import Download2LineIcon from "remixicon-react/Download2LineIcon";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";
import { helpers } from "Legacy/services";
import { copyText, useNotificationService } from "Legacy/utils";
import { SpacedContainer, SpacedUnits } from "../Layout";

SyntaxHighlighter.registerLanguage("javascript", js);

export default function JavaScriptCode({ code, fileName }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();

  return (
    <div className="javascript-code">
      <SyntaxHighlighter
        language="javascript"
        style={docco}
        customStyle={{ maxHeight: "400px", wordBreak: "break-all" }}
        wrapLines
        wrapLongLines
        showLineNumbers={false}
      >
        {code}
      </SyntaxHighlighter>
      <div className="copy">
        <SpacedContainer horizontal gap={SpacedUnits.XSmall}>
          <Button
            icon={<Download2LineIcon />}
            iconPosition="iconOnly"
            type="secondary"
            size="small"
            onClick={() => {
              FileSaver.saveAs(new Blob([code], { type: helpers.javaScriptMimeType }), fileName);
            }}
          />
          <Button
            icon={<FileCopyLineIcon />}
            iconPosition="iconOnly"
            type="secondary"
            size="small"
            onClick={async () => {
              await copyText(code);
              notificationService.notifyInfo(t("app.notifications.copiedToClipboard"));
            }}
          />
        </SpacedContainer>
      </div>
    </div>
  );
}

JavaScriptCode.propTypes = {
  code: PropTypes.string,
  fileName: PropTypes.string.isRequired,
};
