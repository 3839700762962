import { experienceDescription, getDateDaysFromNow, toUTCDate } from "Legacy/utils";
import {
  convertViewDataApiToUi,
  convertViewDataUiToApi,
  customerService,
  experienceFilterHelper,
  experienceService,
  tagService,
} from "Legacy/services";
import { selectEntry } from "Legacy/components";
import {
  getObject,
  LOCALSTORAGE_CUSTOMER_ANALYTICS_INTERVAL,
  LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW,
  persistObject,
} from "Legacy/utils/storage";
import { dateFormatter } from "Legacy/utils/date";
import MotionlyApiService from "./MotionlyApiService";

const startTimestampProperty = "startTimestamp";
const endTimestampProperty = "endTimestamp";

const computeCurrentWeek = () => {
  const dayOfWeek = (new Date().getUTCDay() + 6) % 7;
  return {
    [startTimestampProperty]: toUTCDate(getDateDaysFromNow(dayOfWeek)).getTime(),
    [endTimestampProperty]: toUTCDate(getDateDaysFromNow(0)).getTime(),
  };
};

const computeLastWeek = () => {
  const dayOfWeek = (new Date().getUTCDay() + 6) % 7;
  return {
    [startTimestampProperty]: toUTCDate(getDateDaysFromNow(dayOfWeek + 7)).getTime(),
    [endTimestampProperty]: toUTCDate(getDateDaysFromNow(dayOfWeek)).getTime(),
  };
};

const computeCurrentMonth = () => {
  const dayOfMonth = new Date().getUTCDate();
  return {
    [startTimestampProperty]: toUTCDate(getDateDaysFromNow(dayOfMonth - 1)).getTime(),
    [endTimestampProperty]: toUTCDate(getDateDaysFromNow(0)).getTime(),
  };
};

const computeLastMonth = () => {
  const now = new Date();
  const dayOfMonth = now.getUTCDate() - 1;
  const endDate = getDateDaysFromNow(dayOfMonth);
  const dayWithinPreviousMonthDate = now;
  // Taken from https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
  const numberOfDaysInMonth = new Date(
    dayWithinPreviousMonthDate.getUTCFullYear(),
    dayWithinPreviousMonthDate.getUTCMonth(),
    0
  ).getDate();
  return {
    [startTimestampProperty]: toUTCDate(getDateDaysFromNow(dayOfMonth + numberOfDaysInMonth)).getTime(),
    [endTimestampProperty]: toUTCDate(endDate).getTime(),
  };
};

const computeDaysFromNow = daysFromNow => ({
  [startTimestampProperty]: toUTCDate(getDateDaysFromNow(daysFromNow)).getTime(),
  [endTimestampProperty]: toUTCDate(getDateDaysFromNow(0)).getTime(),
});

// Remembers when the interval options were lastly computed
let intervalOptionsComputationDate;
export const cachedIntervalOptions = [];

const convertTimestampsForQuery = (intervalOptions, intervalOption) =>
  intervalOptions.find(object => object.value === intervalOption).compute();

export const getIntervalOptions = t => {
  const now = new Date();
  if (
    intervalOptionsComputationDate === undefined ||
    now.getUTCDate() !== intervalOptionsComputationDate.getUTCDate()
  ) {
    // We need to (re)compute the interval options
    cachedIntervalOptions.length = 0;
    let intervalOptionsIndex = 1;
    // intervalOptions.push({
    //   label: "Specific",
    //   // eslint-disable-next-line no-plusplus
    //   value: intervalOptionsIndex++,
    //   compute: () => ({
    //     startDate: 1609459200000,
    //     endDate: 1625097600000,
    //   }),
    // });
    if (now.getUTCDay() > 1) {
      // We only add this option provided the current day is Tuesday or later
      cachedIntervalOptions.push({
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.currentWeek")),
        compute: computeCurrentWeek,
      });
    }
    cachedIntervalOptions.push(
      {
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.sevenDays")),
        compute: () => computeDaysFromNow(7),
      },
      // eslint-disable-next-line no-plusplus
      { ...selectEntry(intervalOptionsIndex++, t("analytics.filters.lastWeek")), compute: computeLastWeek }
    );
    if (now.getUTCDate() > 1) {
      // We only add this option provided the current day is not the first day of the month
      cachedIntervalOptions.push({
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.currentMonth")),
        compute: computeCurrentMonth,
      });
    }
    cachedIntervalOptions.push(
      {
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.thirtyDays")),
        compute: () => computeDaysFromNow(30),
      },
      // eslint-disable-next-line no-plusplus
      { ...selectEntry(intervalOptionsIndex++, t("analytics.filters.lastMonth")), compute: computeLastMonth },
      {
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.sixtyDays")),
        compute: () => computeDaysFromNow(60),
      },
      {
        // eslint-disable-next-line no-plusplus
        ...selectEntry(intervalOptionsIndex++, t("analytics.filters.ninetyDays")),
        compute: () => computeDaysFromNow(90),
      }
    );

    intervalOptionsComputationDate = now;
  }
  return cachedIntervalOptions;
};

export const analyticsFilters = {
  INTERVAL: Symbol("interval"),
};

export const analyticsEvents = {
  FAIL: "Fail",
  REQUEST: "Request",
  LOAD: "Load",
  DISPLAY: "Display",
  VIEW: "View",
  SHOW: "Show",
  SCROLL: "Scroll",
  REACH: "Reach",
  COMPLETE: "Complete",
  OPEN: "Open",
};

export const analyticsColor = {
  [analyticsEvents.REQUEST]: "#ABABAB",
  [analyticsEvents.FAIL]: "#FF0000",
  [analyticsEvents.LOAD]: "#787878",
  [analyticsEvents.DISPLAY]: "#297209",
  [analyticsEvents.VIEW]: "#40B30D",
  [analyticsEvents.SHOW]: "#85CF65",
  [analyticsEvents.SCROLL]: "#7E2E75",
  [analyticsEvents.REACH]: "#E653D5",
  [analyticsEvents.COMPLETE]: "#EC81E0",
  [analyticsEvents.OPEN]: "#0433FF",
};

export const allAnalyticsEvents = Object.values(analyticsEvents);

export const standardAnalyticsEvents = [
  analyticsEvents.REQUEST,
  analyticsEvents.FAIL,
  analyticsEvents.LOAD,
  analyticsEvents.DISPLAY,
  analyticsEvents.VIEW,
  analyticsEvents.SHOW,
  analyticsEvents.OPEN,
];

export const specificAnalyticsEvents = Object.values(analyticsEvents).filter(
  event => standardAnalyticsEvents.indexOf(event) === -1
);

export const COLUMN_NAME_EXPERIENCE_ID = "id";

const lightenColor = (color, amount) =>
  `#${color
    .replace(/^#/, "")
    // eslint-disable-next-line no-shadow
    .replace(/../g, color => `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2))}`;

const analyticsEventsChartProps = (event, order) => ({
  color: analyticsColor[event],
  order,
  borderColor: lightenColor(analyticsColor[event], 40),
  cubicInterpolationMode: "monotone",
  tension: 1,
  spanGaps: false,
});

const surface = (event, order) => ({
  ...analyticsEventsChartProps(event, order),
  fill: true,
  // backgroundColor: lightenColor(analyticsColor[event], 40),
});
const analyticsEventsDatasetChartProps = {
  [analyticsEvents.REQUEST]: surface(analyticsEvents.REQUEST, 10),
  [analyticsEvents.FAIL]: surface(analyticsEvents.FAIL, 9),
  [analyticsEvents.LOAD]: surface(analyticsEvents.LOAD, 8),
  [analyticsEvents.DISPLAY]: analyticsEventsChartProps(analyticsEvents.DISPLAY, 7),
  [analyticsEvents.VIEW]: analyticsEventsChartProps(analyticsEvents.VIEW, 6),
  [analyticsEvents.SHOW]: analyticsEventsChartProps(analyticsEvents.SHOW, 5),
  [analyticsEvents.SCROLL]: analyticsEventsChartProps(analyticsEvents.SCROLL, 4),
  [analyticsEvents.REACH]: analyticsEventsChartProps(analyticsEvents.REACH, 3),
  [analyticsEvents.COMPLETE]: analyticsEventsChartProps(analyticsEvents.COMPLETE, 2),
  [analyticsEvents.OPEN]: analyticsEventsChartProps(analyticsEvents.OPEN, 1),
};

const generateLabelsAndDatasets = ({ multipleDatedEventCounts, datedEventCounts }) => {
  const temporaryTimestampsSet = new Set();
  const temporaryEventsSet = new Set();
  const temporaryExperienceIdsArray = [];
  const chartData = {
    datasets: [],
  };

  function generate(_datedEventCounts) {
    // eslint-disable-next-line array-callback-return
    _datedEventCounts?.map(datedEvent => {
      temporaryTimestampsSet.add(datedEvent.date.getTime());
      datedEvent.eventCounts?.map(event => temporaryEventsSet.add(event.event));
    });
  }

  if (multipleDatedEventCounts) {
    // generation for multiple experiences
    // eslint-disable-next-line array-callback-return
    multipleDatedEventCounts.map(multipleDateEvent => {
      temporaryExperienceIdsArray.push(multipleDateEvent.id);
      generate(multipleDateEvent.datedEventCounts);
    });
  }

  if (datedEventCounts) {
    // generation for one experience
    generate(datedEventCounts);
  }

  chartData.labels = Array.from(temporaryTimestampsSet)
    .sort((object1, object2) => {
      if (object1 < object2) {
        return -1;
      }
      if (object1 > object2) {
        return 1;
      }
      return 0;
    })
    .map(timestamp => dateFormatter.timestampToShortDate(timestamp));

  // Initialize chart label and datasets - structure
  Array.from(temporaryEventsSet).map(datasetName =>
    chartData.datasets.push({
      label: datasetName,
      data: [],
      ...analyticsEventsDatasetChartProps[datasetName],
    })
  );

  return { chartData, tempExperiencesIdArray: temporaryExperienceIdsArray };
};

/* eslint-disable no-param-reassign */
function generateData(cardsData, chartData, tableData, eventCount, dateLabel) {
  if (cardsData[eventCount.event] === undefined) {
    cardsData[eventCount.event] = 0;
  }
  cardsData[eventCount.event] += eventCount.count || 0;

  if (tableData.events[eventCount.event] === undefined) {
    tableData.events[eventCount.event] = 0;
  }
  tableData.events[eventCount.event] += eventCount.count || 0;

  const currentDataSet = chartData.datasets.find(obj => obj.label === eventCount.event);
  if (currentDataSet === undefined) {
    // TODO: refactor the code, so that it does not happen!
    // eslint-disable-next-line no-console
    console.error("error");
    return;
  }

  // eslint-disable-next-line array-callback-return
  chartData.labels.map((label, index) => {
    if (currentDataSet.data[index] === undefined) {
      currentDataSet.data[index] = 0;
    }
    // TODO: change this ugly thing!
    if (label === dateLabel) {
      currentDataSet.data[index] += eventCount.count || 0;
    }
  });
}

const filterChartDataByEvents = (cardsData, chartData, tableData, experiences, eventsScope) => ({
  cardsData,
  tableData,
  experiences,
  chartData: {
    ...chartData,
    datasets: chartData.datasets?.filter(dataset => eventsScope.includes(dataset.label)),
  },
});

const fillMissingEvents = (events, eventCounts) => {
  // const missingEvents = events.filter(eventScope => !eventCounts.some(eventCount => eventCount.event === eventScope));
  // eslint-disable-next-line array-callback-return
  // missingEvents.map(event => eventCounts.push(new EventCount(event, 0)));
  return eventCounts;
};

const processCustomerAnalytics = async (metrics, events = standardAnalyticsEvents) => {
  // Cards data
  const cardsData = {
    [analyticsEvents.DISPLAY]: 0,
  };

  // Table data
  const tableData = [];

  // Chart Data and array of experiences ID
  const { chartData, tempExperiencesIdArray } = generateLabelsAndDatasets({
    multipleDatedEventCounts: metrics.multipleDateEventCounts,
  });

  const experiencesSummaryList = await experienceService.getExperiencesSummaryList(tempExperiencesIdArray);
  const experiences = {};

  // eslint-disable-next-line array-callback-return
  metrics.multipleDateEventCounts?.map(multipleDateEvent => {
    const experience = experiencesSummaryList?.find(experienceSummary => experienceSummary.id === multipleDateEvent.id);
    experiences[experience.id] = experience;

    const experienceTableData = {
      experience,
      events: {},
    };
    const { datedEventCounts } = multipleDateEvent;
    if (datedEventCounts !== undefined) {
      datedEventCounts.map(async datedEventCount => {
        const { eventCounts } = datedEventCount;
        if (eventCounts !== undefined) {
          // eslint-disable-next-line array-callback-return
          fillMissingEvents(events, eventCounts).map(eventCount => {
            generateData(
              cardsData,
              chartData,
              experienceTableData,
              eventCount,
              dateFormatter.timestampToShortDate(datedEventCount.date)
            );
          });
        }
      });
    }
    tableData.push(experienceTableData);
  });

  return filterChartDataByEvents(cardsData, chartData, tableData, experiences, events);
};

const processAnalyticsByExperience = (metrics, events = standardAnalyticsEvents, experiences) => {
  const { datedEventCounts } = metrics;

  // We do not need to add missing dates, because the web service already performs it

  // We add the missing events
  // eslint-disable-next-line array-callback-return
  datedEventCounts.map(datedEventCount => {
    fillMissingEvents(events, datedEventCount?.eventCounts);
  });

  // Cards data
  const cardsData = {};

  // Table data
  const tableData = [];

  // Chart data
  const { chartData } = generateLabelsAndDatasets({
    datedEventCounts,
  });

  // eslint-disable-next-line array-callback-return
  datedEventCounts.map(datedEventCount => {
    const dateLabel = dateFormatter.timestampToShortDate(datedEventCount.date);

    const data = {
      date: dateLabel,
      events: {},
    };
    const { eventCounts } = datedEventCount;
    if (eventCounts !== undefined) {
      // eslint-disable-next-line array-callback-return
      eventCounts.map(eventCount => {
        generateData(cardsData, chartData, data, eventCount, dateLabel);
      });
    }
    tableData.push(data);
  });

  return filterChartDataByEvents(cardsData, chartData, tableData, experiences, events);
};

const computeEventsRowsAndColumns = async responseData => {
  const EXPERIENCE_DATA = "experienceData";

  // if one of the row cell contains an experience ID, so we need to load experiences summary list in order to retrieve experience name
  let dataHaveExperienceToLoad = false;

  const columns = [];
  const tableData = [];
  const tempExperiencesIdArray = new Set();

  if (responseData.columns?.length > 0) {
    // eslint-disable-next-line array-callback-return
    responseData.columns.map(aColumn => {
      const column = {
        title: aColumn.name,
        field: aColumn.name,
        sortable: true,
      };
      if (aColumn.name === COLUMN_NAME_EXPERIENCE_ID) {
        // one of the row cell contains an experience ID
        dataHaveExperienceToLoad = true;
        column.field = EXPERIENCE_DATA;
        column.sortKey = COLUMN_NAME_EXPERIENCE_ID;
      }
      columns.push(column);
    });
  }
  if (responseData.rows?.length > 0) {
    // eslint-disable-next-line array-callback-return
    responseData.rows.map(row => {
      const tmpRow = {};
      // eslint-disable-next-line array-callback-return
      row.map((data, dataIndex) => {
        // eslint-disable-next-line array-callback-return
        responseData.columns.map((col, colIndex) => {
          if (dataIndex === colIndex) {
            tmpRow[col.name] = data;
            if (col.name === COLUMN_NAME_EXPERIENCE_ID) {
              tempExperiencesIdArray.add(data);
            }
          }
        });
      });
      tableData.push(tmpRow);
    });

    // One of the row cell contains an experience ID
    if (dataHaveExperienceToLoad) {
      // Loading experiences summary list
      const experiencesSummaryList = await experienceService.getExperiencesSummaryList(
        Array.from(tempExperiencesIdArray)
      );

      tableData.map(row => {
        if (row[COLUMN_NAME_EXPERIENCE_ID] !== undefined) {
          // eslint-disable-next-line no-param-reassign
          row[EXPERIENCE_DATA] = experiencesSummaryList?.find(obj => obj.id === row.id);
        }
        return row;
      });
    }
  }

  return { columns, tableData };
};

const applyAnalyticsSortAndPagination = (tableData, { sort, pagination } = {}) => {
  let filteredTableData = [...tableData];
  const totalFiltered = tableData.length;

  if (sort) {
    const { sortBy = "name", sortOrder = 1 } = sort;

    filteredTableData = filteredTableData.sort((a, b) =>
      a.events[sortBy] > b.events[sortBy] ? sortOrder : sortOrder * -1
    );
  }

  if (pagination) {
    const { offset = 0, limit = 25 } = pagination;
    filteredTableData = filteredTableData.slice(offset, offset + limit);
  }

  return {
    tableData: filteredTableData,
    totalTableData: totalFiltered,
  };
};

const applyEventsSortAndPagination = (tableData, { sort, pagination } = {}) => {
  let filteredTableData = [...tableData];
  const totalFiltered = tableData.length;

  if (sort) {
    const { sortBy = "id", sortOrder = 1 } = sort;
    filteredTableData = filteredTableData.sort((a, b) => (a[sortBy] > b[sortBy] ? sortOrder : sortOrder * -1));
  }

  if (pagination) {
    const { offset = 0, limit = 25 } = pagination;
    filteredTableData = filteredTableData.slice(offset, offset + limit);
  }

  return {
    tableData: filteredTableData,
    totalTableData: totalFiltered,
  };
};

// noinspection JSUnresolvedVariable
const GRANULARITY = "Day";

const buildSearchOptions = ({ filters } = {}) => {
  const criteria = {};

  if (filters) {
    criteria.filters = experienceFilterHelper.uiToApi(filters);
  }
  return JSON.stringify(criteria);
};

const EVENTS_COUNT = 20;

// TODO: a cache re-initialization method is required
class AnalyticsService {
  analytics = {};

  analyticsByExperience = {};

  events = {};

  eventsByExperience = {};

  async getCustomerEvents(searchCriteria, forceFetch = false) {
    if (Object.keys(this.events).length === 0 || forceFetch) {
      // noinspection JSCheckFunctionSignatures
      const events = await MotionlyApiService.analyticsApi().getEvents(
        customerService.getCurrentCustomerSummaryId(),
        EVENTS_COUNT,
        {}
      );

      this.events = await computeEventsRowsAndColumns(events);
    }

    return {
      ...this.events,
      // Apply local pagination and sorting
      ...applyEventsSortAndPagination(this.events.tableData, searchCriteria),
    };
  }

  async getExperienceEvents(experienceId, searchCriteria, forceFetch = false) {
    if (Object.keys(this.eventsByExperience).length === 0 || !this.eventsByExperience[experienceId] || forceFetch) {
      // noinspection JSCheckFunctionSignatures
      const eventsByExperience = await MotionlyApiService.analyticsApi().getEvents(
        customerService.getCurrentCustomerSummaryId(),
        EVENTS_COUNT,
        {
          id: experienceId,
        }
      );
      this.eventsByExperience[experienceId] = await computeEventsRowsAndColumns(eventsByExperience);
    }
    return {
      ...this.eventsByExperience[experienceId],
      // Apply local pagination and sorting
      ...applyEventsSortAndPagination(this.eventsByExperience[experienceId].tableData, searchCriteria),
    };
  }

  async getCustomerAnalytics(intervalOptions, intervalOption, searchCriteria, forceFetch) {
    const { startTimestamp, endTimestamp } = convertTimestampsForQuery(intervalOptions, intervalOption);
    const criteria = buildSearchOptions(searchCriteria);

    if (forceFetch || Object.keys(this.analytics).length === 0) {
      // noinspection JSCheckFunctionSignatures
      const metrics = await MotionlyApiService.metricsApi().multipleGetByFilter(
        customerService.getCurrentCustomerSummaryId(),
        criteria,
        GRANULARITY,
        startTimestamp,
        endTimestamp
      );
      if (metrics.multipleDateEventCounts?.length === 0) {
        return {};
      }
      const events = standardAnalyticsEvents;
      this.analytics = { events, metrics, ...(await processCustomerAnalytics(metrics, events)) };
    }
    return {
      ...this.analytics,
      // Apply local pagination and sorting
      ...applyAnalyticsSortAndPagination(this.analytics.tableData, searchCriteria),
      ...{
        interval: {
          startDate: startTimestamp,
          endDate: endTimestamp,
        },
      },
    };
  }

  async getExperienceAnalytics(experience, intervalOptions, intervalOption, searchCriteria, forceFetch) {
    const { startTimestamp, endTimestamp } = convertTimestampsForQuery(intervalOptions, intervalOption);
    const experienceId = experience.id;
    if (
      Object.keys(this.analyticsByExperience).length === 0 ||
      !this.analyticsByExperience[experienceId] ||
      forceFetch
    ) {
      const metrics = await MotionlyApiService.metricsApi().getExperience(
        customerService.getCurrentCustomerSummaryId(),
        experienceId,
        GRANULARITY,
        startTimestamp,
        endTimestamp
      );
      const events = [
        analyticsEvents.REQUEST,
        analyticsEvents.FAIL,
        analyticsEvents.LOAD,
        analyticsEvents.DISPLAY,
        analyticsEvents.VIEW,
        analyticsEvents.SHOW,
      ];
      if (experience.description === undefined) {
        // This must be a dynamic experience
        const additionalEvents = {};
        // eslint-disable-next-line array-callback-return
        metrics.scores.map(score => {
          additionalEvents[score.event] = true;
        });
        // eslint-disable-next-line array-callback-return
        allAnalyticsEvents.map(event => {
          if (additionalEvents[event] !== undefined && events.indexOf(event) === -1) {
            events.push(event);
          }
        });
      } else {
        if (
          experienceDescription.hasScore(experience.description, experienceDescription.SCORE_MODES.scrolling) === true
        ) {
          events.push(analyticsEvents.SCROLL);
        }
        if (
          experienceDescription.hasScore(experience.description, experienceDescription.SCORE_MODES.inscroll) === true
        ) {
          events.push(analyticsEvents.REACH);
          events.push(analyticsEvents.COMPLETE);
        }
        if (
          experienceDescription.hasAction(experience.description, experienceDescription.ACTION_MODES.same) === true ||
          experienceDescription.hasAction(experience.description, experienceDescription.ACTION_MODES.new) === true
        ) {
          events.push(analyticsEvents.OPEN);
        }
      }

      if (events.length <= 0 || events[0] !== analyticsEvents.REQUEST) {
        // We add the "Request" event, because it does not contain a "score" property
        events.unshift(analyticsEvents.REQUEST);
      }
      metrics.id = experienceId;
      this.analyticsByExperience[experienceId] = {
        events,
        metrics,
        ...processAnalyticsByExperience(metrics, events, { [experienceId]: experience }),
      };
    }
    return {
      ...this.analyticsByExperience[experienceId],
      // Apply local pagination and sorting
      ...applyAnalyticsSortAndPagination(this.analyticsByExperience[experienceId].tableData, searchCriteria),
      ...{
        interval: {
          startDate: startTimestamp,
          endDate: endTimestamp,
        },
      },
    };
  }

  setLastClientView(view) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW);

    if (view) {
      // We associate the current view to the current customer
      // Need to convert the view into api format before storage
      const dataToStore = { ...storedView, [customerId]: { ...view, data: convertViewDataUiToApi(view.data) } };
      persistObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW, dataToStore);
    } else {
      // Erase client view for current customer
      delete storedView[customerId];
      persistObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW, storedView);
    }
  }

  // Get last view used by this client, for the current customer
  async getLastClientView() {
    const allTags = await tagService.listTags();
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW);
    const customerId = customerService.getCurrentCustomerSummaryId();

    if (storedView && storedView[customerId]) {
      // Need to convert the view into UI format
      return { ...storedView[customerId], data: convertViewDataApiToUi(storedView[customerId].data, allTags) };
    }
    return null;
  }

  getLastIntervalValue() {
    const storedIntervalValue = getObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_INTERVAL);
    const customerId = customerService.getCurrentCustomerSummaryId();

    if (storedIntervalValue && storedIntervalValue[customerId]) {
      return storedIntervalValue[customerId];
    }
    return null;
  }

  setLastIntervalValue(intervalValue) {
    const storedIntervalValue = getObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_INTERVAL);
    const customerId = customerService.getCurrentCustomerSummaryId();
    if (intervalValue) {
      const dataToStore = { ...storedIntervalValue, [customerId]: intervalValue };
      persistObject(LOCALSTORAGE_CUSTOMER_ANALYTICS_INTERVAL, dataToStore);
    }
  }
}

export default new AnalyticsService();
