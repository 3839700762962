import React, { useCallback, useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";

import "./ExperiencePlayer.scss";
import { customerService, motionlyService } from "Legacy/services";
import LoadDots from "../../../../components/LoadDots/LoadDots";

const doNotLoadSdk = false;
// TODO: introduce a variable instead
const applicationId = "preview";

export default function ExperiencePlayer({
  experience,
  trackId,
  percentage,
  onReady,
  controller,
  backgroundColor = "#FFFFFF",
}) {
  const [interval, setInterval] = useState();
  const [src, setSrc] = useState("");
  const [isImageVisible, setImageVisible] = useState(false);
  const [motionlyLoaded, setMotionlyLoaded] = useState(false);
  const [elementId, setElementId] = useState();
  const [loadingElementId, setLoadingElementId] = useState();
  const [handler, setHandler] = useState();
  const containerRef = useRef();
  /**
   * @type {React.MutableRefObject<HTMLImageElement>}
   */
  const imageRef = useRef();

  const frontBaseUrl = motionlyService.getFrontBaseUrl();
  const customerPublicId = customerService.getCurrentCustomerPublicId();

  useEffect(() => {
    const newSrc = `${frontBaseUrl}/front/3/svg/nm/${customerPublicId}-e-${experience.id}.svg`;
    if (newSrc !== src) {
      setImageVisible(false);
      imageRef.current.onload = () => {
        if (imageRef.current !== null) {
          imageRef.current.onload = null;
        }
        setImageVisible(true);
      };
      setSrc(newSrc);
    }
  }, [frontBaseUrl, customerPublicId, experience, src]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        if (containerRef.current !== null) {
          const style = window.getComputedStyle(containerRef.current);
          const image = imageRef.current;
          image.style.maxWidth = style.width;
          image.style.maxHeight = style.height;
        }
      });
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, imageRef]);

  // noinspection JSUnresolvedFunction
  const getExperienceContext = useCallback(theHandler => theHandler.delegate.getExperienceContext(), []);

  const goto = useCallback(
    (theHandler, theTrackId, thePercentage) => {
      // noinspection JSUnresolvedVariable,JSUnresolvedFunction
      try {
        getExperienceContext(theHandler).goto(theTrackId, thePercentage / 100, true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `An unexpected error occurred while attempting to go to the track with id '${theTrackId}'`,
          error
        );
      }
    },
    [getExperienceContext]
  );

  const onReadyCallback = useCallback(
    theHandler => {
      if (onReady !== undefined) {
        onReady();
      }
      if (controller !== undefined) {
        controller({
          capture: async () =>
            new Promise((resolve, reject) => {
              // noinspection JSUnresolvedFunction
              theHandler.delegate.exportImage(
                trackId,
                percentage,
                "png",
                100,
                null,
                blob => {
                  resolve(window.URL.createObjectURL(blob));
                },
                error => {
                  reject(error);
                }
              );
            }),
        });
      }
    },
    [onReady, controller, trackId, percentage]
  );

  useEffect(() => {
    if (isImageVisible === true) {
      const scriptId = "motionly-script";
      let script = document.getElementById(scriptId);
      // noinspection JSUnresolvedVariable
      if (window.motionly === undefined || script === null) {
        script = document.createElement("script");
        script.onload = async () => {
          setMotionlyLoaded(true);
        };
        if (doNotLoadSdk === false) {
          script.src = `${frontBaseUrl}/javascript/1/sdk/?customerId=${customerPublicId}&applicationId=${applicationId}`;
        } else {
          setMotionlyLoaded(true);
        }
        script.id = scriptId;
        script.async = true;
        document.head.appendChild(script);
      } else {
        setMotionlyLoaded(true);
      }
      return () => {
        document.head.removeChild(script);
        setMotionlyLoaded(false);
      };
    }
    return undefined;
  }, [frontBaseUrl, customerPublicId, isImageVisible]);

  useEffect(
    () => () => {
      if (interval !== undefined) {
        window.clearInterval(interval);
      }
    },
    [interval]
  );

  useEffect(() => {
    setElementId(experience.id);
  }, [experience]);

  useEffect(() => {
    if (motionlyLoaded === true) {
      if (loadingElementId !== elementId) {
        const { motionly } = window;
        if (motionly !== undefined) {
          const parameters = { transition: "none", setup: { runMode: "manual" } };
          // noinspection JSUnresolvedVariable,JSUnresolvedFunction
          imageRef.current.setAttribute(motionly.AutoLoader.parametersAttribute, JSON.stringify(parameters));
          // noinspection JSUnresolvedVariable,JSUnresolvedFunction
          const theHandler = motionly.Initializer.initializer.getHandlerById(elementId);
          if (theHandler !== null) {
            setHandler(theHandler);
            onReadyCallback(theHandler);
          } else {
            const theInterval = window.setInterval(() => {
              // noinspection JSUnresolvedVariable,JSUnresolvedFunction
              const otherHandler = motionly.Initializer.initializer.getHandlerById(elementId);
              // noinspection JSUnresolvedVariable,JSUnresolvedFunction
              if (
                otherHandler !== null &&
                getExperienceContext(otherHandler).hasState(motionly.HandlerStatusCodes.Installed) === true
              ) {
                window.clearInterval(theInterval);
                setInterval(undefined);
                setHandler(otherHandler);
                onReadyCallback(otherHandler);
              }
            }, 1000 / 60);
            setInterval(theInterval);
            setLoadingElementId(elementId);
          }
        } else if (doNotLoadSdk === true) {
          setHandler("dummy");
        }
      }
    }
  }, [frontBaseUrl, motionlyLoaded, elementId, loadingElementId, getExperienceContext, onReadyCallback]);

  useEffect(() => {
    if (motionlyLoaded === true) {
      if (doNotLoadSdk === false && handler !== undefined) {
        goto(handler, trackId, percentage);
      }
    }
  }, [trackId, percentage, isImageVisible, motionlyLoaded, handler, goto]);

  useEffect(() => {
    if (handler !== undefined) {
      // noinspection JSUnresolvedVariable
      handler.delegate.domElement.style.backgroundColor = backgroundColor;
    }
  }, [backgroundColor, handler]);

  return (
    <div ref={containerRef} className="experience-player-container">
      <img
        className="experience"
        id={elementId}
        alt="experience"
        ref={imageRef}
        src={src}
        style={{ background: backgroundColor, visibility: isImageVisible === true ? "visible" : "hidden" }}
      />
      {handler === undefined && (
        <div className="loader">
          <LoadDots secondary />
        </div>
      )}
    </div>
  );
}

ExperiencePlayer.propTypes = {
  experience: PropTypes.shape({ id: PropTypes.string }).isRequired,
  trackId: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  onReady: PropTypes.func,
  controller: PropTypes.func,
  backgroundColor: PropTypes.string,
};
