import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Tag as TagModel } from "@ogury/motionly-ws-api/ws";

import { FilterBar, FormControl } from "Legacy/components";
import { customerService, siteFilters, tagService, unitService, userService } from "Legacy/services";
import { applyGuestSpecificities, Filters, useNotificationService } from "Legacy/utils";

/* eslint-disable no-unused-vars */
export default function SitesFilters({
  tags,
  searchText,
  onSearchChange,
  filtersValue = [],
  onChange,
  onCleared,
  onSearch,
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [usersOptions, setUsersOptions] = useState([]);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [adUnitsOptions, setAdUnitsOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);

  useEffect(() => {
    if (!tags) {
      return;
    }

    let isMounted = true;
    const loadOptions = async () => {
      try {
        const users = await customerService.listCurrentCustomerUsers().then(customerService.filterPendingUsers);

        // prevent setting state when component is unmouting(memory leak)
        if (!isMounted) return;

        setUsersOptions(Filters.buildOptions(users, user => `(${user.email})`));
        setTemplatesOptions(Filters.buildOptions(tagService.filterByCategory(tags, TagModel.CategoryEnum.Template)));
        setBrandsOptions(Filters.buildOptions(tagService.filterByCategory(tags, TagModel.CategoryEnum.Brand)));
        setTagsOptions(Filters.buildOptions(tagService.filterByCategory(tags, TagModel.CategoryEnum.Customer)));
        setAdUnitsOptions(unitService.list().map(unit => ({ value: unit.technicalId, label: unit.name })));
      } catch (error) {
        notificationService.notifyError(error, "The filters data could not be loaded");
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadOptions();

    return () => {
      isMounted = false;
    };
  }, [tags]);

  const defaultFilters = [siteFilters.TEMPLATE, siteFilters.AD_UNIT, siteFilters.OWNER];

  // const statusOptions = computeValueLabels(Site.StateEnum).sort((a, b) => (a.label > b.label ? 1 : -1));
  const filterBarConfig = [
    {
      filterKey: siteFilters.TEMPLATE,
      label: "filters.template",
      type: "multiselect",
      options: templatesOptions.filter(item => item.value !== "custom"), // due to backend limitations we cannot filter by custom template
      placeholder: t("filters.templatePlaceholder"),
    },
    {
      filterKey: siteFilters.AD_UNIT,
      label: "filters.adUnit",
      type: "select",
      options: adUnitsOptions,
      placeholder: t("filters.adUnitPlaceholder"),
    },
    /*  {
      filterKey: siteFilters.STATUS,
      label: "filters.status",
      type: "select",
      options: statusOptions,
      placeholder: t("filters.statusPlaceholder"),
    }, */
    {
      filterKey: siteFilters.CUSTOMER_TAGS,
      label: "filters.customerTags",
      type: "multiselect",
      options: tagsOptions,
      placeholder: t("filters.tagsPlaceholder"),
    },
    {
      filterKey: siteFilters.BRAND,
      label: "filters.brand",
      type: "multiselect",
      options: brandsOptions,
      placeholder: t("filters.brandPlaceholder"),
    },
  ];
  if (!applyGuestSpecificities(customerService.getCurrentCustomerSummaryId(), userService.getCurrentUserEmail())) {
    filterBarConfig.push({
      filterKey: siteFilters.OWNER,
      label: "filters.owner",
      type: "select",
      options: usersOptions,
      placeholder: t("filters.ownerPlaceholder"),
    });
  }

  return (
    <FilterBar
      searchText={searchText}
      preFilter={
        <div className="filter-control" style={{ position: "relative", top: -2 }}>
          {/* styling quick fix until filters refactoring */}
          <div className="form-label" style={{ marginBottom: 7 }}>
            {t("actions.search")}
          </div>
          <FormControl
            type="search"
            value={searchText}
            onChange={onSearchChange}
            placeholder={t("filters.quickSearchPlaceholder")}
            withPadding={false}
            allowClear={false}
          />
        </div>
      }
      filtersConfig={filterBarConfig}
      filtersEnum={siteFilters}
      defaultFilters={defaultFilters}
      value={filtersValue}
      onChange={onChange}
      onCleared={onCleared}
      onSearch={onSearch}
    />
  );
}

SitesFilters.propTypes = {
  filtersValue: PropTypes.arrayOf(PropTypes.shape({})),
  tags: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onCleared: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
};
