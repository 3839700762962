import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import FileSaver from "file-saver";
import { Modal } from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { experienceService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";

export default function ExperienceIntegrationECommerceModal({ experience, open, renderLoader, onWorking, onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();

  useEffect(() => {
    if (!open) {
      return;
    }

    const loadData = async () => {
      onWorking(true);
      try {
        try {
          const responseWrapper = await experienceService.getPosterWithHttpInfo(experience.id);
          const posterBlob = responseWrapper.data;
          const contentDisposition = responseWrapper.response.headers["Content-Disposition".toLowerCase()];
          const fileName = contentDisposition.substring(contentDisposition.lastIndexOf("''") + 2);
          FileSaver.saveAs(posterBlob, fileName);
        } finally {
          // In all cases, we wish to close the modal dialog
          onClose();
        }
      } catch (error) {
        notificationService.notifyError(error);
      } finally {
        onWorking(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
  }, [open, experience, onWorking, onClose]);

  return (
    <Modal open={open} onCancel={onClose} title={t("experiences.embed.modal.title")}>
      {renderLoader()}
    </Modal>
  );
}

ExperienceIntegrationECommerceModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool,
  experience: PropTypes.object.isRequired,
  renderLoader: PropTypes.func.isRequired,
  onWorking: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
