import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddLineIcon from "remixicon-react/AddLineIcon";
import SettingsLineIcon from "remixicon-react/SettingsLineIcon";

import "./NavbarCustomerMenu.scss";
import { Dropdown } from "@ogury/design-system";
import { ImageWithFallback } from "Legacy/components";
import { applyGuestSpecificities, path, useAuth } from "Legacy/utils";
import { customerPermissions, customerService, userService } from "Legacy/services";
import sampleCustomerImage from "Legacy/assets/img/sampleCustomerImage.png";
import { ChangeCustomerConfirmationModal, CreateCustomerModal } from "./components";

export default function NavbarCustomerMenu() {
  const [t] = useTranslation();
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);

  const [changeCustomerConfirmationModal, setChangeCustomerConfirmationModal] = useState();
  const auth = useAuth();

  function handleOnClickChangeCustomer(customer) {
    if (customer.id !== auth.currentCustomerSummary.id) {
      setChangeCustomerConfirmationModal(customer);
    }
  }

  function handleOnClickCreateCustomer() {
    setShowCreateCustomerModal(true);
  }

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          selectedKeys: [auth.currentCustomerSummary.id?.toString()],
          items: [
            applyGuestSpecificities(
              customerService.getCurrentCustomerSummaryId(),
              userService.getCurrentUserEmail()
            ) === false &&
              customerPermissions.canCreateCustomer() === true && {
                key: "create",
                icon: <AddLineIcon size={16} />,
                onClick: handleOnClickCreateCustomer,
                label: t("navbar.customer.createCustomer"),
              },
            {
              key: "settings",
              icon: <SettingsLineIcon size={16} />,
              label: <Link to={path.CUSTOMERS}>{t("navbar.customer.settings")}</Link>,
            },
            {
              type: "divider",
            },
            ...auth.customersSummaryList.map(customer => ({
              key: customer.id,
              active: customer.id?.toString() === auth.currentCustomerSummary.id?.toString(),
              icon: (
                <ImageWithFallback
                  className="menu-item-image"
                  src={customer.logoImageUrl}
                  alt="Organization avatar"
                  fallbackSrc={sampleCustomerImage}
                />
              ),
              onClick: () => handleOnClickChangeCustomer(customer),
              label: customer.name,
            })),
          ],
        }}
      >
        <ImageWithFallback
          src={auth.currentCustomerSummary.logoImageUrl}
          className="navbar-customer-menu-avatar"
          alt="Organization Logo"
          fallbackSrc={sampleCustomerImage}
        />
      </Dropdown>
      <ChangeCustomerConfirmationModal
        open={changeCustomerConfirmationModal}
        customer={changeCustomerConfirmationModal}
        onCancel={() => setChangeCustomerConfirmationModal()}
      />
      <CreateCustomerModal open={showCreateCustomerModal} onCancel={() => setShowCreateCustomerModal(false)} />
    </>
  );
}
