import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./FilterControl.scss";
import { Input, Select } from "@ogury/design-system";
import { SearchInput, RadioGroup, Radio } from "../../../Form";

const debounceDelay = 300;

const controlTypes = {
  SEARCH_INPUT: "searchinput",
  MULTISELECT: "multiselect",
  SELECT: "select",
  RADIO: "radio",
};

export default function FilterControl({ type, label = "", value = [], onChange = () => {}, ...props }) {
  const [t] = useTranslation();

  /**
   * The value we receive from the outside is always an array.
   * It has to fit into the associated control, ie text having a single value
   */
  const internalValue = useMemo(() => {
    switch (type) {
      case controlTypes.SEARCH_INPUT:
        return (value && value.length > 0 && value[0]) || "";
      case controlTypes.SELECT:
        return value && value.length > 0 ? value[0] : null;
      case controlTypes.MULTISELECT:
        return value;
      case controlTypes.RADIO:
        return value && value.length > 0 ? value[0] : "";
      default:
        // eslint-disable-next-line no-console
        console.error(`Filter type ${type} is unknown. Cannot compute value.`);
        return "";
    }
  }, [type, value]);

  /**
   * Transform the value emitted from the control into an array
   * @param {*} filterValue value from the control
   */
  const onFilterChange = filterValue => {
    let valueToEmit = filterValue;
    if (type === controlTypes.SEARCH_INPUT || type === controlTypes.SELECT) {
      valueToEmit = filterValue ? [filterValue] : [];
    }
    if (type === controlTypes.RADIO) {
      valueToEmit = filterValue === "" ? [] : [filterValue];
    }
    onChange(valueToEmit);
  };

  const FilterComponent = controlType => {
    switch (controlType) {
      case controlTypes.SEARCH_INPUT:
        return <SearchInput value={internalValue} onChange={onFilterChange} debounceDelay={debounceDelay} {...props} />;
      case controlTypes.SELECT:
        return (
          <Select
            showSearch
            value={internalValue}
            onChange={onFilterChange}
            {...props}
            status={props.validity === "invalid" ? "error" : props.validity} // use until FormControl will be replaced with DS form control
          />
        );
      case controlTypes.MULTISELECT:
        return (
          <Select
            multiple
            showSearch
            value={internalValue}
            onChange={onFilterChange}
            {...props}
            status={props.validity === "invalid" ? "error" : props.validity} // use until FormControl will be replaced with DS form control
          />
        );
      case controlTypes.RADIO:
        return (
          <RadioGroup value={internalValue} onChange={onFilterChange}>
            {props.options.map(o => (
              <Radio key={o.value} value={o.value} horizontal>
                {o.label}
              </Radio>
            ))}
          </RadioGroup>
        );
      default:
        // eslint-disable-next-line no-console
        console.error(`Filter type ${controlType} is unknown. Cannot render.`);
        return <Input value="Invalid filter" disabled {...props} />;
    }
  };

  // eslint-disable-next-line react/prop-types
  const { width } = props;
  return (
    <div className="filter-control-widget">
      <label className="form-label">{t(label)}</label>
      <div className="filter-control-control" style={{ width: type === controlTypes.RADIO ? "auto" : width }}>
        {FilterComponent(type)}
      </div>
    </div>
  );
}

FilterControl.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any),
  // We specify options (optional) as we use them into Radio control type
  options: PropTypes.arrayOf(PropTypes.any),
};
