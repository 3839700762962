import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";

import { FormControl } from "Legacy/components";
import "./SignupConfirmationModal.scss";
import { webSiteUrl } from "Legacy/utils";

export default function SignupConfirmationModal({ authenticationData, onCancel, onConfirm }) {
  const [t] = useTranslation();
  const [cguAccepted, setCguAccepted] = useState(false);

  return (
    <Modal
      title={t("auth.signup.confirmationModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onConfirm} disabled={!cguAccepted}>
              {t("customers.modal.creationConfirm")}
            </Button>
          }
        />
      }
      onCancel={onCancel}
    >
      <div className="signup-confirmation-modal">
        <h3 className="form-recap-intro">{t("auth.signup.confirmationModal.description")}</h3>
        <div className="form-recap-label">{t("auth.signup.form.username")}</div>
        <div className="form-recap-description">{authenticationData.name}</div>
        <div className="form-recap-label">{t("fields.customerName")}</div>
        <div className="form-recap-description">{authenticationData.customer.name}</div>
        <div className="form-recap-label">{t("auth.signup.form.customerId")}</div>
        <div className="form-recap-description">
          {authenticationData.customer.id}
          <div className="small-text ">{t("customers.modal.creationWarning")}</div>
        </div>
        <div className="signup-confirmation-cgu">
          <div>
            {t("auth.signup.confirmationModal.cgu1")}
            <a className="typo-link" href={`${webSiteUrl}/privacy-policy`} target="_blank" rel="noreferrer">
              {t("auth.signup.confirmationModal.cgu2")}
            </a>
            {t("auth.signup.confirmationModal.cgu3")}
            <a className="typo-link" href={webSiteUrl} target="_blank" rel="noreferrer">
              {t("auth.signup.confirmationModal.cgu4")}
            </a>
          </div>
          <FormControl
            type="checkbox"
            label={t("auth.signup.confirmationModal.cgu5")}
            onChange={() => setCguAccepted(!cguAccepted)}
          />
        </div>
      </div>
    </Modal>
  );
}

SignupConfirmationModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authenticationData: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
