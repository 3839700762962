import {
  Alert,
  Button,
  Divider,
  Drawer,
  DrawerClosableFooter,
  DrawerHeader,
  FormControlLabel,
  Loader,
  Select,
  Space,
  Typography,
} from "@ogury/design-system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import FileSaver from "file-saver";
import { useNotificationService } from "Legacy/utils";
import { backofficeService, siteService, SolutionVendor } from "Legacy/services";
import { getTodayDate } from "Legacy/utils/date";
import style from "./ThirdPartyTagBulkDrawer.module.scss";

export default function ThirdPartyTagBulkDrawer({ open, sites, onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();

  const [dspList, setDspList] = useState();
  const [dspSelected, setDspSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [sitesAndDsps, setSitesAndDsps] = useState([]);

  async function load() {
    setLoading(true);
    try {
      const _dspList = await backofficeService.getDspList();
      setDspList(_dspList);
      setDspSelected(_dspList?.find(dsp => dsp.value === SolutionVendor.Ogury) || _dspList[0]);
      setSitesAndDsps(
        sites.map(site => ({
          siteId: site.id,
          dspValue: _dspList[0].value,
        }))
      );
    } catch (error) {
      console.warn(error);
      notificationService.notifyError(t("experiences.embed.modal.thirdParty.errorLoadingDspOrApplication"));
      setError(t("experiences.embed.modal.thirdParty.errorLoadingDspOrApplication"));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    load();
  }, [open]); //eslint-disable-line

  function handleOnApplyToAll() {
    if (dspSelected?.value) {
      setSitesAndDsps(
        sites.map(site => ({
          siteId: site.id,
          dspValue: dspSelected.value,
        }))
      );
    }
  }

  function handleOnChangeDsp(dspValue, siteId) {
    setSitesAndDsps(
      sitesAndDsps.map(siteAndDsp => {
        if (siteAndDsp.siteId?.toString() === siteId?.toString()) {
          return {
            siteId,
            dspValue,
          };
        }
        return siteAndDsp;
      })
    );
  }

  async function handleOnDownload() {
    try {
      setRequesting(true);
      const siteIds = sitesAndDsps.map(siteAndDsp => siteAndDsp.siteId);
      const dsps = sitesAndDsps.map(siteAndDsp => siteAndDsp.dspValue);
      const response = await siteService.getThirdPartyTagsBySiteIdsToExcel(siteIds, dsps);

      const blob = new Blob([response], { type: "application/vnd.ms-excel" });

      FileSaver.saveAs(blob, "export-third-party-tag-sites-" + getTodayDate() + ".xls");
    } catch (error) {
      console.warn(error);
      notificationService.notifyError(t("sites.thirdPartyTagBulkDrawer.downloadError"));
    } finally {
      setRequesting(false);
    }
  }

  function renderError() {
    return <Alert description={error} type="error" closable={false} />;
  }

  function renderLoading() {
    return (
      <div className={style.loaderContainer}>
        <Loader />
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        <Space size="s" direction="vertical" style={{ width: "100%" }}>
          <div>
            <FormControlLabel
              label={t("experiences.embed.solutionVendor")}
              hint={t("experiences.embed.solutionVendorTooltip")}
            />
            <Select
              showSearch
              width="100%"
              value={dspSelected?.value}
              options={dspList?.map(dsp => ({ value: dsp.value, label: dsp.text }))}
              onChange={dspValue => setDspSelected(dspList?.find(dsp => dsp.value === dspValue))}
            />
          </div>
          <Button type="secondary" size="small" onClick={handleOnApplyToAll}>
            {t("sites.thirdPartyTagBulkDrawer.applyToAll")}
          </Button>
        </Space>
        <Divider />
        <Space size="m" direction="vertical" style={{ width: "100%" }}>
          {sites.map(site => {
            return (
              <div key={"site-dsp-selector-" + site.id} className={style.contentContainer}>
                <Typography.P2Strong>{site.name}</Typography.P2Strong>{" "}
                <div>
                  <FormControlLabel
                    label={t("experiences.embed.solutionVendor")}
                    hint={t("experiences.embed.solutionVendorTooltip")}
                  />
                  <Select
                    showSearch
                    width="100%"
                    value={
                      sitesAndDsps.find(siteAndDsp => siteAndDsp.siteId?.toString() === site.id?.toString())?.dspValue
                    }
                    options={dspList?.map(dsp => ({ value: dsp.value, label: dsp.text }))}
                    onChange={dspValue => handleOnChangeDsp(dspValue, site.id)}
                  />
                </div>
              </div>
            );
          })}
        </Space>
      </>
    );
  }
  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={<DrawerHeader title={t("sites.thirdPartyTagBulkDrawer.title")} />}
      footer={
        <DrawerClosableFooter
          actions={
            <Button loading={requesting} disabled={requesting || loading} onClick={handleOnDownload}>
              {t("actions.download")}
            </Button>
          }
        />
      }
    >
      {loading && !error && renderLoading()}
      {!loading && error && renderError()}
      {!loading && !error && renderContent()}
    </Drawer>
  );
}

ThirdPartyTagBulkDrawer.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
