import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Empty } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import { applyGuestSpecificities, path, sortOrder, useNotificationService } from "Legacy/utils";
import {
  cache,
  customerService,
  siteFilters,
  siteService,
  siteSortings,
  tagService,
  userService,
} from "Legacy/services";
import { CreativesTypeSwitch, PageHeader, PageLoader } from "Legacy/components";
import processFiltersPayload from "Legacy/app/experiences/pages/ExperiencesPage/utils/processFiltersPayload";
import { DEFAULT_PAGE_SIZE } from "Legacy/utils/pagination";
import { convertSearchDataApiToUi, convertSearchDataUiToApi } from "Legacy/services/SiteService";
import { SitesFilters, SitesTable } from "./components";
import style from "./SitesPage.module.scss";

const messages = {
  FETCH_DATA_REQUEST: Symbol("fetchDataRequest"),
  FETCH_DATA_SUCCESS: Symbol("fetchDataSuccess"),
  FETCH_DATA_FAILURE: Symbol("fetchDataFailure"),
  SET_VIEW: Symbol("setView"),
  SET_VIEWS_LIST: Symbol("setViewsList"),

  // We hide the search stuff at initial loading to avoid UI flickering during data computing
  FIRST_LOAD: Symbol("firstLoad"),
  FIRST_LOAD_END: Symbol("firstLoadEnd"),
};

const pageDataReducer = (state, action) => {
  switch (action.type) {
    case messages.FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case messages.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        sites: action.payload.sites,
        totalSites: action.payload.total,
        loadingError: "",
      };
    case messages.FETCH_DATA_FAILURE:
      return { ...state, loading: false, loadingError: action.payload, sites: [], totalSites: 0 };
    case messages.SET_VIEW:
      return { ...state, view: action.view };
    case messages.SET_VIEWS_LIST:
      return { ...state, viewsList: action.viewsList };
    case messages.FIRST_LOAD:
      return { ...state, firstLoad: true };
    case messages.FIRST_LOAD_END:
      return { ...state, firstLoad: false };
    default:
      return state;
  }
};

const initialPageState = {
  loading: false,
  firstLoad: false,
  sites: [],
  totalSites: 0,
  loadingError: "",
  view: {},
  viewsList: [],
};

const initialSearchCriteria = {
  sort: { sortBy: siteSortings.UPDATE_DATE, sortOrder: sortOrder.DESC },
  pagination: { pageSize: DEFAULT_PAGE_SIZE, pageNumber: 1 },
  searchText: "",
  filters: [],
};

const computePagination = pagination => ({
  offset: pagination.pageSize * (pagination.pageNumber - 1),
  limit: pagination.pageSize,
});

const PARAMETER_SEARCH = "search";

// TODO extract pageState, filtering and table in a custom hook and factorize with ExperiencesPage

export default function SitesPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const location = useLocation();
  // noinspection JSCheckFunctionSignatures
  const [pageState, dispatch] = useReducer(pageDataReducer, initialPageState);
  const firstLoadRef = useRef(false);
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
  const history = useHistory();
  const [tags, setTags] = useState();

  const hideSearch = applyGuestSpecificities(
    customerService.getCurrentCustomerSummaryId(),
    userService.getCurrentUserEmail()
  );

  const getSites = useCallback(
    async (criteria = {}) => {
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      const mergedCriteria = { ...searchCriteria, ...criteria };

      try {
        setSearchCriteria(mergedCriteria);
        const criteriaForUrl = convertSearchDataUiToApi(mergedCriteria);
        history.replace(`${path.SITES}?${PARAMETER_SEARCH}=${encodeURI(JSON.stringify(criteriaForUrl))}`);
        const sites = await siteService.listByFilter({
          ...{ ...mergedCriteria, filters: processFiltersPayload(mergedCriteria.filters) },
          pagination: computePagination(mergedCriteria.pagination),
        });
        dispatch({
          type: messages.FETCH_DATA_SUCCESS,
          payload: sites,
        });
      } catch (error) {
        dispatch({ type: messages.FETCH_DATA_FAILURE, payload: error?.message });
      }
    },
    [history, searchCriteria]
  );

  useEffect(() => {
    // Get criteria to use when search is hidden
    const getSearchHiddenParams = () => ({
      filters: [{ filter: siteFilters.OWNER, values: [userService.getCurrentUserId()] }],
    });

    // Get criteria to be displayed on screen. Data comes from URL or localStorage
    const getVisibleSearchParams = async () => {
      let jsonSearch;

      // Get a parsed JSON of string criteria in the URL
      const searchParam = new URLSearchParams(location.search).get(PARAMETER_SEARCH);
      if (searchParam) {
        try {
          jsonSearch = JSON.parse(searchParam);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn("Unable to parse the JSON parameters, ignoring them", error);
        }
      }

      const allTags = await tagService.listTags();
      setTags(allTags);

      if (jsonSearch !== undefined) {
        // Get criteria based on a parsed JSON
        const convertedCriteria = convertSearchDataApiToUi(jsonSearch, allTags);
        convertedCriteria.sort = convertedCriteria.sorting;
        delete convertedCriteria.sorting;
        return {
          ...convertedCriteria,
          searchText: jsonSearch.searchText,
        };
      }
      return {};
    };

    const loadData = async () => {
      dispatch({ type: messages.FIRST_LOAD });
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      try {
        const criteria = hideSearch ? getSearchHiddenParams() : await getVisibleSearchParams();
        await getSites(criteria);
        dispatch({ type: messages.FIRST_LOAD_END });
      } catch (error) {
        dispatch({ type: messages.FETCH_DATA_FAILURE, payload: error?.message });
        dispatch({ type: messages.FIRST_LOAD_END });
      }
    };

    // We initialize criteria from the URL only once
    if (!firstLoadRef.current) {
      firstLoadRef.current = true;
      // noinspection JSIgnoredPromiseFromCall
      loadData();
    }
  }, [t, getSites, location.search, hideSearch]);

  const renderEmptyState = () => {
    // First the errors, then the 0 results state
    if (pageState.loadingError) {
      return (
        <Empty
          title={t("sites.list.loadingError")}
          description={pageState.loadingError}
          buttonText={t("actions.reload")}
          onClick={getSites}
        />
      );
    }

    if (pageState.totalSites === 0 && !pageState.loading) {
      return <Empty title={t("sites.list.noFilteredDataTitle")} description={t("sites.list.noFilteredDataSubtitle")} />;
    }
    return <></>;
  };

  const launchSearch = () => {
    // noinspection JSIgnoredPromiseFromCall
    getSites({ pagination: { ...searchCriteria.pagination, pageNumber: 1 } });
  };

  const onTableChange = value => {
    const { pagination, ...sort } = value;
    // noinspection JSIgnoredPromiseFromCall
    getSites({ sort, pagination: { pageSize: pagination.pageSize, pageNumber: pagination.current } });
  };

  const onClickRefresh = async ({ shiftKey }) => {
    if (shiftKey === true) {
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      await cache.reload(t, history, false, notificationService);
    }
    await getSites();
    notificationService.notifySuccess(t("messages.refreshSuccess"));
  };

  const renderPageBody = () => (
    <SitesTable
      loading={pageState.loading}
      sites={pageState.sites}
      sorting={searchCriteria.sort}
      onChange={onTableChange}
      pagination={{ ...searchCriteria.pagination, total: pageState.totalSites }}
      onRefresh={launchSearch}
      emptyState={renderEmptyState()}
    />
  );

  const filtersSortChanged = ({ filters, sort, searchText }) => {
    const newSearchCriteria = { ...searchCriteria, filters, searchText };
    if (sort !== undefined) {
      newSearchCriteria.sort = sort;
    }

    // Update filters here because they are not used now
    setSearchCriteria(newSearchCriteria);

    // Reset to 0 if there is no filters
    if (filters.length === 0) {
      // noinspection JSIgnoredPromiseFromCall
      getSites({
        filters: newSearchCriteria.filters,
        sort: newSearchCriteria.sort,
        pagination: { ...newSearchCriteria.pagination, pageNumber: 1 },
        searchText: newSearchCriteria.searchText,
      });
    }
  };

  return (
    <div className={style.container}>
      <CreativesTypeSwitch />
      <PageHeader
        additionalComponent={
          !pageState.firstLoad && (
            <div className="filters-container">
              <SitesFilters
                searchText={searchCriteria.searchText}
                onSearchChange={value => setSearchCriteria({ ...searchCriteria, searchText: value })}
                filtersValue={searchCriteria.filters}
                onChange={filters => {
                  filtersSortChanged({ filters, searchText: searchCriteria.searchText });
                }}
                onCleared={() => {
                  const filters = [];
                  const { sort } = initialSearchCriteria;
                  filtersSortChanged({ filters, sort, searchText: "" });
                }}
                onSearch={launchSearch}
                tags={tags}
              />
            </div>
          )
        }
      >
        <PageLoader inline active={pageState.loading} />
        <Button onClick={onClickRefresh} type="secondary" icon={<RefreshLineIcon />} iconPosition="iconOnly" />
      </PageHeader>

      {renderPageBody()}
    </div>
  );
}
