import React from "react";
import * as PropTypes from "prop-types";

import "./ExperienceDetailAssetsPage.scss";

export default function ExperienceDetailAssetsPage({ experienceId }) {
  return (
    <div>
      <h1>Details Assets page for experience id = {experienceId}</h1>
    </div>
  );
}

ExperienceDetailAssetsPage.propTypes = {
  experienceId: PropTypes.string,
};
