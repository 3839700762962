import { useTranslation } from "react-i18next";
import { SiteStatusTag } from "Legacy/app/sites/components";
import { dateFormatter } from "Legacy/utils";
import * as PropTypes from "prop-types";
import { Space, Typography } from "@ogury/design-system";
import style from "./SiteDetailHeader.module.scss";

export default function SiteDetailHeaderTitle({ site }) {
  const [t] = useTranslation();

  function renderSeparator() {
    return <Typography.P2Regular> | </Typography.P2Regular>;
  }
  function renderSiteStatus() {
    return <SiteStatusTag status={site.state} />;
  }

  function renderDateUpdated() {
    return dateFormatter.toShortDateTime(site.updateDate);
  }
  function renderAdUnitsCount() {
    if (site.experienceIds?.length >= 1) {
      return site.experienceIds.length;
    }
    return "0";
  }

  return (
    <div className={style.informationsContainer}>
      <div>
        <Typography.H6Strong>{site.name}</Typography.H6Strong>
      </div>
      <div className={style.informations}>
        <Space gap={10}>
          <Typography.P2Regular>{renderSiteStatus()}</Typography.P2Regular>
          {site.account && site.brand && (
            <>
              <div>{renderSeparator()}</div>
              <Typography.P2Regular>
                {site.account.name} - {site.brand.name}
              </Typography.P2Regular>
            </>
          )}
          <div>{renderSeparator()}</div>
          <Typography.P2Regular>
            {renderAdUnitsCount()} {t("sites.detail.units")}
          </Typography.P2Regular>
          <div>{renderSeparator()}</div>
          <Typography.P2Regular>
            {t("sites.detail.lastUpdate")} : {renderDateUpdated()}
          </Typography.P2Regular>
        </Space>
      </div>
    </div>
  );
}

SiteDetailHeaderTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
};
