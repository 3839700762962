import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter, Space, Typography, theme } from "@ogury/design-system";

import { userService } from "Legacy/services";

import "./RemoveFromCustomerConfirmationModal.scss";
import { useNotificationService } from "Legacy/utils";

import { ModalWidths } from "Legacy/components/Modal/Modal";

import { UserInfoCell } from "..";

export default function RemoveFromCustomerConfirmationModal({ onClose, onSuccess, user, customerId, open }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onRemoveFromCustomer = async () => {
    setWorking(true);
    try {
      await userService.removeRole(user.id, customerId);
      notificationService.notifySuccess(t("customers.members.removeFromCustomerConfirmationModal.success"));
      setWorking(false);
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
      setWorking(false);
    }
  };

  return (
    <Modal
      open={open}
      title={t("customers.members.removeFromCustomerConfirmationModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={onRemoveFromCustomer} loading={working} disabled={working}>
              {t("customers.members.removeFromCustomerConfirmationModal.action")}
            </Button>
          }
        />
      }
      onCancel={onClose}
      width={ModalWidths.Medium}
    >
      <Space size={theme.spacing.space_m} direction="vertical">
        <Typography.P2Regular>
          {t("customers.members.removeFromCustomerConfirmationModal.explanation")}
        </Typography.P2Regular>
        <UserInfoCell user={user} />
      </Space>
    </Modal>
  );
}

RemoveFromCustomerConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  customerId: PropTypes.string,
};
