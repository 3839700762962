// Enables to disable some code
/* eslint-disable */
// Variable used to identifie the standalone list of creatives
export const DESTINATION_DETACH_FROM_SITES = -1;

export const disableFeature = "a" === "";
// The Motionly web service URL
export const webSiteUrl = "https://www.ogury.co/";

//The version of the preferences settings. Careful, by modifying this value you will reset every user preferences (browser-stored preferences, such as the quick actions)
export const currentUserPreferencesSettingsVersion = "2.0";

export const localBuilderUrl = "http://localhost:3000/";
export const isLocalEnvironment = window.location.href.startsWith("http://localhost") === true;
export const owningOrganizationEmailDomain = "ogury.co";
// A specific use case for the owning organization user account
export const applyOwningCustomerTweak = userEmail => userEmail.endsWith(`@${owningOrganizationEmailDomain}`);
// A specific use case for the "Guest" customer account
export const applyGuestSpecificities = (customerId, userEmail) => {
  const isTestEnvironment =
    window.location.href.startsWith("https://bo-test") === true ||
    window.location.href.startsWith("https://app-test") === true;
  return (
    isTestEnvironment === false &&
    customerId === "5748225885601792" &&
    userEmail.endsWith(`@${owningOrganizationEmailDomain}`) === false
  );
};
// export const applyGuestSpecificities = () => true;
// The Motionly support e-mail address
export const supportEmailAddress = `support@${owningOrganizationEmailDomain}`;
// Hides the "Sites" functionality from the UIv
// TODO: when this property will have been removed, think of updating the "sitePermissions.canAccess()" accordingly
export const disableSites = false;

// Forces enabling login with email and password.
export const allowLoginWithEmailAndPassword = process.env.REACT_APP_ENV !== "prod";
