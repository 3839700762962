import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./EditableText.scss";
import { Button, Input, Space } from "@ogury/design-system";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import CheckLineIcon from "remixicon-react/CheckLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import { useNotificationService } from "Legacy/utils";
import { LoadDots } from "Legacy/components";

const cleanText = text => (text === undefined || text === null ? "" : text);

export default function EditableText({ text, large, disabled, maximumLength, update }) {
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [working, setWorking] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(cleanText(text));

  useEffect(() => {
    setValue(cleanText(text));
  }, [text]);

  const applyChanges = async () => {
    setWorking(true);
    try {
      const newValue = value.trim();
      const message = await update(newValue);
      setValue(newValue);
      setEditing(false);
      notificationService.notifySuccess(message);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  const handleOnKeyPress = event => {
    if (event.keyCode === 27) {
      // Escape
      setValue(cleanText(text));
      setEditing(false);
    }
  };

  const isValid = value.length > 0 && value.length <= maximumLength;
  const Component = large ? Input.TextArea : Input;
  const renderEditingForm = () => (
    <div className="editing-form">
      <Component
        value={value}
        onChange={setValue}
        width="400px"
        disabled={working}
        onPressEnter={async () => {
          if (!large && isValid) {
            await applyChanges();
          }
        }}
        onKeyDown={handleOnKeyPress}
        autoFocus
      />
      <Space className="edit-actions" size="xxs">
        {working && <LoadDots />}
        <Button
          type="secondary"
          size="small"
          icon={<CloseLineIcon />}
          iconPosition="iconOnly"
          onClick={() => setEditing(false)}
          disabled={working}
        />
        <Button
          type="primary"
          size="small"
          icon={<CheckLineIcon />}
          iconPosition="iconOnly"
          onClick={applyChanges}
          disabled={working || isValid === false}
        />
      </Space>
    </div>
  );

  const renderReadonlyForm = () => (
    <>
      <div className="readonly-value"> {value}</div>
      {disabled === false && (
        <div className="actions-block">
          <Button type="secondary" icon={<PencilLineIcon />} iconPosition="iconOnly" onClick={() => setEditing(true)} />
        </div>
      )}
    </>
  );

  return <div className="editable-text">{editing ? renderEditingForm() : renderReadonlyForm()}</div>;
}

EditableText.propTypes = {
  text: PropTypes.string,
  large: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  maximumLength: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
};
