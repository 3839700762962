import { useRef, useCallback, useEffect } from "react";

/**
 * Debounce a callback : the callback is called when the timeout reaches the end of the delay
 * The arguments of the callback are set by calling the hook's returned function
 * Setting new args to the callback cancels the timeout and starts a new one
 * @param callback the function to debounce
 * @param delay duration before the debounced callback is called
 * @returns {*}
 */
export default function useDebouncedCallback(callback, delay) {
  const timeoutHandler = useRef(null);

  useEffect(
    () => () => {
      // Cleanup any remaining timeout when leaving
      clearTimeout(timeoutHandler.current);
    },
    []
  );

  return useCallback(
    (...args) => {
      // Clear previous timeout
      clearTimeout(timeoutHandler.current);

      timeoutHandler.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [delay, callback]
  );
}
