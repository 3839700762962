import React from "react";
import * as PropTypes from "prop-types";

import "./ButtonGroup.scss";

export default function ButtonGroup({ children }) {
  return <div className="button-group">{children}</div>;
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
};
