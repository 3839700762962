import React from "react";
import ReactDOM from "react-dom";
import { DesignSystemProvider } from "@ogury/design-system";
import { initErrorMonitoring } from "Legacy/utils";
import App from "./App";
import "./assets/style/app.scss";

// variables should be declared at build time based on env and releaseid
// CRA requires REACT_APP_ prefix on env variables
const { REACT_APP_DATA_DOG_APP_ID, REACT_APP_DATA_DOG_CLIENT_TOKEN, REACT_APP_ENV, REACT_APP_RELEASE_ID } = process.env;
if (REACT_APP_ENV && !["localhost", "e2e"].includes(REACT_APP_ENV)) {
  initErrorMonitoring({
    applicationId: REACT_APP_DATA_DOG_APP_ID,
    clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
    env: REACT_APP_ENV,
    version: REACT_APP_RELEASE_ID,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <DesignSystemProvider>
      <App />
    </DesignSystemProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
