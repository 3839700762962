import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { useTranslation } from "react-i18next";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { GuardedModal, ModalHeights } from "../../Modal";

import "./ImageCropperModal.scss";
export default function ImageCropperModal({
  src,
  ratio,
  outputImageFormat,
  explanation,
  outputImageMaximumWidth,
  outputImageMaximumHeight,
  onClose,
  open,
}) {
  const [t] = useTranslation();
  const cropRef = useRef();
  const [image, setImage] = useState();
  const [detectedImageFormat, setDetectedImageFormat] = useState();
  const [imageMaxHeight, setImageMaxHeight] = useState();
  const [crop, setCrop] = useState({
    unit: "%",
    aspect: ratio,
    x: 0,
    width: 100,
    y: 0,
    height: 100,
  });
  const [hasChanged, setChanged] = useState(false);

  useEffect(() => {
    if (!open) {
      return;
    }

    const result = /^(data:)([\w/+-]*)(;charset=[\w-]+|;base64)?,(.*)/gi.exec(src);
    if (result !== null) {
      const format = result[2].split("/")[1];
      setDetectedImageFormat(format);
    }
  }, [src, open]);

  const onValidate = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const { naturalWidth, naturalHeight } = image;
    let width = (crop.width * naturalWidth) / 100;
    let factor = 1;
    if (outputImageMaximumWidth !== undefined && width > outputImageMaximumWidth) {
      factor = outputImageMaximumWidth / width;
      width *= factor;
    }
    let height = ((crop.height * naturalHeight) / 100) * factor;
    if (outputImageMaximumHeight !== undefined && height > outputImageMaximumHeight) {
      factor = outputImageMaximumHeight / height;
      height *= factor;
      width *= factor;
    }
    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingQuality = "high";
    context.drawImage(
      image,
      (crop.x * naturalWidth) / 100,
      (crop.y * naturalHeight) / 100,
      (crop.width * naturalWidth) / 100,
      (crop.height * naturalHeight) / 100,
      0,
      0,
      canvas.width,
      canvas.height
    );
    let format = outputImageFormat;
    if (format === undefined) {
      format = detectedImageFormat ?? "png";
    }
    onClose(canvas.toDataURL(`image/${format}`));
  };

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Large}
      height={ModalHeights.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" onClick={onValidate}>
              {t("actions.ok")}
            </Button>
          }
        />
      }
      onClose={onClose}
      canBeClosed={() => (hasChanged === true ? t("components.guardedModal.closeQuestion") : undefined)}
    >
      {explanation !== undefined && <Typography.P2Regular as="p">{explanation}</Typography.P2Regular>}
      <ReactCrop
        ref={cropRef}
        src={src}
        crop={crop}
        onChange={(newCrop, percentCrop) => {
          setCrop(percentCrop);
          setChanged(true);
        }}
        onImageLoaded={loadedImage => {
          setImage(loadedImage);
          // We consider the "ReactCrop" style properties
          // noinspection JSUnresolvedVariable
          setImageMaxHeight(window.getComputedStyle(cropRef.current.componentRef.current).maxHeight);
          const imageRatio = loadedImage.naturalWidth / loadedImage.naturalHeight;
          let newCrop;
          if (imageRatio >= ratio) {
            const dimension = ratio / imageRatio;
            newCrop = {
              ...crop,
              x: ((1 - dimension) / 2) * 100,
              width: dimension * 100,
              y: 0,
              height: 100,
            };
          } else {
            const dimension = imageRatio / ratio;
            newCrop = {
              ...crop,
              x: 0,
              width: 100,
              y: ((1 - dimension) / 2) * 100,
              height: dimension * 100,
            };
          }
          setCrop(newCrop);
          return false;
        }}
        crossorigin="anonymous"
        minWidth={50}
        minHeight={50}
        ruleOfThirds
        keepSelection
        className="image-cropper"
        imageStyle={{ maxHeight: imageMaxHeight }}
      />
    </GuardedModal>
  );
}

ImageCropperModal.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  outputImageFormat: PropTypes.oneOf(["jpeg", "png", "webp"]),
  explanation: PropTypes.string,
  outputImageMaximumWidth: PropTypes.number,
  outputImageMaximumHeight: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
