import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import Icon from "@mdi/react";
import { mdiCropSquare } from "@mdi/js";

import { Experience } from "@ogury/motionly-ws-api/ws";
import { FormControl } from "Legacy/components";
import { experienceService, templateService, unitService } from "Legacy/services";
import { DESTINATION_DETACH_FROM_SITES } from "Legacy/utils/constants";
import { DestinationSelector } from "Legacy/components/ExperienceMoveToModal/components";
import style from "./DestinationAndAdUnitSelector.module.scss";

export default function DestinationAndAdUnitSelector({ sourceSite, sourceExperience, onChange }) {
  const [t] = useTranslation();

  const [destinationSite, setDestinationSite] = useState(sourceSite);
  const [destinationAdUnit, setDestinationAdUnit] = useState();
  const [destinationAdUnitList, setDestinationAdUnitList] = useState([]);
  const [destinationAlreadyContainsAdUnit, setDestinationAlreadyContainsAdUnit] = useState(false);

  function isBespoke() {
    return sourceExperience.origin !== Experience.OriginEnum.FromTemplate;
  }

  async function getAvailableAdUnits(existingAdUnitsInDestination) {
    let units = unitService.list();

    if (existingAdUnitsInDestination?.length) {
      units = units.filter(
        unit => !existingAdUnitsInDestination.some(existingUnit => existingUnit.technicalId === unit.technicalId)
      );
    }
    const template = experienceService.getTemplate(sourceExperience);
    const filteredUnits = templateService.getAvailableUnits(template.id, units);
    return filteredUnits.map(unit => ({ label: unit.name, value: unit.technicalId }));
  }

  useEffect(() => {
    if (!destinationSite) {
      return;
    }

    (async function () {
      setDestinationAlreadyContainsAdUnit(false);
      setDestinationAdUnit();

      const existingAdUnitsInDestination = destinationSite.units;
      const experienceAdUnit = sourceExperience.units[0];

      const destinationUnitIsAlreadyUsed = existingAdUnitsInDestination.some(
        unit => unit.technicalId === experienceAdUnit.technicalId
      );
      if (isBespoke()) {
        setDestinationAlreadyContainsAdUnit(destinationUnitIsAlreadyUsed);
        if (!destinationUnitIsAlreadyUsed) {
          setDestinationAdUnit({
            label: unitService.getUnitNameFromTechnicalId(experienceAdUnit.technicalId),
            value: experienceAdUnit.technicalId,
          });
        }
        return;
      }

      const availableAdUnits = await getAvailableAdUnits(existingAdUnitsInDestination);
      setDestinationAdUnitList(availableAdUnits);

      if (availableAdUnits?.length === 1) {
        setDestinationAdUnit(availableAdUnits[0]);
      } else if (destinationSite.id?.toString() !== sourceSite.id?.toString() && !destinationUnitIsAlreadyUsed) {
        setDestinationAdUnit({
          label: unitService.getUnitNameFromTechnicalId(experienceAdUnit.technicalId),
          value: experienceAdUnit.technicalId,
        });
      }
    })();
  }, [destinationSite, sourceExperience]); //eslint-disable-line

  useEffect(() => {
    let isValid = true;
    if (!destinationAdUnit || !destinationSite) {
      isValid = false;
    }
    if (destinationSite?.id !== DESTINATION_DETACH_FROM_SITES && !destinationAdUnit) {
      isValid = false;
    }
    if (isBespoke() && destinationAlreadyContainsAdUnit) {
      isValid = false;
    }

    onChange(destinationSite, destinationAdUnit?.value, isValid);
  }, [destinationSite, destinationAdUnit]); // eslint-disable-line react-hooks/exhaustive-deps

  function renderOptions(site) {
    if (site.value === DESTINATION_DETACH_FROM_SITES) {
      return (
        <>
          <div className={style.optionContainer}>
            <Icon path={mdiCropSquare} size={1} />
            <span> {site.label}</span>
          </div>
        </>
      );
    }
    return (
      <div className={style.optionContainer}>
        <span>{site.label}</span>
      </div>
    );
  }

  function handleOnChangeAdUnit(adUnitValue) {
    setDestinationAdUnit(destinationAdUnitList?.find(adUnit => adUnit.value === adUnitValue));
  }

  function renderBespoke() {
    if (destinationAlreadyContainsAdUnit) {
      return (
        <div className="typo-warning typo-semi-bold">
          {t("sites.experienceDuplicateModal.adUnitAlreadyExistsMessage")}
        </div>
      );
    }
  }

  function renderFromTemplate() {
    if (!destinationSite) {
      return <div className="typo-warning typo-semi-bold">{t("sites.experienceDuplicateModal.noAdUnitAvailable")}</div>;
    }
    return (
      <FormControl
        required
        type="select"
        formatOptionLabel={renderOptions}
        onChange={handleOnChangeAdUnit}
        value={destinationAdUnit?.value}
        options={destinationAdUnitList}
        label={t("sites.experienceDuplicateModal.adUnit")}
        placeholder={t("sites.experienceDuplicateModal.adUnitPlaceholder")}
      />
    );
  }

  function renderAdunitSelection() {
    return isBespoke() ? renderBespoke() : renderFromTemplate();
  }

  function renderBespokeWarning() {
    return (
      <div className="typo-warning typo-semi-bold">
        {t("sites.experienceDuplicateModal.bespokeDuplicationInformation")}
      </div>
    );
  }

  return (
    <>
      {isBespoke() && renderBespokeWarning()}
      <DestinationSelector defaultDestination={sourceSite} onChange={setDestinationSite} />
      {destinationAdUnitList && renderAdunitSelection()}
    </>
  );
}

DestinationAndAdUnitSelector.propTypes = {
  sourceSite: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
