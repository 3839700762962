/**
 * Helper functions to convert filter values from/to api/ui
 */

import { Filter, Tag } from "@ogury/motionly-ws-api/ws";
import { Filters } from "Legacy/utils";

export const experienceFilters = {
  FULLTEXT: Symbol("fulltext"),
  RATIO: Symbol("ratio"),
  TEMPLATE: Symbol("template"),
  AD_UNIT: Symbol("adUnit"),
  USAGE: Symbol("usage"),
  ORIGIN: Symbol("origin"),
  OWNER: Symbol("owner"),
  STATUS: Symbol("status"),
  UX: Symbol("ux"),
  INDUSTRY: Symbol("industry"),
  CUSTOMER_TAGS: Symbol("customerTags"),
  FAVOURITES: Symbol("favourites"),
};

const filterToAttribute = filter => {
  switch (filter) {
    case experienceFilters.UX:
    case experienceFilters.INDUSTRY:
    case experienceFilters.TEMPLATE:
    case experienceFilters.CUSTOMER_TAGS:
    case experienceFilters.FAVOURITES:
    case experienceFilters.USAGE:
      return Filter.FieldEnum.TagIds;
    case experienceFilters.AD_UNIT:
      return Filter.FieldEnum.UnitTechnicalId;
    case experienceFilters.ORIGIN:
      return Filter.FieldEnum.Origin;
    case experienceFilters.OWNER:
      return Filter.FieldEnum.Owner;
    case experienceFilters.STATUS:
      return Filter.FieldEnum.State;
    default:
      return "";
  }
};

// This determines if a tag related filter should be treated as a "OR" or a "AND" condition within the others tags.
// For example, the filter "Usage" is a "AND" and so is an addition to the
const tagsFiltersOperator = filter => {
  switch (filter) {
    case experienceFilters.USAGE:
    case experienceFilters.AD_UNIT:
      return Filters.operators.AND;
    default:
      return Filters.operators.OR;
  }
};

const attributeToFilter = attribute => {
  switch (attribute) {
    case Filter.FieldEnum.Origin:
      return experienceFilters.ORIGIN;
    case Filter.FieldEnum.Owner:
      return experienceFilters.OWNER;
    case Filter.FieldEnum.State:
      return experienceFilters.STATUS;
    case Filter.FieldEnum.UnitTechnicalId:
      return experienceFilters.AD_UNIT;
    default:
      return "";
  }
};

const tagCategoryToFilter = category => {
  switch (category) {
    case Tag.CategoryEnum.Customer:
      return experienceFilters.CUSTOMER_TAGS;
    case Tag.CategoryEnum.Ux:
      return experienceFilters.UX;
    case Tag.CategoryEnum.Application:
      return experienceFilters.INDUSTRY;
    case Tag.CategoryEnum.Template:
      return experienceFilters.TEMPLATE;
    case Tag.CategoryEnum.Favorite:
      return experienceFilters.FAVOURITES;
    case Tag.CategoryEnum.Usage:
      return experienceFilters.USAGE;
    case Tag.CategoryEnum.Unit:
      return experienceFilters.AD_UNIT;
    default:
      return "";
  }
};

// Convert filters (UI value) into Filters (API value)
export const uiToApi = filters => Filters.uiToApi(filters, filterToAttribute, tagsFiltersOperator);

// Convert Filters (API value) into filters (UI value)
export const apiToUi = (filters, tags) => Filters.apiToUi(filters, tags, tagCategoryToFilter, attributeToFilter);

export default { uiToApi, apiToUi };
