import { Tag } from "@ogury/motionly-ws-api/ws";
import MotionlyApiService from "./MotionlyApiService";
import { customerService } from ".";

class TagService {
  allTags = [];

  allTagsById = {};

  filterByCategory(tags, category) {
    return tags.filter(tag => tag.category === category);
  }

  clearCache() {
    this.allTags = [];
    this.allTagsById = {};
  }

  async listTags() {
    if (this.allTags.length === 0) {
      this.allTags = await MotionlyApiService.tagApi().list(customerService.getCurrentCustomerSummaryId());
      const brandTags = await MotionlyApiService.tagApi().list(customerService.getCurrentCustomerSummaryId(), {
        category: Tag.CategoryEnum.Brand,
      });
      this.allTags = this.allTags.concat(brandTags);

      // add custom template tag
      this.allTags.push({
        id: "custom",
        name: "Custom",
        category: Tag.CategoryEnum.Template,
      });
    }
    return this.allTags;
  }

  async listCustomerTags() {
    const tags = await this.listTags();
    return tags.filter(tag => tag.category === Tag.CategoryEnum.Customer);
  }

  async listBrandTags() {
    const tags = await this.listTags();
    return this.filterByCategory(tags, Tag.CategoryEnum.Brand);
  }

  async listTagsById() {
    if (Object.keys(this.allTagsById).length === 0) {
      const tags = await this.listTags();
      tags.forEach(tag => {
        this.allTagsById[tag.id] = tag;
      });
    }
    return this.allTagsById;
  }

  async get(id) {
    return MotionlyApiService.tagApi().get(id, { customerId: customerService.getCurrentCustomerSummaryId() });
  }

  async search(name, category) {
    return MotionlyApiService.tagApi().search(name, category, {
      customerId: customerService.getCurrentCustomerSummaryId(),
    });
  }

  // Locally search customer tags in mode : tag name contains searchstring (all in lowercase)
  async searchLocal(searchString) {
    const customerTags = await this.listCustomerTags();
    return customerTags.filter(tag => tag.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
  }

  async create(name) {
    const result = await MotionlyApiService.tagApi().create(customerService.getCurrentCustomerSummaryId(), name);
    this.clearCache();
    return result;
  }

  async rename(id, name) {
    const result = await MotionlyApiService.tagApi().update(id, name, {
      customerId: customerService.getCurrentCustomerSummaryId(),
    });
    this.clearCache();
    return result;
  }

  async delete(id) {
    const result = await MotionlyApiService.tagApi().callDelete(id, {
      customerId: customerService.getCurrentCustomerSummaryId(),
    });
    this.clearCache();
    return result;
  }
}
export default new TagService();
