import { AuthenticateInputs } from "@ogury/motionly-ws-api/ws";
import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "@ogury/design-system";

import { DetailPage, Form, FormControl, PageHeader } from "Legacy/components";
import { userService } from "Legacy/services";
import { useAuth, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { ChangeEmailConfirmationModal } from "./components";
import "./UserProfileEmailPage.scss";

const inputsWidth = "400px";

export default function UserProfileEmailPage({ user }) {
  const [working, setWorking] = useState(false);
  const [showChangeEmailConfirmationModal, setShowChangeEmailConfirmationModal] = useState(false);
  const changeEmailDisabled = useMemo(() => user.source === AuthenticateInputs.SourceEnum.Google, [user]);

  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const auth = useAuth();

  const initialFormValue = {
    email: user.email,
  };

  const onChangeEmail = async formValue => {
    setShowChangeEmailConfirmationModal(false);
    setWorking(true);
    try {
      await userService.changeEmail(user.id, formValue.email);
      await auth.logout();
      setWorking(false);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      email: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onChangeEmail,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  return (
    <>
      <div className="profile-page-header">
        <PageHeader title={t("users.email.title")} />
      </div>
      <DetailPage className="users-email-form">
        {changeEmailDisabled && <Alert type="warning" description={t("users.email.changeEmailDisabled")} />}
        <Form {...getFormProps()}>
          <div>
            <FormControl
              id="email"
              label={t("fields.email")}
              placeholder={t("fields.emailPlaceholder")}
              disabled={working || changeEmailDisabled}
              required
              width={inputsWidth}
              {...getFieldProps("email")}
            />
          </div>
          <Button
            className="button-change-email"
            onClick={() => setShowChangeEmailConfirmationModal(true)}
            type="primary"
            loading={working}
            disabled={working || changeEmailDisabled}
          >
            {t("actions.saveChanges")}
          </Button>

          {showChangeEmailConfirmationModal && (
            <ChangeEmailConfirmationModal
              onConfirm={() => getFormProps().onSubmit()}
              onCancel={() => setShowChangeEmailConfirmationModal(false)}
            />
          )}
        </Form>
      </DetailPage>
    </>
  );
}

UserProfileEmailPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};
