import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { Button, Modal, ModalClosableFooter, Space, Typography, theme } from "@ogury/design-system";
import { ExperienceThumbnail, Form, FormControl } from "Legacy/components";
import { disableFeature, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { experienceService, metaService, templateService } from "Legacy/services";

import "./ExperienceUpgradeModal.scss";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "100%";

export default function ExperienceUpgradeModal({ experience, onClose, onSuccess, open }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [template, setTemplate] = useState();
  const [version, setVersion] = useState();

  useEffect(() => {
    if (!open) {
      return;
    }

    const loadTemplate = async () => {
      setWorking(true);
      try {
        const theTemplate = await templateService.get(
          experience.data.templateId,
          experience.data.templateVersion,
          false
        );
        setTemplate(theTemplate);
        setVersion([...theTemplate.versions].pop());
        // If we cannot upgrade, we close the modal
        if ((await metaService.canUpgrade(experience, theTemplate)) === false) {
          onClose();
        }
      } finally {
        setWorking(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadTemplate();
  }, [open, experience, onClose]);

  const onUpgradeExperience = async () => {
    try {
      setWorking(true);
      const newExperience = await experienceService.upgrade(experience, version);
      notificationService.notifySuccess(t("experiences.upgradeModal.upgradeSuccess"));
      onSuccess(newExperience);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.upgradeModal.upgradeErrorTitle"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => ({ version: experience?.data?.templateVersion }), [experience]);

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      version: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onUpgradeExperience,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  const renderForm = () => (
    <fieldset disabled={working}>
      <FormControl
        id="version"
        type="text"
        label={t("experiences.upgradeModal.version")}
        width={inputsWidth}
        required
        {...getFieldProps("version")}
      />
    </fieldset>
  );

  return (
    <Modal
      open={open}
      title={t("experiences.upgradeModal.title")}
      onCancel={onClose}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.upgrade")}
            </Button>
          }
        />
      }
    >
      {template !== undefined && (
        <>
          <Space direction="vertical">
            <Typography.P2Regular>
              {t("experiences.upgradeModal.explanation", {
                currentRevision: experience.data.templateVersion,
                latestVersion: version,
              })}
            </Typography.P2Regular>
            <Space size={theme.spacing.space_m} type="vertical">
              <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
              <Form {...getFormProps()}>{disableFeature && renderForm()}</Form>
            </Space>

            <Typography.P2Regular>
              {
                <Trans
                  i18nKey="experiences.upgradeModal.explanation2"
                  components={[
                    <strong key="strong-explaination" />,
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      key="link-explaination"
                      className={"changelog-link"}
                      href="https://ogury.atlassian.net/wiki/spaces/EN/pages/228982866/Template+release+notes"
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                  values={{ latestRevision: version }}
                />
              }
            </Typography.P2Regular>
          </Space>
        </>
      )}
    </Modal>
  );
}

ExperienceUpgradeModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool,
  experience: PropTypes.object,
  experienceId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
