import React, { useMemo } from "react";
import { AuthenticateInputs } from "@ogury/motionly-ws-api/ws";
import * as PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";

import { Button } from "@ogury/design-system";
import { Form, FormControl } from "Legacy/components";
import { motionlyService } from "Legacy/services";
import { path, useFormValidation, validators } from "Legacy/utils";
import { allowLoginWithEmailAndPassword } from "Legacy/utils/constants";
import StudioCompanion from "../../../studio/StudioCompanion";

function GoogleLogin({ onSuccess, actionLabel, working }) {
  const onGoogleSuccess = async googleResponse => {
    onSuccess(googleResponse, AuthenticateInputs.SourceEnum.Google);
  };
  const onGoogleFailure = error => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  const googleLoginParameters = {
    prompt: "consent",
    scope: "https://www.googleapis.com/auth/userinfo.email",
    onSuccess: onGoogleSuccess,
    onError: onGoogleFailure,
    flow: "auth-code",
  };

  const [t] = useTranslation();
  const signIn = useGoogleLogin(googleLoginParameters);

  return (
    <Button block type="primary" loading={working} disabled={working} onClick={signIn}>
      {actionLabel || t("auth.login.title")}
      {t("auth.googleAuth")}
    </Button>
  );
}

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  actionLabel: PropTypes.string,
  working: PropTypes.bool.isRequired,
};

export default function CredentialsForm({
  initialFormValue,
  emailIsReadOnly,
  actionLabel,
  forgottenPasswordLink = true,
  onEmailAuth = () => {},
  onGoogleAuth = () => {},
  setEmail,
  working,
}) {
  const authenticate = formValue => {
    onEmailAuth(formValue, AuthenticateInputs.SourceEnum.Motionly);
  };

  const history = useHistory();
  const [t] = useTranslation();
  const googleClientId = motionlyService.getGoogleClientId();
  const displayEmailAndPassword = useMemo(
    () => allowLoginWithEmailAndPassword || StudioCompanion.withStudio() === true,
    []
  );

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      email: [{ name: validators.IS_REQUIRED }, { name: validators.IS_EMAIL, t }],
      password: [
        { name: validators.IS_REQUIRED },
        { name: validators.IS_MIN_LENGTH, length: 4, message: t("app.passwordConstraints") },
        { name: validators.IS_MAX_LENGTH, length: 128, message: t("form.errorMaxLength", { LENGTH: 128 }) },
      ],
    },
    onSubmit: authenticate,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  const emailFieldProps = getFieldProps("email");
  const handleOnChange = value => {
    setEmail(value);
    return emailFieldProps.onChange(value);
  };

  return (
    <Form {...getFormProps()}>
      {displayEmailAndPassword === true && (
        <>
          <FormControl
            id="email"
            autoComplete="username"
            label={t("fields.email")}
            placeholder={t("fields.emailPlaceholder")}
            disabled={emailIsReadOnly || working}
            required
            {...emailFieldProps}
            onChange={handleOnChange}
          />
          <FormControl
            id="password"
            autoComplete="current-password"
            type="password"
            label={t("fields.password")}
            disabled={working}
            required
            {...getFieldProps("password")}
          />
        </>
      )}
      {displayEmailAndPassword === true &&
        (forgottenPasswordLink === true ? (
          <div className="auth-form-link">
            <Link
              className="typo-link"
              to={{
                pathname: path.PASSWORD_FORGOTTEN,
                state: { email: emailFieldProps.value, from: history.location.pathname },
              }}
            >
              {t("auth.login.passwordForgotten")}
            </Link>
          </div>
        ) : (
          <div className="small-text ">{t("app.passwordConstraints")}</div>
        ))}
      <div className="auth-form-actions">
        {displayEmailAndPassword === true && (
          <Button type="primary" block loading={working} disabled={working} submit>
            {actionLabel || t("auth.login.title")}
          </Button>
        )}
        {googleClientId !== undefined && (
          <>
            {displayEmailAndPassword === true && <small>{t("app.or")}</small>}
            <GoogleLogin onSuccess={onGoogleAuth} actionLabel={actionLabel} working={working} />
          </>
        )}
      </div>
    </Form>
  );
}

CredentialsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialFormValue: PropTypes.object,
  emailIsReadOnly: PropTypes.bool,
  forgottenPasswordLink: PropTypes.bool,
  actionLabel: PropTypes.string,
  onEmailAuth: PropTypes.func.isRequired,
  onGoogleAuth: PropTypes.func.isRequired,
  working: PropTypes.bool,
  setEmail: PropTypes.func,
};
