import React from "react";

import "./CustomerDetailLicensePage.scss";
import * as PropTypes from "prop-types";

export default function CustomerDetailLicensePage({ customer }) {
  return <h1>{customer.name} license page </h1>;
}

CustomerDetailLicensePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customer: PropTypes.object,
};
