import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomerRole from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/CustomerRole";
import { Button, Modal, ModalClosableFooter, Typography } from "@ogury/design-system";
import { computeValueLabels, customerService, userService } from "Legacy/services";
import { useAuth, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import UserInfoCell from "../UserInfoCell/UserInfoCell";

const { RoleEnum } = CustomerRole;

export default function ChangeRoleModal({ open, onClose, onSuccess, customerId, user, role }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const auth = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const rolesOptions = useMemo(() => {
    let options = computeValueLabels(RoleEnum);

    // User is not an owner, so he can not assign a new owner to the organisation. Hiding the "Owner" option
    if (userService.getRoleForCustomer(customerId) !== RoleEnum.Owner) {
      options = options.filter(obj => obj.value !== RoleEnum.Owner);
    }
    return options;
  }, [customerId]);

  const onChangeRole = async formValue => {
    setWorking(true);
    try {
      // Role to set is owner, calling special webservice to change ownership
      if (formValue.role === RoleEnum.Owner) {
        await customerService.setOwner(customerId, user.id);
      } else {
        await userService.setRole(user.id, customerId, formValue.role);
      }

      notificationService.notifySuccess(t("customers.members.changeRoleModal.success"));
      setWorking(false);
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
      setWorking(false);
    }
  };

  const initialFormValue = {
    role,
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      role: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onChangeRole,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const roleFieldProps = getFieldProps("role");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const handleConfirmation = () => {
    getFormProps().onSubmit();
    setShowConfirmation(false);
  };

  const getQuestionLabel = () => {
    if (getFieldProps("role").value === RoleEnum.Owner) {
      return t("customers.members.changeRoleModal.confirmTransferOwnership");
    }
    // Selected user is current user
    if (user.id === auth.currentUser.id) {
      return t("customers.members.changeRoleModal.confirmSelf");
    }

    return t("customers.members.changeRoleModal.confirm");
  };

  const handleChangeRoleClick = () => {
    modal.confirm({
      content: getQuestionLabel(),
      okText: t("actions.confirm"),
      onOk: handleConfirmation,
    });
  };

  return (
    <GuardedModal
      open={open}
      title={t("customers.members.changeRoleModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button type="primary" onClick={handleChangeRoleClick} loading={working}>
              {t("customers.members.changeRoleModal.changeRole")}
            </Button>
          }
        />
      }
      canBeClosed={() => (roleFieldProps.value !== role ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
      width={ModalWidths.Medium}
    >
      <Typography.P2Regular as="p">{t("customers.members.changeRoleModal.explanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <UserInfoCell user={user} />
        <FormControl
          required
          id="role"
          type="select"
          label={t("customers.members.changeRoleModal.role")}
          options={rolesOptions}
          {...roleFieldProps}
          disabled={showConfirmation}
        />
      </Form>
      {contextHolder}
    </GuardedModal>
  );
}

ChangeRoleModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  user: PropTypes.object,
  role: PropTypes.string,
  customerId: PropTypes.string,
  open: PropTypes.bool,
};
