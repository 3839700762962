import { path } from "Legacy/utils";
import Applications from "./Applications";

export default [
  {
    path: path.APPLICATIONS,
    key: "applications",
    component: Applications,
  },
];
