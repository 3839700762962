import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Form, FormControl, GuardedModal, SiteThumbnail, SpacedContainer, SpacedUnits } from "Legacy/components";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { helpers, siteService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "100%";

export default function SiteDuplicateModal({ open, site, siteName, siteComment, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async formData => {
    try {
      setWorking(true);
      const newSite = await siteService.duplicate(site.id, {
        name: formData.name.trim(),
        comment: formData.comment,
      });
      notificationService.notifySuccess(t("sites.duplicateModal.success"));
      onSuccess(newSite);
      onClose();
    } catch (error) {
      notificationService.notifyError(error, t("sites.duplicateModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => ({ name: `${siteName}-copy`, comment: siteComment }), [siteName, siteComment]);

  const nameMaxLength = helpers.constants.siteNameLengthMaximum;
  const commentMaxLength = helpers.constants.commentLengthMaximum;

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_MAX_LENGTH,
          length: nameMaxLength,
          message: t("fields.formValidationErrors.name", { length: nameMaxLength }),
        },
      ],
      comment: [
        {
          name: validators.IS_MAX_LENGTH,
          length: commentMaxLength,
          message: t("fields.formValidationErrors.comment", { length: commentMaxLength }),
        },
      ],
    },
    onSubmit,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const nameFieldProps = getFieldProps("name");
  const commentFieldProps = getFieldProps("comment");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderForm = () => (
    <Form {...getFormProps()}>
      <fieldset disabled={working}>
        <FormControl
          id="name"
          type="text"
          label={t("fields.name")}
          placeholder={t("fields.namePlaceholder")}
          width={inputsWidth}
          required
          {...nameFieldProps}
        />
        {/* <FormControl
          id="comment"
          type="textarea"
          label={t("fields.comment")}
          placeholder={t("fields.commentPlaceholder")}
          width={inputsWidth}
          {...commentFieldProps}
        /> */}
      </fieldset>
    </Form>
  );

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("sites.duplicateModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.duplicate")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        nameFieldProps.value !== initialFormValue.name || commentFieldProps.value !== initialFormValue.comment
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("sites.duplicateModal.explanation")} </Typography.P2Regular>
      <SpacedContainer gap={SpacedUnits.Small}>
        <SiteThumbnail site={site} withLink={false} />
        {renderForm()}
      </SpacedContainer>
    </GuardedModal>
  );
}

SiteDuplicateModal.propTypes = {
  site: PropTypes.object,
  siteName: PropTypes.string,
  siteComment: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
