import React from "react";
import * as PropTypes from "prop-types";

import "./LayoutWithPreviewPane.scss";

export default function LayoutWithPreviewPane({ children, previewPane }) {
  return (
    <div className="layout-with-preview-pane">
      <div className="page-content">{children}</div>
      <div className="preview-pane">{previewPane}</div>
    </div>
  );
}

LayoutWithPreviewPane.propTypes = {
  children: PropTypes.node,
  previewPane: PropTypes.node,
};
