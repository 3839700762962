import { Site, Sorting } from "@ogury/motionly-ws-api/ws";
import { DEFAULT_PAGE_SIZE } from "Legacy/utils/pagination";
import i18n from "i18next";
import {
  customerService,
  experienceService,
  siteFilterHelper,
  siteFilters,
  siteSortingHelper,
  tagService,
  userService,
} from "Legacy/services";
import MotionlyApiService from "./MotionlyApiService";

export const convertSearchDataUiToApi = criteria => {
  try {
    const sorting = siteSortingHelper.uiToApi(criteria.sort);
    const filters = siteFilterHelper.uiToApi(criteria.filters || []);
    return {
      sorting,
      filters,
      pagination: criteria.pagination,
      intervalValue: criteria.intervalValue,
      searchText: criteria.searchText,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(criteria),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

export const convertSearchDataApiToUi = (parameters, allTags) => {
  try {
    const sorting = siteSortingHelper.apiToUi(parameters.sorting);
    const filters = siteFilterHelper.apiToUi(parameters.filters, allTags);
    return { sorting, filters, pagination: parameters.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(parameters),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

const buildSearchOptions = ({ searchText, sort, pagination, filters } = {}) => {
  const criteria = {};
  const options = {};
  if (sort) {
    criteria.sorting = siteSortingHelper.uiToApi(sort);
  }
  if (pagination) {
    options.range = { start: pagination.offset, end: pagination.offset + pagination.limit - 1 };
  }
  if (searchText) {
    options.text = searchText;
  }
  if (filters) {
    criteria.filters = siteFilterHelper.uiToApi(filters);
  }
  return { criteria, options };
};

const calculateTotalSites = (sites, { pagination }) => {
  if (!pagination) {
    return sites.length;
  }

  return pagination.limit === sites.length
    ? pagination.offset + pagination.limit + 1
    : pagination.offset + sites.length;
};

const hydrateSite = async ({ site, experiencesArray, usersById, tagsById, skipFirstExperienceHydratation }) => {
  if (!site) {
    return;
  }
  const siteTags = site.tagIds.map(id => tagsById[id] || { id, name: "Unknown tag" });
  let firstExperience;

  const hydratedSite = {
    ...site,
    owner: usersById[site.ownerId?.toString()] || userService.computeNotReachableUser(site.ownerId),
    tags: siteTags,
  };

  if (skipFirstExperienceHydratation) {
    return hydratedSite;
  }

  if (!experiencesArray?.length) {
    if (site.experienceIds?.length >= 1) {
      firstExperience = await experienceService.get(site.experienceIds[0]);
    }
  } else {
    firstExperience = experiencesArray.find(
      experience => experience.id?.toString() === site.experienceIds[0]?.toString()
    );
  }

  return {
    ...hydratedSite,
    firstExperience,
  };
};

const hydrateSites = async ({ sitesList, usersById, tagsById, skipFirstExperienceHydratation }) => {
  const firstExperienceIdsArray = [];
  let experiencesArray;
  if (!skipFirstExperienceHydratation) {
    sitesList?.entities?.forEach(_site => {
      if (_site.experienceIds?.length >= 1) {
        firstExperienceIdsArray.push(_site.experienceIds[0]);
      }
    });

    if (firstExperienceIdsArray.length > 0) {
      const apiResponse = await MotionlyApiService.experienceApi().listByIds(
        customerService.getCurrentCustomerSummaryId(),
        firstExperienceIdsArray
      );
      experiencesArray = apiResponse?.experiences;
    }
  }

  return await Promise.all(
    sitesList.entities.map(async site =>
      hydrateSite({ site, experiencesArray, usersById, tagsById, skipFirstExperienceHydratation })
    )
  );
};

class SiteService {
  async listAll() {
    const sitesList = await MotionlyApiService.siteApi().list(customerService.getCurrentCustomerSummaryId());

    const usersById = await customerService.listCurrentCustomerUsersById();
    const tagsById = await tagService.listTagsById();

    const builtSites = await hydrateSites({ sitesList, usersById, tagsById, skipFirstExperienceHydratation: true });
    return { sites: builtSites };
  }

  async list(searchCriteria) {
    // eslint-disable-next-line no-param-reassign
    delete searchCriteria.filters;
    const { criteria, options } = buildSearchOptions(searchCriteria);
    const sitesList = await MotionlyApiService.siteApi().search(
      customerService.getCurrentCustomerSummaryId(),
      searchCriteria.searchText || "",
      { ...options, sorting: criteria.sorting }
    );

    if (sitesList?.entities?.length) {
      const usersById = await customerService.listCurrentCustomerUsersById();
      const tagsById = await tagService.listTagsById();

      const builtSites = await hydrateSites({ sitesList, usersById, tagsById });
      return { sites: builtSites, total: calculateTotalSites(builtSites, searchCriteria) };
    }
    return { sites: [], total: 0 };
  }

  async listByFilter(searchCriteria) {
    // eslint-disable-next-line no-param-reassign
    const { criteria, options } = buildSearchOptions(searchCriteria);
    const sitesList = await MotionlyApiService.siteApi().listByFilter(
      customerService.getCurrentCustomerSummaryId(),
      criteria,
      options
    );

    const usersById = await customerService.listCurrentCustomerUsersById();
    const tagsById = await tagService.listTagsById();
    const builtSites = await hydrateSites({ sitesList, usersById, tagsById });
    return { sites: builtSites, total: sitesList.total };
  }

  async listByOwner(ownerId) {
    const searchCriteria = {
      filters: [
        {
          filter: siteFilters.OWNER,
          values: [ownerId?.toString()],
        },
      ],
    };
    return this.listByFilter(searchCriteria);
  }

  async get(siteId) {
    const site = await MotionlyApiService.siteApi().get(customerService.getCurrentCustomerSummaryId(), siteId);

    const usersById = await customerService.listCurrentCustomerUsersById();
    const tagsById = await tagService.listTagsById();
    // TODO remove fetching first experience if not needed
    return hydrateSite({ site, usersById, tagsById });
  }

  async create(siteName, account, brand) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return MotionlyApiService.siteApi().create(customerId, siteName, {
      siteDto: {
        account,
        brand,
      },
    });
  }

  async update(siteId, siteName, account, brand) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return MotionlyApiService.siteApi().update(customerId, {
      id: siteId,
      name: siteName,
      siteDto: {
        account,
        brand,
      },
    });
  }

  async rename(siteId, siteName) {
    return MotionlyApiService.siteApi().rename(customerService.getCurrentCustomerSummaryId(), siteId, {
      name: siteName,
    });
  }

  async attachExperience(site, experienceId) {
    let experienceIsAlreadyAttached = false;
    if (site?.experienceIds?.length) {
      experienceIsAlreadyAttached = site.experienceIds.includes(experienceId);
    }
    if (experienceIsAlreadyAttached === false) {
      return MotionlyApiService.siteApi().withExperience(
        customerService.getCurrentCustomerSummaryId(),
        site.id,
        experienceId,
        true
      );
    }
    throw Error(i18n.t("sites.uploadExperienceBundleModal.error.experienceIsAlreadyAttached", { experienceId }));
  }

  async setTags(siteId, tagIds = []) {
    return MotionlyApiService.siteApi().setTags(customerService.getCurrentCustomerSummaryId(), siteId, {
      tagIds,
    });
  }

  async detachExperience(siteId, experienceId) {
    return MotionlyApiService.siteApi().withExperience(
      customerService.getCurrentCustomerSummaryId(),
      siteId,
      experienceId,
      false
    );
  }
  async getThirdPartyTagsBySiteIdsToExcel(siteIds, dsps) {
    return MotionlyApiService.siteApi().getThirdPartyTagsBySiteIdsToExcel(
      customerService.getCurrentCustomerSummaryId(),
      siteIds,
      dsps
    );
  }
  async getExperienceSite(experienceId) {
    const sites = await MotionlyApiService.siteApi().getExperienceSites(
      customerService.getCurrentCustomerSummaryId(),
      experienceId
    );
    if (sites?.length >= 0) {
      const usersById = await customerService.listCurrentCustomerUsersById();
      const tagsById = await tagService.listTagsById();
      return hydrateSite({
        site: sites[0],
        experiencesArray: [],
        usersById,
        tagsById,
        skipFirstExperienceHydratation: true,
      });
    }
    return undefined;
  }

  publish(siteId) {
    return MotionlyApiService.siteApi().setState(
      customerService.getCurrentCustomerSummaryId(),
      siteId,
      Site.StateEnum.Published
    );
  }

  async duplicate(siteId, { name, comment }) {
    return MotionlyApiService.siteApi().duplicate(customerService.getCurrentCustomerSummaryId(), siteId, name, {
      comment,
      deepCopy: true,
    });
  }

  deleteSite(siteId) {
    return MotionlyApiService.siteApi().callDelete(customerService.getCurrentCustomerSummaryId(), siteId);
  }

  async getThirdPartyTag(siteId, minified = false, applicationPublicId, dsp) {
    const customerId = customerService.getCurrentCustomerSummaryId();

    return await MotionlyApiService.siteApi().getThirdPartyTagBySiteId(customerId, siteId, {
      dsp,
      applicationPublicId,
      minified,
    });
  }

  async listAccounts(search) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return MotionlyApiService.siteApi().listAccount(customerId, search);
  }

  async listBrands(search) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return MotionlyApiService.siteApi().listBrand(customerId, search);
  }
}

export default new SiteService();
