import { useCallback, useReducer } from "react";

const messages = {
  FETCH_DATA_REQUEST: Symbol("fetchDataRequest"),
  FETCH_DATA_SUCCESS: Symbol("fetchDataSuccess"),
  FETCH_DATA_FAILURE: Symbol("fetchDataFailure"),
  RESET_PAGINATION: Symbol("resetPagination"),
  SET_PAGINATION: Symbol("setPagination"),
  SET_FILTERS: Symbol("setFilters"),
  SET_SORT: Symbol("setSort"),
};

const computePageDataReducer = property => (state, action) => {
  switch (action.type) {
    case messages.FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case messages.FETCH_DATA_SUCCESS: {
      const { list, totalCount } = action.payload;
      return {
        ...state,
        loading: false,
        [property]: list,
        count: list.length,
        totalCount,
      };
    }
    case messages.FETCH_DATA_FAILURE:
      return { ...state, loading: false, loadingError: true, errorMessage: action.payload };
    case messages.SET_FILTERS:
      return { ...state, filters: action.filters };
    case messages.SET_SORT:
      return { ...state, sort: action.sort };
    case messages.SET_PAGINATION:
      return { ...state, pagination: action.pagination };
    case messages.RESET_PAGINATION:
      return { ...state, pagination: { ...state.pagination, pageNumber: 1 } };
    default:
      return state;
  }
};

const computeInitialPageState = (property, { filters, sort, pagination }) => ({
  loading: false,
  [property]: [],
  count: -1,
  totalCount: -1,
  loadingError: false,
  filters,
  sort,
  pagination,
});

const loadPageState = async (dispatch, { filters, sort, pagination }, loadFunction) => {
  if (loadFunction !== undefined) {
    dispatch({ type: messages.FETCH_DATA_REQUEST });
  }
  try {
    dispatch({ type: messages.SET_FILTERS, filters });
    dispatch({ type: messages.SET_SORT, sort });
    dispatch({ type: messages.SET_PAGINATION, pagination });
    if (loadFunction !== undefined) {
      const entities = await loadFunction();
      dispatch({
        type: messages.FETCH_DATA_SUCCESS,
        payload: entities,
      });
    }
  } catch (error) {
    dispatch({ type: messages.FETCH_DATA_FAILURE, payload: error?.message });
  }
};

export const usePageDataReducerWithFunction = (
  property,
  { filters, sortBy, sortOrder, pageSize, pageNumber },
  loadFunction
) => {
  const [pageState, dispatch] = useReducer(
    computePageDataReducer(property),
    computeInitialPageState(property, { filters, sortBy, sortOrder, pageSize, pageNumber }),
    undefined
  );
  const load = useCallback(
    async (forceFetch = false) => {
      await loadPageState(dispatch, {}, () => loadFunction(forceFetch));
    },
    [dispatch, loadFunction]
  );
  return [pageState, load];
};

export const usePageDataReducer = (property, { filters, sort, pagination }) => {
  const [pageState, dispatch] = useReducer(
    computePageDataReducer(property),
    computeInitialPageState(property, { filters, sort, pagination }),
    undefined
  );
  return [pageState, dispatch, loadPageState];
};
