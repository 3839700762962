import {
  AuthenticatedUserAndCustomerCreationInputs,
  AuthenticatedUserCreationInputs,
  AuthenticateInputs,
  CustomerCreationInputs,
  ForgotPasswordInputs,
  LoginCredentials,
} from "@ogury/motionly-ws-api/ws";

import { encryptPassword } from "Legacy/utils";
import {
  deleteObject,
  getObject,
  LOCALSTORAGE_CREDENTIALS,
  LOCALSTORAGE_CURRENT_CUSTOMER_DATA,
  LOCALSTORAGE_CURRENT_CUSTOMER_USERS,
  LOCALSTORAGE_CURRENT_USER,
  LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST,
  LOCALSTORAGE_SETTINGS,
  persistObject,
} from "Legacy/utils/storage";
import MotionlyApiService from "./MotionlyApiService";

/**
 * @return {AuthenticateInputs}
 */
function generateAuthenticateInputs(data, source) {
  // noinspection JSUnresolvedVariable
  if (source === AuthenticateInputs.SourceEnum.Motionly) {
    return new AuthenticateInputs(data.email, source, encryptPassword(data.password));
  }
  // noinspection JSUnresolvedVariable
  if (source === AuthenticateInputs.SourceEnum.Google) {
    return new AuthenticateInputs(data.email, source, data.code);
  }
  return undefined;
}

const generateLoginCredentials = object => new LoginCredentials(object.email, object.source, object.accessToken);

class AuthenticationService {
  async authenticateAndLogin(data, source, authenticateResult) {
    let actualAuthenticateResult = authenticateResult;
    if (authenticateResult === undefined) {
      const authenticateInputs = generateAuthenticateInputs(data, source);
      const redirectUri = `${document.location.protocol}//${document.location.host}`; // + location.pathname;
      // noinspection JSCheckFunctionSignatures
      actualAuthenticateResult = await MotionlyApiService.userApi().authenticate(redirectUri, authenticateInputs);
    }

    const loginCredentials = generateLoginCredentials(actualAuthenticateResult);

    const { user } = await this.login(loginCredentials);
    persistObject(LOCALSTORAGE_CREDENTIALS, {
      ...loginCredentials,
      refreshToken: actualAuthenticateResult.refreshToken,
    });

    const settings = getObject(LOCALSTORAGE_SETTINGS);

    if (settings === undefined || settings.lastCustomerId === undefined) {
      persistObject(LOCALSTORAGE_SETTINGS, { lastCustomerId: user.roles.list[0].customerId?.toString() });
    }

    return { user, accessToken: loginCredentials.accessToken };
  }

  async login(loginCredentials) {
    const actualCredentials =
      loginCredentials !== undefined ? loginCredentials : generateLoginCredentials(getObject(LOCALSTORAGE_CREDENTIALS));
    const { user, customers } = await MotionlyApiService.userApi().login(actualCredentials);

    if (!Array.isArray(user.roles.list) || user.roles.list.length === 0 || customers === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`The user with e-mail '${user.email}' does not have customer account.`);
      throw new Error("The user does not have customer account");
    }

    persistObject(LOCALSTORAGE_CURRENT_USER, user);
    persistObject(LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST, customers);
    return { user, customers };
  }

  logout() {
    // noinspection JSUnresolvedVariable
    if (window.gapi !== undefined) {
      // noinspection JSUnresolvedFunction, JSUnresolvedVariable
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        auth2.signOut();
        auth2.disconnect();
      }
    }

    deleteObject(LOCALSTORAGE_CREDENTIALS);
    deleteObject(LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST);
    deleteObject(LOCALSTORAGE_CURRENT_USER);
    deleteObject(LOCALSTORAGE_CURRENT_CUSTOMER_DATA);
    deleteObject(LOCALSTORAGE_CURRENT_CUSTOMER_USERS);
  }

  async forgotPassword(email) {
    const forgotPasswordInputs = new ForgotPasswordInputs(email);
    return MotionlyApiService.userApi().forgotPassword(forgotPasswordInputs);
  }

  async acceptInvitation(customerId, name, authCredentials) {
    const authenticateInputs = generateAuthenticateInputs(authCredentials.formData, authCredentials.source);
    const redirectUri = `${document.location.protocol}//${document.location.host}`;
    const authenticatedUserCreationInputs = AuthenticatedUserCreationInputs.constructFromObject(
      {
        authentication: authenticateInputs,
        name,
      },
      undefined
    );

    return MotionlyApiService.userApi().acceptInvitation(customerId, redirectUri, authenticatedUserCreationInputs);
  }

  async signup(authCredentials, authenticationData) {
    const authenticateInputs = generateAuthenticateInputs(authCredentials.formData, authCredentials.source);
    const customerCreationInputs = new CustomerCreationInputs(
      authenticationData.customer.id,
      authenticationData.customer.name
    );
    const redirectUri = `${document.location.protocol}//${document.location.host}`;

    const authenticatedUserAndCustomerCreationInputs = AuthenticatedUserAndCustomerCreationInputs.constructFromObject(
      {
        authentication: authenticateInputs,
        customer: customerCreationInputs,
        name: authenticationData.name,
      },
      undefined
    );

    return MotionlyApiService.userApi().createUserAndCustomer(redirectUri, authenticatedUserAndCustomerCreationInputs);
  }
}

export default new AuthenticationService();
