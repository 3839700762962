import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { path, useAuth } from "Legacy/utils";
import { JoinPage, LoginPage, NotFoundPage, PasswordForgottenPage, SignupPage, UnauthorizedPage } from "./pages";

const RedirectWithLocation = () => {
  const auth = useAuth();
  if (auth.isAuthenticated()) {
    return <Redirect to={path.NOT_FOUND} />;
  }
  return <Redirect to={{ pathname: path.LOGIN, state: { from: window.location.hash } }} />;
};

export default function Motionly() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={path.DEFAULT_HOME} />
      </Route>
      <Route exact path={`${path.JOIN}/:customerId/:encodedEmail`} component={JoinPage} />
      <Route exact path={path.PASSWORD_FORGOTTEN} component={PasswordForgottenPage} />
      <Route exact path={path.LOGIN} component={LoginPage} />
      <Route exact path={path.SIGNUP} component={SignupPage} />
      <Route exact path={path.UNAUTHORIZED} component={UnauthorizedPage} />
      <Route exact path={path.NOT_FOUND} component={NotFoundPage} />
      <Route exact path="*">
        <RedirectWithLocation />
      </Route>
    </Switch>
  );
}
