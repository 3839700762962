import React from "react";

import "./AppLoader.scss";
import Logo from "Legacy/assets/img/Logo.svg";

export default function AppLoader() {
  return (
    <div className="app-loader-container">
      <img className="animated-logo" src={Logo} alt="Welcome to Motionly" />
    </div>
  );
}
