import React from "react";
import * as PropTypes from "prop-types";

import "./Checkbox.scss";

export default function Checkbox({
  name,
  label,
  checked,
  defaultChecked,
  labelClass,
  id = "checkbox",
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
}) {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={name}
        disabled={disabled}
        onChange={event => {
          onChange(event.target.checked);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
    </>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};
