import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@ogury/design-system";
import { experiencePermissions, templateService } from "Legacy/services";
import { path } from "Legacy/utils";

export default function TemplateLink({ tagId }) {
  const [template, setTemplate] = useState();

  async function load() {
    const _template = templateService.getByTagId(tagId);
    setTemplate(_template);
  }

  useEffect(() => {
    load();
  }, [tagId]); //eslint-disable-line

  if (!template) {
    return <></>;
  }

  if (experiencePermissions.canCreateStandaloneExperience()) {
    return (
      <Link className="typo-link" to={`${path.TEMPLATES}/${template.id}`}>
        {template?.name}
      </Link>
    );
  }
  return <Typography.P2Regular>{template?.name}</Typography.P2Regular>;
}
