import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";

import "./Tooltip.scss";
import { theme } from "@ogury/design-system";
import { randomString } from "Legacy/utils";
import SpacedContainer, { SpacedUnits } from "../Layout/SpacedContainer/SpacedContainer";
import { TooltipRightPane } from "./components";

export const TooltipsTypes = {
  Simple: "simple",
  Standard: "standard",
};

export default function Tooltip({
  id,
  title,
  type = TooltipsTypes.Standard,
  shortHtmlContent,
  longHtmlContent,
  withIcon,
  children,
}) {
  const currentId = id || randomString();
  const [showLongHtmlContent, setShowLongHtmlContent] = useState(false);
  const [t] = useTranslation();

  const processedChildren = typeof children === "string" ? <div>{children}</div> : children;
  const toCloneElement = withIcon !== undefined ? <Icon path={mdiInformation} size={0.6} /> : processedChildren;
  // noinspection JSCheckFunctionSignatures
  const element = React.cloneElement(toCloneElement, {
    tooltipid: currentId,
    "data-tip": "",
    "data-for": currentId,
  });

  let fullElement;
  if (withIcon !== undefined) {
    fullElement = (
      <SpacedContainer gap={SpacedUnits.XXSmall} horizontal>
        {withIcon === false && processedChildren}
        {element}
        {withIcon === true && processedChildren}
      </SpacedContainer>
    );
  } else {
    fullElement = element;
  }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      setShowLongHtmlContent(true);
    }
  };
  const offset = {};
  if (type === TooltipsTypes.Simple) {
    offset.bottom = 10;
  }
  return (
    <>
      {fullElement}
      <ReactTooltip
        // TODO: retrieve the colors from the styles
        backgroundColor={type === TooltipsTypes.Simple ? theme.colors.shape.neutral100 : "#000"}
        arrowColor={type === TooltipsTypes.Simple ? "rgba(0,0,0,0)" : "#000"}
        id={currentId}
        delayHide={type === TooltipsTypes.Simple ? 0 : 500}
        effect="solid"
        className="inline-tooltip"
        type={type === TooltipsTypes.Simple ? "light" : "dark"}
        offset={offset}
        place={type === TooltipsTypes.Simple ? "bottom" : "top"}
      >
        {shortHtmlContent}
        {longHtmlContent && (
          <div
            onKeyPress={handleKeyPress}
            role="presentation"
            onClick={() => setShowLongHtmlContent(true)}
            className="inline-tooltip-more"
          >
            {t("app.tooltip.more")}
          </div>
        )}
      </ReactTooltip>
      {showLongHtmlContent === true && (
        <TooltipRightPane
          title={title}
          longHtmlContent={longHtmlContent}
          onClose={() => setShowLongHtmlContent(false)}
        />
      )}
    </>
  );
}

Tooltip.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf([TooltipsTypes.Simple, TooltipsTypes.Standard]),
  shortHtmlContent: PropTypes.node,
  longHtmlContent: PropTypes.node,
  withIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
