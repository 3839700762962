import { Site } from "@ogury/motionly-ws-api/ws";
import { userPermissions } from "Legacy/services";
import experiencePermissions from "./experiencePermissions";

const siteIsDraft = site => site.state === Site.StateEnum.Draft;

const siteHasAtLeastOneExperience = site => site.experienceIds && site.experienceIds.length >= 1;

/* eslint-disable no-unused-vars */
function currentUserHasWritePermission(site) {
  return userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps();
}

export default {
  canAccess: () => userPermissions !== undefined && userPermissions.currentUserIsOwnerOrAdmin(),
  canEmbed: () => true,
  canCreate: () => userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps(),
  canPublish: site => currentUserHasWritePermission(site) && siteIsDraft(site) && siteHasAtLeastOneExperience(site),
  canDuplicate: site => currentUserHasWritePermission(site),
  canChangeBrand: site => currentUserHasWritePermission(site) && siteIsDraft(site),
  canEdit: site => currentUserHasWritePermission(site),
  canEmbedExperience: (experience, environment) => experiencePermissions.canEmbed(experience, environment),
  canAddExperience: site => currentUserHasWritePermission(site),
  canDuplicateExperience: site => currentUserHasWritePermission(site), // Add hide duplicates if the site is full
  canManageExperienceTags: experience => experiencePermissions.canManageTags(experience),
  canDetachExperience: site => currentUserHasWritePermission(site),
  canDeleteExperience: (experience, site) =>
    !experiencePermissions.experienceIsPublished(experience) && currentUserHasWritePermission(site),
  canDeepDeleteExperience: experience => experiencePermissions.canDelete(experience),
  canShareExperience: experience => experiencePermissions.sharing.canShare(experience),
  canMoveToExperience: (experience, site) =>
    !experiencePermissions.experienceIsPublished(experience) && currentUserHasWritePermission(site),
  canPublishExperience: experience => experiencePermissions.canPublish(experience),
  canPublishOverExperience: () => false,
  canDelete: site => currentUserHasWritePermission(site) && siteIsDraft(site),
};
