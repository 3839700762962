class LocalFilterService {
  executeFilter(filterConfig, searchCriteria, items) {
    let filteredItems = [...items];

    // Each group processing creates the list to be filtered by the next group
    searchCriteria.forEach(criterion => {
      const conf = filterConfig[criterion.filter];

      // for a group, add all filter results of each value
      if (conf) {
        const groupResult = new Set();

        criterion.values.forEach(value => {
          // I want to use the conf comparator with the value
          const comparator = conf.comparator(value);

          // The filter function needs the comparator before use
          const filterFn = conf.filterFn(comparator);

          // Here we filter the remaining items and add them to the group result
          filteredItems.filter(filterFn).forEach(o => groupResult.add(o));
        });

        filteredItems = Array.from(groupResult);
      }
    });
    return filteredItems;
  }
}

export default new LocalFilterService();
