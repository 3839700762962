import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperienceDownloadButton.scss";
import { Button, Dropdown, Tooltip } from "@ogury/design-system";
import DownloadCloudLineIcon from "remixicon-react/DownloadCloudLineIcon";
import { experienceDownloadCommands, experiencePermissions } from "Legacy/services";
import { ExperienceDownloaderModal } from "./components";

export default function ExperienceDownloadButton({ experience }) {
  const [t] = useTranslation();
  const [downloadCommand, setDownloadCommand] = useState();

  const onDownloadComplete = () => {
    setDownloadCommand(null);
  };

  const openDownloadModal = command => {
    setDownloadCommand(command);
  };

  const menuItems = [
    experiencePermissions.download.canDownloadDescription(experience) && {
      key: "description",
      onClick: () => openDownloadModal(experienceDownloadCommands.DESCRIPTION),
      label: t("experiences.download.options.description"),
    },
    experiencePermissions.download.canDownloadInputs(experience) && {
      key: "inputs",
      onClick: () => openDownloadModal(experienceDownloadCommands.INPUTS),
      label: t("experiences.download.options.inputs"),
    },
    experiencePermissions.download.canDownloadBundle(experience) && {
      key: "bundle",
      onClick: () => openDownloadModal(experienceDownloadCommands.BUNDLE),
      label: t("experiences.download.options.bundle"),
    },
  ];

  const downloadIcon = (
    <Tooltip title={t("actions.download")} placement="top">
      <Button
        iconPosition="iconOnly"
        icon={<DownloadCloudLineIcon />}
        type="secondary"
        disabled={
          !experiencePermissions.download.canDownloadDescription(experience) &&
          !experiencePermissions.download.canDownloadInputs(experience) &&
          !experiencePermissions.download.canDownloadBundle(experience)
        }
      />
    </Tooltip>
  );

  return (
    <>
      <Dropdown trigger="click" menu={{ items: menuItems }}>
        {downloadIcon}
      </Dropdown>
      <ExperienceDownloaderModal
        open={downloadCommand && downloadCommand !== experienceDownloadCommands.HTML}
        experience={experience}
        command={downloadCommand}
        onDownloadComplete={onDownloadComplete}
        onCancel={() => setDownloadCommand(null)}
      />
    </>
  );
}

ExperienceDownloadButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
};
