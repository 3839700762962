import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Modal } from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { useNotificationService } from "Legacy/utils";
import { Environment, experienceService } from "Legacy/services";
import style from "./ExperienceIntegrationWebModal.module.scss";

export default function ExperienceIntegrationWebModal({ open, experience, renderLoader, working, onWorking, onClose }) {
  const [frameData, setFrameData] = useState();
  const [t] = useTranslation();
  const notificationService = useNotificationService();

  useEffect(() => {
    if (!open) {
      return;
    }

    const loadData = async () => {
      onWorking(true);
      try {
        setFrameData(await experienceService.getIntegrationData(experience.id, Environment.Web));
      } catch (error) {
        notificationService.notifyError(error);
      } finally {
        onWorking(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
  }, [open, experience, onWorking]);

  return (
    <Modal open={open} onCancel={onClose} title={t("experiences.embed.modal.title")}>
      {working ? renderLoader() : <iframe className={style.iframe} title="preview-iframe" srcDoc={frameData} />}
    </Modal>
  );
}

ExperienceIntegrationWebModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool,
  experience: PropTypes.object.isRequired,
  renderLoader: PropTypes.func.isRequired,
  working: PropTypes.bool.isRequired,
  onWorking: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
