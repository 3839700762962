import React from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import "./TemplatesDetailGeneralPage.scss";
import { Tag } from "@ogury/design-system";
import { Attribute, Avatar, DetailPage, EmptyState, SpacedContainer, SpacedUnits } from "Legacy/components";
import motionlyAuthor from "Legacy/assets/img/motionlyAuthor.png";
import { DemosCard } from "./components";

export default function TemplatesDetailGeneralPage({ template = {}, onDemoSelected = () => {} }) {
  const [t] = useTranslation();

  const handleLoadPreview = demo => {
    onDemoSelected(demo);
  };

  const renderTemplateDemos = () => {
    if (template.demos && template.demos.length >= 1) {
      return template.demos.map(demo => (
        <DemosCard key={demo.id} templateId={template.id} demo={demo} loadPreview={() => handleLoadPreview(demo)} />
      ));
    }
    return (
      <EmptyState title={t("templates.details.noDemos.title")} subtitle={t("templates.details.noDemos.subtitle")} />
    );
  };

  return (
    <DetailPage className="template-detail-general">
      <SpacedContainer className="template-detail-general-body">
        <Attribute name={t("templates.details.template")}>
          <h1>{template.name}</h1>
        </Attribute>
        <div className="row">
          <Attribute name={t("templates.details.summary")} value={template.comment} />
          <Attribute name={t("templates.details.creator")}>
            <div className="template-detail-general-body-creator">
              <Avatar src={motionlyAuthor} /> Motionly
            </div>
          </Attribute>
        </div>
        <Attribute name={t("fields.tags")}>
          <SpacedContainer className="template-detail-general-body-tags" horizontal gap={SpacedUnits.XSmall}>
            {template.tags?.map(tag => (
              <Tag key={`template-tag-${tag}`}>{tag}</Tag>
            ))}
          </SpacedContainer>
        </Attribute>
      </SpacedContainer>
      <div className="template-detail-general-demos">{renderTemplateDemos()}</div>
    </DetailPage>
  );
}

TemplatesDetailGeneralPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object,
  onDemoSelected: PropTypes.func,
};
