import { Sorting } from "@ogury/motionly-ws-api/ws";
import { buildOptions, DEFAULT_PAGE_SIZE } from "Legacy/utils/pagination";
import MotionlyApiService from "./MotionlyApiService";
import { localFilterService, templateFilterService } from "./filter";
import { customerService, templateFilterHelper } from ".";

const TEMPLATES_ID_THAT_SUPPORT_BANNER = ["image", "slideshow", "bounce", "twup", "demask", "html"];

export const convertSearchDataUiToApi = criteria => {
  try {
    const filters = templateFilterHelper.uiToApi(criteria.filters || []);
    return {
      sort: criteria.sort,
      filters,
      pagination: criteria.pagination,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(criteria),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

export const convertSearchDataApiToUi = (parameters, allTags) => {
  try {
    const filters = templateFilterHelper.apiToUi(parameters.filters, allTags);
    return { sorting: parameters.sort, filters, pagination: parameters.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(parameters),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

const applyCriteria = (templates, { filters, sort, pagination } = {}) => {
  let filteredTemplates = [...templates];
  let totalFiltered = templates.length;

  // 1. Filter by the criteria
  if (filters) {
    const filterConfig = templateFilterService.getFilterConfig();
    filteredTemplates = localFilterService.executeFilter(filterConfig, filters, templates);
    totalFiltered = filteredTemplates.length;
  }

  // 2. Sort the data
  if (sort) {
    // const { sortBy = 'name', sortOrder = order.ASC } = sort;
    // filteredTemplates = filteredTemplates.sort(TemplateSorterService.getSorter(sortBy, sortOrder));
  }

  // 3. Splice the list at specified limit
  if (pagination) {
    const { offset = 0, limit = 25 } = pagination;
    filteredTemplates = filteredTemplates.slice(offset, offset + limit);
  }

  return {
    templates: filteredTemplates,
    totalTemplates: totalFiltered,
  };
};

// TODO: a cache re-initialization method is required
class TemplateService {
  allTemplates = [];
  loadingPromise = null;
  // Contains all the already individually loaded templates
  templatesMap = {};

  /**
   * Initialize function is called in motionlyservice.initialize() , at the initialization of the app.
   * The UI is not accessible until this function ends, so it means that the unit list always exists when it is called by other services.
   * @returns {Promise<*[]>}
   */
  async initialize() {
    if (this.allTemplates.length === 0) {
      if (!this.loadingPromise) {
        this.loadingPromise = MotionlyApiService.templateApi().list(customerService.getCurrentCustomerSummaryId());
      }
      this.allTemplates = await this.loadingPromise;
    }
    return this.allTemplates;
  }

  async clearCache() {
    this.allTemplates = [];
    this.loadingPromise = null;
    await this.initialize();
  }

  listLocal(searchCriteria) {
    return applyCriteria(this.allTemplates, searchCriteria);
  }

  async list(searchCriteria) {
    const options = buildOptions(searchCriteria);
    return MotionlyApiService.templateApi().list(customerService.getCurrentCustomerSummaryId(), options);
  }

  get(id, version, forceFetch = false) {
    const key = `${id}-${version}`;
    if (forceFetch === false) {
      const cachedValue = this.templatesMap[key];
      if (cachedValue !== undefined) {
        return cachedValue;
      }
    }
    const result = MotionlyApiService.templateApi().get(customerService.getCurrentCustomerSummaryId(), id, { version });
    this.templatesMap[key] = result;
    return result;
  }

  getByTagId(_tagId) {
    const templatesList = this.listLocal({});
    const allTemplates = templatesList?.templates;
    return allTemplates.find(template => template.tagIds.includes(_tagId));
  }

  getAvailableUnits(templateId, unitsAlreadyAvailable) {
    return unitsAlreadyAvailable.filter(unit => {
      if (templateId === "brandspotlight" || templateId === "interactivepuzzle") {
        return unit.technicalId === "interstitial";
      }
      if (templateId === "coresurvey-mpu") {
        return ["banner-300x250", "in_article-300x250"].includes(unit.technicalId);
      }
      if (templateId === "coresurvey") {
        return ["interstitial", "in_article-reveal", "banner-300x600", "banner-320x480"].includes(unit.technicalId);
      }
      if (!TEMPLATES_ID_THAT_SUPPORT_BANNER.includes(templateId)) {
        return !unit.technicalId.startsWith("banner");
      }
      return true;
    });
  }
}

export default new TemplateService();
