import * as PropTypes from "prop-types";
import React from "react";

import "./TemplatePreviewPlaceholder.scss";
import { Button } from "@ogury/design-system";
import PlayLineIcon from "remixicon-react/PlayLineIcon";

import { PreviewPlaceholder } from "Legacy/components";

export default function TemplatePreviewPlaceholder({ title }) {
  return (
    <PreviewPlaceholder disabled>
      <div className="template-page-preview-placeholder">
        <Button icon={<PlayLineIcon />} iconPosition="iconOnly" disabled type="primary" />
        {title}
      </div>
    </PreviewPlaceholder>
  );
}

TemplatePreviewPlaceholder.propTypes = {
  title: PropTypes.string,
};
