export { default as Checkbox } from "./Checkbox/Checkbox";
export { default as FileInput } from "./FileInput/FileInput";
export { default as ColorInput } from "./ColorInput/ColorInput";
export { default as FormControl } from "./FormControl/FormControl";
export { default as FormGroup } from "./FormGroup/FormGroup";
export { default as Form } from "./Form/Form";
export { default as SearchInput } from "./SearchInput/SearchInput";
export * from "./Radio";

export const selectEntry = (value, label) => ({ value, label: label === undefined ? value : label });
