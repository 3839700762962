import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Divider,
  Drawer,
  DrawerClosableFooter,
  DrawerHeader,
  Form,
  Input,
  Space,
  Typography,
} from "@ogury/design-system";
import { useNotificationService } from "Legacy/utils";
import { siteService } from "Legacy/services";

const SitesDuplicateDrawer = ({ open, onClose, onSuccess, sites }) => {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const initialValues = useMemo(() => Object.fromEntries(sites.map(site => [site.id, `${site.name} - copy`])), [sites]);

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const handleFormSubmit = async values => {
    setSubmitting(true);
    const duplicationPromises = [];
    try {
      sites.forEach(site => {
        const newSiteName = values[site.id];
        if (!newSiteName) {
          return;
        }

        duplicationPromises.push(
          siteService.duplicate(site.id, {
            name: newSiteName.trim(),
          })
        );
      });
      await Promise.all(duplicationPromises);

      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("sites.duplicateDrawer.errorTitle"));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={<DrawerHeader title={t("sites.duplicateDrawer.title")} />}
      footer={
        <DrawerClosableFooter
          closeButtonText={t("actions.cancel")}
          actions={
            <Button loading={submitting} type="primary" onClick={() => form.submit()}>
              {t("actions.duplicate")}
            </Button>
          }
        />
      }
    >
      <Form form={form} initialValues={initialValues} onFinish={handleFormSubmit}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            showIcon
            closable={false}
            type="warning"
            description={<div style={{ whiteSpace: "pre-line" }}>{t("sites.duplicateDrawer.alert")}</div>}
          />

          {sites.map((site, index) => {
            return (
              <>
                {index > 0 && <Divider key={`divider-${site.id}`} style={{ margin: 0 }} />}
                <Typography.P2Strong key={`label-${site.id}`}>{site.name}</Typography.P2Strong>
                <Form.Item
                  key={`formitem-${site.id}`}
                  name={site.id}
                  label={t("fields.name")}
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            );
          })}
        </Space>
      </Form>
    </Drawer>
  );
};

SitesDuplicateDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
};

export default SitesDuplicateDrawer;
