import { Experience } from "@ogury/motionly-ws-api/ws";
import { templateService } from "./index";

const { OriginEnum } = Experience;

class MetaService {
  async canUpgrade(experience, template = undefined) {
    // noinspection JSUnresolvedVariable
    if (
      (experience !== undefined && experience.origin === OriginEnum.FromTemplate) ||
      experience.origin === OriginEnum.Dynamic
    ) {
      const currentVersion = experience.data.templateVersion;
      const actualTemplate = template ?? (await templateService.get(experience.data.templateId, currentVersion, false));
      return actualTemplate.versions.indexOf(currentVersion) !== actualTemplate.versions.length - 1;
    }
    return false;
  }
}

export default new MetaService();
