import React from "react";
import * as PropTypes from "prop-types";

import { RadioGroupContext } from "./RadioGroupContext";

/**
 * This RadioGroup is a container designed to contain Radio-like children
 * For now, we have only one available component :
 * - Radio : regular radio inputs, displayable vertically (default) or horizontally
 *
 * <RadioGroup value="A">
 *   <Radio value="A">Value A</Radio>
 *   <Radio value="B">Value B</Radio>
 * </RadioGroup>
 *
 * @param name
 * @param onChange
 * @param value
 * @param children
 * @param disabled
 * @param rest all remaining props, passed to the container
 * @returns {*}
 * @constructor
 */
export default function RadioGroup({ name, onChange, value, children, disabled, ...rest }) {
  return (
    <div role="radiogroup" {...rest}>
      <RadioGroupContext.Provider value={{ selectedValue: value, onChange, name, disabled }}>
        {children}
      </RadioGroupContext.Provider>
    </div>
  );
}

RadioGroup.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};
