import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";

import "./ExperiencePosterModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import {
  Form,
  FormControl,
  GuardedModal,
  ImageCropperModal,
  ImageSelectorPreview,
  ModalHeights,
  Radio,
  RadioGroup,
  selectEntry,
  SpacedContainer,
  SpacedUnits,
} from "Legacy/components";
import { experienceService, getExperienceRatio } from "Legacy/services";
import {
  dataUriToArrayBuffer,
  experienceDescription,
  useFormValidation,
  validators,
  useNotificationService,
} from "Legacy/utils";
import { ExperiencePlayer } from "Legacy/app/experiences/components";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const percentageRange = { MIN: 0, MAX: 100 };
const inputsWidth = "100%";

export default function ExperiencePosterModal({ open, experience, onCancel, onPosterSucceed = () => {} }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const types = [
    { type: "none", label: t("experiences.posterModal.types.none") },
    { type: "file", label: t("experiences.posterModal.types.file") },
    { type: "compute", label: t("experiences.posterModal.types.compute") },
  ];
  const noneType = types[0].type;
  const fileType = types[1].type;
  const computeType = types[2].type;
  let initialType;
  // TODO: use the enum instead
  if (experience.data?.posterRendering?.type === "None") {
    initialType = noneType;
  } else {
    initialType = experience.data?.posterComputation !== undefined ? computeType : fileType;
  }
  const [type, setType] = useState(initialType);
  const [isPlayerReady, setPlayerReady] = useState(false);
  // noinspection JSUnresolvedVariable
  const isDynamicExperience = experience.origin === Experience.OriginEnum.Dynamic;
  const [imageCropperModal, setImageCropperModal] = useState(false);
  const [fileImage, setFileImage] = useState();

  const trackIds = useMemo(
    () => (isDynamicExperience === true ? [] : experienceDescription.computeTrackIds(experience.description)),
    [experience, isDynamicExperience]
  );
  const trackOptions = isDynamicExperience === true ? undefined : trackIds.map(id => selectEntry(id, id));

  const onSavePoster = useCallback(
    async formValue => {
      setWorking(true);
      try {
        let payload;
        if (type === noneType) {
          payload = undefined;
        } else if (type === fileType) {
          payload = { requestBody: dataUriToArrayBuffer(fileImage) };
        } else {
          payload = { ...formValue };
        }
        await experienceService.setPoster(experience.id, payload);
        setWorking(false);
        notificationService.notifySuccess(t("experiences.posterModal.createSuccess"));
        onPosterSucceed();
      } catch (error) {
        setWorking(false);
        notificationService.notifyError(error, t("experiences.posterModal.createError"));
      }
    },
    [t, type, fileType, noneType, fileImage, onPosterSucceed, experience.id]
  );

  const formConfig = useMemo(() => {
    if (type === noneType) {
      return {
        initialValue: {},
        fields: {},
        onSubmit: onSavePoster,
      };
    }
    if (type === fileType) {
      return {
        initialValue: { image: null },
        fields: {
          image: [{ name: validators.IS_REQUIRED }],
        },
        onSubmit: onSavePoster,
      };
    }
    return {
      initialValue: {
        image: null,
        percentage: experience.data.posterComputation?.percentage || 0,
        trackId: experience.data.posterComputation?.trackId || (trackIds.length <= 0 ? "" : trackIds[0]),
        backgroundColor: experience.data.posterComputation?.backgroundColor || "#FFFFFF",
      },
      fields: {
        image: [],
        percentage: [
          { name: validators.IS_REQUIRED },
          { name: validators.IS_INTEGER },
          {
            name: validators.IS_INT_IN_RANGE,
            message: t("form.invalidRange", percentageRange),
            range: percentageRange,
          },
        ],
        trackId: [{ name: validators.IS_REQUIRED }],
        backgroundColor: [{ name: validators.IS_REQUIRED }],
      },
      onSubmit: onSavePoster,
    };
  }, [t, type, fileType, noneType, onSavePoster, experience, trackIds]);

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const imageFieldProps = getFieldProps("image");
  const percentageFieldProps = getFieldProps("percentage");
  const trackIdFieldProps = getFieldProps("trackId");
  const backgroundColorFieldProps = getFieldProps("backgroundColor");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderNoneModeForm = () => <div>{t("experiences.posterModal.none")}</div>;

  const renderFileModeForm = () => (
    <fieldset disabled={working}>
      <FormControl
        id="posterFile"
        type="file"
        label={t("experiences.posterModal.file")}
        tooltipText={t("experiences.posterModal.fileExplanation")}
        accept="image/*"
        required
        width={inputsWidth}
        {...imageFieldProps}
        onChange={value => {
          imageFieldProps.onChange(value);
          setImageCropperModal(true);
        }}
      />
      <div className="poster-preview">
        {fileImage !== undefined && (
          <>
            <div className="label">
              <span>{t("fields.selectedFilename")}</span>&nbsp;
              <span className="selected-file-name">{imageFieldProps.value?.selectedFile?.name}</span>
            </div>
            <div className="label">{t("fields.preview")}</div>
          </>
        )}
        <ImageSelectorPreview src={fileImage} size="small" />
      </div>
    </fieldset>
  );

  const renderDetailedForm = () => (
    <div className="poster-form-and-player">
      <div className="form">
        <fieldset disabled={working}>
          <FormControl
            id="posterPercentage"
            type="number"
            label={t("experiences.posterModal.percentage")}
            min={0}
            max={100}
            required
            disabled={isPlayerReady === false}
            width={inputsWidth}
            {...percentageFieldProps}
          />
          <FormControl
            id="posterTrackId"
            type="select"
            label={t("experiences.posterModal.track")}
            options={trackOptions}
            required
            width={inputsWidth}
            {...trackIdFieldProps}
          />
          <FormControl
            id="posterBackgroundColor"
            type="color"
            label={t("experiences.posterModal.backgroundColor")}
            required
            width={inputsWidth}
            {...backgroundColorFieldProps}
          />
        </fieldset>
      </div>
      <div className="player">
        <ExperiencePlayer
          experience={experience}
          trackId={trackIdFieldProps.value}
          percentage={parseFloat(percentageFieldProps.value)}
          backgroundColor={backgroundColorFieldProps.value}
          onReady={() => {
            setPlayerReady(true);
          }}
        />
      </div>
    </div>
  );

  const renderInnerForm = () => {
    if (type === noneType) {
      return renderNoneModeForm();
    }
    if (type === fileType) {
      return renderFileModeForm();
    }
    return renderDetailedForm();
  };

  const renderMainModal = () => (
    <GuardedModal
      open={open}
      width={ModalWidths.XLarge}
      title={t("experiences.posterModal.title")}
      height={ModalHeights.Large}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              disabled={type === fileType && fileImage === undefined}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.save")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        parseFloat(percentageFieldProps.value) !== formConfig.initialValue.percentage ||
        trackIdFieldProps.value !== formConfig.initialValue.trackId ||
        backgroundColorFieldProps.value !== formConfig.initialValue.backgroundColor ||
        imageFieldProps.value !== null
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onCancel}
    >
      <Typography.P2Regular as="p">{t("experiences.posterModal.explanation")}</Typography.P2Regular>
      <SpacedContainer gap={SpacedUnits.Medium}>
        <RadioGroup value={type} disabled={working} onChange={setType}>
          {types.map(theType => (
            <Radio
              key={theType.type}
              value={theType.type}
              disabled={theType.type === computeType && isDynamicExperience === true}
              horizontal
            >
              {theType.label}
            </Radio>
          ))}
        </RadioGroup>
        <Form {...getFormProps()}>
          <div className="poster-form-body">{renderInnerForm()}</div>
        </Form>
      </SpacedContainer>
    </GuardedModal>
  );

  const renderCropModal = () => (
    <ImageCropperModal
      open={open}
      src={imageFieldProps.value.selectedFilePreview}
      ratio={getExperienceRatio(experience)}
      explanation={t("experiences.posterModal.cropperExplanation")}
      onClose={imageDataUrl => {
        if (imageDataUrl !== undefined) {
          setFileImage(imageDataUrl);
        }
        setImageCropperModal(false);
      }}
    />
  );

  return imageCropperModal === true ? renderCropModal() : renderMainModal();
}

ExperiencePosterModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onPosterSucceed: PropTypes.func,
  open: PropTypes.bool,
};
