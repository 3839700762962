import React from "react";
import * as PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./NavbarMenuItem.scss";

export default function NavbarMenuItem({ menuItem, isActive }) {
  const [t] = useTranslation();
  const history = useHistory();

  const handleKeyPress = (event, path) => {
    if (event.key === "Enter") {
      history.push(path);
    }
  };

  return (
    !menuItem.hidden && (
      <div role="menu" tabIndex={0} onKeyPress={event => handleKeyPress(event, menuItem.path)}>
        <Link className={`navbar-menu-item ${isActive ? "active" : ""}`} to={menuItem.path}>
          {t(menuItem.labelKey)}
        </Link>
      </div>
    )
  );
}

NavbarMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    path: PropTypes.string,
    hidden: PropTypes.bool,
    labelKey: PropTypes.string,
  }),
  isActive: PropTypes.bool,
};
