import { Button, Checkbox, Drawer, DrawerClosableFooter, DrawerHeader, Space, Typography } from "@ogury/design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { experienceActions } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { useUserSettings } from "Legacy/utils/hooks/useUserSettings";
import style from "./ExperienceTableSettingsDrawer.module.scss";

const MAXIMUM_QUICK_ACTIONS = 2;

export default function ExperienceTableSettingsDrawer({ onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const { experienceTableQuickAccessActions, setExperienceTableQuickAccessActions } = useUserSettings();
  const [selected, setSelected] = useState(experienceTableQuickAccessActions || []);

  async function toggleCheckbox(key) {
    setSelected(selected.includes(key) ? selected.filter(value => value !== key) : [...selected, key]);
  }

  async function onSave() {
    notificationService.notifySuccess(t("experiences.tableSettingsModal.success"));
    setExperienceTableQuickAccessActions(selected);
    return onClose();
  }

  function shouldBeDisabled(action) {
    return !selected.includes(action.key) && selected.length >= MAXIMUM_QUICK_ACTIONS;
  }

  return (
    <Drawer
      open
      onClose={onClose}
      header={<DrawerHeader title={t("experiences.tableSettingsModal.title")} />}
      footer={<DrawerClosableFooter actions={<Button onClick={onSave}>{t("actions.save")}</Button>} />}
    >
      <Space size="s" direction="vertical" style={{ width: "100%" }}>
        <Typography.P1Regular>
          {t("experiences.tableSettingsModal.description", { maximumActions: MAXIMUM_QUICK_ACTIONS })}
        </Typography.P1Regular>

        <Space size="s" direction="vertical" style={{ width: "100%" }}>
          {Object.keys(experienceActions).map(actionKey => {
            const action = experienceActions[actionKey];
            return (
              <Checkbox
                key={"checkbox-" + action.key}
                disabled={shouldBeDisabled(action)}
                checked={selected.includes(action.key)}
                onChange={() => toggleCheckbox(action.key)}
              >
                {/* TODO update the style by injecting the icon in the checkbox when design system is ready */}
                <div className={style.item}>
                  {action.icon}
                  {action.title}
                </div>
              </Checkbox>
            );
          })}
        </Space>
      </Space>
    </Drawer>
  );
}
