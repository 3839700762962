import * as PropTypes from "prop-types";
import React, { useCallback } from "react";

/**
 * Form container allowing native form behavior (eg. submit on "enter").
 * Disables autoComplete
 * @param children
 * @param className
 * @param onSubmit
 * @returns {*}
 */
export default function Form({ children, className, onSubmit }) {
  const submitForm = useCallback(
    event => {
      event.preventDefault(); // suppress forced page refresh on form submit
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <form className={className} onSubmit={submitForm} autoComplete="off">
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};
