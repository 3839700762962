import { UserRole } from "@ogury/motionly-ws-api/ws";

import { customerService, userService } from "./index";

const { RoleEnum } = UserRole;
const { Owner, Creator, Administrator, Ops } = RoleEnum;

const currentUserIsOwner = () =>
  userService.getRoleForCustomer(customerService.getCurrentCustomerSummaryId()) === Owner;

const currentUserIsOps = () => userService.getRoleForCustomer(customerService.getCurrentCustomerSummaryId()) === Ops;

const currentUserIsAdmin = () =>
  userService.getRoleForCustomer(customerService.getCurrentCustomerSummaryId()) === Administrator;

const currentUserIsOwnerOrAdmin = () => {
  const currentRole = userService.getRoleForCustomer(customerService.getCurrentCustomerSummaryId());
  return currentRole === Owner || currentRole === Administrator;
};

const currentUserIsOwnerOrAdminOrCreatorOrOps = () => {
  const currentRole = userService.getRoleForCustomer(customerService.getCurrentCustomerSummaryId());
  return currentRole === Owner || currentRole === Administrator || currentRole === Creator || currentRole === Ops;
};

export default {
  currentUserIsOwner,
  currentUserIsAdmin,
  currentUserIsOps,
  currentUserIsOwnerOrAdmin,
  currentUserIsOwnerOrAdminOrCreatorOrOps,
};
