import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { connectToChild } from "penpal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { experiencePermissions, experienceService, motionlyService } from "Legacy/services";
import { path, useAuth, useNotificationService } from "Legacy/utils";
import { isLocalEnvironment, localBuilderUrl } from "Legacy/utils/constants";
import { checkCredentials } from "Legacy/services/MotionlyApiService";
import StudioCompanion from "../../app/studio/StudioCompanion";

import "./Builder.scss";

const isLogDebug = true;
const isPenPalDebug = false;

const TEMPLATE_ROUTE = "template";
const SITE_ROUTE = "siteId";
const EXPERIENCE_ROUTE = "experience";
const FROM_EXPERIENCE_ROUTE = "fromExperience";

const EXPERIENCE_NAME_PARAM = "experienceName";
const RATIO_PARAM = "ratio";
const AD_UNIT_TECHNICAL_ID_PARAM = "adUnitTechnicalId";
const TEMPLATE_REVISION_PARAM = "templateRevision";

export default function Builder({
  templateId,
  templateRevision,
  experienceId,
  fromExperienceId,
  siteId,
  experienceName,
  ratio,
  adUnitTechnicalId,
  inputsUrl,
  onClose,
  onAfterBuild = () => {},
  shouldHideManagerNavbar = true,
}) {
  const notificationService = useNotificationService();
  const history = useHistory();
  const auth = useAuth();
  const iframeRef = useRef();
  const shouldRegeneratePoster = useRef(false);
  // const [child, setChild] = useState();
  const child = React.useRef();
  const [loading, setloading] = useState(true);
  const [t] = useTranslation();
  const builderUrl = useMemo(() => {
    try {
      const segments = [];
      if (experienceId) {
        segments.push(EXPERIENCE_ROUTE, experienceId);
      } else if (fromExperienceId) {
        segments.push(FROM_EXPERIENCE_ROUTE, fromExperienceId);
      } else if (templateId) {
        segments.push(TEMPLATE_ROUTE, templateId);
      }
      if (templateId && inputsUrl) {
        segments.push(encodeURIComponent(inputsUrl));
      }

      const serverBaseUrl = StudioCompanion.getServerBaseUrl();
      const isFormBaseUrlOverloaded = StudioCompanion.getFormBaseUrl() !== undefined;
      const formBaseUrl =
        isFormBaseUrlOverloaded === true ? StudioCompanion.getFormBaseUrl() : motionlyService.getFormBaseUrl();
      const { origin, pathname, search } = new URL(formBaseUrl);

      let url = isLocalEnvironment ? localBuilderUrl : origin + pathname;
      url += `${search === "" ? "?" : `${search}&`}`;

      const searchParams = new URLSearchParams();
      searchParams.append("flavor", "backoffice");

      const params = {
        serverBaseUrl,
        [RATIO_PARAM]: ratio,
        [AD_UNIT_TECHNICAL_ID_PARAM]: adUnitTechnicalId,
        [EXPERIENCE_NAME_PARAM]: experienceName && encodeURIComponent(experienceName),
        [TEMPLATE_REVISION_PARAM]: templateRevision,
        [SITE_ROUTE]: siteId,
      };

      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
          searchParams.append(key, value);
        }
      });

      url += searchParams.toString();
      url += "#/";
      url = `${url}${segments.join("/")}`;

      if (isLogDebug) {
        console.debug(`The Form web application URL is '${url}'`);
      }

      return url;
    } catch (error) {
      console.warn("An error occured while computing the Builder URL", error);
      notificationService.notifyError(error, t("messages.errorOpeningBuilder"));
      onClose();
    }
  }, [experienceId, templateId, inputsUrl]); // eslint-disable-line

  const onCloseWrapper = useCallback(() => {
    (async function () {
      if (!experienceId) {
        return;
      }

      const experience = await experienceService.get(experienceId);
      let event = new CustomEvent("experience", {
        detail: { experience },
      });
      document.dispatchEvent(event);

      if (shouldRegeneratePoster.current === true) {
        let posterComputation = experience?.data?.posterComputation;
        if (posterComputation === undefined) {
          const template = experienceService.getTemplate(experience);
          posterComputation = template.poster;
        }
        const newExperience = await experienceService.setPoster(experienceId, {
          trackId: posterComputation.trackId,
          percentage: posterComputation.percentage,
          quality: posterComputation.quality,
          backgroundColor: posterComputation.backgroundColor,
        });

        let event = new CustomEvent("thumbnail", {
          detail: {
            experienceId: newExperience.id,
            thumbnailUrl: newExperience.thumbnailUrl,
          },
        });
        document.dispatchEvent(event);
      }
    })();

    return onClose();
  }, [onClose, experienceId]);

  const onBeforeClose = useCallback(async () => {
    if (child.current) {
      try {
        // noinspection JSUnresolvedFunction
        const shouldClose = await child.current.shouldClose();
        if (shouldClose) {
          onCloseWrapper();
          return true;
        }
      } catch (error) {
        notificationService.notifyError(error, "Communication failure");
      }
    } else {
      onCloseWrapper();
      return true;
    }
    return false;
  }, [onCloseWrapper]);

  useEffect(() => {
    const className = shouldHideManagerNavbar ? "builder-opened-hide-navbar" : "builder-opened";
    document.getElementById("root").classList.add(className);
    return () => document.getElementById("root").classList.remove(className);
  }, []); // eslint-disable-line

  // noinspection JSUnusedGlobalSymbols
  const iframeMethods = useMemo(
    () => ({
      onCredentials: async () => {
        const credentials = await checkCredentials();
        delete credentials.refreshToken;
        return {
          ...credentials,
          customerId: auth.currentCustomerSummary.id,
          customerPublicId: auth.currentCustomerSummary.publicId,
        };
      },
      onExperienceUrls(experienceStoragePath) {
        return { assetsBaseUrl: `${motionlyService.getAssetsBaseUrl()}${experienceStoragePath}/` };
      },
      onBeforeBuild(payload) {
        return payload;
      },
      onClose() {
        return onBeforeClose();
      },
      onAfterBuild(buildResult) {
        if (experienceId) {
          shouldRegeneratePoster.current = true;
        }
        onAfterBuild(buildResult);
        return buildResult;
      },
      canSaveExperience: async () => {
        if (experienceId !== undefined) {
          const experience = await experienceService.get(experienceId);
          return experiencePermissions.canEdit(experience);
        }
        return undefined;
      },
      async onUpgradeTemplate() {
        if (await child.current.shouldClose()) {
          if (experienceId) {
            history.replace({ pathname: `${path.EXPERIENCES}/${experienceId}`, state: { showModalUpgrade: true } });
          }
        }
      },
      async onShowExperience(object) {
        if ((await onBeforeClose()) === true) {
          history.push(`${path.EXPERIENCES}/${object.experienceId}`);
        }
      },
    }),
    [auth, history, onBeforeClose] // eslint-disable-line
  );

  useEffect(() => {
    let connection;
    const run = async () => {
      if (iframeRef.current) {
        connection = connectToChild({
          iframe: iframeRef.current,
          methods: iframeMethods,
          debug: isPenPalDebug,
        });
        // noinspection JSValidateTypes
        child.current = await connection.promise;
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
    return () => {
      if (connection) {
        //TODO check if this is absolutely needed : connection.destroy();
      }
    };
  }, [iframeMethods]);

  return (
    <div className="builder-container">
      {loading && <div className="builder-loader">{t("app.openingBuilder")}</div>}
      {builderUrl && (
        <iframe ref={iframeRef} title="builder-iframe" src={builderUrl} onLoad={() => setloading(false)} />
      )}
    </div>
  );
}

Builder.propTypes = {
  templateId: PropTypes.string,
  templateRevision: PropTypes.string,
  experienceName: PropTypes.string,
  ratio: PropTypes.number,
  fromExperienceId: PropTypes.string,
  experienceId: PropTypes.string,
  inputsUrl: PropTypes.string,
  onAfterBuild: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  shouldHideManagerNavbar: PropTypes.bool,
};
