import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddLineIcon from "remixicon-react/AddLineIcon";
import LayoutGridLineIcon from "remixicon-react/LayoutGridLineIcon";
import ShapeLineIcon from "remixicon-react/ShapeLineIcon";
import ArrowUpLineIcon from "remixicon-react/ArrowUpLineIcon";

import "./NavbarCreateButton.scss";
import { Button, Dropdown } from "@ogury/design-system";
import { path, useNotificationService } from "Legacy/utils";
import { experiencePermissions, sitePermissions } from "Legacy/services";
import { Builder, CreateExperienceModal, CreateSiteModal, UploadExperienceBundleModal } from "Legacy/components";
import { disableSites } from "Legacy/utils/constants";

export default function NavbarCreateButton() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const [showCreateExperienceModal, setShowCreateExperienceModal] = useState(false);
  const [showUploadExperienceBundleModal, setShowUploadExperienceBundleModal] = useState(false);
  const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);
  const [builderTemplateId, setBuilderTemplateId] = useState();

  const clickCreateExperience = () => {
    if (experiencePermissions.canCreate()) {
      setShowCreateExperienceModal(true);
    }
  };

  const clickCreateSite = () => {
    if (sitePermissions.canCreate()) {
      setShowCreateSiteModal(true);
    }
  };

  const createFromUpload = () => {
    if (experiencePermissions.canCreate()) {
      setShowUploadExperienceBundleModal(true);
    }
  };

  const handleOnUploadBundle = experienceId => {
    setShowUploadExperienceBundleModal(false);
    notificationService.notifySuccess(t("navbar.create.uploadSuccess"));
    history.push(`${path.EXPERIENCES}/${experienceId}`);
  };

  const handleCreateFromTemplate = templateId => {
    setBuilderTemplateId(templateId);
    setShowCreateExperienceModal(false);
  };

  const handleCreateSite = site => {
    setShowCreateSiteModal(false);
    notificationService.notifySuccess(t("navbar.createSiteModal.success"));
    history.push(`${path.SITES}/${site.id}`);
  };

  const disabled = experiencePermissions.canCreate() === false;

  function renderButton() {
    if (!experiencePermissions.canCreateStandaloneExperience()) {
      return (
        <Button type="primary" icon={<AddLineIcon />} iconPosition="left" disabled={disabled} onClick={clickCreateSite}>
          {t("actions.add")}
        </Button>
      );
    }
    return (
      <>
        <Dropdown
          trigger="click"
          disabled={disabled}
          menu={{
            items: [
              (disableSites === false || sitePermissions.canAccess() === true) && {
                icon: <LayoutGridLineIcon size={16} />,
                onClick: clickCreateSite,
                label: t("navbar.create.site"),
              },
              {
                icon: <ShapeLineIcon size={16} />,
                onClick: clickCreateExperience,
                label: t("navbar.create.experience"),
              },
              {
                icon: <ArrowUpLineIcon size={16} />,
                onClick: createFromUpload,
                label: t("navbar.create.fromBundle"),
              },
            ],
          }}
        >
          <Button type="primary" icon={<AddLineIcon />} iconPosition="left" disabled={disabled}>
            {t("actions.add")}
          </Button>
        </Dropdown>

        <CreateExperienceModal
          open={showCreateExperienceModal}
          onClose={() => setShowCreateExperienceModal(false)}
          onSuccess={handleCreateFromTemplate}
        />
        <UploadExperienceBundleModal
          open={showUploadExperienceBundleModal}
          onClose={() => setShowUploadExperienceBundleModal(false)}
          onSuccess={handleOnUploadBundle}
        />
      </>
    );
  }
  return (
    <>
      {renderButton()}

      <CreateSiteModal
        open={showCreateSiteModal}
        onClose={() => setShowCreateSiteModal(false)}
        onSuccess={handleCreateSite}
      />

      {builderTemplateId && (
        <Builder
          templateId={builderTemplateId}
          onClose={() => setBuilderTemplateId()}
          shouldHideManagerNavbar={false}
        />
      )}
    </>
  );
}
