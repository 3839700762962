import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./DeleteViewModal.scss";
import { Button, Modal, ModalClosableFooter, Typography } from "@ogury/design-system";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { ViewType } from "Legacy/propTypes";

export default function DeleteViewModal({
  open,
  currentView,
  onCancel = () => {},
  callAction = () => {},
  onActionPerformed = () => {},
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const deleteView = async () => {
    setWorking(true);
    try {
      await callAction(currentView.id);
      setWorking(false);
      notificationService.notifySuccess(t("viewManagement.deleteSuccess"));
      onActionPerformed();
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  return (
    <Modal
      open={open}
      width={ModalWidths.Small}
      title={t("viewManagement.deleteModalTitle")}
      footer={
        <ModalClosableFooter
          actions={
            <Button type="danger" onClick={deleteView} disabled={working}>
              {t("actions.delete")}
            </Button>
          }
        />
      }
      onCancel={onCancel}
    >
      <Typography.P2Regular>{t("viewManagement.deleteModalQuestion", { name: currentView.name })}</Typography.P2Regular>
    </Modal>
  );
}

DeleteViewModal.propTypes = {
  currentView: ViewType,
  onCancel: PropTypes.func,
  callAction: PropTypes.func,
  onActionPerformed: PropTypes.func,
};
