import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "@ogury/design-system";
import { Form, FormControl } from "Legacy/components";
import { useFormValidation, validators } from "Legacy/utils";

const initialFormValue = {
  name: "",
  customerName: "",
  customerId: "",
};

export default function SignupForm({ working, onSignup }) {
  const [t] = useTranslation();

  const onSubmitForm = formValue => {
    onSignup({
      name: formValue.name,
      customer: {
        id: formValue.customerId,
        name: formValue.customerName,
      },
    });
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [{ name: validators.IS_REQUIRED }],
      customerName: [{ name: validators.IS_REQUIRED }],
      customerId: [{ name: validators.IS_REQUIRED }, { name: validators.IS_PUBLIC_ID }],
    },
    onSubmit: onSubmitForm,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  return (
    <Form {...getFormProps()}>
      <FormControl
        id="name"
        label={t("auth.signup.form.username")}
        placeholder={t("fields.namePlaceholder")}
        required
        {...getFieldProps("name")}
      />
      <div className="auth-form-separator" />
      <FormControl
        id="customerName"
        label={t("fields.customerName")}
        placeholder={t("fields.customerNamePlaceholder")}
        required
        {...getFieldProps("customerName")}
      />
      <FormControl
        id="customerId"
        label={t("fields.customerId")}
        placeholder={t("fields.customerIdPlaceholder")}
        required
        {...getFieldProps("customerId")}
      />
      <div className="auth-form-actions">
        <Button type="primary" block loading={working} submit>
          {t("auth.signup.action")}
        </Button>
      </div>
    </Form>
  );
}
SignupForm.propTypes = {
  working: PropTypes.bool,
  onSignup: PropTypes.func,
};
