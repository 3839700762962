import { path } from "Legacy/utils";
import Experiences from "./Experiences";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: path.EXPERIENCES,
    key: "experiences",
    component: Experiences,
  },
];
