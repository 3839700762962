import {
  authenticationService,
  customerService,
  motionlyService,
  tagService,
  templateService,
  unitService,
} from "./index";

class Cache {
  async reload(t, history, reloadPage, notificationService) {
    try {
      await authenticationService.login();
      await customerService.listCurrentCustomerUsers(true);
      await templateService.clearCache();
      await tagService.clearCache();
      await unitService.clearCache();
      await motionlyService.clearCache();
      notificationService.notifySuccess(t("app.notifications.applicationDataUpdatedSuccess"));
      if (reloadPage === true) {
        history.go(0);
      }
    } catch (error) {
      notificationService.notifyError(t("app.notifications.applicationDataUpdatedFailure", error));
    }
  }
}

// We define a singleton
const instance = new Cache();

export default instance;
