import React, { useMemo } from "react";
import * as PropTypes from "prop-types";

import "./ExperienceDetailHeader.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import { useHistory } from "react-router-dom";
import { experienceActions } from "Legacy/services";
import { DetailPageHeader, ExperienceBuilderButton } from "Legacy/components";
import { path } from "Legacy/utils";
import { ExperienceInlineActions } from "Legacy/app/experiences/components";
import { ExperienceDownloadButton, ExperienceEmbedButton, ExperienceThirdPartyTagButton } from "./components";

export default function ExperienceDetailHeader({ experience, experienceSite, loading, onReload, showName }) {
  const history = useHistory();
  const backPath = useMemo(() => {
    if (history.length > 2) {
      return "";
    }

    if (experienceSite) {
      return `${path.SITES}/${experienceSite.id}`;
    }
    return path.EXPERIENCES;
  }, [experienceSite, history]);

  // noinspection JSUnresolvedVariable
  return (
    <DetailPageHeader backPath={backPath} title={showName ? experience.name : undefined} loading={loading}>
      <Button
        onClick={() => {
          // We swallow the "event" argument on purpose
          onReload();
        }}
        type="secondary"
        iconPosition="iconOnly"
        icon={<RefreshLineIcon />}
      />
      <ExperienceBuilderButton experience={experience} site={experienceSite} type="text" />
      <ExperienceThirdPartyTagButton experience={experience} />
      <ExperienceDownloadButton experience={experience} />
      <ExperienceEmbedButton experience={experience} />
      <ExperienceInlineActions
        withQuickActions={false}
        experience={experience}
        onRefresh={onReload}
        actionsExcluded={[
          experienceActions.Design,
          experienceActions.Publish,
          experienceActions.PublishOver,
          experienceActions.Share,
          experienceActions.ThirdPartyTag,
          experienceActions.Labels,
          experienceActions.HTML5Bundle,
        ]}
      />
    </DetailPageHeader>
  );
}

ExperienceDetailHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  experienceSize: PropTypes.object,
  loading: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  showName: PropTypes.bool.isRequired,
};
