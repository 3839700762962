import { path } from "Legacy/utils";
import Users from "./Users";

export default [
  {
    path: path.USERS,
    key: "users",
    component: Users,
  },
];
