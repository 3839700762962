import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Tag as TagModel } from "@ogury/motionly-ws-api/ws";

import "./ExperienceDetailTags.scss";
import { Tag } from "@ogury/design-system";
import { Attribute } from "Legacy/components";
import { experiencePermissions } from "Legacy/services";
import { tagTypes } from "Legacy/utils";
import { ExperienceTagsModal } from "Legacy/app/experiences/components";

const { CategoryEnum } = TagModel;

export default function ExperienceDetailTags({ experience, tags, onTagsUpdated }) {
  const [t] = useTranslation();
  const [showTagsModal, setShowTagsModal] = useState(false);

  const clickManageTags = () => {
    setShowTagsModal(true);
  };
  const tagsUpdated = () => {
    setShowTagsModal(false);
    onTagsUpdated();
  };

  return (
    <>
      <Attribute name={t("fields.tags")}>
        <div className="tags-attr-container">
          {tags
            .filter(tag => tag.category !== CategoryEnum.Template)
            .map(tag => (
              <Tag key={tag.id} color={tag.category === CategoryEnum.Customer ? tagTypes.WARNING : tagTypes.GEEKBLUE}>
                {tag.name}
              </Tag>
            ))}
        </div>
        {experiencePermissions.canManageTags(experience) && (
          <div className="manage-tags-link">
            {/* eslint-disable-next-line */}
            <a onClick={clickManageTags}>{t("experiences.generalTab.manageTags")}</a>
          </div>
        )}
      </Attribute>

      <ExperienceTagsModal
        open={showTagsModal && experiencePermissions.canManageTags(experience)}
        experienceId={experience.id}
        tags={experience.tags.filter(tag => tag.category === CategoryEnum.Customer)}
        onClose={() => setShowTagsModal(null)}
        onSuccess={tagsUpdated}
      />
    </>
  );
}

ExperienceDetailTags.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onTagsUpdated: PropTypes.func.isRequired,
};
