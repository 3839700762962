import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Tooltip } from "@ogury/design-system";
import { useHistory, useLocation } from "react-router-dom";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import { experiencePermissions, experienceService } from "Legacy/services";
import StudioCompanion from "Legacy/app/studio/StudioCompanion";
import { Builder } from "Legacy/components/index";

import "./ExperienceBuilderButton.scss";

export const BuilderParams = {
  experienceId: "exId",
  builder: "bd",
};

export default function ExperienceBuilderButton({
  experience,
  site,
  reload = () => {},
  type = "icon",
  showBuilderDirectly = false,
}) {
  const [t] = useTranslation();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [showBuilder, setShowBuilder] = useState(false);
  const [adUnitTechnicalId, setAdUnitTechnicalId] = useState();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // opening builder is done by listening to the URL params
    const urlParams = new URLSearchParams(search.substring(1));
    if (
      urlParams.get(BuilderParams.experienceId) === experience.id?.toString() &&
      !showBuilder &&
      urlParams.get(BuilderParams.builder) === "true"
    ) {
      setShowBuilder(true);
    } else {
      setShowBuilder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleEditClick = () => {
    if (StudioCompanion.withStudio() === true) {
      experienceService.openExperienceViaStudio(experience);
    } else {
      const urlParams = new URLSearchParams(search.substring(1));
      urlParams.set(BuilderParams.builder, "true");
      urlParams.set(BuilderParams.experienceId, experience.id);
      history.replace(`${pathname}?${urlParams.toString()}`);
    }
  };

  const handleBuilderClose = () => {
    // closing the builder is done by event handling than changing the URL
    setShowBuilder(false);
    const urlParams = new URLSearchParams(search.substring(1));
    urlParams.delete(BuilderParams.builder);
    urlParams.delete(BuilderParams.experienceId);
    history.replace(`${pathname}?${urlParams.toString()}`);
    reload();
  };

  async function load() {
    const isInSite = experienceService.isInSite(experience);
    if (isInSite && experience.units) {
      const unitTechnicalId = experience.units[0].technicalId;
      setAdUnitTechnicalId(unitTechnicalId);
    }

    setInitialized(true);
  }
  useEffect(() => {
    if (showBuilderDirectly && initialized) {
      handleEditClick();
    }
  }, [showBuilderDirectly, initialized]);

  useEffect(() => {
    load();
  }, [experience]); // eslint-disable-line react-hooks/exhaustive-deps

  function renderButton() {
    if (type === "icon") {
      return (
        <Tooltip title={t("actions.design")}>
          <Button
            disabled={!experiencePermissions.canSeeEditButton(experience)}
            iconPosition="iconOnly"
            icon={<PencilLineIcon />}
            type="tertiary"
            onClick={handleEditClick}
          />
        </Tooltip>
      );
    }
    if (type === "text") {
      return (
        <Button
          type="secondary"
          iconPosition="left"
          icon={<PencilLineIcon />}
          onClick={handleEditClick}
          disabled={!experiencePermissions.canSeeEditButton(experience)}
        >
          {t("actions.design")}
        </Button>
      );
    }
    return <></>;
  }

  return (
    <>
      {renderButton()}
      {showBuilder && initialized && (
        <Builder
          adUnitTechnicalId={adUnitTechnicalId}
          siteId={site?.id}
          experienceId={experience.id}
          onClose={handleBuilderClose}
        />
      )}
    </>
  );
}

ExperienceBuilderButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  site: PropTypes.object,
  reload: PropTypes.func,
  showBuilderDirectly: PropTypes.bool,
  type: PropTypes.oneOf(["icon", "text", "hidden"]),
};
