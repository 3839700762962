import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";

import { Button } from "@ogury/design-system";
import { DetailPage, Form, FormControl, PageHeader, SpacedContainer } from "Legacy/components";
import { experienceService } from "Legacy/services";
import { useFormValidation, useNotificationService, validators } from "Legacy/utils";

const inputsWidth = "100%";

export default function ExperienceDetailSettingsPage({ experience, reloadExperience }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [localExperience, setLocalExperience] = useState();

  useEffect(() => {
    setLocalExperience(experience);
  }, [experience]);

  const initialFormValue = useMemo(
    () => ({
      inputsUrl: localExperience?.data?.inputsUrl ?? "",
    }),
    [localExperience]
  );

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      inputsUrl: [{ name: validators.IS_REQUIRED }, { name: validators.IS_URL, t }],
    },
    onSubmit: async formValue => {
      setWorking(true);
      try {
        const method =
          localExperience.origin === Experience.OriginEnum.FromTemplate
            ? experienceService.setFromTemplateSettings
            : experienceService.setDynamicSettings;
        const newExperience = await method(
          localExperience.id,
          localExperience.data.templateVersion,
          formValue.inputsUrl
        );
        reloadExperience(newExperience);
        notificationService.notifySuccess(undefined, t("experiences.settingsTab.submitSuccess"));
      } catch (error) {
        notificationService.notifyError(error, t("experiences.settingsTab.title"));
      } finally {
        setWorking(false);
      }
    },
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  return (
    <DetailPage className="page-settings-container">
      <PageHeader title={t("experiences.settingsTab.name")} />
      <Form {...getFormProps()}>
        <SpacedContainer withBottom>
          <FormControl
            id="inputsUrl"
            type="text"
            label={t("fields.inputsUrl")}
            placeholder={t("fields.urlPlaceholder")}
            tooltipText={t("experiences.settingsTab.inputsUrlTooltip")}
            disabled={working}
            width={inputsWidth}
            required
            {...getFieldProps("inputsUrl")}
          />
        </SpacedContainer>
        <Button submit type="primary" loading={working}>
          {t("actions.save")}
        </Button>
      </Form>
    </DetailPage>
  );
}

ExperienceDetailSettingsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  reloadExperience: PropTypes.func.isRequired,
};
