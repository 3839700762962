import React from "react";
import * as PropTypes from "prop-types";

import "./DetailPageHeader.scss";
import { LoadDots, SpacedContainer, SpacedUnits } from "Legacy/components";
import { isReactComponent } from "Legacy/utils";
import { BackLinkButton } from "Legacy/components/Button";

export default function DetailPageHeader({ children, backPath, title, loading }) {
  function renderTitle() {
    if (title) {
      if (isReactComponent(title)) {
        return title;
      }
      return (
        <div className="title">
          <h3>{title}</h3>
        </div>
      );
    }
    return <></>;
  }

  return (
    <div className="detail-page-header">
      <div className="header-block">
        <BackLinkButton path={backPath} />
      </div>
      {renderTitle()}
      {loading === true && <LoadDots />}
      <SpacedContainer horizontal gap={SpacedUnits.XSmall}>
        {children}
      </SpacedContainer>
    </div>
  );
}

DetailPageHeader.propTypes = {
  children: PropTypes.node,
  backPath: PropTypes.string.isRequired,
  // eslint-disable-next-line no-bitwise
  title: PropTypes.any,
  loading: PropTypes.bool.isRequired,
};
