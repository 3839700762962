import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@ogury/design-system";

import { EmptyState, LoadDots, Pager, TemplatesCard } from "Legacy/components";
import { templateService } from "Legacy/services";
import { path, usePageDataReducer } from "Legacy/utils";
import "./CreateExperienceModal.scss";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const loadEntities = async criteria => {
  const result = templateService.listLocal(criteria);
  return { list: result.templates, totalCount: result.totalTemplates };
};

export default function CreateExperienceModal({ open, onClose, onSuccess }) {
  const [t] = useTranslation();
  const history = useHistory();
  const [pageState, dispatch, loadPageState] = usePageDataReducer("list", {});
  const getTemplates = useCallback(
    async (criteria = false) => loadPageState(dispatch, {}, () => loadEntities(criteria)),
    [dispatch, loadPageState]
  );
  const [pagination, setPagination] = useState({ pageSize: 3, pageNumber: 1 });

  // refresh filtered list whenever parameters change
  const listQueryParamsPayload = useMemo(
    () => ({
      pagination: { offset: pagination.pageSize * (pagination.pageNumber - 1), limit: pagination.pageSize },
    }),
    [pagination]
  );

  const reloadTemplates = useCallback(
    forceFetch => {
      // noinspection JSIgnoredPromiseFromCall
      getTemplates(listQueryParamsPayload, forceFetch);
    },
    [getTemplates, listQueryParamsPayload]
  );

  useEffect(() => {
    if (!open) {
      return;
    }
    reloadTemplates();
  }, [open, reloadTemplates]);

  const handleGoToTemplates = () => {
    history.push(path.TEMPLATES);
    onClose();
  };

  const renderEmptyState = () => {
    if (pageState.loading) {
      return <LoadDots />;
    }
    if (pageState.loadingError) {
      return (
        <EmptyState
          title={t("templates.list.loadingError")}
          subtitle={pageState.loadingError}
          action={{ label: t("actions.reload"), disabled: pageState.loading, onClick: reloadTemplates }}
        />
      );
    }
    if (pageState.totalCount === 0) {
      return (
        <EmptyState
          title={t("templates.list.noFilteredDataTitle")}
          subtitle={t("templates.list.noFilteredDataSubtitle")}
        />
      );
    }
    return <></>;
  };

  const renderPageBody = () => (
    <>
      <div className="content-wrap">
        {pageState.list.map(template => (
          <TemplatesCard isCreate key={template.id} template={template} onClick={onSuccess} />
        ))}
      </div>
      {renderEmptyState()}
    </>
  );

  const handlePaginationChange = (pageSize, pageNumber) => {
    setPagination({ pageSize, pageNumber });
  };

  return (
    <Modal
      open={open}
      width="65%"
      style={{ minWidth: ModalWidths.Medium }}
      title={t("navbar.createExperienceModal.title")}
      onCancel={onClose}
    >
      <div className="create-experience-modal">
        <div className="create-experience-modal-header">
          <div className="medium-text">{t("navbar.createExperienceModal.subtitle")}</div>
          <Button type="secondary" onClick={handleGoToTemplates}>
            {t("navbar.createExperienceModal.allTemplates")}
          </Button>
        </div>
        {renderPageBody()}
        <hr />
        <Pager
          displaySizeSelector={false}
          total={pageState.totalCount}
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          onPagerChange={handlePaginationChange}
        />
      </div>
    </Modal>
  );
}
CreateExperienceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
