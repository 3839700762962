import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./ApplicationDetailPage.scss";
import { path, useNotificationService } from "Legacy/utils";
import { applicationService } from "Legacy/services";
import { DetailPageWithLeftNav } from "../../../../components/Layout";
import { ProtectedRoute } from "../../../../components";
import { ApplicationDetailCallbacksPage, ApplicationDetailGeneralPage, ApplicationDetailParametersPage } from "..";
import ApplicationDetailHeader from "./components";

export default function ApplicationDetailPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const { applicationId } = useParams();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState();

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await applicationService.get(applicationId);
      setApplication(result);
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
    } finally {
      setLoading(false);
    }
  }, [t, applicationId]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getData();
  }, [getData]);

  const nestedPaths = {
    GENERAL: `${url}/general`,
    PARAMETERS: `${url}/parameters`,
    CALLBACKS: `${url}/callbacks`,
  };

  const links = [
    { id: 0, path: nestedPaths.GENERAL, label: "applications.sidebar.general" },
    { id: 1, path: nestedPaths.PARAMETERS, label: "applications.sidebar.parameters" },
    { id: 2, path: nestedPaths.CALLBACKS, label: "applications.sidebar.callbacks" },
  ];

  const renderViewContent = () => (
    <Switch>
      <ProtectedRoute path={nestedPaths.GENERAL}>
        <ApplicationDetailGeneralPage application={application} onUpdatedApplication={setApplication} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.PARAMETERS}>
        <ApplicationDetailParametersPage application={application} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.CALLBACKS}>
        <ApplicationDetailCallbacksPage application={application} reloadApplication={getData} />
      </ProtectedRoute>
      <Redirect to={{ pathname: nestedPaths.GENERAL }} />
    </Switch>
  );

  return (
    <DetailPageWithLeftNav
      detailHeader={
        application !== undefined && (
          <ApplicationDetailHeader
            application={application}
            onReload={getData}
            onDeleted={() => {
              history.push(path.APPLICATIONS);
            }}
            loading={loading}
            showName={location.pathname !== nestedPaths.GENERAL}
          />
        )
      }
      links={links}
      isEmpty={application === undefined}
      withRightPadding={false}
    >
      {application !== undefined && renderViewContent()}
    </DetailPageWithLeftNav>
  );
}
