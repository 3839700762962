import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Tag } from "@ogury/design-system";
import AddLineIcon from "remixicon-react/AddLineIcon";

import { Attribute, FormControl, LoadDots, SpacedContainer, SpacedUnits } from "Legacy/components";
import { tagService } from "Legacy/services";
import { regexp, validators, useNotificationService } from "Legacy/utils";
import "./TagSearchPane.scss";

/**
 * @param excludeTags tags to filter from the search results (because thay are already used)
 * @param onTagSelected
 */
export default function TagSearchPane({ excludeTags = [], onTagSelected = () => {} }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [searching, setSearching] = useState(false);
  const [createConfirmMode, setCreateConfirmMode] = useState(false);
  const [creating, setCreating] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [formatError, setFormatError] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const tagNameValidator = validators.IS_REGEXP({ message: t("form.tagsWrongFormat"), regexp: regexp.TAGS });

  const createButtonDisabled = useMemo(() => creating || searchResult.some(item => item.name === searchValue), [
    searchValue,
    searchResult,
    creating,
  ]);

  const filteredSearchResults = useMemo(
    () => searchResult.filter(tag => !excludeTags.find(item => item.id === tag.id)),
    [searchResult, excludeTags]
  );

  const searchTags = async name => {
    setSearching(true);
    try {
      const result = await tagService.searchLocal(name);
      setSearchResult(result || []);
      setSearching(false);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.tagsModal.formValidationErrors.searchError"));
      setSearching(false);
    }
  };

  const createTag = async () => {
    setCreating(true);
    setCreateConfirmMode(false);
    try {
      const result = await tagService.create(searchValue.toLowerCase());
      // Relaunch the search, to have the created tag inside the results too
      await searchTags(searchValue);
      onTagSelected(result);
      setCreating(false);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.tagsModal.formValidationErrors.createError"));
      setCreating(false);
    }
  };

  const onSearchValueChanged = value => {
    setSearchValue(value);
    const message = tagNameValidator(value);
    setFormatError(message !== "" ? message : undefined);
    searchTags(value);
  };

  const renderSearchForm = () => (
    <div className="search-form">
      <FormControl
        required
        type="search"
        value={searchValue}
        placeholder={t("experiences.tagsModal.searchPlaceholder")}
        onChange={onSearchValueChanged}
        debounceDelay={300}
        error={formatError}
        disabled={searching || creating}
      />

      {searchValue && (
        <Button
          iconPosition="left"
          icon={<AddLineIcon />}
          onClick={() => setCreateConfirmMode(true)}
          disabled={createButtonDisabled || formatError}
          loading={creating}
        >
          {t("experiences.tagsModal.create")}
        </Button>
      )}
    </div>
  );

  const renderConfirmForm = () => (
    <SpacedContainer className="create-tag-confirm-pane" horizontal gap={SpacedUnits.Small}>
      <div className="create-confirm-question">{t("experiences.tagsModal.createConfirmQuestion")}</div>
      <Tag>{searchValue}</Tag>

      <Button type="secondary" size="small" onClick={() => setCreateConfirmMode(false)}>
        {t("actions.cancel")}
      </Button>
      <Button size="small" onClick={createTag}>
        {t("actions.confirm")}
      </Button>
    </SpacedContainer>
  );

  const renderSearchResults = () => {
    const resultsLabel = t(`experiences.tagsModal.result${filteredSearchResults.length > 1 ? "s" : ""}`, {
      number: filteredSearchResults.length,
    });

    return (
      <Attribute name={resultsLabel}>
        {searching ? (
          <div className="tags-list">
            <LoadDots />
          </div>
        ) : (
          <div className="tags-list">
            {filteredSearchResults.map(tag => (
              <Tag key={tag.id} label={tag.name} large onClick={() => onTagSelected(tag)} />
            ))}
          </div>
        )}
      </Attribute>
    );
  };

  return (
    <>
      <Attribute name={t("experiences.tagsModal.searchOrCreate")}>
        {createConfirmMode ? renderConfirmForm() : renderSearchForm()}
      </Attribute>

      {searchValue && filteredSearchResults.length > 0 && renderSearchResults()}

      {searchValue && filteredSearchResults.length === 0 && (
        <Attribute
          name={t(`experiences.tagsModal.result`, { number: 0 })}
          value={t("experiences.tagsModal.noResult")}
        />
      )}
    </>
  );
}

TagSearchPane.propTypes = {
  excludeTags: PropTypes.arrayOf(PropTypes.shape({})),
  onTagSelected: PropTypes.func,
};
