import { AuthenticateInputs } from "@ogury/motionly-ws-api/ws";
import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "@ogury/design-system";

import { DetailPage, Form, FormControl, PageHeader, SpacedContainer } from "Legacy/components";
import { userService } from "Legacy/services";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import "./UserProfilePasswordPage.scss";

export default function UserProfilePasswordPage({ user }) {
  const [working, setWorking] = useState(false);
  const changePasswordDisabled = useMemo(() => user.source === AuthenticateInputs.SourceEnum.Google, [user]);

  const [t] = useTranslation();
  const notificationService = useNotificationService();

  const initialFormValue = {
    currentPassword: "",
    newPassword: "",
  };

  const onChangePassword = async formValue => {
    try {
      await userService.changePassword(user.id, formValue.currentPassword, formValue.newPassword);
      notificationService.notifySuccess(t("messages.saveSuccess"));
      // eslint-disable-next-line no-use-before-define
      resetForm();
      setWorking(false);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      currentPassword: [{ name: validators.IS_REQUIRED }],
      newPassword: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onChangePassword,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);

  return (
    <>
      <div className="profile-page-header">
        <PageHeader title={t("users.password.title")} />
      </div>
      <DetailPage className="users-password-form">
        <SpacedContainer>
          {changePasswordDisabled && <Alert type="warning" description={t("users.password.changePasswordDisabled")} />}

          <Form {...getFormProps()}>
            <div>
              <FormControl
                disabled={changePasswordDisabled}
                type="password"
                placeholder={t("users.password.currentPasswordPlaceholder")}
                required
                id="currentPassword"
                label={t("users.password.currentPassword")}
                {...getFieldProps("currentPassword")}
                width="400px"
              />
            </div>
            <div>
              <FormControl
                disabled={changePasswordDisabled}
                type="password"
                placeholder={t("users.password.newPasswordPlaceholder")}
                required
                id="newPassword"
                label={t("users.password.newPassword")}
                {...getFieldProps("newPassword")}
                width="400px"
              />
            </div>
            <div className="small-text ">{t("app.passwordConstraints")}</div>

            <Button submit type="primary" loading={working} disabled={changePasswordDisabled}>
              {t("actions.saveChanges")}
            </Button>
          </Form>
        </SpacedContainer>
      </DetailPage>
    </>
  );
}

UserProfilePasswordPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};
