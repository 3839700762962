import MotionlyApiService from "./MotionlyApiService";
import { customerService, templateService, unitService } from "./index";

class MotionlyService {
  sdkVersion = undefined;

  async initialize(simulatedEnvironment, isLegacy) {
    this.backofficeData = await MotionlyApiService.backofficeApi().bootstrap({ simulatedEnvironment, isLegacy });
  }

  async initializeBusinessReferences() {
    await unitService.initialize();
    await templateService.initialize();
  }

  async clearCache() {
    this.sdkVersion = undefined;
    await this.initialize();
  }

  getGoogleClientId() {
    return this.backofficeData.googleClientId;
  }

  getWebServicesHostName() {
    return this.backofficeData.webServicesHostName;
  }

  getFormBaseUrl() {
    return this.backofficeData.formBaseUrl;
  }

  getShareBaseUrl() {
    return this.backofficeData.shareBaseUrl;
  }

  getFrontBaseUrl() {
    return this.backofficeData.frontBaseUrl;
  }

  getAssetsBaseUrl() {
    return this.backofficeData.assetsBaseUrl;
  }

  async getSdkVersion() {
    if (this.sdkVersion === undefined) {
      this.sdkVersion = await MotionlyApiService.motionlyApi().sdkVersion();
    }
    return this.sdkVersion;
  }

  getSdkUrl(applicationPublicId) {
    return `${
      this.backofficeData.frontBaseUrl
    }/javascript/1/sdk/?customerId=${customerService.getCurrentCustomerPublicId()}&applicationId=${applicationPublicId}`;
  }
}

export default new MotionlyService();
