import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ApplicationParameters, ApplicationUpdateInputs } from "@ogury/motionly-ws-api/ws";

import { Button } from "@ogury/design-system";
import { DetailPage, Form, FormControl, PageHeader, SpacedContainer } from "Legacy/components";
import { deepCopy, useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { applicationService, computeValue, computeValueLabels, customerPermissions } from "Legacy/services";
import MotionlyService from "Legacy/services/MotionlyService";

const inputsWidth = "100%";
const { LogLevelEnum } = ApplicationParameters;
const { MetricsLevelEnum, RepicturApplicationTypeEnum } = ApplicationUpdateInputs;

const metricsDelayInMillisecondsRange = { MIN: 0, MAX: 5000 };
const resizeDelayInMillisecondsRange = { MIN: -1, MAX: 2000 };

export default function ApplicationDetailParametersPage({ application }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [data, setData] = useState(undefined);
  const [sdkVersion, setSdkVersion] = useState(undefined);

  const logLevelOptions = useMemo(() => computeValueLabels(LogLevelEnum), []);
  const resizingSolutionVendorOptions = useMemo(() => computeValueLabels(RepicturApplicationTypeEnum, false), []);
  const metricsLevelOptions = useMemo(() => computeValueLabels(MetricsLevelEnum, true), []);
  const canEditApplicationAdvancedParameters = customerPermissions.canEditApplicationAdvancedParameters();
  const formConfig = useMemo(() => {
    // noinspection JSUnresolvedVariable
    const initialValue = {
      logLevel: data?.parameters?.logLevel ?? computeValue(LogLevelEnum.NONE),
      sdkVersion: data?.sdkVersion ?? "",
      resizingSolutionVendor: data?.repicturApplicationType ?? RepicturApplicationTypeEnum.Dynamic,
      disableAutoLoad: data?.disableAutoLoad ?? false,
      metricsLevel: data?.metricsLevel ?? computeValue(MetricsLevelEnum.All, true),
      metricsDelayInMilliseconds: data?.metricsDelayInMilliseconds ?? 0,
      resizeDelayInMilliseconds: data?.resizeDelayInMilliseconds ?? 1000,
      useAssetsUrl: data?.assetsUrl !== undefined,
    };
    const sdkVersionRange = { MIN: -9, MAX: sdkVersion };
    return {
      initialValue,
      fields: {
        logLevel: [{ name: validators.IS_REQUIRED }],
        sdkVersion: [
          {
            name: validators.IS_INT_IN_RANGE,
            message: t("form.invalidRange", sdkVersionRange),
            range: sdkVersionRange,
          },
        ],
        resizingSolutionVendor: [{ name: validators.IS_REQUIRED }],
        disableAutoLoad: [{ name: validators.IS_REQUIRED }],
        metricsLevel: [{ name: validators.IS_REQUIRED }],
        metricsDelayInMilliseconds: [
          { name: validators.IS_REQUIRED },
          {
            name: validators.IS_INT_IN_RANGE,
            message: t("form.invalidRange", metricsDelayInMillisecondsRange),
            range: metricsDelayInMillisecondsRange,
          },
        ],
        resizeDelayInMilliseconds: [
          { name: validators.IS_REQUIRED },
          {
            name: validators.IS_INT_IN_RANGE,
            message: t("form.invalidRange", resizeDelayInMillisecondsRange),
            range: resizeDelayInMillisecondsRange,
          },
        ],
        useAssetsUrl: [{ name: validators.IS_REQUIRED }],
      },
      onSubmit: async formValue => {
        setWorking(true);
        try {
          // We only override the editable properties
          const newApplicationUpdateInputs = deepCopy(data);
          delete newApplicationUpdateInputs.assetsUrl;
          newApplicationUpdateInputs.parameters = { logLevel: formValue.logLevel };
          if (canEditApplicationAdvancedParameters === true) {
            // noinspection JSUnresolvedVariable
            newApplicationUpdateInputs.repicturApplicationType = formValue.resizingSolutionVendor;
          }
          if (canEditApplicationAdvancedParameters === true) {
            newApplicationUpdateInputs.sdkVersion =
              formValue.sdkVersion === "" ? undefined : parseInt(formValue.sdkVersion, 10);
          }
          newApplicationUpdateInputs.disableAutoLoad = formValue.disableAutoLoad;
          const metricsDelayInMilliseconds = parseInt(formValue.metricsDelayInMilliseconds, 10);
          newApplicationUpdateInputs.metricsDelayInMilliseconds = metricsDelayInMilliseconds;
          if (newApplicationUpdateInputs.metricsReport !== undefined) {
            newApplicationUpdateInputs.metricsReport.batchDelayInMilliseconds = metricsDelayInMilliseconds;
          }
          newApplicationUpdateInputs.metricsLevel = formValue.metricsLevel;
          newApplicationUpdateInputs.resizeDelayInMilliseconds = parseInt(formValue.resizeDelayInMilliseconds, 10);
          newApplicationUpdateInputs.useAssetsUrl = formValue.useAssetsUrl;
          await applicationService.update(application.id, newApplicationUpdateInputs);
          notificationService.notifySuccess(undefined, t("applications.parametersTab.success"));
        } catch (error) {
          notificationService.notifyError(error, t("applications.parametersTab.title"));
        } finally {
          setWorking(false);
        }
      },
    };
  }, [t, application, data, sdkVersion, canEditApplicationAdvancedParameters]);
  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  useEffect(() => {
    const getData = async () => {
      setSdkVersion(await MotionlyService.getSdkVersion());
    };
    // noinspection JSIgnoredPromiseFromCall
    getData();
  }, []);

  useEffect(() => {
    setData(application.data);
  }, [application]);

  const canSave = customerPermissions.canEditApplicationParameters(application);
  return (
    <DetailPage>
      <PageHeader title={t("applications.parametersTab.title")} />
      <Form {...getFormProps()}>
        <fieldset disabled={working === true || canSave === false}>
          <SpacedContainer withBottom>
            <FormControl
              id="logLevel"
              type="select"
              label={t("applications.parametersTab.logLevel")}
              width={inputsWidth}
              options={logLevelOptions}
              required
              {...getFieldProps("logLevel")}
            />
            <FormControl
              id="sdkVersion"
              type="number"
              label={t("applications.parametersTab.sdkVersion")}
              width={inputsWidth}
              {...getFieldProps("sdkVersion")}
            />{" "}
            <FormControl
              id="resizingSolutionVendor"
              type="select"
              label={t("applications.parametersTab.resizingSolutionVendor")}
              width={inputsWidth}
              options={resizingSolutionVendorOptions}
              required
              {...getFieldProps("resizingSolutionVendor")}
            />
            <FormControl
              id="disableAutoLoad"
              type="checkbox"
              label={t("applications.parametersTab.disableAutoLoad")}
              width={inputsWidth}
              required
              {...getFieldProps("disableAutoLoad")}
            />
            <FormControl
              id="metricsLevel"
              type="select"
              label={t("applications.parametersTab.metricsLevel")}
              width={inputsWidth}
              options={metricsLevelOptions}
              required
              {...getFieldProps("metricsLevel")}
            />
            <FormControl
              id="metricsDelayInMilliseconds"
              type="number"
              label={t("applications.parametersTab.metricsDelayInMilliseconds")}
              width={inputsWidth}
              min={metricsDelayInMillisecondsRange.MIN}
              max={metricsDelayInMillisecondsRange.MAX}
              required
              {...getFieldProps("metricsDelayInMilliseconds")}
            />
            <FormControl
              id="resizeDelayInMilliseconds"
              type="number"
              label={t("applications.parametersTab.resizeDelayInMilliseconds")}
              width={inputsWidth}
              min={resizeDelayInMillisecondsRange.MIN}
              max={resizeDelayInMillisecondsRange.MAX}
              required
              {...getFieldProps("resizeDelayInMilliseconds")}
            />
            <FormControl
              id="useAssetsUrl"
              type="checkbox"
              label={t("applications.parametersTab.useAssetsUrl")}
              width={inputsWidth}
              required
              {...getFieldProps("useAssetsUrl")}
            />
          </SpacedContainer>
        </fieldset>
        <Button submit type="primary" loading={working} disabled={canSave === false}>
          {t("actions.save")}
        </Button>
      </Form>
    </DetailPage>
  );
}

ApplicationDetailParametersPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object.isRequired,
};
