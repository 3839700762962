import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";

import "./UserProfilePage.scss";
import { LayoutWithLeftNav, PageLoader, ProtectedRoute } from "Legacy/components";
import { useAuth } from "Legacy/utils";
import { UserProfileDetailPage, UserProfileEmailPage, UserProfilePasswordPage } from "..";

export default function UserProfilePage() {
  const auth = useAuth();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [user, setUser] = useState();

  const getData = useCallback(async () => {
    setUser(auth.currentUser);
  }, [auth]);

  useEffect(() => {
    getData();
  }, [getData]);

  const nestedPaths = {
    PROFILE: `${url}`,
    EMAIL: `${url}/email`,
    PASSWORD: `${url}/password`,
  };

  const links = [
    { id: 0, path: nestedPaths.PROFILE, label: "users.sidebar.profile" },
    { id: 1, path: nestedPaths.EMAIL, label: "fields.email" },
    { id: 2, path: nestedPaths.PASSWORD, label: "fields.password" },
  ];

  const renderViewContent = () => (
    <Switch>
      <ProtectedRoute exact path={nestedPaths.PROFILE}>
        <UserProfileDetailPage user={user} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.EMAIL}>
        <UserProfileEmailPage user={user} />
      </ProtectedRoute>
      <ProtectedRoute path={nestedPaths.PASSWORD}>
        <UserProfilePasswordPage user={user} />
      </ProtectedRoute>

      <Redirect to={{ pathname: nestedPaths.PROFILE }} />
    </Switch>
  );

  return (
    <LayoutWithLeftNav links={links} currentPath={location.pathname}>
      <div className="users-detail-page-container">{user ? renderViewContent() : <PageLoader />}</div>
    </LayoutWithLeftNav>
  );
}
