import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import "./TemplatesPagePreview.scss";
import { Button } from "@ogury/design-system";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import { PageLoader, Preview } from "Legacy/components";
import { TemplatePreviewPlaceholder } from "../../../../components";
export default function TemplatesPagePreview({ demos = [], onClose }) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [descriptionUrl, setDescriptionUrl] = useState();
  const [currentDemoIndex, setCurrentDemoIndex] = useState(0);

  const renderPreviewContainer = () => {
    if (loading) {
      return <PageLoader />;
    }
    return <TemplatePreviewPlaceholder title={t("templates.preview.template")} />;
  };

  const handleChangeDemoIndex = demoIndex => {
    if (demoIndex >= 0 && demoIndex < demos.length) {
      setCurrentDemoIndex(demoIndex);
      setDescriptionUrl(demos[demoIndex].descriptionUrl);
    }
  };

  useEffect(() => {
    setCurrentDemoIndex(0);
    setDescriptionUrl(undefined);
    if (demos.length > 0) {
      setDescriptionUrl(demos[0].descriptionUrl);
    }
  }, [demos]);

  // TODO Maybe show the carousel and let the user try to select and load the demos
  // return demos.length > 0 ? (
  return descriptionUrl !== undefined ? (
    <Preview descriptionUrl={descriptionUrl} onLoading={setLoading} onClose={onClose}>
      <div className="preview-pane-template-header">
        <Button
          icon={<ArrowLeftSLineIcon />}
          iconPosition="iconOnly"
          type="secondary"
          onClick={() => handleChangeDemoIndex(currentDemoIndex - 1)}
        />
        <div className="normal-text">
          {currentDemoIndex + 1} / {demos.length}
        </div>
        <Button
          icon={<ArrowRightSLineIcon />}
          iconPosition="iconOnly"
          type="secondary"
          onClick={() => handleChangeDemoIndex(currentDemoIndex + 1)}
        />
      </div>
    </Preview>
  ) : (
    renderPreviewContainer()
  );
}
TemplatesPagePreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  demos: PropTypes.array,
  onClose: PropTypes.func,
};
