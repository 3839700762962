import * as PropTypes from "prop-types";

export const PaginationValueType = PropTypes.shape({
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
});

export const PaginationConfigType = {
  total: PropTypes.number,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  onPagerChange: PropTypes.func,
};
