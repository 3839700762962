import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./ApplicationsPage.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import { path, usePageDataReducerWithFunction } from "Legacy/utils";
import { applicationService, customerPermissions } from "Legacy/services";
import { PageHeader, PageLoader } from "Legacy/components";
import { ApplicationsTable } from "../../components";
import { CreateApplicationModal } from "./components";

const loadEntities = async forceFetch => {
  const list = await applicationService.list(forceFetch);
  return { list, totalCount: list.length };
};

export default function ApplicationsPage() {
  const [t] = useTranslation();
  const history = useHistory();
  const [pageState, reloadApplications] = usePageDataReducerWithFunction("list", {}, loadEntities);
  const [showCreateApplicationModal, setShowCreateApplicationModal] = useState(false);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    reloadApplications(false);
  }, [reloadApplications]);

  const renderPageBody = () => (
    <ApplicationsTable
      applications={pageState.list}
      errorMessage={pageState.errorMessage}
      reload={async () => {
        await reloadApplications(true);
      }}
    />
  );

  return (
    <div className="applications-page">
      <PageHeader
        title={t("applications.list.title")}
        countText={pageState.count === -1 ? "" : t("applications.list.count", { count: pageState.count })}
      >
        <PageLoader inline active={pageState.loading} />
        <Button
          onClick={async () => {
            await reloadApplications(true);
          }}
          type="secondary"
          iconPosition="iconOnly"
          icon={<RefreshLineIcon />}
        />
        {customerPermissions.canCreateApplication() === true && (
          <Button
            onClick={() => setShowCreateApplicationModal(true)}
            iconPosition="left"
            icon={<AddLineIcon />}
            type="secondary"
          >
            {t("applications.list.create")}
          </Button>
        )}
        <CreateApplicationModal
          open={showCreateApplicationModal}
          onClose={() => {
            setShowCreateApplicationModal(false);
          }}
          onSuccess={newApplication => {
            setShowCreateApplicationModal(false);
            history.push(`${path.APPLICATIONS}/${newApplication.id}`);
          }}
        />
      </PageHeader>
      {renderPageBody()}
    </div>
  );
}

ApplicationsPage.propTypes = {};
