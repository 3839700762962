import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import { Button } from "@ogury/design-system";
import { Environment, experiencePermissions } from "Legacy/services";
import { ExperienceEmbedModal } from "../ExperienceEmbedButton/components";

export default function ExperienceThirdPartyTagButton({ experience }) {
  const [showThirdPartyTagDrawer, setShowThirdPartyTagDrawer] = useState(false);
  const [t] = useTranslation();
  const clickThirdPartyTag = async () => {
    setShowThirdPartyTagDrawer(true);
  };

  return (
    <>
      <Button type="secondary" onClick={clickThirdPartyTag}>
        {t("experiences.embed.thirdParty")}
      </Button>
      <ExperienceEmbedModal
        open={showThirdPartyTagDrawer && experiencePermissions.sharing.canExport(experience)}
        experience={experience}
        environment={Environment.ThirdParty}
        onCancel={() => setShowThirdPartyTagDrawer(null)}
      />
    </>
  );
}

ExperienceThirdPartyTagButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
};
