import { Experience } from "@ogury/motionly-ws-api/ws";
import { experienceFilters } from "Legacy/services/experienceFilterHelper";

// Process filters before sending them to API.
// There is no such thing as a custom template, but visually,
// we can allow user to filter custom template, by replacing custom filter template with "Synchronized" origin filter.
const processFiltersPayload = filters => {
  if (!filters || filters.length === 0) {
    return filters;
  }

  const processedFilters = [...filters];
  const templateFilterIndex = processedFilters.findIndex(item => item.filter === experienceFilters.TEMPLATE);
  const templateFilter = processedFilters[templateFilterIndex];

  const originFilterIndex = processedFilters.findIndex(item => item.filter === experienceFilters.ORIGIN);
  const originFilter = processedFilters[originFilterIndex];

  if (templateFilter && templateFilter.values.includes("custom")) {
    // remove custom value from payload since there is no filtering option for custom template, only for Synchronized origin
    processedFilters[templateFilterIndex] = {
      ...templateFilter,
      values: templateFilter.values.filter(value => value !== "custom"),
    };

    if (originFilter) {
      processedFilters[originFilterIndex] = { ...originFilter, values: [Experience.OriginEnum.Synchronized] };
    } else {
      processedFilters.push({
        filter: experienceFilters.ORIGIN,
        values: [Experience.OriginEnum.Synchronized],
      });
    }
  }

  return processedFilters;
};

export default processFiltersPayload;
