import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Customer, ExternalLink } from "@ogury/motionly-ws-api/ws";

import { Button, Dropdown, Space, Spin } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import {
  ExperienceBuilderButton,
  ExperienceDeleteModal,
  ExperienceMoveToModal,
  Preview,
  VPAIDModal,
} from "Legacy/components";
import { Environment, experienceActions, experienceService, siteService } from "Legacy/services";
import { path, useNotificationService } from "Legacy/utils";
import {
  ExperienceAssignAdUnitModal,
  ExperienceDuplicateModal,
  ExperienceExternalTrackingModal,
  ExperienceTagsModal,
  ExperienceTransferOwnershipModal,
} from "Legacy/app/experiences/components";
import { ExperiencePublishModal } from "Legacy/app/experiences/pages/ExperienceDetailGeneralPage/components/ExperiencePublishButton/components";
import { ExperiencePublishOverModal } from "Legacy/app/experiences/pages/ExperienceDetailGeneralPage/components/ExperiencePublishOverButton/components";
import { ExperienceEmbedModal } from "Legacy/app/experiences/pages/ExperienceDetailPage/components/ExperienceDetailHeader/components/ExperienceEmbedButton/components";
import { SiteExperienceDuplicateModal } from "Legacy/app/sites/components";
import { useUserSettings } from "Legacy/utils/hooks/useUserSettings";
import { ExternalSyncFailed, QuickActionsButton } from "./components";

export default function ExperienceInlineActions({
  experience,
  site,
  actionsAvailable = Object.values(experienceActions),
  actionsExcluded = [],
  onExperienceDuplicatedInSite = () => {},
  withQuickActions = true,
  onRefresh = () => {},
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const notificationService = useNotificationService();

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTransferOwnershipModal, setShowTransferOwnershipModal] = useState(false);
  const [experienceMoveToModal, setExperienceMoveToModal] = useState();
  const [experienceAssignAdUnitModal, setExperienceAssignAdUnitModal] = useState();
  const [experiencePublishModal, setExperiencePublishModal] = useState();
  const [experiencePublishOverModal, setExperiencePublishOverModal] = useState();
  const [experienceThirdPartyModal, setExperienceThirdPartyModal] = useState();
  const [experienceVpaidUrlModal, setExperienceVpaidUrlModal] = useState();
  const [openBuilder, setOpenBuilder] = useState(false);
  const [experienceTagsModal, setExperienceTagsModal] = useState();
  const [experienceExternalTrackingModal, setExperienceExternalTrackingModal] = useState();
  const [experienceSite, setExperienceSite] = useState(site);
  const [showPreview, setShowPreview] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { experienceTableQuickAccessActions } = useUserSettings();

  const shouldDisplayAction = (action, isQuickAction = false) => {
    if (!action.hasPermission(experience) || actionsExcluded.includes(action) || !actionsAvailable.includes(action)) {
      return false;
    }

    if (withQuickActions) {
      return isQuickAction === experienceTableQuickAccessActions.includes(action.key);
    }

    return true;
  };

  const onClickDuplicate = () => {
    setShowDuplicateModal(true);
  };

  const onExperienceDuplicated = newExperience => {
    setShowDuplicateModal(false);
    history.push(`${path.EXPERIENCES}/${newExperience.id}`);
  };

  const clickTransferOwnership = () => {
    setShowTransferOwnershipModal(true);
  };

  const onExperienceOwnershipTransferred = () => {
    setShowTransferOwnershipModal(false);
    onRefresh();
  };

  const clickDelete = () => {
    setShowDeleteModal(true);
  };
  const onExperienceDeleted = () => {
    onRefresh();
  };

  const onClickMoveTo = () => {
    setExperienceMoveToModal(experience);
  };
  const onMoveTo = () => {
    onRefresh();
  };

  const onClickPublish = () => {
    setExperiencePublishModal(experience);
  };
  const onExperiencePublished = () => {
    onRefresh();
  };

  const onClickPublishOver = () => {
    setExperiencePublishOverModal(experience);
  };
  const onExperiencePublishedOver = () => {
    onRefresh();
  };

  const onClickAssignAdUnit = () => {
    setExperienceAssignAdUnitModal(experience);
  };
  const onAdUnitAssigned = () => {
    setExperienceAssignAdUnitModal();
    onRefresh();
  };

  const onClickExternalTracking = () => {
    setExperienceExternalTrackingModal(experience);
  };
  const onClickThirdParty = () => {
    setExperienceThirdPartyModal(experience);
  };
  const onClickManageTags = () => {
    setExperienceTagsModal(experience);
  };

  function openExternalLink(externalLinkType) {
    setIsFetching(true);
    experienceService
      .getTracking(experience.id)
      .then(response => {
        let trackingUrl = undefined;
        if (response?.clicks && response.clicks.length > 0) {
          trackingUrl = response.clicks[0].url;
        }

        let externalLink = experienceService.getExternalLinkByType(experience, externalLinkType);
        if (externalLink) {
          if (externalLinkType === ExternalLink.TypeEnum.DEMO_SHOWCASE) {
            externalLink.url = `${externalLink.url}${trackingUrl ? `&url=${trackingUrl}` : ""}`;
          }
          window.open(externalLink.url, "_blank");
        }
      })
      .catch(error => notificationService.notifyError(error))
      .finally(() => setIsFetching(false));
  }

  const onClickGoToCreativeConsole = () => {
    return openExternalLink(ExternalLink.TypeEnum.CREATIVE_CONSOLE);
  };

  const onClickGoToDemoPage = () => {
    return openExternalLink(ExternalLink.TypeEnum.DEMO_SHOWCASE);
  };
  const onClickShowPreview = () => setShowPreview(true);

  const onTagsUpdated = () => {
    setExperienceTagsModal();
    onRefresh();
  };
  const onClickOpenBuilder = () => setOpenBuilder(true);

  const onClickVpaidUrl = () => setExperienceVpaidUrlModal(experience);

  const moreActionsIcon = <Button iconPosition="iconOnly" icon={<More2FillIcon />} type="tertiary" />;

  //TODO put a "siteId" in the experienceObject
  useEffect(() => {
    (async () => {
      if (!site) {
        const isInSite = experienceService.isInSite(experience);
        if (isInSite) {
          const _site = await siteService.getExperienceSite(experience.id);
          setExperienceSite(_site);
        }
      }
    })();
  }, [site]);

  const syncWithCreativeConsoleFailed = useMemo(() => {
    return experienceService.doesCreativeConsoleSyncFailed(experience);
  }, [experience]);

  function handleOnBuilderClose() {
    setOpenBuilder(false);
    onRefresh();
  }

  const menuItems = [
    shouldDisplayAction(experienceActions.Design) && {
      key: "design",
      icon: experienceActions.Design.icon,
      onClick: onClickOpenBuilder,
      label: t("actions.design"),
    },
    shouldDisplayAction(experienceActions.Preview) && {
      key: "preview",
      icon: experienceActions.Preview.icon,
      onClick: onClickShowPreview,
      label: t("actions.preview"),
    },
    shouldDisplayAction(experienceActions.Duplicate) && {
      key: "duplicate",
      icon: experienceActions.Duplicate.icon,
      onClick: onClickDuplicate,
      label: t("actions.duplicate"),
    },
    shouldDisplayAction(experienceActions.AssignAdUnit) && {
      key: "assignAdUnit",
      icon: experienceActions.AssignAdUnit.icon,
      onClick: onClickAssignAdUnit,
      label: t("actions.assignAdUnit"),
    },
    shouldDisplayAction(experienceActions.DemoPageLink) && {
      key: "demoPage",
      icon: experienceActions.DemoPageLink.icon,
      onClick: onClickGoToDemoPage,
      label: t("actions.demoPageLink"),
    },
    shouldDisplayAction(experienceActions.CreativeConsoleLink) && {
      key: "creativeConsole",
      icon: experienceActions.CreativeConsoleLink.icon,
      onClick: onClickGoToCreativeConsole,
      label: t("actions.creativeConsoleLink"),
    },
    shouldDisplayAction(experienceActions.MoveTo) && {
      key: "moveTo",
      icon: experienceActions.MoveTo.icon,
      onClick: onClickMoveTo,
      label: t("actions.moveTo"),
    },
    shouldDisplayAction(experienceActions.Publish) && {
      icon: experienceActions.Publish.icon,
      onClick: onClickPublish,
      label: t("actions.publish"),
    },
    shouldDisplayAction(experienceActions.PublishOver) && {
      icon: experienceActions.PublishOver.icon,
      onClick: onClickPublishOver,
      label: t("actions.publishOver"),
    },
    shouldDisplayAction(experienceActions.Transfer) && {
      key: "ownership",
      icon: experienceActions.Transfer.icon,
      onClick: clickTransferOwnership,
      label: t("actions.transferOwnership"),
    },
    shouldDisplayAction(experienceActions.Labels) && {
      key: "tags",
      icon: experienceActions.Labels.icon,
      onClick: onClickManageTags,
      label: t("actions.manageTags"),
    },
    {
      type: "divider",
    },
    shouldDisplayAction(experienceActions.TrackingAndRedirection) && {
      key: "tracking",
      icon: experienceActions.TrackingAndRedirection.icon,
      onClick: onClickExternalTracking,
      label: t("actions.externalTracking"),
    },
    shouldDisplayAction(experienceActions.ThirdPartyTag) && {
      key: "thirdParty",
      icon: experienceActions.ThirdPartyTag.icon,
      onClick: onClickThirdParty,
      label: t("experiences.embed.thirdParty"),
    },
    shouldDisplayAction(experienceActions.VpaidURL) && {
      key: "vpaidUrl",
      icon: experienceActions.VpaidURL.icon,
      onClick: onClickVpaidUrl,
      label: t("experiences.embed.vpaidUrl"),
    },
    shouldDisplayAction(experienceActions.Delete) && {
      type: "divider",
    },
    shouldDisplayAction(experienceActions.Delete) && {
      key: "delete",
      icon: experienceActions.Delete.icon,
      onClick: clickDelete,
      label: t("actions.delete"),
    },
  ];

  return (
    <div key={"menu-dropdown-" + experience.id}>
      <Spin spinning={isFetching} size="small">
        <ExperienceBuilderButton
          experience={experience}
          site={experienceSite}
          reload={handleOnBuilderClose}
          showBuilderDirectly={openBuilder}
          type="hidden"
        />

        <Space>
          {syncWithCreativeConsoleFailed && <ExternalSyncFailed experienceId={experience.id} onSuccess={onRefresh} />}
          {withQuickActions && (
            <React.Fragment>
              {/* Quick actions */}
              {shouldDisplayAction(experienceActions.Design, true) && (
                <QuickActionsButton action={experienceActions.Design} onClick={onClickOpenBuilder} />
              )}
              {shouldDisplayAction(experienceActions.DemoPageLink, true) && (
                <QuickActionsButton action={experienceActions.DemoPageLink} onClick={onClickGoToDemoPage} />
              )}
              {shouldDisplayAction(experienceActions.Preview, true) && (
                <QuickActionsButton action={experienceActions.Preview} onClick={onClickShowPreview} />
              )}
              {shouldDisplayAction(experienceActions.Duplicate, true) && (
                <QuickActionsButton action={experienceActions.Duplicate} onClick={onClickDuplicate} />
              )}
              {shouldDisplayAction(experienceActions.AssignAdUnit, true) && (
                <QuickActionsButton action={experienceActions.AssignAdUnit} onClick={onClickAssignAdUnit} />
              )}
              {shouldDisplayAction(experienceActions.MoveTo, true) && (
                <QuickActionsButton action={experienceActions.MoveTo} onClick={onClickMoveTo} />
              )}
              {shouldDisplayAction(experienceActions.CreativeConsoleLink, true) && (
                <QuickActionsButton
                  action={experienceActions.CreativeConsoleLink}
                  onClick={onClickGoToCreativeConsole}
                />
              )}
              {shouldDisplayAction(experienceActions.Publish, true) && (
                <QuickActionsButton action={experienceActions.Publish} onClick={onClickPublish} />
              )}
              {shouldDisplayAction(experienceActions.PublishOver, true) && (
                <QuickActionsButton action={experienceActions.PublishOver} onClick={onClickPublishOver} />
              )}
              {shouldDisplayAction(experienceActions.Transfer, true) && (
                <QuickActionsButton action={experienceActions.Transfer} onClick={clickTransferOwnership} />
              )}
              {shouldDisplayAction(experienceActions.Labels, true) && (
                <QuickActionsButton action={experienceActions.Labels} onClick={onClickManageTags} />
              )}
              {shouldDisplayAction(experienceActions.TrackingAndRedirection, true) && (
                <QuickActionsButton
                  action={experienceActions.TrackingAndRedirection}
                  onClick={onClickExternalTracking}
                />
              )}
              {shouldDisplayAction(experienceActions.ThirdPartyTag, true) && (
                <QuickActionsButton action={experienceActions.ThirdPartyTag} onClick={onClickThirdParty} />
              )}
              {shouldDisplayAction(experienceActions.VpaidURL, true) && (
                <QuickActionsButton action={experienceActions.VpaidURL} onClick={onClickVpaidUrl} />
              )}
              {shouldDisplayAction(experienceActions.Delete, true) && (
                <QuickActionsButton action={experienceActions.Delete} onClick={clickDelete} />
              )}
            </React.Fragment>
          )}
          {/* More actions */}
          <Dropdown trigger="click" menu={{ items: menuItems }}>
            {moreActionsIcon}
          </Dropdown>
          <ExperienceAssignAdUnitModal
            open={!!experienceAssignAdUnitModal}
            experience={experienceAssignAdUnitModal}
            onClose={() => setExperienceAssignAdUnitModal()}
            onSuccess={onAdUnitAssigned}
          />

          <ExperiencePublishModal
            open={!!experiencePublishModal}
            experience={experiencePublishModal}
            onClose={() => setExperiencePublishModal()}
            onSuccess={onExperiencePublished}
          />
          <ExperiencePublishOverModal
            open={!!experiencePublishOverModal}
            experience={experiencePublishOverModal}
            onClose={() => setExperiencePublishOverModal()}
            onSuccess={onExperiencePublishedOver}
          />
          <ExperienceDuplicateModal
            open={showDuplicateModal && !site}
            experience={experience}
            experienceName={experience.name}
            experienceComment={experience.comment}
            onClose={() => setShowDuplicateModal(false)}
            onSuccess={onExperienceDuplicated}
          />

          <SiteExperienceDuplicateModal
            open={showDuplicateModal && site}
            site={site}
            experience={experience}
            onClose={() => setShowDuplicateModal(null)}
            onSuccess={onExperienceDuplicatedInSite}
          />

          <ExperienceTransferOwnershipModal
            open={showTransferOwnershipModal}
            experience={experience}
            ownerId={experience?.ownerId?.toString()}
            onClose={() => setShowTransferOwnershipModal(false)}
            onSuccess={onExperienceOwnershipTransferred}
          />
          <ExperienceDeleteModal
            open={showDeleteModal}
            siteId={site?.id}
            experience={experience}
            onClose={() => setShowDeleteModal(false)}
            onSuccess={onExperienceDeleted}
          />
          <ExperienceMoveToModal
            open={!!experienceMoveToModal}
            fromSiteId={site?.id}
            experience={experienceMoveToModal}
            onClose={() => setExperienceMoveToModal()}
            onSuccess={onMoveTo}
          />
          <ExperienceExternalTrackingModal
            open={!!experienceExternalTrackingModal}
            experience={experienceExternalTrackingModal}
            onClose={() => setExperienceExternalTrackingModal()}
            onSuccess={onRefresh}
          />

          <ExperienceEmbedModal
            open={!!experienceThirdPartyModal}
            experience={experienceThirdPartyModal}
            environment={Environment.ThirdParty}
            onCancel={() => setExperienceThirdPartyModal()}
          />
          <VPAIDModal
            open={!!experienceVpaidUrlModal}
            experience={experienceVpaidUrlModal}
            onCancel={() => setExperienceVpaidUrlModal()}
          />
          <ExperienceTagsModal
            open={!!experienceTagsModal}
            experienceId={experienceTagsModal?.id}
            tags={experienceTagsModal?.tags?.filter(tag => tag.category === Customer)}
            onClose={() => setExperienceTagsModal()}
            onSuccess={onTagsUpdated}
          />
          {showPreview && (
            <Preview experienceId={experience.id} isDetached closable onClose={() => setShowPreview(false)} />
          )}
        </Space>
      </Spin>
    </div>
  );
}

ExperienceInlineActions.propTypes = {
  experience: PropTypes.object,
  site: PropTypes.object,
  actionsAvailable: PropTypes.array,
  actionsExcluded: PropTypes.array,
  onExperienceDuplicatedInSite: PropTypes.func,
  onRefresh: PropTypes.func,
  withQuickActions: PropTypes.bool,
};
