import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "@ogury/design-system";

import { LoadDots } from "Legacy/components";

import "./EmptyState.scss";

/**
 * A simple contextualized button (internal)
 * @param label Content
 * @param disabled
 * @param onClick Handler
 * @param type Button type
 */
const ActionButton = ({ label, disabled = false, onClick = () => {}, type }) => (
  <Button type={type} disabled={disabled} onClick={onClick}>
    {label}
  </Button>
);
ActionButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

/**
 *
 * @param title
 * @param subtitle
 * @param action an object containing the props of an action button
 * @returns {JSX.Element}
 * @constructor
 */
export default function EmptyState({ title, subtitle, action, footer, loading = false }) {
  const [t] = useTranslation();
  return (
    <div className="empty-state-container">
      {loading ? (
        <LoadDots />
      ) : (
        <>
          <div className="empty-state-title">{title || t("app.oops")}</div>
          {subtitle && <div className="empty-state-subtitle">{subtitle}</div>}
          {action && (
            <div className="empty-state-action">
              <ActionButton {...action} type="secondary" />
            </div>
          )}
          <div className="empty-state-footer">{footer && footer}</div>
        </>
      )}
    </div>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  footer: PropTypes.element,
  action: PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};
