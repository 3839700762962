import MotionlyApiClient from "@ogury/motionly-ws-api/MotionlyApiClient";
import MotionlyApiCompanion from "@ogury/motionly-ws-api/MotionlyApiCompanion";
import { AccessTokenInputs } from "@ogury/motionly-ws-api/ws";
import { path } from "Legacy/utils";
import { getObject, LOCALSTORAGE_CREDENTIALS, persistObject } from "Legacy/utils/storage";
import { authenticationService, overridingManager } from "./index";

async function handleCredentialsError() {
  authenticationService.logout();
  path.set("/", false);
}

export async function renewCredentials() {
  try {
    const credentials = getObject(LOCALSTORAGE_CREDENTIALS);

    if (!credentials) {
      await handleCredentialsError();
      return;
    }
    const accessTokenInputs = new AccessTokenInputs(credentials.email, credentials.source, credentials.refreshToken);
    const accessTokenResult = await MotionlyApiClient.userApi().accessToken(accessTokenInputs);
    const now = Date.now();

    const newCredentials = {
      email: credentials.email,
      source: credentials.source,
      refreshToken: credentials.refreshToken,
      accessToken: accessTokenResult.accessToken,
      accessTokenCreationTimestampInMilliseconds: now,
      accessTokenExpirationTimestampInMilliseconds: now + MotionlyApiClient.accessTokenValidityTimespanInMilliseconds,
    };

    persistObject(LOCALSTORAGE_CREDENTIALS, newCredentials);
    return newCredentials;
  } catch (error) {
    await handleCredentialsError();
    throw error;
  }
}

export async function checkCredentials() {
  let credentials = getObject(LOCALSTORAGE_CREDENTIALS);

  if (credentials !== undefined) {
    const { accessTokenExpirationTimestampInMilliseconds } = credentials;
    if (
      accessTokenExpirationTimestampInMilliseconds === undefined ||
      Date.now() >= accessTokenExpirationTimestampInMilliseconds
    ) {
      credentials = await renewCredentials();
    }
    return credentials;
  }
  return undefined;
}

export const initializeApiClient = async () => {
  // The base URL of the web services
  let actualServerBaseUrl;
  await MotionlyApiClient.initialize({
    API_LAYOUT_HEADER_NAME: MotionlyApiCompanion.API_LAYOUT_HEADER_NAME,
    API_LAYOUT_HEADER_VALUE: MotionlyApiCompanion.API_LAYOUT_HEADER_VALUE,
    isDebug: () => false,
    getTimeoutInMilliseconds: () => 5 * 60000,
    useAccessTokenForAuthentication: () => true,
    initialize: async () => {
      // We search for the server base URL in the window location query string
      const serverBaseUrl = overridingManager.getServerBaseUrl();
      if (serverBaseUrl === undefined) {
        actualServerBaseUrl = process.env.REACT_APP_API_URL;
      } else {
        actualServerBaseUrl = serverBaseUrl;
      }
    },
    getServerBaseUrl: () => actualServerBaseUrl,
    getAccessToken: () => {
      const credentials = getObject(LOCALSTORAGE_CREDENTIALS);
      return credentials?.accessToken;
    },
    getApiKey: () => undefined,
    renewAccessToken: async () => {
      const credentials = await renewCredentials();
      return credentials?.accessToken;
    },
    ensureAccessToken: async () => {
      const credentials = await checkCredentials();
      return credentials?.accessToken;
    },
    onPermissionError: (statusCode, code, message) => {
      // eslint-disable-next-line no-console
      console.debug("Error", statusCode, code, message);
    },
  });
  const simulatedEnvironment = overridingManager.getSimulatedEnvironment();
  const isLegacy = overridingManager.isLegacy();
  return {
    simulatedEnvironment,
    isLegacy,
  };
};

export default MotionlyApiClient;
