import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { Button } from "@ogury/design-system";
import AddLineIcon from "remixicon-react/AddLineIcon";

import { Builder } from "Legacy/components";
import "./DemosCard.scss";
export default function DemosCard({ templateId, demo = [], loadPreview }) {
  const [t] = useTranslation();
  const [showDuplicateBuilder, setShowDuplicateBuilder] = useState(false);

  const handleOnClickDuplicate = event => {
    event.stopPropagation();
    setShowDuplicateBuilder(true);
  };

  return (
    <>
      <div role="presentation" className="template-demo-card" onClick={loadPreview}>
        <div className="template-demo-card-background-container">
          <div className="template-demo-card-background" style={{ backgroundImage: `url(${demo.illustrationUrl})` }} />
        </div>
        <div className="template-demo-card-actions">
          <Button type="secondary" icon={<AddLineIcon />} iconPosition="left" onClick={handleOnClickDuplicate}>
            {t("templates.demoCard.duplicate")}
          </Button>
        </div>
      </div>
      {showDuplicateBuilder && (
        <Builder templateId={templateId} inputsUrl={demo.inputsUrl} onClose={() => setShowDuplicateBuilder(false)} />
      )}
    </>
  );
}

DemosCard.propTypes = {
  templateId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  demo: PropTypes.object,
  loadPreview: PropTypes.func,
};
