import { CustomerUpdateInputs } from "@ogury/motionly-ws-api/ws";
import { path } from "Legacy/utils";
import {
  deleteObject,
  getObject,
  LOCALSTORAGE_CURRENT_CUSTOMER_DATA,
  LOCALSTORAGE_CURRENT_CUSTOMER_USERS,
  LOCALSTORAGE_CURRENT_USER,
  LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST,
  LOCALSTORAGE_SETTINGS,
  persistObject,
} from "Legacy/utils/storage";
import StudioCompanion from "../app/studio/StudioCompanion";
import MotionlyApiService from "./MotionlyApiService";
import { customerPermissions, userService } from "./index";

class CustomerService {
  currentCustomerId;

  // Cache of all customers summary list related to user
  customersSummaryList = [];

  // Cache of current customer summary
  currentCustomerSummary = {};

  // Cache of current customer complete data
  currentCustomerData = {};

  // Cache of current organization users
  currentCustomerUsers = [];

  // Cache the same users in a dict { id: user }
  currentCustomerUsersById = {};

  constructor() {
    this.ensureCurrentCustomer();
  }

  initialize(customers) {
    this.customersSummaryList = customers.sort((object1, object2) => object1.name.localeCompare(object2.name));
    const settings = getObject(LOCALSTORAGE_SETTINGS);
    const user = getObject(LOCALSTORAGE_CURRENT_USER);

    const currentCustomerData = getObject(LOCALSTORAGE_CURRENT_CUSTOMER_DATA) || this.currentCustomerData;
    const currentCustomerUsers = getObject(LOCALSTORAGE_CURRENT_CUSTOMER_USERS) || this.currentCustomerUsers;

    // Setting current customer to first customer.
    // eslint-disable-next-line prefer-destructuring
    this.currentCustomerSummary = customers[0];

    // Current customer id saved in localstorage
    if (settings?.lastCustomerId) {
      // Check if exists in user's customer list. If yes assign.
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < user.roles.list.length; i++) {
        if (user.roles.list[i].customerId?.toString() === settings.lastCustomerId?.toString()) {
          this.currentCustomerSummary = customers.find(
            customer => customer.id?.toString() === settings.lastCustomerId?.toString()
          );
          this.currentCustomerData = currentCustomerData;
          this.currentCustomerUsers = currentCustomerUsers;
        }
      }
    }
  }

  updateCachedCustomerData(freshCustomerData) {
    this.currentCustomerData = freshCustomerData;
    persistObject(LOCALSTORAGE_CURRENT_CUSTOMER_DATA, this.currentCustomerData);
    // Update the current customer summary
    this.currentCustomerSummary = {
      id: freshCustomerData.id,
      name: freshCustomerData.name,
      logoImageUrl: freshCustomerData.data?.logoImageUrl,
    };
    // Update the customers stored data
    // eslint-disable-next-line no-plusplus
    let exists = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.customersSummaryList.length; i++) {
      if (this.customersSummaryList[i].id === freshCustomerData.id) {
        this.customersSummaryList[i] = this.currentCustomerSummary;
        exists = true;
      }
    }
    if (!exists) {
      this.customersSummaryList.push(this.currentCustomerSummary);
    }
    persistObject(LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST, this.customersSummaryList);
  }

  ensureCurrentCustomer() {
    const customersSummaryList = getObject(LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST);
    if (customersSummaryList) {
      this.initialize(customersSummaryList);
    }
  }

  // This method is invoked every time the end-user changes her current customer: this is the ideal place for flushing the caches
  changeCustomer(customerId, urlPath) {
    if (customerId === null) {
      deleteObject(LOCALSTORAGE_SETTINGS);
    } else if (customerId !== undefined) {
      persistObject(LOCALSTORAGE_SETTINGS, { lastCustomerId: customerId.toString() });
    }
    deleteObject(LOCALSTORAGE_CURRENT_CUSTOMER_USERS);
    deleteObject(LOCALSTORAGE_CURRENT_CUSTOMER_DATA);
    this.currentCustomerData = {};
    this.currentCustomerUsers = [];
    // TODO: flush the other services caches

    path.set(urlPath, false);
  }

  updateLocalSettings(property, value) {
    const persistedSettings = getObject(LOCALSTORAGE_SETTINGS);
    persistObject(LOCALSTORAGE_SETTINGS, { ...persistedSettings, [property]: value });
  }

  filterPendingUsers(users) {
    return users
      .sort((user1, user2) => user1.name?.localeCompare(user2.name))
      .filter(user => user.roles.list.some(userRole => userRole.pending !== true));
  }

  sortAndFilterOwnerOrAdministratorUsers(users, customerId) {
    return users
      .sort((user1, user2) => user1.name?.localeCompare(user2.name))
      .filter(user =>
        user.roles.list.some(
          userRole =>
            userRole.customerId?.toString() === customerId?.toString() &&
            userRole.pending !== true &&
            customerPermissions.isOwnerOrAdminOrCreator(userRole.role) === true
        )
      );
  }

  getCustomersSummaryList() {
    return this.customersSummaryList;
  }

  getCurrentCustomerSummary() {
    return this.currentCustomerSummary;
  }

  setCurrentCustomerId(id) {
    this.currentCustomerId = id.toString();
    StudioCompanion.runCommand("authenticationSuccess", {
      customerPublicId: this.getCurrentCustomerPublicId(),
      customerPrivateId: this.getCurrentCustomerSummaryId(),
    });
  }

  getCurrentCustomerSummaryId() {
    return this.currentCustomerId?.toString();
  }

  getCurrentCustomerPublicId() {
    return this.getCurrentCustomerSummary().publicId;
  }

  async listCurrentCustomerUsers(forceFetch = false) {
    if (this.currentCustomerUsers.length === 0 || forceFetch) {
      this.currentCustomerUsers = await userService.getUsersFromCustomersId(this.getCurrentCustomerSummaryId());
      this.currentCustomerUsersById = {};
      persistObject(LOCALSTORAGE_CURRENT_CUSTOMER_USERS, this.currentCustomerUsers);
    }
    return this.currentCustomerUsers;
  }

  async listCurrentCustomerUsersById() {
    if (Object.keys(this.currentCustomerUsersById).length === 0) {
      const users = await this.listCurrentCustomerUsers();
      users.forEach(user => {
        this.currentCustomerUsersById[user.id.toString()] = user;
      });
    }
    return this.currentCustomerUsersById;
  }

  async getCurrentCustomerData(freshFetch = false) {
    if (Object.keys(this.currentCustomerData).length === 0 || freshFetch) {
      this.currentCustomerData = await MotionlyApiService.customerApi().get(this.getCurrentCustomerSummaryId());
      persistObject(LOCALSTORAGE_CURRENT_CUSTOMER_DATA, this.currentCustomerData);
    }
    return this.currentCustomerData;
  }

  async sendInvitation(customerId, email, role) {
    return MotionlyApiService.customerApi().sendInvitation(customerId, email, role);
  }

  async update(customerId, name, websiteUrl) {
    const customerUpdateInputs = new CustomerUpdateInputs(name, websiteUrl);
    return MotionlyApiService.customerApi().update(customerId, customerUpdateInputs);
  }

  async setLogo(customerId, requestBody) {
    return MotionlyApiService.customerApi().setLogo(customerId, { requestBody });
  }

  async setOwner(customerId, userId) {
    return MotionlyApiService.customerApi().setOwner(customerId, userId);
  }
}

export default new CustomerService();
