import { locale } from "Legacy/utils";

const utcTimeZone = "UTC";

export const dateFormatter = {
  toShortDate: dateString => {
    if (Number.isInteger(Date.parse(dateString))) {
      return new Date(dateString).toLocaleString(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: utcTimeZone,
      });
    }
    return dateString;
  },
  timestampToShortDate: timestamp => dateFormatter.toString(new Date(timestamp), true),
  toShortDateTime: timestamp =>
    new Date(timestamp).toLocaleString(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  /**
   * @param {Date} date
   * @param {boolean} hideYear
   */
  toString: (date, hideYear = false) =>
    date.toLocaleString(locale, {
      timeZone: utcTimeZone,
      year: hideYear === true ? undefined : "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
};

/**
 * @returns {Date} the past date X days from now, at midnight
 */
export const getDateDaysFromNow = days => {
  const now = new Date();
  const date = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
  date.setUTCDate(date.getUTCDate() - days);
  return date;
};

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export const toUTCDate = date => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
