import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./ExperiencesPage.scss";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import Settings2LineIcon from "remixicon-react/Settings2LineIcon";
import { Filters, path, useNotificationService } from "Legacy/utils";
import { CreativesTypeSwitch, PageHeader, PageLoader, ViewSwitcher } from "Legacy/components";
import {
  cache,
  experienceFilters,
  experiencePermissions,
  experienceService,
  experienceSortings,
  tagService,
} from "Legacy/services";
import { LOCALSTORAGE_CUSTOMER_VIEW, deleteObject } from "Legacy/utils/storage";
import { DEFAULT_PAGE_SIZE } from "Legacy/utils/pagination";
import { convertSearchDataApiToUi, convertSearchDataUiToApi } from "Legacy/services/ExperienceService";
import { sortOrder } from "Legacy/utils/sort";
import { Button, Empty, theme } from "@ogury/design-system";
import processFiltersPayload from "./utils/processFiltersPayload";
import { ExperiencesFilters, ExperiencesTable, ExperienceTableSettingsDrawer } from "./components";

const messages = {
  FETCH_DATA_REQUEST: Symbol("fetchDataRequest"),
  FETCH_DATA_SUCCESS: Symbol("fetchDataSuccess"),
  FETCH_DATA_FAILURE: Symbol("fetchDataFailure"),
  SET_VIEW: Symbol("setView"),
  SET_VIEWS_LIST: Symbol("setViewsList"),

  // We hide the search stuff at initial loading to avoid UI flickering during data computing
  FIRST_LOAD: Symbol("firstLoad"),
  FIRST_LOAD_END: Symbol("firstLoadEnd"),
};

const pageDataReducer = (state, action) => {
  switch (action.type) {
    case messages.FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case messages.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        experiences: action.payload.experiences,
        totalExperiences: action.payload.total,
        loadingError: "",
      };
    case messages.FETCH_DATA_FAILURE:
      return { ...state, loading: false, loadingError: action.payload, experiences: [], totalExperiences: 0 };
    case messages.SET_VIEW:
      return { ...state, view: action.view };
    case messages.SET_VIEWS_LIST:
      return { ...state, viewsList: action.viewsList };
    case messages.FIRST_LOAD:
      return { ...state, firstLoad: true };
    case messages.FIRST_LOAD_END:
      return { ...state, firstLoad: false };
    default:
      return state;
  }
};

const initialPageState = {
  loading: false,
  firstLoad: false,
  experiences: [],
  totalExperiences: 0,
  loadingError: "",
  view: {},
  viewsList: [],
};

const initialSearchCriteria = {
  sort: { sortBy: experienceSortings.UPDATE_DATE, sortOrder: sortOrder.DESC },
  pagination: { pageSize: DEFAULT_PAGE_SIZE, pageNumber: 1 },
  searchText: "",
  filters: [],
};

const computePagination = pagination => ({
  offset: pagination.pageSize * (pagination.pageNumber - 1),
  limit: pagination.pageSize,
});

const DEFAULT_VIEW_ID = "defaultId";

const computeDefaultView = t => ({
  name: t("viewManagement.defaultView"),
  data: { filters: initialSearchCriteria.filters, sorting: initialSearchCriteria.sort },
  default: true,
  id: DEFAULT_VIEW_ID,
});

const PARAMETER_SEARCH = "search";
const PARAMETER_VIEW_ID = "viewId";

export default function ExperiencesPage() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  // noinspection JSCheckFunctionSignatures
  const [pageState, dispatch] = useReducer(pageDataReducer, initialPageState);
  const firstLoadRef = useRef(false);
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
  const [tags, setTags] = useState();

  const [showExperienceTableSettingsDrawer, setShowExperienceTableSettingsDrawer] = useState(false);

  const updateUrl = useCallback(
    mergedCriteria => {
      const criteriaForUrl = convertSearchDataUiToApi(mergedCriteria);
      history.replace(`${path.EXPERIENCES}?${PARAMETER_SEARCH}=${encodeURI(JSON.stringify(criteriaForUrl))}`);
    },
    [history]
  );

  /**
   * Fetch experiences after the provided criteria are merged with current searchCriteria
   * @param criteria the criteria to override
   */
  const getExperiences = useCallback(
    async (criteria = {}) => {
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      const mergedCriteria = { ...searchCriteria, ...criteria };
      try {
        setSearchCriteria(mergedCriteria);
        updateUrl(mergedCriteria);

        dispatch({
          type: messages.FETCH_DATA_SUCCESS,
          payload: await experienceService.listByFilter({
            ...{ ...mergedCriteria, filters: processFiltersPayload(mergedCriteria.filters) },
            pagination: computePagination(mergedCriteria.pagination),
          }),
        });
      } catch (error) {
        dispatch({ type: messages.FETCH_DATA_FAILURE, payload: error?.message });
      }
    },
    [searchCriteria, updateUrl]
  );

  useEffect(() => {
    // Get criteria based on a view, from URL or from localStorage
    const getViewSearchParams = async (id = DEFAULT_VIEW_ID, viewsList) => {
      const viewToLoad = viewsList.find(v => v.id === id) ?? computeDefaultView(t);
      dispatch({ type: messages.SET_VIEW, view: viewToLoad });
      return {
        filters: viewToLoad.data.filters,
        sort: viewToLoad.data.sorting,
      };
    };

    // Get criteria to be displayed on screen. Data comes from URL or localStorage
    const getVisibleSearchParams = async viewsWithDefault => {
      let jsonSearch;

      const allTags = await tagService.listTags();
      setTags(allTags);

      // Get a parsed JSON of string criteria in the URL
      const searchParam = new URLSearchParams(location.search).get(PARAMETER_SEARCH);
      if (searchParam) {
        try {
          jsonSearch = JSON.parse(searchParam);

          const convertedCriteria = convertSearchDataApiToUi(jsonSearch, allTags);
          convertedCriteria.sort = convertedCriteria.sorting;
          delete convertedCriteria.sorting;
          const viewId = jsonSearch[PARAMETER_VIEW_ID];
          if (viewId !== undefined) {
            return getViewSearchParams(viewId, viewsWithDefault);
          }
          return {
            ...convertedCriteria,
            searchText: jsonSearch.searchText,
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn("Unable to parse the JSON parameters, ignoring them", error);
        }
      }

      // Load a view : viewIdParam in url OR storedView in localStorage OR default view
      const viewIdParam = new URLSearchParams(location.search).get(PARAMETER_VIEW_ID);
      const storedView = await experienceService.getLastClientView();
      return getViewSearchParams(viewIdParam || storedView?.id, viewsWithDefault);
    };

    const loadData = async () => {
      dispatch({ type: messages.FIRST_LOAD });
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      try {
        const views = await experienceService.listViews();
        const viewsWithDefault = [computeDefaultView(t), ...views];
        dispatch({ type: messages.SET_VIEWS_LIST, viewsList: viewsWithDefault });

        const criteria = await getVisibleSearchParams(viewsWithDefault);
        await getExperiences(criteria);
        dispatch({ type: messages.FIRST_LOAD_END });
      } catch (error) {
        dispatch({ type: messages.FETCH_DATA_FAILURE, payload: error?.message });
        dispatch({ type: messages.FIRST_LOAD_END });
      }
    };

    // We initialize criteria from the URL only once
    if (!firstLoadRef.current) {
      firstLoadRef.current = true;
      // noinspection JSIgnoredPromiseFromCall
      loadData();
    }
  }, [t, getExperiences, location.search]);
  const launchSearch = () => {
    // noinspection JSIgnoredPromiseFromCall
    getExperiences({ pagination: { ...searchCriteria.pagination, pageNumber: 1 } });
  };

  const onTableChange = value => {
    const { pagination, ...sort } = value;
    // noinspection JSIgnoredPromiseFromCall
    getExperiences({ sort, pagination: { pageSize: pagination.pageSize, pageNumber: pagination.current } });
  };

  const filtersSortChanged = ({ filters, sort, searchText }) => {
    // Prevent having "Custom" template filter and origin filter at the same time
    // since custom template uses "Synchronized" origin filter
    const existingTemplateFilter = searchCriteria.filters.find(item => item.filter === experienceFilters.TEMPLATE);
    const existingOriginFilter = searchCriteria.filters.find(item => item.filter === experienceFilters.ORIGIN);
    const newOriginFilter = filters.find(item => item.filter === experienceFilters.ORIGIN);
    const newTemplateFilter = filters.find(item => item.filter === experienceFilters.TEMPLATE);

    if (existingTemplateFilter && existingTemplateFilter.values.includes("custom") && newOriginFilter) {
      newTemplateFilter.values = newTemplateFilter.values.filter(value => value !== "custom");
    } else if (existingOriginFilter && newTemplateFilter && newTemplateFilter.values.includes("custom")) {
      filters.splice(filters.indexOf(newOriginFilter), 1);
    }
    // ----

    const newSearchCriteria = { ...searchCriteria, filters, searchText };
    if (sort !== undefined) {
      newSearchCriteria.sort = sort;
    }
    // Update filters here because they are not used now
    setSearchCriteria(newSearchCriteria);

    // Reset to 0 if there is no filters
    if (filters.length === 0) {
      // noinspection JSIgnoredPromiseFromCall
      getExperiences({
        filters: newSearchCriteria.filters,
        sort: newSearchCriteria.sort,
        pagination: { ...newSearchCriteria.pagination, pageNumber: 1 },
        searchText,
      });
    }
  };

  const changeView = value => {
    // Find view by Id
    const fullView = pageState.viewsList.find(view => view.id === value);
    if (fullView) {
      experienceService.setLastClientView(fullView);
      dispatch({ type: messages.SET_VIEW, view: fullView });
      // noinspection JSIgnoredPromiseFromCall
      getExperiences({
        viewId: fullView.id,
        sort: fullView.data.sorting,
        filters: fullView.data.filters,
        pagination: { ...searchCriteria.pagination, pageNumber: 1 },
      });
    }
  };

  const viewSwitcherComponent = pageState.firstLoad ? (
    <></>
  ) : (
    <ViewSwitcher
      value={pageState.view.id}
      onChange={changeView}
      options={pageState.viewsList.map(view => ({ value: view.id, label: view.name }))}
    />
  );

  // Change view, but with views list refresh. If no viewId is provided, set to Default View
  const resetView = async viewId => {
    try {
      const views = await experienceService.listViews();
      const viewsWithDefault = [computeDefaultView(t), ...views];
      dispatch({ type: messages.SET_VIEWS_LIST, viewsList: viewsWithDefault });
      const view = viewsWithDefault.find(v => v.id === viewId);

      experienceService.setLastClientView(view);
      dispatch({ type: messages.SET_VIEW, view: view || {} });
      // noinspection ES6MissingAwait
      getExperiences({
        sort: view?.data?.sorting || initialSearchCriteria.sort,
        filters: view?.data?.filters || [],
        pagination: { ...searchCriteria.pagination, pageNumber: 1 },
      });
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  // Check if filters or sort are different from the current view
  // Used to enable/disable save button in filterBar's ViewManager
  const criteriaChanged = () => {
    if (!pageState.view.id) {
      return true;
    }
    const sortingHaveChanged =
      pageState.view.data.sorting.sortBy !== searchCriteria.sort.sortBy ||
      pageState.view.data.sorting.sortOrder !== searchCriteria.sort.sortOrder;

    const filtersHaveChanged = !Filters.uiFiltersAreEqual(pageState.view.data.filters, searchCriteria.filters);

    return sortingHaveChanged || filtersHaveChanged;
  };

  const viewManagerConfig = experiencePermissions.canManageViews()
    ? {
        criteriaChanged: criteriaChanged(),
        currentView: pageState.view,
        callCreate: async name => experienceService.createView(name, searchCriteria.filters, searchCriteria.sort),
        callSave: async viewId =>
          experienceService.updateView(viewId, { filters: searchCriteria.filters, sorting: searchCriteria.sort }),
        callRename: async (viewId, name) => experienceService.renameView(viewId, name),
        callDelete: async viewId => experienceService.deleteView(viewId),
        onViewCreated: view => resetView(view.id),
        onViewSaved: view => resetView(view.id),
        onViewRenamed: view => resetView(view.id),
        onViewDeleted: () => resetView(DEFAULT_VIEW_ID),
      }
    : null;

  const renderEmptyState = () => {
    // First the errors, then the 0 results state
    if (pageState.loadingError) {
      return (
        <Empty
          title={t("experiences.list.loadingError")}
          description={pageState.loadingError}
          buttonText={t("actions.reload")}
          onClick={getExperiences}
        />
      );
    }
    if (pageState.totalExperiences === 0) {
      return (
        <Empty
          title={t("experiences.list.noFilteredDataTitle")}
          description={t("experiences.list.noFilteredDataSubtitle")}
        />
      );
    }
    if (pageState.experiences.length === 0 && searchCriteria.pagination.pageNumber > 1) {
      return (
        <Empty
          title={t("experiences.list.emptyPageTitle")}
          buttonText={t("experiences.list.goBack")}
          onClick={() =>
            getExperiences({
              pagination: {
                pageSize: searchCriteria.pagination.pageSize,
                pageNumber: searchCriteria.pagination.pageNumber - 1,
              },
            })
          }
        />
      );
    }
    return null;
  };

  const onClickRefresh = async ({ shiftKey }) => {
    if (shiftKey === true) {
      dispatch({ type: messages.FETCH_DATA_REQUEST });
      await cache.reload(t, history, false, notificationService);
    }
    await getExperiences();
    notificationService.notifySuccess(t("messages.refreshSuccess"));
  };

  const renderPageBody = () => (
    <ExperiencesTable
      loading={pageState.loading}
      experiences={pageState.experiences}
      onChange={onTableChange}
      onRefresh={launchSearch}
      pagination={{ ...searchCriteria.pagination, total: pageState.totalExperiences }}
      sort={searchCriteria.sort}
      emptyState={renderEmptyState()}
    />
  );

  return (
    <div className="experiences-page">
      {showExperienceTableSettingsDrawer && (
        <ExperienceTableSettingsDrawer onClose={() => setShowExperienceTableSettingsDrawer(false)} />
      )}
      <CreativesTypeSwitch />
      <PageHeader additionalComponent={viewSwitcherComponent}>
        <PageLoader inline active={pageState.loading} />
        <Button onClick={onClickRefresh} type="secondary" iconPosition="iconOnly" icon={<RefreshLineIcon />} />
        <Button
          onClick={() => setShowExperienceTableSettingsDrawer(true)}
          type="secondary"
          iconPosition="iconOnly"
          icon={<Settings2LineIcon />}
        />
      </PageHeader>
      {!pageState.firstLoad && (
        <div style={{ marginBottom: theme.spacing.space_m }}>
          <ExperiencesFilters
            filtersValue={searchCriteria.filters}
            searchText={searchCriteria.searchText}
            onSearchChange={value => setSearchCriteria({ ...searchCriteria, searchText: value })}
            onChange={filters => {
              filtersSortChanged({ filters, searchText: searchCriteria.searchText });
            }}
            onCleared={() => {
              const filters = [];
              const { sort } = initialSearchCriteria;
              filtersSortChanged({ filters, sort, searchText: "" });
              const view = computeDefaultView(t);
              view.data.filters = filters;
              view.data.sorting = sort;
              dispatch({ type: messages.SET_VIEW, view });
              deleteObject(LOCALSTORAGE_CUSTOMER_VIEW);
            }}
            onSearch={launchSearch}
            viewManagerConfig={viewManagerConfig}
            tags={tags}
          />
        </div>
      )}
      {renderPageBody()}
    </div>
  );
}
