import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon from "@mdi/react";
import { mdiGridLarge } from "@mdi/js";
import { Typography } from "@ogury/design-system";
import { ThumbnailContainer, WithCopyButton } from "Legacy/components";
import { path, randomString } from "Legacy/utils";
import { experienceService } from "Legacy/services";
import "./ExperienceThumbnail.scss";

export default function ExperienceThumbnail({
  experience = {},
  linkPathSuffix,
  isFull = true,
  withLink = true,
  editable = false,
}) {
  const [t] = useTranslation();
  const size = isFull === true ? "medium" : "small";
  const isInSite = experienceService.isInSite(experience);
  const [experienceName, setExperienceName] = useState(experience.name);

  useEffect(() => {
    setExperienceName(experience.name);
  }, [experience.name]);

  const DetailPageLink = ({ children }) => {
    return (
      <Link
        onClick={handleOnLinkClick}
        to={`${path.EXPERIENCES}/${experience.id}${linkPathSuffix === undefined ? "" : linkPathSuffix}`}
      >
        {children}
      </Link>
    );
  };

  function handleOnLinkClick(event) {
    if (event.target.tagName === "TEXTAREA") {
      event.preventDefault();
    }
  }

  async function handleOnEditName(value) {
    try {
      const { name } = await experienceService.updateName(experience.id, value);
      setExperienceName(name);
    } catch (error) {}
  }

  function renderName() {
    const content = (
      <Typography.P2Strong {...(editable && { editable: { onChange: handleOnEditName } })}>
        {experienceName}
      </Typography.P2Strong>
    );
    return withLink ? <DetailPageLink>{content}</DetailPageLink> : content;
  }

  const thumbnailContainer = (
    <ThumbnailContainer key={randomString(6)} experienceId={experience.id} url={experience.thumbnailUrl} size={size} />
  );

  return (
    <div className="experience-thumbnail">
      <div className={`poster-${size}`}>
        {withLink ? <DetailPageLink>{thumbnailContainer}</DetailPageLink> : thumbnailContainer}
      </div>
      <div>
        <div className="title-block">
          {isInSite && <Icon path={mdiGridLarge} size={0.7} />}
          {renderName()}
        </div>
        {isFull === true && <div className="comment-block">{experience.comment}</div>}

        <Typography.P3Regular className="id-block">
          <span className="normal-text">{t("fields.id")}&nbsp;&nbsp;&nbsp;</span>
          <WithCopyButton text={experience.id} />
        </Typography.P3Regular>
      </div>
    </div>
  );
}

ExperienceThumbnail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  linkPathSuffix: PropTypes.string,
  isFull: PropTypes.bool,
  withLink: PropTypes.bool,
};
