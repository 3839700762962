import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { ExperienceThumbnail, GuardedModal } from "Legacy/components";
import { experienceService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function ExperiencePublishModal({ open, experience, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const publish = async () => {
    setWorking(true);
    try {
      await experienceService.publish(experience.id);
      notificationService.notifySuccess(t("experiences.publishModal.publishSuccessMessage"));
      onSuccess();
      onClose();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  return (
    <GuardedModal
      open={open}
      title={t("experiences.publishModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={publish} type="primary" loading={working} disabled={working}>
              {t("actions.publish")}
            </Button>
          }
        />
      }
      canBeClosed={() => (working === true ? false : undefined)}
      onClose={onClose}
      width={ModalWidths.Medium}
    >
      <Typography.P2Regular as="p">{t("experiences.publishModal.warning")}</Typography.P2Regular>
      <Typography.P2Strong as="p">{t("experiences.publishModal.warningQuestion")}</Typography.P2Strong>
      <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
    </GuardedModal>
  );
}

ExperiencePublishModal.propTypes = {
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
