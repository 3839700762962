import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./ApplicationsTable.scss";

import { Button, Dropdown, Table, Empty } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import { Avatar } from "Legacy/components";
import { randomString } from "Legacy/utils";
import { customerPermissions } from "Legacy/services";
import { dateFormatter } from "Legacy/utils/date";
import DeleteApplicationModal from "../../pages/ApplicationsPage/components/DeleteApplicationModal/DeleteApplicationModal";

export default function ApplicationsTable({ errorMessage, applications, reload }) {
  const [t] = useTranslation();
  const { url } = useRouteMatch();
  const [deleteModal, setDeleteModal] = useState();

  const onClickDelete = (event, row) => {
    setDeleteModal(row);
  };

  const renderNameAndComment = application => (
    <>
      <div className="h3 name">{application.name}</div>
      <div className="comment-block">{application.comment}</div>
      <div className="normal-text typo-black-light">
        {t("fields.id")}&nbsp;&nbsp;&nbsp;<span className="normal-text-bold">{application.id}</span>
      </div>
    </>
  );

  const renderNameAndCommentCell = application => (
    <Link className="application-thumbnail" to={`${url}/${application.id}`}>
      {renderNameAndComment(application)}
    </Link>
  );

  const renderDateCell = date => dateFormatter.toShortDate(date);

  const renderOwnerCell = owner => (
    <Avatar
      key={randomString(8)}
      src={owner.avatarUrl}
      name={owner.name ?? t(owner.id === null ? "users.null" : "users.notPresentInCustomer")}
      compact
    />
  );

  const renderActionCell = row => {
    const canDelete = customerPermissions.canDeleteApplication(row);
    return (
      <div className="action-cell">
        <Dropdown
          trigger="click"
          menu={{
            items: [
              canDelete === true && {
                key: "delete",
                onClick: () => onClickDelete(row),
                label: t("actions.delete"),
              },
            ],
          }}
          disabled={canDelete === false}
        >
          <Button iconPosition="iconOnly" icon={<More2FillIcon />} type="secondary" />
        </Dropdown>
      </div>
    );
  };
  const columns = [
    {
      title: t("applications.properties.name"),
      key: "id",
      dataIndex: "id",
      render: (_, record) => renderNameAndCommentCell(record),
    },
    { title: t("applications.properties.kind"), key: "kind", dataIndex: "kind" },
    { title: t("applications.properties.publicId"), key: "publicId", dataIndex: "publicId" },
    {
      title: t("applications.properties.updateDate"),
      key: "updateDate",
      dataIndex: "updateDate",
      render: value => renderDateCell(value),
    },
    {
      title: t("applications.properties.creationDate"),
      key: "creationDate",
      dataIndex: "creationDate",
      render: value => renderDateCell(value),
    },
    { key: "owner", dataIndex: "owner", render: value => renderOwnerCell(value) },
    { key: "actions-col", dataIndex: "id", render: (_, record) => renderActionCell(record) },
  ];

  return (
    <div className="applications-table">
      <Table
        columns={columns}
        dataSource={applications}
        emptyState={
          errorMessage && (
            <Empty
              title={t("applications.list.loadingError")}
              description={errorMessage}
              onClick={reload}
              buttonText={t("actions.reload")}
            />
          )
        }
      />
      <DeleteApplicationModal
        open={deleteModal !== undefined}
        application={deleteModal}
        onClose={() => setDeleteModal(undefined)}
        onSuccess={() => {
          setDeleteModal(undefined);
          reload();
        }}
      />
    </div>
  );
}

ApplicationsTable.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({})),
  reload: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};
