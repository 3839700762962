import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AddLineIcon from "remixicon-react/AddLineIcon";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Dropdown } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import { Environment, sitePermissions } from "Legacy/services";
import { path } from "Legacy/utils";
import { DetailPageHeader, ThirdPartyTagDrawer } from "Legacy/components";
import Path from "Legacy/utils/path";
import { SiteDeleteModal, SiteExternalTrackingDrawer } from "../../../../components";
import { SiteDetailHeaderTitle } from "./components";

export default function SiteDetailHeader({ site, loading, onClickAddExperience, onClickEditSite, onClickPublishSite }) {
  const [t] = useTranslation();
  const [siteThirdPartyModal, setSiteThirdPartyModal] = useState(null);
  const [siteDeleteModal, setSiteDeleteModal] = useState(null);
  const history = useHistory();
  const [siteExternalTrackingDrawer, setSiteExternalTrackingDrawer] = useState(null);

  function onClickThirdParty() {
    setSiteThirdPartyModal(site);
  }
  function onClickDeleteSite() {
    setSiteDeleteModal(site);
  }
  function onDeleteSuccess() {
    history.push(Path.SITES);
  }
  function onClickExternalTracking() {
    setSiteExternalTrackingDrawer(site);
  }
  return (
    <DetailPageHeader backPath={path.SITES} title={<SiteDetailHeaderTitle site={site} />} loading={loading}>
      <SiteDeleteModal
        open={!!siteDeleteModal}
        onClose={() => setSiteDeleteModal(null)}
        site={siteDeleteModal}
        onSuccess={onDeleteSuccess}
      />
      <ThirdPartyTagDrawer
        open={!!siteThirdPartyModal}
        onClose={() => setSiteThirdPartyModal(null)}
        site={siteThirdPartyModal}
      />
      <SiteExternalTrackingDrawer
        open={!!siteExternalTrackingDrawer}
        onClose={() => setSiteExternalTrackingDrawer(null)}
        site={siteExternalTrackingDrawer}
      />

      <Dropdown
        trigger="click"
        menu={{
          items: [
            {
              key: "edit",
              disabled: !sitePermissions.canEdit(site),
              onClick: onClickEditSite,
              label: t("actions.edit"),
            },
            {
              key: "externalTracking",
              onClick: onClickExternalTracking,
              label: t("actions.externalTracking"),
            },
            {
              key: "thirdParty",
              disabled: !sitePermissions.canEmbed(site, Environment.ThirdParty) === true,
              onClick: onClickThirdParty,
              label: t("sites.actionsMenu.thirdParty"),
            },
            {
              key: "delete",
              disabled: !sitePermissions.canEdit(site),
              onClick: onClickDeleteSite,
              label: t("actions.delete"),
            },
          ],
        }}
      >
        <Button icon={<More2FillIcon />} iconPosition="iconOnly" type="tertiary" />
      </Dropdown>

      {sitePermissions.canPublish(site) && (
        <Button type="secondary" onClick={onClickPublishSite}>
          {t("actions.publishForReview")}
        </Button>
      )}
      <Button
        disabled={!sitePermissions.canAddExperience(site)}
        icon={<AddLineIcon />}
        iconPosition="left"
        type="primary"
        onClick={onClickAddExperience}
      >
        {t("actions.add")}
      </Button>
    </DetailPageHeader>
  );
}
SiteDetailHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onClickAddExperience: PropTypes.func,
  onClickPublishSite: PropTypes.func,
  onClickEditSite: PropTypes.func,
};
