import Motionly from "./Motionly";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: "/",
    key: "mtly",
    component: Motionly,
  },
];
