function isClassComponent(component) {
  return typeof component === "function" && !!component.prototype.isReactComponent;
}

function isFunctionComponent(component) {
  return typeof component === "function" && String(component).includes("return React.createElement");
}

// eslint-disable-next-line import/prefer-default-export
export function isReactComponent(component) {
  return isClassComponent(component) || isFunctionComponent(component);
}
