import React, { useCallback, useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import FullscreenLineIcon from "remixicon-react/FullscreenLineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import { useTranslation } from "react-i18next";

import "./Preview.scss";
import { Button, useAppNotification } from "@ogury/design-system";
import { FormControl } from "Legacy/components";
import { randomString, useNotificationService } from "Legacy/utils";
import { experienceBuiltInRatios, default as experienceService } from "Legacy/services/ExperienceService";
import { OPENED_BODY_CLASS_NAME } from "Legacy/utils/classes";

// We cannot use the "selectEntry()" function, because this generates a runtime error, probably due to cycle dependencies
const computeWidthSelect = width => ({ label: `${width} px`, value: `${width}` });
const builtInWidths = Object.values(experienceBuiltInRatios)
  .filter(item => item.dimensions !== undefined)
  .map(item => item.dimensions.width)
  .concat([200, 300, 400, 500, 600, 700, 800, 900, 1000, 1080, 1100, 1200])
  // We remove the duplicates, taken from https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
  .filter((value, index, self) => self.indexOf(value) === index)
  .sort((item1, item2) => {
    const value1 = parseInt(item1, 10);
    const value2 = parseInt(item2, 10);
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  });
const defaultSelectOptions = builtInWidths.map(width => computeWidthSelect(width));
const minimumWidth = parseInt(defaultSelectOptions[0].value, 10);
const maximumWidth = parseInt(defaultSelectOptions[defaultSelectOptions.length - 1].value, 10);

export default function Preview({
  experienceId,
  descriptionUrl,
  isDetached = false,
  closable = false,
  onLoading = () => {},
  onClose = () => {},
  children,
}) {
  const notificationService = useNotificationService();
  const notificationAPI = useAppNotification();
  const [t] = useTranslation();
  const iframeRef = useRef(null);
  const previewHandlerLeftRef = useRef(null);
  const previewHandlerRightRef = useRef(null);
  const newIframeKey = useCallback(() => randomString(8), []);
  const [isCurrentlyDetached, setCurrentlyDetached] = useState(isDetached);
  const [iframeKey, setIframeKey] = useState(newIframeKey());
  const [selectOptions, setSelectOptions] = useState(defaultSelectOptions);
  const [selectSize, setSelectSize] = useState(
    defaultSelectOptions.find(option => option.value === "300") || defaultSelectOptions[3]
  );
  const [isDragging, setDragging] = useState(false);
  const [latestWidth, setLatestWidth] = useState(parseInt(selectSize.value, 10));
  const [previewIframeData, setPreviewIframeData] = useState();
  const currentWidth = parseInt(selectSize.value, 10);

  // noinspection NestedConditionalExpressionJS
  const previousIframeStyleWidth =
    isCurrentlyDetached === true ? `${isDragging === true ? latestWidth : selectSize.value}px` : "100%";

  const handleChangeSize = value => {
    const newSize = defaultSelectOptions.find(element => element.value === value);
    if (newSize !== undefined) {
      // We reset the select options
      setSelectOptions(defaultSelectOptions);
      setSelectSize(newSize);
      setLatestWidth(parseInt(newSize.value, 10));
    }
  };

  useEffect(() => {
    const run = async () => {
      onLoading(true);
      try {
        setPreviewIframeData(await experienceService.getPreview({ experienceId, descriptionUrl }));
      } catch (error) {
        notificationService.notifyError(error, t("messages.errorTitle"));
      } finally {
        onLoading(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [experienceId, descriptionUrl, t]);

  useEffect(() => {
    if (isCurrentlyDetached === false) {
      return () => {};
    }
    const iframe = iframeRef.current;
    const handlers = [previewHandlerLeftRef.current, previewHandlerRightRef.current];

    let originalWidth;
    let originalX;
    let previousWidth;
    let isLeft;

    function onMouseMove(event) {
      if (isLeft === undefined) {
        return;
      }
      const mouseOffsetX = (event.pageX - originalX) * 2;
      const width = Math.max(
        minimumWidth,
        Math.min(maximumWidth, originalWidth + mouseOffsetX * (isLeft === true ? -1 : 1))
      );
      if (previousWidth !== width) {
        previousWidth = width;
        setLatestWidth(width);
      }
    }

    function onMouseUp() {
      iframe.style.pointerEvents = "all";
      isLeft = undefined;
      setDragging(false);
      if (previousWidth !== originalWidth) {
        const options = [...new Set([previousWidth].concat(builtInWidths))]
          .sort((object1, object2) => {
            if (object1 < object2) {
              return -1;
            }
            if (object1 > object2) {
              return 1;
            }
            return 0;
          })
          .map(aWidth => computeWidthSelect(aWidth));
        setSelectOptions(options);
        setSelectSize(computeWidthSelect(previousWidth));
      }
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    const listenerRemovers = [];

    function computeOnMouseDown(isLeftHandler) {
      return event => {
        event.preventDefault();
        setDragging(true);
        iframe.style.pointerEvents = "none";
        isLeft = isLeftHandler;
        originalWidth = previousWidth !== undefined ? previousWidth : parseInt(selectSize.value, 10);
        originalX = event.pageX;
        previousWidth = originalWidth;
      };
    }

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < handlers.length; index++) {
      const handler = handlers[index];
      const onMouseDown = computeOnMouseDown(handler === previewHandlerLeftRef.current);
      handler.addEventListener("mousedown", onMouseDown);
      listenerRemovers.push(() => {
        handler.removeEventListener("mousedown", onMouseDown);
      });
    }

    return () => {
      listenerRemovers.forEach(listenerRemover => {
        listenerRemover();
      });
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isCurrentlyDetached, selectSize]);

  useEffect(() => {
    if (isCurrentlyDetached) {
      document.body.classList.add(OPENED_BODY_CLASS_NAME);
    } else {
      document.body.classList.remove(OPENED_BODY_CLASS_NAME);
    }
  }, [isCurrentlyDetached]);

  useEffect(() => {
    return () => {
      window.removeEventListener("message", handleMessageEvent);
      document.body.classList.remove(OPENED_BODY_CLASS_NAME);
    };
  }, []);

  const onClickClosingItem = () => {
    if (closable === true && isDetached) {
      onClose();
    } else {
      setCurrentlyDetached(false);
    }
  };

  const renderSideHeader = () => (
    <div className="preview-header-default">
      <Button type="tertiary" icon={<RefreshLineIcon />} iconPosition="iconOnly" onClick={onRefresh} />
      <Button
        type="tertiary"
        icon={<FullscreenLineIcon />}
        iconPosition="iconOnly"
        onClick={() => setCurrentlyDetached(true)}
      />
      <Button type="tertiary" icon={<CloseLineIcon />} iconPosition="iconOnly" onClick={handleOnClose} />
    </div>
  );

  const renderHeaderWidth = () => {
    if (currentWidth < 200 || isDragging === true) {
      return <span className="width">{isDragging === true ? latestWidth : currentWidth} px</span>;
    }
    return (
      <FormControl
        required
        type="select"
        options={selectOptions}
        value={selectSize.value}
        onChange={handleChangeSize}
        width="8.5em"
      />
    );
  };

  const renderDetachedHeader = () => (
    <div className="preview-header-default">
      <Button type="tertiary" onClick={onRefresh} icon={<RefreshLineIcon />} iconPosition="iconOnly" />
      {renderHeaderWidth()}
      <Button type="tertiary" icon={<CloseLineIcon />} iconPosition="iconOnly" onClick={onClickClosingItem} />
    </div>
  );

  const handleMessageEvent = useCallback(event => {
    if (event?.data?.type === "click") {
      const url = event?.data?.url;

      if (document.querySelector('[data-testid="preview-notify"]')) {
        return;
      }

      if (!url) {
        notificationAPI.info({
          description: t("experiences.preview.clickActionTitleNoUrl"),
          props: { "data-testid": "preview-notify" },
        });
      } else {
        notificationAPI.info({
          description: (
            <>
              {t("experiences.preview.clickActionTitle")}{" "}
              <a className="clickthrough-link" target="_blank" rel="noreferrer" href={url}>
                {url}
              </a>
            </>
          ),
          props: { "data-testid": "preview-notify" },
        });
      }
    }
  }, []);

  function onRefresh() {
    window.removeEventListener("message", handleMessageEvent);
    setIframeKey(newIframeKey());
  }

  function handleOnClose() {
    window.removeEventListener("message", handleMessageEvent);
    onClose();
  }

  function addMessageEventListener() {
    window.addEventListener("message", handleMessageEvent);
  }

  return (
    <>
      {isCurrentlyDetached === true && (
        <div className="preview-wrapper">{closable === false && t("experiences.preview.detachedMention")}</div>
      )}
      <div className={`preview-wrapper${isCurrentlyDetached ? " detached modal-overlay " : ""}`}>
        <div className={`preview-container${isCurrentlyDetached ? " detached " : ""}`}>
          <div className="preview-header">
            <>
              {isCurrentlyDetached === true ? renderDetachedHeader() : renderSideHeader()}
              {children}
            </>
          </div>
          <div
            className="preview-iframe"
            style={{
              width: previousIframeStyleWidth,
              boxSizing: isCurrentlyDetached === true ? "content-box" : "border-box",
            }}
          >
            <iframe
              ref={iframeRef}
              key={iframeKey}
              title="preview-iframe"
              srcDoc={previewIframeData}
              onLoad={addMessageEventListener}
            />
            {isCurrentlyDetached === true && (
              <>
                <div ref={previewHandlerLeftRef} className="preview-handler left">
                  <Icon path={mdiChevronLeft} size={0.6} />
                </div>
                <div ref={previewHandlerRightRef} className="preview-handler right">
                  <Icon path={mdiChevronRight} size={0.6} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
Preview.propTypes = {
  experienceId: PropTypes.string,
  descriptionUrl: PropTypes.string,
  isDetached: PropTypes.bool,
  closable: PropTypes.bool,
  onLoading: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
};
