import * as PropTypes from "prop-types";
import React from "react";

import { Modal } from "@ogury/design-system";
import { ModalHeights } from "./Modal";

export default function GuardedModal({ title, height, footer, children, width, open, onClose, canBeClosed }) {
  const [modal, contextHolder] = Modal.useModal();

  return (
    <Modal
      width={width}
      open={open}
      title={title}
      footer={footer}
      height={height}
      onCancel={() => {
        const question = canBeClosed && canBeClosed();
        if (question !== undefined && typeof question === "string") {
          modal.confirm({
            content: question,
            onOk: onClose,
          });
        } else {
          onClose();
        }
      }}
    >
      {contextHolder}
      {children}
    </Modal>
  );
}

GuardedModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  height: PropTypes.oneOf([ModalHeights.Small, ModalHeights.Medium, ModalHeights.Large, ModalHeights.Unspecified]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  footer: PropTypes.node.isRequired,
  children: PropTypes.node,
  canBeClosed: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};
