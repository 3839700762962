export const AD_UNIT_PREFIXES = {
  interstitial: "FS",
  header_ad: "HD",
  footer_ad: "FT",
  overlay_thumbnail: "TN",
  "in_article-landscape": "INLd",
  "in_article-300x250": "INmpu",
  "in_article-reveal": "INRv",
  "banner-160x600": "BA-160x600",
  "banner-300x250": "BA-300x250",
  "banner-300x50": "BA-300x50",
  "banner-300x100": "BA-300x100",
  "banner-300x600": "BA-300x600",
  "banner-320x100": "BA-320x100",
  "banner-320x480": "BA-320x480",
  "banner-320x50": "BA-320x50",
  "banner-336x280": "BA-336x280",
  "banner-640x360": "BA-640x360",
  "banner-728x90": "BA-728x90",
  "banner-970x250": "BA-970x250",
  "banner-970x90": "BA-970x90",
};
