import { datadogRum } from "@datadog/browser-rum";
import { useAppNotification } from "@ogury/design-system";

const useNotificationService = () => {
  const notificationApi = useAppNotification();

  return {
    ...notificationApi,
    notifySuccess: (description, message = "") => {
      notificationApi.success({ description, message });
    },
    notifyError: (error, message = "") => {
      if (error) {
        datadogRum.addError(error);
      }

      notificationApi.error({ description: error.message, message });
    },
    notifyWarning: (description, message = "") => {
      notificationApi.warning({ description, message });
    },
    notifyInfo: (description, message = "") => {
      notificationApi.warning({ description, message });
    },
  };
};

export default useNotificationService;
