import React from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./NavigableSidebar.scss";

export default function NavigableSidebar({ links, currentPath }) {
  const [t] = useTranslation();

  return (
    <ul className="sidebar-link-container">
      {links.map(
        link =>
          !link.hidden && (
            <li key={link.id}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link className={`sidebar-link-item ${link.path === currentPath ? "active" : ""}`} to={link.path} replace>
                {t(link.label)}
              </Link>
            </li>
          )
      )}
    </ul>
  );
}

NavigableSidebar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  currentPath: PropTypes.string,
};
