import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperiencePosterButton.scss";
import { Button } from "@ogury/design-system";
import { experiencePermissions } from "Legacy/services";
import { ExperiencePosterModal } from "./components";

export default function ExperiencePosterButton({ experience, onPosterSucceed = () => {} }) {
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const clickPoster = () => {
    setShowModal(true);
  };
  const onPosterChanged = () => {
    setShowModal(false);
    onPosterSucceed();
  };

  return (
    <>
      <Button type="secondary" disabled={experiencePermissions.canPoster(experience) === false} onClick={clickPoster}>
        {t("actions.poster")}
      </Button>

      <ExperiencePosterModal
        open={showModal && experiencePermissions.canPoster(experience)}
        experience={experience}
        onCancel={() => setShowModal(false)}
        onPosterSucceed={onPosterChanged}
      />
    </>
  );
}

ExperiencePosterButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  onPosterSucceed: PropTypes.func,
};
