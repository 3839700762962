import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { AutoComplete, Empty } from "@ogury/design-system";
import { experienceService, siteService } from "Legacy/services";

import { useDebouncedCallback, useNotificationService } from "Legacy/utils";
import { DESTINATION_DETACH_FROM_SITES } from "Legacy/utils/constants";

const MAX_RESULTS_AUTOCOMPLETE = 10;

export default function DestinationSelector({ defaultDestination, sourceExperience, onChange }) {
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [siteId, setSiteId] = useState(defaultDestination?.id);
  const [sitesList, setSitesList] = useState();
  const isInSite = experienceService.isInSite(sourceExperience);

  useEffect(() => {
    if (siteId && sitesList?.length) {
      onChange(
        siteId === DESTINATION_DETACH_FROM_SITES
          ? siteId
          : sitesList?.find(site => site.id?.toString() === siteId?.toString())
      );
    }
  }, [siteId, sitesList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultDestination) {
      setSitesList([defaultDestination]);
    } else {
      load();
    }
  }, [defaultDestination]);

  async function load() {
    try {
      const { sites } = await siteService.list({
        searchText: "",
        pagination: { offset: 0, limit: MAX_RESULTS_AUTOCOMPLETE },
      });
      setSitesList(sites);
    } catch (_error) {
      notificationService.notifyError(t("experiences.moveToModal.destinationSelector.detachFromSite"));
    }
  }

  const options = useMemo(() => {
    const sites =
      sitesList
        ?.filter(site => !sourceExperience || !site.experienceIds?.includes(sourceExperience.id))
        .map(site => ({
          value: site.id,
          label: `${site.name} - ${site.id}`,
        })) || [];
    if (isInSite) {
      const detachFromSite = {
        value: DESTINATION_DETACH_FROM_SITES,
        label: t("experiences.moveToModal.destinationSelector.detachFromSite"),
      };

      sites.unshift(detachFromSite);
    }
    return sites;
  }, [sitesList]);

  function handleOnChange(value) {
    setSiteId(value);
  }

  const debouncedCallback = useDebouncedCallback(async searchText => {
    const sitesEntities = await siteService.list({
      searchText,
      pagination: { offset: 0, limit: MAX_RESULTS_AUTOCOMPLETE },
    });
    setSitesList(sitesEntities?.sites);
  }, 300);

  async function handleOnSearch(searchText) {
    if (!searchText || searchText.length === 0) {
      load();
      return;
    }
    if (searchText?.length >= 3) {
      debouncedCallback(searchText);
    }
  }

  function renderAutocomplete() {
    return (
      <AutoComplete
        placeholder={t("experiences.moveToModal.destinationSelector.placeholder")}
        onSearch={handleOnSearch}
        onSelect={handleOnChange}
        onClear={() => {
          setSiteId(undefined);
          load();
        }}
        options={options}
        notFoundContent={<Empty />}
        value={options?.find(option => option.value === siteId)?.label}
        allowClear
      />
    );
  }

  return <>{renderAutocomplete()}</>;
}

DestinationSelector.propTypes = {
  defaultDestination: PropTypes.number,
  sourceExperience: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
