import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import i18n from "i18next";

// noinspection JSUnresolvedFunction
export const encryptPassword = password => Base64.stringify(sha256(password));

export const randomString = (length = 12) =>
  Math.random()
    .toString(36)
    .substring(2, length + 2);

export const bufferToString = buffer => new TextDecoder().decode(buffer);

export const dataUriToArrayBuffer = dataUrl => {
  const base64Data = dataUrl.split(";")[1].split(",")[1];
  // Taken from https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
  const byteCharacters = atob(base64Data);
  const len = byteCharacters.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < len; index++) {
    bytes[index] = byteCharacters.charCodeAt(index);
  }
  return bytes.buffer;
};

export const isValidHttpsUrl = url => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "https:";
  } catch {
    return false;
  }
};

export const customAntURLValidator = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }
  if (isValidHttpsUrl(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error(i18n.t("form.errorURL")));
  }
};
