import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperienceTransferOwnershipModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { ExperienceThumbnail, Form, FormControl, GuardedModal } from "Legacy/components";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { customerService, experienceService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function ExperienceTransferOwnershipModal({ open, experience, ownerId, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!open) {
      return;
    }

    customerService
      .listCurrentCustomerUsers(false)
      .then(resultUsers =>
        setUsers(
          customerService.sortAndFilterOwnerOrAdministratorUsers(
            resultUsers,
            customerService.getCurrentCustomerSummaryId()
          )
        )
      );
  }, [open]);

  const onTransferExperience = async formData => {
    try {
      setWorking(true);
      const newExperience = await experienceService.setOwner(experience.id, formData.userId);
      notificationService.notifySuccess(t("experiences.transferOwnershipModal.submitSuccess"));
      onSuccess(newExperience);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.transferOwnershipModal.submitFailure"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => ({ userId: ownerId }), [ownerId]);

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      userId: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onTransferExperience,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const userIdFieldProps = getFieldProps("userId");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderForm = () => {
    const sameNames = {};
    users.forEach(user => {
      if (sameNames[user.name] === undefined) {
        sameNames[user.name] = 1;
      } else {
        sameNames[user.name] += 1;
      }
    });

    return (
      <fieldset disabled={working}>
        <FormControl
          id="userId"
          label={t("app.user")}
          type="select"
          options={users.map(user => ({
            label: user.name + (sameNames[user.name] > 1 ? ` (${user.email})` : ""),
            value: user.id,
            avatarUrl: user.avatarUrl,
          }))}
          formatOptionLabel={user => (
            <div className="user-select-option">
              <img alt="" src={user.avatarUrl} />
              <span>{user.label}</span>
            </div>
          )}
          width="400px"
          required
          {...userIdFieldProps}
        />
      </fieldset>
    );
  };

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Large}
      title={t("experiences.transferOwnershipModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.transferOwnership")}
            </Button>
          }
        />
      }
      canBeClosed={() => (userIdFieldProps.value !== ownerId ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("experiences.transferOwnershipModal.explanation")}</Typography.P2Regular>
      <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
      <Form className="experience-transfer-ownership-modal" {...getFormProps()}>
        {renderForm()}
      </Form>
    </GuardedModal>
  );
}

ExperienceTransferOwnershipModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  ownerId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
