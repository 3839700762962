import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./UserTransferOwnershipModal.scss";
import { Button, Modal, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { customerService, userService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import UserInfoCell from "../UserInfoCell/UserInfoCell";

export default function UserTransferOwnershipModal({ open, user, customerId, onClose, onSuccess }) {
  const [modal, contextHolder] = Modal.useModal();
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [working, setWorking] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!open) {
      return;
    }

    customerService
      .listCurrentCustomerUsers(false)
      .then(resultUsers =>
        setUsers(
          customerService
            .sortAndFilterOwnerOrAdministratorUsers(resultUsers, customerId)
            .filter(aUser => aUser.id.toString() !== user.id.toString())
        )
      );
  }, [open, user, customerId]);

  const onTransferExperience = async formData => {
    try {
      setWorking(true);
      await userService.transferOwnership(user.id, customerId, formData.userId);
      notificationService.notifySuccess(t("customers.members.userTransferOwnershipModal.submitSuccess"));
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("customers.members.userTransferOwnershipModal.submitFailure"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => ({ userId: "" }), []);

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      userId: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onTransferExperience,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const userIdFieldProps = getFieldProps("userId");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderForm = () => {
    const sameNames = {};
    users.forEach(aUser => {
      if (sameNames[aUser.name] === undefined) {
        sameNames[aUser.name] = 1;
      } else {
        sameNames[aUser.name] += 1;
      }
    });
    return (
      <fieldset disabled={working}>
        <FormControl
          id="userId"
          label={t("app.user")}
          type="select"
          placeholder={t("customers.members.userTransferOwnershipModal.userPlaceholder")}
          options={users.map(_user => ({
            label: _user.name + (sameNames[_user.name] > 1 ? ` (${_user.email})` : ""),
            value: _user.id,
            avatarUrl: _user.avatarUrl,
          }))}
          formatOptionLabel={_user => (
            <div className="user-select-option">
              <img alt="" src={_user.avatarUrl} />
              <span>{_user.label}</span>
            </div>
          )}
          width="400px"
          required
          {...userIdFieldProps}
        />
      </fieldset>
    );
  };

  const handleTransferClick = () => {
    modal.confirm({
      content: t("customers.members.userTransferOwnershipModal.confirmation"),
      okText: t("actions.confirm"),
      onOk: () => getFormProps().onSubmit(),
    });
  };

  return (
    <GuardedModal
      open={open}
      title={t("customers.members.userTransferOwnershipModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              type="primary"
              onClick={handleTransferClick}
              loading={working}
              disabled={userIdFieldProps.value === undefined}
            >
              {t("actions.transferOwnership")}
            </Button>
          }
        />
      }
      canBeClosed={() => (userIdFieldProps.value !== "" ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
      width={ModalWidths.Medium}
    >
      <Typography.P2Regular as="p">
        {t("customers.members.userTransferOwnershipModal.explanation", { user: user?.name })}
      </Typography.P2Regular>
      <UserInfoCell user={user} />
      <Form {...getFormProps()}>{renderForm()}</Form>
      {contextHolder}
    </GuardedModal>
  );
}

UserTransferOwnershipModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
