import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./WithCopyButton.scss";
import { Button } from "@ogury/design-system";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";
import { copyText, useNotificationService } from "Legacy/utils";

export default function WithCopyButton({ text }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();

  return (
    <div className="with-copy-button">
      {text}
      <Button
        icon={<FileCopyLineIcon />}
        iconPosition="iconOnly"
        type="secondary"
        size="small"
        className="button"
        onClick={async event => {
          event.preventDefault();
          await copyText(text);
          notificationService.notifyInfo(t("app.notifications.copiedToClipboard"));
        }}
      />
    </div>
  );
}

WithCopyButton.propTypes = {
  text: PropTypes.any.isRequired,
};
