import React, { useEffect, useMemo } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Form, ModalClosableFooter, Select, Typography } from "@ogury/design-system";
import { GuardedModal } from "Legacy/components";
import { formRules } from "Legacy/utils/formRules";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function TemplateCreateFromRevisionModal({ open, template, onCancel, onSuccess }) {
  const [t] = useTranslation();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initialFormValue = useMemo(
    () => ({
      templateRevision: template.version.toString(),
    }),
    [template?.version]
  );

  async function handleFormFinish(values) {
    onSuccess(values.templateRevision);
    onCancel();
  }

  const [form] = Form.useForm();
  const templateRevisionFormValue = form.getFieldValue("templateRevision");

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("templates.createFromRevisionModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" onClick={() => form.submit()}>
              {t("actions.create")}
            </Button>
          }
        />
      }
      onClose={onCancel}
      canBeClosed={() =>
        templateRevisionFormValue !== initialFormValue.templateRevision
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
    >
      <Typography.P2Regular as="p">
        {t("templates.createFromRevisionModal.explanation", { templateName: template.name })}
      </Typography.P2Regular>
      <Form form={form} initialValues={initialFormValue} onFinish={handleFormFinish}>
        <Form.Item
          name="templateRevision"
          label={t("templates.createFromRevisionModal.revision")}
          rules={[formRules.IS_REQUIRED()]}
        >
          <Select
            options={template.versions.map(version => ({
              label: version.toString(),
              value: version.toString(),
            }))}
          />
        </Form.Item>
      </Form>
    </GuardedModal>
  );
}

TemplateCreateFromRevisionModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
