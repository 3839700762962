import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { AutoComplete, Empty } from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { experienceService } from "Legacy/services";
import "./ExperienceSelector.scss";
import { useDebouncedCallback, useNotificationService } from "Legacy/utils";

const MAX_RESULTS_AUTOCOMPLETE = 10;

export default function ExperienceSelector({ onChange = () => {} }) {
  const [t] = useTranslation();
  const [experienceId, setExperienceId] = useState();
  const [experiencesList, setExperiencesList] = useState();
  const notificationService = useNotificationService();
  const filters = [];

  const debouncedCallback = useDebouncedCallback(async searchText => {
    const experiencesEntities = await experienceService.listByFilter({
      searchText,
      filters,
      pagination: { offset: 0, limit: MAX_RESULTS_AUTOCOMPLETE },
    });
    setExperiencesList(experiencesEntities?.experiences);
  }, 300);

  async function load() {
    try {
      const { experiences } = await experienceService.listByFilter({
        searchText: "",
        filters,
        pagination: { offset: 0, limit: MAX_RESULTS_AUTOCOMPLETE },
      });
      setExperiencesList(experiences);
    } catch (_error) {
      notificationService.notifyError(t("experiences.publishOverModal.loadingDestinationError"));
    }
  }

  async function handleOnSearch(searchText) {
    if (!searchText || searchText.length === 0) {
      load();
      return;
    }
    if (searchText?.length >= 3) {
      debouncedCallback(searchText);
    }
  }

  useEffect(() => {
    load();
  }, []);

  const options = useMemo(() => {
    return experiencesList?.map(experience => ({
      value: experience.id,
      label: experience.name,
    }));
  }, [experiencesList]);

  function handleOnChange(value) {
    setExperienceId(value);
    onChange(experiencesList.find(experience => experience.id === value));
  }

  return (
    <AutoComplete
      placeholder={t("experiences.publishOverModal.searchPlaceholder")}
      onSearch={handleOnSearch}
      onSelect={handleOnChange}
      onClear={() => {
        setExperienceId(undefined);
        onChange(undefined);
        load();
      }}
      options={options}
      notFoundContent={<Empty />}
      value={options?.find(option => option.value === experienceId)?.label}
      allowClear
    />
  );
}

ExperienceSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
