import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import "./ThumbnailContainer.scss";

import { ImageWithFallback } from "..";

export default function ThumbnailContainer({ experienceId, url, alt = "Thumbnail", size = "", fallbackSrc }) {
  const [stateUrl, setStateUrl] = useState(url);

  const onThumbnailChange = useCallback(
    event => {
      if (experienceId && experienceId?.toString() === event?.detail?.experienceId?.toString()) {
        setStateUrl(event?.detail.thumbnailUrl);
      }
    },
    [experienceId]
  );

  useEffect(() => {
    setStateUrl(url);
  }, [url]);

  useEffect(() => {
    document.addEventListener("thumbnail", onThumbnailChange);
    return () => document.removeEventListener("thumbnail", onThumbnailChange);
  }, [onThumbnailChange]);

  return (
    <div className={`thumbnail-container ${size}`}>
      <ImageWithFallback src={stateUrl} alt={alt} fallbackSrc={fallbackSrc} />
    </div>
  );
}

ThumbnailContainer.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fallbackSrc: PropTypes.any,
};
