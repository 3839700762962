import * as PropTypes from "prop-types";

// We admit only primitive types as values because they cannot be compared by reference
export const OptionValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.symbol,
]);

// This type is used to populate all dropdowns options
export const OptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: OptionValueType,
});
