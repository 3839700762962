import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";

import { Experience } from "@ogury/motionly-ws-api/ws";

import "./ExperienceDetailParametersPage.scss";
import { Button, InputNumber, Select, Switch } from "@ogury/design-system";
import { DetailPage, Form, FormGroup, PageHeader, selectEntry, SpacedContainer, Tooltip } from "Legacy/components";
import { experienceBucketDimensions, experienceService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";

function PropertyControl({ id, label, disabled, overrideFunctions, tooltip, children }) {
  const [override, setOverride] = useState(overrideFunctions[0]);

  useEffect(() => {
    setOverride(overrideFunctions[0]);
  }, [overrideFunctions]);

  return (
    <div className="property-control">
      <div className="property-control-label">
        {label}
        {tooltip !== undefined && (
          <div className="property-control-tooltip">
            <Tooltip shortHtmlContent={tooltip}>
              <Icon path={mdiInformation} size={0.6} />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="property-control-control">
        <div className="property-control-toggle">
          <Switch
            id={id}
            name={id}
            disabled={disabled}
            checked={override}
            onChange={value => {
              setOverride(value);
              overrideFunctions[1](value);
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

PropertyControl.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
  // TODO: find the time to properly define this contract
  // eslint-disable-next-line react/forbid-prop-types
  overrideFunctions: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

const defaultDimensionsBuckets = Object.keys(experienceBucketDimensions)[0];
const defaultDimensionsDensityPercentage = 100;
const defaultDimensionsUseDevicePixelRatio = true;
const renderingQualities = {
  high: "High",
  medium: "Medium",
  low: "Low",
  noSmoothing: "No Smoothing",
};
const defaultRenderingQuality = "high";
const transitions = {
  none: "None",
  opacity: "Opacity",
  pixelation: "Pixelation",
};
const defaultTransition = "opacity";
const metricsTypes = {
  none: "None",
  default: "Default (Motionly)",
  // "googleAnalytics": "Google Analytics"
};
const defaultMetricsTypes = "default";
const defaultMetricsDelayInMilliseconds = 1000;
const metricsLevels = {
  none: "None",
  request: "Request",
  requestAndFail: "Request & Fail",
  allButRequestAndFail: "All But Request & Fail",
  allButFail: "All But Fail",
  allButRequest: "All But Request",
  all: "All",
};
const defaultMetricsLevel = "all";
const setupModes = {
  lazy: "Lazy",
  load: "Load",
  install: "Install",
};
const defaultSetupMode = "install";
const defaultSetupWrap = false;
const setupRunModes = {
  automatic: "Automatic",
  paused: "Paused",
  manual: "Manual",
};
const defaultSetupRunMode = "automatic";
const defaultSetupInstallVisibilityPercentageThreshold = 100;
const defaultSetupLoadVisibilityPercentageThreshold = 150;
// Taken from https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
const areNumbersArraysEqual = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]) === true;

export default function ExperienceDetailParametersPage({ experience, reloadExperience }) {
  const computeOverride = (override, value) => ({ override, value });
  const getOverride = property => property.override;
  const setOverride = (property, setter) => override => {
    setter(computeOverride(override, property.value));
  };
  const getValue = property => property.value;
  const setValue = (property, value) => {
    // eslint-disable-next-line no-param-reassign
    property.value = value;
  };
  const onChange = setter => value => {
    setter(computeOverride(true, value));
  };
  const computeOverrideFunctions = (property, setter) => [property.override, setOverride(property, setter)];

  const controlMediumWidth = "250px";
  const controlSmallWidth = "100px";
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const yes = "true";
  const no = "false";
  const yesno = {
    [yes]: t("app.yes"),
    [no]: t("app.no"),
  };
  const [working, setWorking] = useState(false);
  const [localExperience, setLocalExperience] = useState();
  const defaultOverride = false;
  const [dimensionsBuckets, setDimensionsBuckets] = useState(
    computeOverride(defaultOverride, defaultDimensionsBuckets)
  );
  const [dimensionsDensityPercentage, setDimensionsDensityPercentage] = useState(
    computeOverride(defaultOverride, defaultDimensionsDensityPercentage)
  );
  const [dimensionsUseDevicePixelRatio, setDimensionsUseDevicePixelRatio] = useState(
    computeOverride(defaultOverride, defaultDimensionsUseDevicePixelRatio)
  );
  const [renderingQuality, setRenderingQuality] = useState(computeOverride(defaultOverride, defaultRenderingQuality));
  const [transition, setTransition] = useState(computeOverride(defaultOverride, defaultTransition));
  const [metricsType, setMetricsType] = useState(computeOverride(defaultOverride, defaultMetricsTypes));
  const [metricsDelayInMilliseconds, setMetricsDelayInMilliseconds] = useState(
    computeOverride(defaultOverride, defaultMetricsDelayInMilliseconds)
  );
  const [metricsLevel, setMetricsLevel] = useState(computeOverride(defaultOverride, defaultMetricsLevel));
  const [setupRunMode, setSetupRunMode] = useState(computeOverride(defaultOverride, defaultSetupRunMode));
  const [setupWrap, setSetupWrap] = useState(computeOverride(defaultOverride, defaultSetupWrap));
  const [setupMode, setSetupMode] = useState(computeOverride(defaultOverride, defaultSetupMode));
  const [setupInstallVisibilityPercentageThreshold, setSetupInstallVisibilityPercentageThreshold] = useState(
    computeOverride(defaultOverride, defaultSetupInstallVisibilityPercentageThreshold)
  );
  const [setupLoadVisibilityPercentageThreshold, setSetupLoadVisibilityPercentageThreshold] = useState(
    computeOverride(defaultOverride, defaultSetupLoadVisibilityPercentageThreshold)
  );
  const [possibleBucketDimensions, setPossibleBucketDimensions] = useState(experienceBucketDimensions);

  useEffect(() => {
    setLocalExperience(experience);
    const { parameters } = experience.data;
    const buckets = parameters?.dimensions?.buckets;
    if (buckets !== undefined) {
      // We add the missing buckets if necessary
      let withCustomBucketDimensions;
      if (
        Object.values(experienceBucketDimensions).find(item => areNumbersArraysEqual(buckets, item.value)) === undefined
      ) {
        withCustomBucketDimensions = { ...experienceBucketDimensions };
        withCustomBucketDimensions.Custom = { value: buckets, label: "Custom" };
      } else {
        withCustomBucketDimensions = experienceBucketDimensions;
      }
      setPossibleBucketDimensions(withCustomBucketDimensions);
    }
  }, [experience]);

  useEffect(() => {
    if (localExperience === undefined) {
      return;
    }
    const { parameters } = localExperience.data;
    setDimensionsBuckets(
      computeOverride(
        parameters.dimensions !== undefined && parameters.dimensions.buckets !== undefined,
        !(parameters.dimensions !== undefined && parameters.dimensions.buckets !== undefined)
          ? defaultDimensionsBuckets
          : Object.keys(possibleBucketDimensions).find(key =>
              areNumbersArraysEqual(possibleBucketDimensions[key].value, parameters.dimensions.buckets)
            )
      )
    );
    setDimensionsDensityPercentage(
      computeOverride(
        parameters.dimensions !== undefined && parameters.dimensions.densityPercentage !== undefined,
        !(parameters.dimensions !== undefined && parameters.dimensions.densityPercentage !== undefined)
          ? defaultDimensionsDensityPercentage
          : parameters.dimensions.densityPercentage
      )
    );
    setDimensionsUseDevicePixelRatio(
      computeOverride(
        parameters.dimensions !== undefined && parameters.dimensions.useDevicePixelRatio !== undefined,
        !(parameters.dimensions !== undefined && parameters.dimensions.useDevicePixelRatio !== undefined)
          ? defaultDimensionsUseDevicePixelRatio
          : parameters.dimensions.useDevicePixelRatio
      )
    );
    setRenderingQuality(
      computeOverride(
        parameters.renderingQuality !== undefined,
        !(parameters.renderingQuality !== undefined) ? defaultRenderingQuality : parameters.renderingQuality
      )
    );
    setTransition(
      computeOverride(
        parameters.transition !== undefined,
        !(parameters.transition !== undefined) ? defaultTransition : parameters.transition
      )
    );
    setMetricsType(
      computeOverride(
        parameters.metrics !== undefined && parameters.metrics.type !== undefined,
        !(parameters.metrics !== undefined && parameters.metrics.type !== undefined)
          ? defaultMetricsTypes
          : parameters.metrics.type
      )
    );
    setMetricsDelayInMilliseconds(
      computeOverride(
        parameters.metricsDelayInMilliseconds !== undefined,
        !(parameters.metricsDelayInMilliseconds !== undefined)
          ? defaultMetricsDelayInMilliseconds
          : parameters.metricsDelayInMilliseconds
      )
    );
    setMetricsLevel(
      computeOverride(
        parameters.metricsLevel !== undefined,
        !(parameters.metricsLevel !== undefined) ? defaultMetricsLevel : parameters.metricsLevel
      )
    );
    const overrideSetupRunMode =
      parameters.setup !== undefined &&
      (parameters.setup.runMode !== undefined || parameters.setup.manual !== undefined);
    let setupRunModeValue;
    if (!overrideSetupRunMode) {
      setupRunModeValue = defaultSetupRunMode;
    } else {
      setupRunModeValue =
        parameters.setup.manual !== undefined
          ? Object.keys(setupRunModes).find(key => setupRunModes[key] === setupRunModes.manual)
          : parameters.setup.runMode;
    }
    setSetupRunMode(computeOverride(overrideSetupRunMode, setupRunModeValue));
    setSetupWrap(
      computeOverride(
        parameters.setup !== undefined && parameters.setup.wrap !== undefined,
        !(parameters.setup !== undefined && parameters.setup.wrap !== undefined)
          ? defaultSetupWrap
          : parameters.setup.wrap
      )
    );
    setSetupMode(
      computeOverride(
        parameters.setup !== undefined && parameters.setup.mode !== undefined,
        !(parameters.setup !== undefined && parameters.setup.mode !== undefined)
          ? defaultSetupMode
          : parameters.setup.mode
      )
    );
    setSetupInstallVisibilityPercentageThreshold(
      computeOverride(
        parameters.setup !== undefined && parameters.setup.installVisibilityPercentageThreshold !== undefined,
        !(parameters.setup !== undefined && parameters.setup.installVisibilityPercentageThreshold !== undefined)
          ? defaultSetupInstallVisibilityPercentageThreshold
          : parameters.setup.installVisibilityPercentageThreshold
      )
    );
    setSetupLoadVisibilityPercentageThreshold(
      computeOverride(
        parameters.setup !== undefined && parameters.setup.loadVisibilityPercentageThreshold !== undefined,
        !(parameters.setup !== undefined && parameters.setup.loadVisibilityPercentageThreshold !== undefined)
          ? defaultSetupLoadVisibilityPercentageThreshold
          : parameters.setup.loadVisibilityPercentageThreshold
      )
    );
  }, [localExperience, possibleBucketDimensions]);

  const isDisabled = property => working === true || property.override === false;

  const computeOptions = array => Object.keys(array).map(key => selectEntry(key, array[key]));

  const onSaveParameters = useCallback(async () => {
    setWorking(true);
    try {
      const motionlyInputParams = {};
      // We add the handled properties
      const handledProperties = {
        dimensions: {
          entries: [dimensionsBuckets, dimensionsDensityPercentage, dimensionsUseDevicePixelRatio],
          // eslint-disable-next-line no-shadow
          to: (dimensionsBuckets, dimensionsDensityPercentage, dimensionsUseDevicePixelRatio) => {
            if (
              dimensionsBuckets === undefined &&
              dimensionsDensityPercentage === undefined &&
              dimensionsUseDevicePixelRatio === undefined
            ) {
              return undefined;
            }
            const dimensions = {};
            if (dimensionsBuckets !== undefined) {
              dimensions.buckets = possibleBucketDimensions[dimensionsBuckets].value;
            }
            if (dimensionsDensityPercentage !== undefined) {
              dimensions.densityPercentage = dimensionsDensityPercentage;
            }
            if (dimensionsUseDevicePixelRatio !== undefined) {
              dimensions.useDevicePixelRatio = dimensionsUseDevicePixelRatio;
            }
            return dimensions;
          },
        },
        renderingQuality: { entries: [renderingQuality] },
        transition: { entries: [transition] },
        metrics: {
          entries: [metricsType],
          // eslint-disable-next-line no-shadow
          to: metricsType => {
            if (metricsType === undefined) {
              return undefined;
            }
            const metrics = {};
            metrics.type = metricsType;
            return metrics;
          },
        },
        metricsDelayInMilliseconds: { entries: [metricsDelayInMilliseconds] },
        metricsLevel: { entries: [metricsLevel] },
        setup: {
          entries: [
            setupRunMode,
            setupWrap,
            setupMode,
            setupInstallVisibilityPercentageThreshold,
            setupLoadVisibilityPercentageThreshold,
          ],
          to: (
            // eslint-disable-next-line no-shadow
            setupRunMode,
            // eslint-disable-next-line no-shadow
            setupWrap,
            // eslint-disable-next-line no-shadow
            setupMode,
            // eslint-disable-next-line no-shadow
            setupInstallVisibilityPercentageThreshold,
            // eslint-disable-next-line no-shadow
            setupLoadVisibilityPercentageThreshold
          ) => {
            if (
              setupRunMode === undefined &&
              setupWrap === undefined &&
              setupMode === undefined &&
              setupInstallVisibilityPercentageThreshold === undefined &&
              setupLoadVisibilityPercentageThreshold === undefined
            ) {
              return undefined;
            }
            const setup = {};
            if (setupRunMode !== undefined) {
              setup.runMode = setupRunMode;
            }
            if (setupWrap !== undefined) {
              setup.wrap = setupWrap;
            }
            if (setupMode !== undefined) {
              setup.mode = setupMode;
              if (setupMode !== "install" && setupInstallVisibilityPercentageThreshold !== undefined) {
                setup.installVisibilityPercentageThreshold = parseFloat(setupInstallVisibilityPercentageThreshold);
              }
              if (setupMode === "lazy" && setupLoadVisibilityPercentageThreshold !== undefined) {
                setup.loadVisibilityPercentageThreshold = parseFloat(setupLoadVisibilityPercentageThreshold);
              }
            }
            return setup;
          },
        },
      };

      Object.keys(handledProperties).forEach(key => {
        const handledProperty = handledProperties[key];
        const { entries } = handledProperty;
        if (entries.length <= 1) {
          const entry = entries[0];
          if (getOverride(entry) === true) {
            const { value } = entry;
            motionlyInputParams[key] = handledProperty.to === undefined ? value : handledProperty.to(value);
          }
        } else {
          const values = entries.map(entry => (getOverride(entry) === true ? getValue(entry) : undefined));
          const value = handledProperty.to.apply(null, values);
          if (value !== undefined) {
            motionlyInputParams[key] = value;
          }
        }
      });

      const { parameters } = localExperience.data;
      // We add the potentially missing other properties, which are not handled
      Object.keys(parameters).forEach(key => {
        if (motionlyInputParams[key] === undefined && handledProperties[key] === undefined) {
          motionlyInputParams[key] = parameters[key];
        }
      });

      try {
        const newExperience = await experienceService.setParameters(localExperience.id, motionlyInputParams);
        setLocalExperience(newExperience);
        reloadExperience(newExperience);
        notificationService.notifySuccess(undefined, t("experiences.parametersTab.submitSuccess"));
      } catch (error) {
        notificationService.notifyError(error, t("experiences.parametersTab.name"));
      }
    } finally {
      setWorking(false);
    }
  }, [
    dimensionsBuckets,
    dimensionsDensityPercentage,
    dimensionsUseDevicePixelRatio,
    localExperience,
    metricsDelayInMilliseconds,
    metricsLevel,
    metricsType,
    renderingQuality,
    setupInstallVisibilityPercentageThreshold,
    setupLoadVisibilityPercentageThreshold,
    setupMode,
    setupRunMode,
    setupWrap,
    transition,
    possibleBucketDimensions,
    reloadExperience,
    t,
  ]);

  return (
    <DetailPage className="page-parameters-container">
      <PageHeader
        title={
          <>
            {t("experiences.parametersTab.name")}
            <Tooltip
              shortHtmlContent={t("experiences.parametersTab.shortTooltip")}
              longHtmlContent={t("experiences.parametersTab.longTooltip")}
            >
              <Icon path={mdiInformation} size={0.6} />
            </Tooltip>
          </>
        }
      />
      <Form onSubmit={onSaveParameters}>
        <SpacedContainer withBottom>
          <PropertyControl
            id="dimensions-buckets"
            label={t("experiences.parametersTab.dimensionsBuckets")}
            tooltip={t("experiences.parametersTab.dimensionsBucketsTooltip")}
            overrideFunctions={computeOverrideFunctions(dimensionsBuckets, setDimensionsBuckets)}
          >
            <Select
              value={getValue(dimensionsBuckets)}
              disabled={isDisabled(dimensionsBuckets)}
              onChange={onChange(setDimensionsBuckets)}
              options={Object.keys(possibleBucketDimensions).map(key => {
                const dimension = possibleBucketDimensions[key];
                return selectEntry(key, `${dimension.label} [${dimension.value.join(",")}]`);
              })}
              width={500}
            />
          </PropertyControl>
          <FormGroup
            label={t("experiences.parametersTab.quality")}
            tooltip={t("experiences.parametersTab.qualityTooltip")}
          >
            <PropertyControl
              id="dimensions-density-percentage"
              label={t("experiences.parametersTab.dimensionsDensityPercentage")}
              tooltip={t("experiences.parametersTab.dimensionsDensityPercentageTooltip")}
              overrideFunctions={computeOverrideFunctions(dimensionsDensityPercentage, setDimensionsDensityPercentage)}
            >
              <InputNumber
                value={getValue(dimensionsDensityPercentage)}
                disabled={isDisabled(dimensionsDensityPercentage)}
                onChange={onChange(setDimensionsDensityPercentage)}
                width={controlSmallWidth}
                min={1}
                max={100}
              />
            </PropertyControl>
            <PropertyControl
              id="dimensions-use-device-pixel-ratio"
              label={t("experiences.parametersTab.dimensionsUseDeviceDensity")}
              tooltip={t("experiences.parametersTab.dimensionsUseDeviceDensityTooltip")}
              overrideFunctions={computeOverrideFunctions(
                dimensionsUseDevicePixelRatio,
                setDimensionsUseDevicePixelRatio
              )}
            >
              <Select
                value={getValue(dimensionsUseDevicePixelRatio) === true ? yes : no}
                disabled={isDisabled(dimensionsUseDevicePixelRatio)}
                onChange={onChange(value => {
                  setValue(value, getValue(value) === yes);
                  setDimensionsUseDevicePixelRatio(value);
                })}
                options={computeOptions(yesno)}
                width={controlSmallWidth}
              />
            </PropertyControl>
            <PropertyControl
              id="rendering-quality"
              label={t("experiences.parametersTab.renderingQuality")}
              tooltip={t("experiences.parametersTab.renderingQualityTooltip")}
              overrideFunctions={computeOverrideFunctions(renderingQuality, setRenderingQuality)}
            >
              <Select
                value={getValue(renderingQuality)}
                disabled={isDisabled(renderingQuality)}
                onChange={onChange(setRenderingQuality)}
                options={computeOptions(renderingQualities)}
                width={controlMediumWidth}
              />
            </PropertyControl>
          </FormGroup>
          <FormGroup label={t("experiences.parametersTab.ux")} tooltip={t("experiences.parametersTab.uxTooltip")}>
            <PropertyControl
              id="transition"
              label={t("experiences.parametersTab.transition")}
              tooltip={t("experiences.parametersTab.transitionTooltip")}
              overrideFunctions={computeOverrideFunctions(transition, setTransition)}
            >
              <Select
                value={getValue(transition)}
                disabled={isDisabled(transition)}
                onChange={onChange(setTransition)}
                options={computeOptions(transitions)}
                width={controlMediumWidth}
              />
            </PropertyControl>
          </FormGroup>
          <FormGroup
            label={t("experiences.parametersTab.metrics")}
            tooltip={t("experiences.parametersTab.metricsTooltip")}
          >
            <PropertyControl
              id="metrics-type"
              label={t("experiences.parametersTab.metricsType")}
              tooltip={t("experiences.parametersTab.metricsTooltip")}
              overrideFunctions={computeOverrideFunctions(metricsType, setMetricsType)}
            >
              <Select
                value={getValue(metricsType)}
                disabled={isDisabled(metricsType)}
                onChange={onChange(setMetricsType)}
                options={computeOptions(metricsTypes)}
                width={controlMediumWidth}
              />
            </PropertyControl>
            <PropertyControl
              id="metrics-delay-in-milliseconds"
              label={t("experiences.parametersTab.metricsDelayInMilliseconds")}
              tooltip={t("experiences.parametersTab.metricsDelayInMillisecondsTooltip")}
              overrideFunctions={computeOverrideFunctions(metricsDelayInMilliseconds, setMetricsDelayInMilliseconds)}
            >
              <InputNumber
                value={getValue(metricsDelayInMilliseconds)}
                disabled={isDisabled(metricsDelayInMilliseconds)}
                onChange={onChange(setMetricsDelayInMilliseconds)}
                width={controlSmallWidth}
                min={0}
                max={3000}
              />
            </PropertyControl>
            <PropertyControl
              id="metrics-level"
              label={t("experiences.parametersTab.metricsLevel")}
              tooltip={t("experiences.parametersTab.metricsLevelTooltip")}
              overrideFunctions={computeOverrideFunctions(metricsLevel, setMetricsLevel)}
            >
              <Select
                value={getValue(metricsLevel)}
                disabled={isDisabled(metricsLevel)}
                onChange={onChange(setMetricsLevel)}
                options={computeOptions(metricsLevels)}
                width={controlMediumWidth}
              />
            </PropertyControl>
          </FormGroup>
          <FormGroup label={t("experiences.parametersTab.setup")} tooltip={t("experiences.parametersTab.setupTooltip")}>
            <PropertyControl
              id="setup-run-mode"
              label={t("experiences.parametersTab.setupRunMode")}
              tooltip={t("experiences.parametersTab.setupRunModeTooltip")}
              overrideFunctions={computeOverrideFunctions(setupRunMode, setSetupRunMode)}
            >
              <Select
                value={getValue(setupRunMode)}
                disabled={isDisabled(setupRunMode)}
                onChange={onChange(setSetupRunMode)}
                options={computeOptions(setupRunModes)}
                width={controlMediumWidth}
              />
            </PropertyControl>
            <PropertyControl
              id="setup-wrap"
              label={t("experiences.parametersTab.setupWrap")}
              tooltip={t("experiences.parametersTab.setupWrapTooltip")}
              overrideFunctions={computeOverrideFunctions(setupWrap, setSetupWrap)}
            >
              <Select
                value={getValue(setupWrap) === true ? yes : no}
                disabled={isDisabled(setupWrap)}
                onChange={onChange(value => {
                  setValue(value, getValue(value) === yes);
                  setSetupWrap(value);
                })}
                options={computeOptions(yesno)}
                width={controlSmallWidth}
              />
            </PropertyControl>
            {/* In order to have the 3 last controls on the same row */}
            <div />
            <PropertyControl
              id="setup-mode"
              label={t("experiences.parametersTab.setupMode")}
              tooltip={t("experiences.parametersTab.setupModeTooltip")}
              overrideFunctions={computeOverrideFunctions(setupMode, setSetupMode)}
            >
              <Select
                value={getValue(setupMode)}
                disabled={isDisabled(setupMode)}
                onChange={onChange(setSetupMode)}
                options={computeOptions(setupModes)}
                width={controlMediumWidth}
              />
            </PropertyControl>
            <PropertyControl
              id="setup-install-visibility-percentage-threshold"
              label={t("experiences.parametersTab.setupInstallVisibilityPercentageThreshold")}
              tooltip={t("experiences.parametersTab.setupInstallVisibilityPercentageThresholdTooltip")}
              disabled={getValue(setupMode) === "install"}
              overrideFunctions={computeOverrideFunctions(
                setupInstallVisibilityPercentageThreshold,
                setSetupInstallVisibilityPercentageThreshold
              )}
            >
              <InputNumber
                value={getValue(setupInstallVisibilityPercentageThreshold)}
                disabled={getValue(setupMode) === "install" || isDisabled(setupInstallVisibilityPercentageThreshold)}
                onChange={onChange(setSetupInstallVisibilityPercentageThreshold)}
                width={controlSmallWidth}
                min={0}
              />
            </PropertyControl>
            <PropertyControl
              id="setup-install-load-percentage-threshold"
              label={t("experiences.parametersTab.setupLoadVisibilityPercentageThreshold")}
              tooltip={t("experiences.parametersTab.setupLoadVisibilityPercentageThresholdTooltip")}
              disabled={getValue(setupMode) !== "lazy"}
              overrideFunctions={computeOverrideFunctions(
                setupLoadVisibilityPercentageThreshold,
                setSetupLoadVisibilityPercentageThreshold
              )}
            >
              <InputNumber
                value={getValue(setupLoadVisibilityPercentageThreshold)}
                disabled={getValue(setupMode) !== "lazy" || isDisabled(setupLoadVisibilityPercentageThreshold)}
                onChange={onChange(setSetupLoadVisibilityPercentageThreshold)}
                width={controlSmallWidth}
                min={0}
              />
            </PropertyControl>
          </FormGroup>
        </SpacedContainer>
        <Button
          submit
          type="primary"
          loading={working}
          disabled={working || experience.state !== Experience.StateEnum.Draft}
        >
          {t("actions.save")}
        </Button>
      </Form>
    </DetailPage>
  );
}

ExperienceDetailParametersPage.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  reloadExperience: PropTypes.func.isRequired,
};
