import i18n from "Legacy/i18n/i18n";

const isRequired = (message = "form.required") => ({
  message: i18n.t(message),
  required: true,
});

const isInteger = (message = "form.noDecimals") => ({
  message: i18n.t(message),
  type: "integer",
});

const isIntInRange = (range, message = "form.invalidRange") => ({
  message: i18n.t(message, range),
  min: range.MIN,
  max: range.MAX,
});

const isURL = (message = "form.errorURL") => ({
  message: i18n.t(message),
  type: "url",
});

export const formRules = {
  IS_REQUIRED: isRequired,
  IS_INTEGER: isInteger,
  IS_URL: isURL,
  IS_INT_IN_RANGE: isIntInRange,
};
