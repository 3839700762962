const helpers = {
  constants: {
    ratioPrecisionLimit: 4,
    nameLengthMaximum: 64,
    siteNameLengthMaximum: 150,
    commentLengthMaximum: 128,
    experienceNameLengthMaximum: 150,
    experienceCommentLengthMaximum: 128,
  },
  javaScriptMimeType: "text/javascript",
  applicationCallbacksFileName: "motionlyApplicationCallbacks.js",
  experienceCallbacksFileName: "motionlyExperienceCallbacks.js",
};

export default helpers;
