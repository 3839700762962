import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import { Button, Space } from "@ogury/design-system";

import { cache, customerPermissions, customerService, experiencePermissions, userService } from "Legacy/services";
import { applyGuestSpecificities, disableFeature, path } from "Legacy/utils";

import "./Navbar.scss";
import { useNotificationService } from "Legacy/utils";
import { computeDefaultHome } from "Legacy/utils/path";
import Logo from "Legacy/assets/img/Logo.svg";
import { NavbarCreateButton, NavbarCustomerMenu, NavbarMenuItem, NavbarSearch, NavbarUserMenu } from "./components";

const getFirstRouteSegment = route => {
  const aPath = route.startsWith("/") ? route.substring(1) : route;
  const splitRoute = aPath.split("/");
  return splitRoute.length > 0 ? splitRoute[0] : "";
};

export default function Navbar({ currentRoute }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const currentRouteRoot = useMemo(() => getFirstRouteSegment(currentRoute), [currentRoute]);

  const isMenuItemActive = (aPath, children) => {
    const firstRouteSegment = getFirstRouteSegment(aPath);
    let isActive = firstRouteSegment === currentRouteRoot;
    if (children) {
      isActive = !isActive ? children === currentRouteRoot : isActive;
    }
    return isActive;
  };

  const renderMenuItems = group =>
    group.map(menuItem => (
      <NavbarMenuItem
        menuItem={menuItem}
        key={menuItem.key}
        isActive={isMenuItemActive(menuItem.path, menuItem.children)}
      />
    ));
  const computeMenuConfig = () => {
    const group2 = [];
    group2.push(
      ...[
        /* {
        id: 4,
        key: "assets",
        labelKey: "navbar.assets",
        path: "/assets",
      },
      {
        id: 5,
        key: "sites",
        labelKey: "navbar.sites",
        path: "/sites",
      }, */
      ]
    );

    const group3 = [];
    if (customerPermissions.canAccessApplications() === true) {
      group3.push({
        id: 6,
        key: "applications",
        labelKey: "navbar.applications",
        path: path.APPLICATIONS,
      });
    }

    const menuConfig = {
      group1: [
        /* {
          id: 0,
          key: "dashboard",
          labelKey: "navbar.dashboard",
          path: "/dashboard",
        }, */
        {
          id: 1,
          key: "sites",
          labelKey: "navbar.experiences",
          path: computeDefaultHome(),
          children: "experiences",
        },
        {
          id: 2,
          key: "templates",
          labelKey: "navbar.templates",
          path: path.TEMPLATES,
        },
        {
          id: 3,
          key: "experiences",
          labelKey: "navbar.sites",
          path: path.EXPERIENCES,
          hidden: true,
        },
      ],
    };
    if (group2.length > 0) {
      menuConfig.group2 = group2;
    }
    if (group3.length > 0) {
      menuConfig.group3 = group3;
    }
    return menuConfig;
  };
  const menuConfig = computeMenuConfig();

  const onReload = async () => {
    await cache.reload(t, history, false, notificationService);
  };

  return (
    <nav className="navbar">
      <div className="navbar-block-content">
        <Link to="/">
          <img src={Logo} alt="Logo" className="navbar-logo" />
        </Link>
        {experiencePermissions.canCreateStandaloneExperience() && renderMenuItems(menuConfig.group1)}
        {menuConfig.group2 !== undefined && (
          <>
            <div className="menu-separator" />
            {renderMenuItems(menuConfig.group2)}
          </>
        )}
        {menuConfig.group3 !== undefined && (
          <>
            <div className="menu-separator" />
            {renderMenuItems(menuConfig.group3)}
          </>
        )}
      </div>
      <Space direction="horizontal">
        <NavbarSearch />
        {disableFeature && (
          <>
            <Button onClick={onReload} type="secondary" icon={<RefreshLineIcon />} iconPosition="iconOnly" />
            <div className="navbar-margin" />
          </>
        )}
        {applyGuestSpecificities(customerService.getCurrentCustomerSummaryId(), userService.getCurrentUserEmail()) ===
          false && <NavbarCustomerMenu />}
        <NavbarUserMenu />
        <NavbarCreateButton />
      </Space>
    </nav>
  );
}

Navbar.propTypes = {
  currentRoute: PropTypes.string,
};
