import { datadogRum } from "@datadog/browser-rum";

const initErrorMonitoring = config => {
  const { applicationId, clientToken, env, version } = config;

  if (!(applicationId && clientToken && env)) {
    return;
  }

  // disable console log in production
  if (env === "prod") {
    // eslint-disable-next-line no-console
    console.log = () => {};
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.eu",
    service: "ocs-manager",
    env,
    version,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "allow",
    beforeSend: event => !shouldDiscardEvent(event),
  });

  datadogRum.startSessionReplayRecording();
};

export const shouldDiscardEvent = event => {
  if (!event.error) {
    return false;
  }

  const errorMessage = event.error.message;
  const exceptions = [
    "Connection destroyed",
    "Request has been terminated",
    "The user does not have customer account",
    "Timeout of 300000ms exceeded",
    "Script error.",
    'Provided "The application could not be loaded."',
    '"code": 10,', // BE validation errors should not be logged in datadog
    'Provided {"code":', // form validation errors should not be logged in datadog
    'Provided {"message":', // form validation errors should not be logged in datadog
    'Uncaught {"code":', // form validation errors should not be logged in datadog
    "Reached max sessionReplay events size queued for upload:",
    "[GSI_LOGGER]: Failed to open popup window on url",
  ];

  if (process.env.REACT_APP_ENV === "devc") {
    exceptions.push('Provided "The application could not be loaded."'); // devc env is known to have loading issues
  }

  if (exceptions.findIndex(exception => errorMessage.indexOf(exception) >= 0) >= 0) {
    return true;
  }
};

export default initErrorMonitoring;
