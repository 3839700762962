import { path } from "Legacy/utils";
import Templates from "./Templates";

// We export only the root route of the app. Nested routes are internal
export default [
  {
    path: path.TEMPLATES,
    key: "templates",
    component: Templates,
  },
];
