import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Alert, Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Attribute, ExperienceThumbnail, GuardedModal, SpacedContainer, SpacedUnits } from "Legacy/components";
import { experienceService, siteService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { ExperienceSelector } from "./components";

export default function ExperiencePublishOverModal({ open, experience, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [experienceToReplace, setExperienceToReplace] = useState();

  const publishOver = async () => {
    setWorking(true);
    try {
      const replacedExperience = await experienceService.replace(experience.id, experienceToReplace.id);
      const site = await siteService.getExperienceSite(experience.id);
      if (site) {
        await siteService.detachExperience(site.id, experience.id);
      }
      await experienceService.deleteExperience(experience.id);
      notificationService.notifySuccess(t("experiences.publishOverModal.successMessage"));
      onSuccess(replacedExperience);
      onClose();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  const handleOnChangeExperienceToReplace = _experience => setExperienceToReplace(_experience);

  const experienceIsNotPublished = useMemo(() => {
    return experienceToReplace && !experienceService.experienceIsPublished(experienceToReplace);
  }, [experienceToReplace]);

  return (
    <GuardedModal
      open={open}
      title={t("experiences.publishOverModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              onClick={publishOver}
              type="danger"
              loading={working}
              disabled={!experienceToReplace || experienceIsNotPublished}
            >
              {t("actions.publishOver")}
            </Button>
          }
        />
      }
      canBeClosed={() => {
        if (working === true) {
          return false;
        }
        return experienceToReplace !== undefined ? t("components.guardedModal.closeQuestion") : undefined;
      }}
      onClose={onClose}
      width={ModalWidths.Medium}
    >
      <Typography.P2Regular as="p">{t("experiences.publishOverModal.warning")}</Typography.P2Regular>
      <Attribute name={t("experiences.publishOverModal.oldExperience")} />
      <SpacedContainer gap={SpacedUnits.Small} withBottom>
        <ExperienceSelector onChange={handleOnChangeExperienceToReplace} />
        {experienceIsNotPublished && (
          <Alert description={t("experiences.publishOverModal.errorNotPublished")} type="error" closable={false} />
        )}
      </SpacedContainer>
      <Attribute name={t("experiences.publishOverModal.newExperience")} />
      <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
    </GuardedModal>
  );
}

ExperiencePublishOverModal.propTypes = {
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
