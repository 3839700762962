import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./CustomerThumbnail.scss";
import { ThumbnailContainer } from "Legacy/components";
import { randomString } from "Legacy/utils";

export default function CustomerThumbnail({ customer }) {
  const [t] = useTranslation();

  return (
    <div className="customer-thumbnail">
      <div className="poster">
        <ThumbnailContainer key={randomString(6)} url={customer.logoImageUrl} size="medium" />
      </div>
      <div>
        <div className="h3 name">{customer.name}</div>
        <div className="normal-text typo-black-light">
          {t("fields.id")}&nbsp;&nbsp;&nbsp;<span className="normal-text-bold">{customer.id}</span>
        </div>
      </div>
    </div>
  );
}

CustomerThumbnail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customer: PropTypes.object,
};
