import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import FileSaver from "file-saver";

import { MediaInputs } from "@ogury/motionly-ws-api/ws";

import "./ExperienceExportModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import {
  Form,
  FormControl,
  GuardedModal,
  Radio,
  RadioGroup,
  SpacedContainer,
  SpacedUnits,
  Tooltip,
} from "Legacy/components";
import { experienceDescription } from "Legacy/utils";
import { useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { experienceService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { ExperiencePlayer } from "../index";

// eslint-disable-next-line no-self-compare
const enablePlayer = "" === "";

const { ImageFormatEnum, VideoFormatEnum } = MediaInputs;
// noinspection JSUnresolvedVariable
const imageFormats = [ImageFormatEnum.Jpeg, ImageFormatEnum.Png, ImageFormatEnum.WebP, ImageFormatEnum.Gif];
// noinspection JSUnresolvedVariable
const videoFormats = [
  VideoFormatEnum.MP4,
  VideoFormatEnum.AVI,
  VideoFormatEnum.MOV,
  VideoFormatEnum.WEBM,
  VideoFormatEnum.OGG,
  VideoFormatEnum.MKV,
];
const modes = ["track", "score"];
const trackMode = modes[0];
const scoreMode = modes[1];

const percentageRange = { MIN: 0, MAX: 100 };
const widthRange = { MIN: 32, MAX: 1080 };
const qualityRange = { MIN: 1, MAX: 100 };
const bitrateInKiloBytesPerSecondRange = { MIN: 1000, MAX: 4000 };
const framesPerSecondRange = { MIN: 1, MAX: 90 };

const inputsWidth = "100%";

export default function ExperienceExportModal({ experience, onClose, open }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const types = [
    { type: "image", label: t("experiences.exportModal.types.image") },
    { type: "video", label: t("experiences.exportModal.types.video") },
    { type: "animatedGif", label: t("experiences.exportModal.types.animatedGif") },
  ];
  const imageType = types[0].type;
  const videoType = types[1].type;
  const animatedGifType = types[2].type;

  const [working, setWorking] = useState(false);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [type, setType] = useState(imageType);
  const [mode, setMode] = useState(trackMode);

  const videoFramesPerSecond = 25;
  const animatedGifFramesPerSecond = 2;
  const experienceScores = experienceDescription.computeScores(experience?.description, undefined, score =>
    experienceDescription.isScoreTimeBased(score)
  );
  const initialFormValue = useMemo(() => {
    return {
      trackId: experienceDescription.computeTrackIds(experience?.description)[0],
      mode: trackMode === scoreMode,
      scoreId: experienceScores.length < 1 ? "" : experienceScores[0].id,
      concatenate: true,
      percentage: 0,
      width: 512,
      format: imageFormats[0],
      quality: 80,
      backgroundColor: "#FFFFFF",
      advanced: false,
      bitrateInKiloBytesPerSecond: 3500,
      framesPerSecond: videoFramesPerSecond,
    };
  }, [experience, experienceScores]);

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      trackId: [{ name: validators.IS_REQUIRED }],
      scoreId: experienceScores.length <= 0 ? undefined : [{ name: validators.IS_REQUIRED }],
      concatenate: [{ name: validators.IS_REQUIRED }],
      percentage: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_INT_IN_RANGE,
          message: t("form.invalidRange", percentageRange),
          range: percentageRange,
        },
      ],
      width: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_INT_IN_RANGE,
          message: t("form.invalidRange", widthRange),
          range: widthRange,
        },
        {
          name: validators.CUSTOM,
          validator: value => {
            if (type === videoType && value % 2 !== 0) {
              return t("experiences.exportModal.formValidationErrors.wrongWidth");
            }
            return "";
          },
        },
      ],
      format: [{ name: validators.IS_REQUIRED }],
      quality: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_INT_IN_RANGE,
          message: t("form.invalidRange", qualityRange),
          range: qualityRange,
        },
      ],
      backgroundColor: [{ name: validators.IS_REQUIRED }],
      advanced: [{ name: validators.IS_REQUIRED }],
      bitrateInKiloBytesPerSecond: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_INT_IN_RANGE,
          message: t("form.invalidRange", bitrateInKiloBytesPerSecondRange),
          range: bitrateInKiloBytesPerSecondRange,
        },
      ],
      framesPerSecond: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_INT_IN_RANGE,
          message: t("form.invalidRange", framesPerSecondRange),
          range: framesPerSecondRange,
        },
      ],
    },
    onSubmit: async formData => {
      try {
        setWorking(true);

        // noinspection JSUnresolvedVariable
        const imageQuality = formData.format !== ImageFormatEnum.Png ? parseInt(formData.quality, 10) : undefined;
        let mediaInputs;
        if (type === imageType) {
          // noinspection JSUnresolvedVariable
          mediaInputs = {
            imageQuality,
            percentage: parseFloat(formData.percentage),
            imageFormat: formData.format.toUpperCase(),
            backgroundColor: formData.format !== ImageFormatEnum.Png ? formData.backgroundColor : undefined,
          };
        } else if (type === videoType || type === animatedGifType) {
          let framesPerSecond;
          if (formData.advanced === true) {
            framesPerSecond = formData.framesPerSecond;
          } else {
            framesPerSecond = type === animatedGifType ? animatedGifFramesPerSecond : videoFramesPerSecond;
          }
          // noinspection ES6MissingAwait
          const durationInMilliseconds =
            mode === scoreMode
              ? undefined
              : experienceDescription.computeAnimationDurationInMilliseconds(experience?.description, formData.trackId);
          const minimumBitrateInKiloBytesPerSecond = bitrateInKiloBytesPerSecondRange.MIN;
          const maximumBitrateInKiloBytesPerSecond = bitrateInKiloBytesPerSecondRange.MAX;
          // We need to discard the non strictly necessary properties, because the web services web are strict on this level

          // noinspection NestedConditionalExpressionJS, JSUnresolvedVariable
          mediaInputs = {
            imageQuality,
            fromPercentage: mode === scoreMode ? undefined : 0,
            toPercentage: mode === scoreMode ? undefined : 100,
            imageFormat: ImageFormatEnum.Png.toUpperCase(),
            framesPerSecond,
            framesCount:
              mode === scoreMode
                ? undefined
                : (framesPerSecond * (durationInMilliseconds === undefined ? 2000 : durationInMilliseconds)) / 1000,
            backgroundColor: formData.backgroundColor,
          };
          if (type === videoType) {
            mediaInputs.videoFormat = formData.format.toUpperCase();
            mediaInputs.videoQuality = imageQuality;
            mediaInputs.bitrateInKiloBytesPerSecond =
              formData.advanced === true
                ? parseInt(formData.bitrateInKiloBytesPerSecond, 10)
                : minimumBitrateInKiloBytesPerSecond +
                  ((maximumBitrateInKiloBytesPerSecond - minimumBitrateInKiloBytesPerSecond) * imageQuality) / 100;
          }
        }
        const width = parseInt(formData.width, 10);
        let buffer;
        if (mode === scoreMode && type === videoType) {
          buffer = await experienceService.exportScore(
            experience.id,
            formData.scoreId,
            width,
            formData.concatenate,
            mediaInputs
          );
        } else {
          buffer = await experienceService.exportMedia(experience.id, type, formData.trackId, width, mediaInputs);
        }
        const fileExtension = buffer.type === "application/zip" ? "zip" : `${formData.format.toLowerCase()}`;
        FileSaver.saveAs(new Blob([buffer]), `${experience.name}.${fileExtension}`);
      } catch (error) {
        notificationService.notifyError(error, t("experiences.exportModal.submitFailure"));
      } finally {
        setWorking(false);
      }
    },
  };

  const { getFormProps, getFieldProps, validate, resetForm } = useFormValidation(formConfig);
  const trackIdFieldProps = getFieldProps("trackId");
  const advancedFieldProps = getFieldProps("advanced");
  const percentageFieldProps = getFieldProps("percentage");
  const formatFieldProps = getFieldProps("format");
  const backgroundColorFieldProps = getFieldProps("backgroundColor");
  const framesPerSecondFieldProps = getFieldProps("framesPerSecond");

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (!open) {
      return;
    }

    let newFormat;
    if (type === imageType) {
      // noinspection JSUnresolvedVariable
      newFormat = ImageFormatEnum.Jpeg;
    } else {
      // noinspection JSUnresolvedVariable
      newFormat = type === videoType ? VideoFormatEnum.MP4 : ImageFormatEnum.Gif;
    }
    formatFieldProps.onChange(newFormat);
    if (type === videoType || type === animatedGifType) {
      framesPerSecondFieldProps.onChange(type === videoType ? videoFramesPerSecond : animatedGifFramesPerSecond);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, open]);

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  // noinspection JSUnresolvedVariable
  const renderForm = () => (
    <Form {...getFormProps()}>
      <fieldset disabled={working}>
        {type === videoType && experienceScores.length > 0 && (
          <FormControl
            id="mode"
            label={t("experiences.exportModal.mode")}
            type="toggle"
            disabled={working || type !== videoType}
            checked={mode === scoreMode}
            onChange={value => {
              setMode(value === true ? scoreMode : trackMode);
            }}
            required
          />
        )}
        {(type !== videoType || mode !== scoreMode) && (
          <FormControl
            id="trackId"
            label={t("experiences.exportModal.track")}
            type="select"
            options={experienceDescription
              .computeTrackIds(experience?.description)
              .map(trackId => ({ label: trackId, value: trackId }))}
            width={inputsWidth}
            required
            {...trackIdFieldProps}
          />
        )}
        {type === videoType && mode === scoreMode && experienceScores.length > 0 && (
          <>
            <FormControl
              id="scoreId"
              label={t("experiences.exportModal.score")}
              type="select"
              options={experienceDescription
                .computeScores(experience?.description, undefined, score =>
                  experienceDescription.isScoreTimeBased(score)
                )
                .map(score => ({ label: score.id, value: score.id }))}
              width={inputsWidth}
              required
              {...getFieldProps("scoreId")}
            />
            <FormControl
              id="concatenate"
              label={t("experiences.exportModal.concatenate")}
              type="checkbox"
              width={inputsWidth}
              required
              {...getFieldProps("concatenate")}
            />
          </>
        )}
        {type === imageType && (
          <FormControl
            id="percentage"
            label={t("experiences.exportModal.percentage")}
            type="number"
            width={inputsWidth}
            required
            disabled={isPlayerReady === false}
            min={percentageRange.MIN}
            max={percentageRange.MAX}
            {...percentageFieldProps}
          />
        )}
        <FormControl
          id="width"
          label={t("experiences.exportModal.width")}
          type="number"
          width={inputsWidth}
          required
          min={widthRange.MIN}
          max={widthRange.MAX}
          {...getFieldProps("width")}
        />
        {type !== animatedGifType && (
          <FormControl
            id="format"
            label={t("experiences.exportModal.format")}
            type="select"
            options={(type === imageType ? imageFormats : videoFormats).map(format => ({
              label: format.toUpperCase(),
              value: format,
            }))}
            width={inputsWidth}
            required
            {...formatFieldProps}
          />
        )}
        <FormControl
          id="quality"
          label={t("experiences.exportModal.quality")}
          type="number"
          width={inputsWidth}
          required
          min={qualityRange.MIN}
          max={qualityRange.MAX}
          disabled={formatFieldProps.value === ImageFormatEnum.Png}
          {...getFieldProps("quality")}
        />
        <FormControl
          id="backgroundColor"
          type="color"
          label={t("experiences.exportModal.backgroundColor")}
          width={inputsWidth}
          required
          disabled={type === imageType && formatFieldProps.value === ImageFormatEnum.Png}
          {...backgroundColorFieldProps}
        />
        {type !== imageType && (
          <>
            <FormControl id="advanced" type="toggle" label={t("fields.advanced")} required {...advancedFieldProps} />
            {advancedFieldProps.value === true && (
              <>
                {type === videoType && (
                  <FormControl
                    id="bitrateInKiloBytesPerSecond"
                    type="number"
                    label={t("experiences.exportModal.bitrateInKiloBytesPerSecond")}
                    width={inputsWidth}
                    required
                    min={bitrateInKiloBytesPerSecondRange.MIN}
                    max={bitrateInKiloBytesPerSecondRange.MAX}
                    {...getFieldProps("bitrateInKiloBytesPerSecond")}
                  />
                )}
                <FormControl
                  id="framesPerSecond"
                  type="number"
                  label={t("experiences.exportModal.framesPerSecond")}
                  width={inputsWidth}
                  required
                  min={framesPerSecondRange.MIN}
                  max={framesPerSecondRange.MAX}
                  {...framesPerSecondFieldProps}
                />
              </>
            )}
          </>
        )}
      </fieldset>
    </Form>
  );

  return (
    <GuardedModal
      open={open}
      width={enablePlayer ? ModalWidths.XLarge : ModalWidths.Medium}
      title={t("experiences.exportModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.export")}
            </Button>
          }
        />
      }
      canBeClosed={() => {
        if (working === true) {
          return false;
        }
        return t("components.guardedModal.closeQuestion");
      }}
      onClose={onClose}
    >
      <Tooltip
        shortHtmlContent={t("experiences.exportModal.shortTooltip")}
        longHtmlContent={t("experiences.exportModal.longTooltip")}
        withIcon={false}
      >
        <Typography.P2Regular as="p">{t("experiences.exportModal.description")}</Typography.P2Regular>
      </Tooltip>

      <SpacedContainer className="experience-export-modal" gap={SpacedUnits.Medium}>
        <RadioGroup value={type} disabled={working} onChange={setType}>
          {types.map(theType => (
            <Radio key={theType.type} value={theType.type} horizontal>
              {theType.label}
            </Radio>
          ))}
        </RadioGroup>
        <SpacedContainer gap={SpacedUnits.Medium} horizontal>
          <div className="form">{renderForm()}</div>
          {enablePlayer && (
            <div className="player">
              <ExperiencePlayer
                experience={experience}
                trackId={trackIdFieldProps.value}
                percentage={parseFloat(percentageFieldProps.value)}
                backgroundColor={backgroundColorFieldProps.value}
                onReady={() => {
                  setPlayerReady(true);
                }}
              />
            </div>
          )}
        </SpacedContainer>
      </SpacedContainer>
    </GuardedModal>
  );
}

ExperienceExportModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
