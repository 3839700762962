import React, { useMemo } from "react";
import * as PropTypes from "prop-types";

import { Select } from "@ogury/design-system";

export default function ViewSwitcher({ value = "defaultId", options, onChange = () => {} }) {
  const menuItems = useMemo(
    () => options.map(option => ({ key: option.value, label: option.label, value: option.value })),
    [options]
  );

  return (
    <Select
      showSearch
      variant="borderless"
      style={{ marginLeft: -10 }}
      options={menuItems}
      width={150}
      dropdownWidth={200}
      onChange={onChange}
      value={value}
    />
  );
}

ViewSwitcher.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
