import React from "react";
import * as PropTypes from "prop-types";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";

import "./FormGroup.scss";
import { Tooltip } from "../..";

export default function FormGroup({ label, tooltip, children }) {
  return (
    <div>
      <div className="form-group-label">
        {label}
        {tooltip !== undefined && (
          <span className="form-group-tooltip-container">
            <Tooltip shortHtmlContent={tooltip}>
              <Icon path={mdiInformation} size={0.6} />
            </Tooltip>
          </span>
        )}
      </div>
      <div className="form-group-children">{children}</div>
    </div>
  );
}

FormGroup.propTypes = {
  label: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  children: PropTypes.node,
};
