import { useContext } from "react";
import { experienceActions } from "Legacy/services";
import { UserSettingsContext } from "Legacy/context/UserSettingsProvider";
import {
  LOCALSTORAGE_CURRENT_USER_PREFERENCES,
  LOCALSTORAGE_CURRENT_USER_PREFERENCES_VERSION,
} from "Legacy/utils/storage";
import { currentUserPreferencesSettingsVersion } from "Legacy/utils/constants";

const EXPERIENCE_TABLE_QUICK_ACCESS_ACTIONS = "experienceTableQuickAccessActions";

export function checkAndResetUserSettings() {
  const storedVersion = localStorage.getItem(LOCALSTORAGE_CURRENT_USER_PREFERENCES_VERSION);

  if (storedVersion !== currentUserPreferencesSettingsVersion) {
    localStorage.removeItem(LOCALSTORAGE_CURRENT_USER_PREFERENCES);
    localStorage.setItem(LOCALSTORAGE_CURRENT_USER_PREFERENCES_VERSION, currentUserPreferencesSettingsVersion);
  }
}

export function useUserSettings() {
  const { userSettings, updateUserSettings } = useContext(UserSettingsContext);

  const setExperienceTableQuickAccessActions = settings => {
    updateUserSettings(EXPERIENCE_TABLE_QUICK_ACCESS_ACTIONS, settings);
  };

  const experienceTableQuickAccessActions = userSettings[EXPERIENCE_TABLE_QUICK_ACCESS_ACTIONS] || [
    experienceActions.DemoPageLink.key,
    experienceActions.Preview.key,
  ];

  return { experienceTableQuickAccessActions, setExperienceTableQuickAccessActions };
}
