const LOCALSTORAGE_PREFIX = "motionly_bo_";

export const LOCALSTORAGE_VERSION = `${LOCALSTORAGE_PREFIX}version`;
export const LOCALSTORAGE_CREDENTIALS = `${LOCALSTORAGE_PREFIX}credentials`;
export const LOCALSTORAGE_SETTINGS = `${LOCALSTORAGE_PREFIX}settings`;
export const SETTINGS_HOME = "home";
export const LOCALSTORAGE_CURRENT_USER = `${LOCALSTORAGE_PREFIX}currentUser`;
export const LOCALSTORAGE_CURRENT_USER_PREFERENCES = `${LOCALSTORAGE_PREFIX}currentUserPreferences`;
export const LOCALSTORAGE_CURRENT_USER_CUSTOMER_SUMMARY_LIST = `${LOCALSTORAGE_PREFIX}currentUserCustomersSummaryList`;
export const LOCALSTORAGE_CURRENT_CUSTOMER_DATA = `${LOCALSTORAGE_PREFIX}currentCustomerData`;
export const LOCALSTORAGE_CURRENT_CUSTOMER_USERS = `${LOCALSTORAGE_PREFIX}currentCustomerUsers`;
export const LOCALSTORAGE_CURRENT_USER_PREFERENCES_VERSION = `${LOCALSTORAGE_PREFIX}currentUserPreferencesVersion`;

export const LOCALSTORAGE_CUSTOMER_VIEW = `${LOCALSTORAGE_PREFIX}customerView`;
export const LOCALSTORAGE_CUSTOMER_ANALYTICS_VIEW = `${LOCALSTORAGE_PREFIX}customerAnalyticsView`;
export const LOCALSTORAGE_CUSTOMER_ANALYTICS_INTERVAL = `${LOCALSTORAGE_PREFIX}customerAnalyticsInterval`;

export function deleteObject(key) {
  localStorage.removeItem(key);
}

export function persistObject(key, object) {
  localStorage.setItem(key, JSON.stringify(object));
  return object;
}

export function getObject(key) {
  const object = localStorage.getItem(key);
  if (object !== undefined && object !== null) {
    return JSON.parse(object);
  }
  return undefined;
}
