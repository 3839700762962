import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./TemplatePreview.scss";
import { PageLoader, Preview } from "Legacy/components";
import { TemplatePreviewPlaceholder } from "../../../../components";

export default function TemplatePreview({ demo, onClose }) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const renderPreviewContainer = () => {
    if (loading) {
      return <PageLoader />;
    }
    return <TemplatePreviewPlaceholder title={t("templates.preview.demos")} />;
  };

  const handleOnClose = () => {
    onClose();
  };

  return demo !== undefined ? (
    <Preview descriptionUrl={demo.descriptionUrl} onLoading={setLoading} onClose={handleOnClose} />
  ) : (
    renderPreviewContainer()
  );
}
TemplatePreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  demo: PropTypes.object,
  onClose: PropTypes.func,
};
