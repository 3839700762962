import React from "react";
import { Redirect, Route } from "react-router-dom";
import * as PropTypes from "prop-types";
import { authorizationService } from "Legacy/services";
import { path, useAuth } from "Legacy/utils";

export default function ProtectedRoute({ children, authorization, ...rest }) {
  const auth = useAuth();

  const renderRoute = () => {
    if (auth.isAuthenticated()) {
      if (authorizationService.isAuthorized(authorization)) {
        return children;
      }
      return <Redirect to={{ pathname: path.UNAUTHORIZED }} />;
    }
    return <Redirect to={{ pathname: path.LOGIN, state: { from: window.location.hash } }} />;
  };

  return <Route {...rest} render={renderRoute} />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  authorization: PropTypes.number,
};
