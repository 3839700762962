import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { applicationService, helpers } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "100%";

export default function CreateApplicationModal({ onClose, open, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async formData => {
    try {
      setWorking(true);
      const newApplication = await applicationService.create(formData.publicId, formData.name.trim(), formData.comment);
      notificationService.notifySuccess(t("applications.createModal.success"));
      // We need to invoke the "setWorking" state setter before invoking the "onSuccess" callback, because the callback changes the navigation URL
      setWorking(false);
      onSuccess(newApplication);
    } catch (error) {
      notificationService.notifyError(error, t("applications.createModal.errorTitle"));
      setWorking(false);
    }
  };

  const initialFormValue = { publicId: "", name: "", comment: undefined };

  const nameMaxLength = helpers.constants.nameLengthMaximum;
  const commentMaxLength = helpers.constants.commentLengthMaximum;
  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      publicId: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_PUBLIC_ID,
        },
      ],
      name: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_MAX_LENGTH,
          length: nameMaxLength,
          message: t("fields.formValidationErrors.name", { length: nameMaxLength }),
        },
      ],
      comment: [
        {
          name: validators.IS_MAX_LENGTH,
          length: commentMaxLength,
          message: t("fields.formValidationErrors.comment", { length: commentMaxLength }),
        },
      ],
    },
    onSubmit,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const publicIdFieldProps = getFieldProps("publicId");
  const nameFieldProps = getFieldProps("name");
  const commentFieldProps = getFieldProps("comment");

  const renderForm = () => (
    <Form {...getFormProps()}>
      <fieldset disabled={working}>
        <FormControl
          id="publicId"
          type="text"
          label={t("fields.publicId")}
          placeholder={t("fields.publicIdPlaceholder")}
          tooltipText={t("fields.publicIdTooltip")}
          width={inputsWidth}
          required
          {...publicIdFieldProps}
        />
        <FormControl
          id="name"
          type="text"
          label={t("fields.name")}
          placeholder={t("fields.namePlaceholder")}
          width={inputsWidth}
          required
          {...nameFieldProps}
        />
        <FormControl
          id="comment"
          type="textarea"
          label={t("fields.comment")}
          placeholder={t("fields.commentPlaceholder")}
          width={inputsWidth}
          {...commentFieldProps}
        />
      </fieldset>
    </Form>
  );

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      title={t("applications.createModal.title")}
      open={open}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.create")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        publicIdFieldProps.value !== initialFormValue.publicId ||
        nameFieldProps.value !== initialFormValue.name ||
        commentFieldProps.value !== initialFormValue.comment
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("applications.createModal.explanation")}</Typography.P2Regular>
      {renderForm()}
    </GuardedModal>
  );
}

CreateApplicationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
