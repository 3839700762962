import { routes as experienceRoutes } from "Legacy/app/experiences";
import { routes as dashboardRoutes } from "Legacy/app/dashboard";
import { routes as motionlyRoutes } from "Legacy/app/motionly";
import { routes as templateRoutes } from "Legacy/app/templates";
import { routes as assetsRoutes } from "Legacy/app/assets";
import { routes as sitesRoutes } from "Legacy/app/sites";
import { routes as customersRoutes } from "Legacy/app/customers";
import { routes as applicationsRoutes } from "Legacy/app/applications";
import { routes as usersRoutes } from "Legacy/app/users";

// Aggregate nested routes from different apps
const routes = [
  ...dashboardRoutes,
  ...experienceRoutes,
  ...templateRoutes,
  ...assetsRoutes,
  ...sitesRoutes,
  ...customersRoutes,
  ...applicationsRoutes,
  ...usersRoutes,
  ...motionlyRoutes,
];

export { routes as default };
