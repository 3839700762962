import React, { useMemo } from "react";
import * as PropTypes from "prop-types";

import "./PageLoader.scss";
import { LoadDots } from "..";

/**
 * This is a page loader. It can work three ways :
 * - <PageLoader /> : for a loader in full page
 * - <PageLoader title="Please wait" /> : for a loader in full page, with text before the loading dots
 * - <PageLoader inline active=true /> : the loader is small and can be placed inline.
 *         When inactive, the space is still used so the UI does not jump
 * @param message
 * @param inline
 * @param active
 */
export default function PageLoader({ message, inline = false, active = true }) {
  const containerClass = useMemo(() => (inline ? "page-loader-container-inline" : "page-loader-container"), [inline]);

  return active ? (
    <div className={containerClass}>
      {message && <div className="page-loader-message">{message}</div>}
      <LoadDots />
    </div>
  ) : (
    <>{inline ? <div className={containerClass}>&nbsp;</div> : <></>}</>
  );
}

PageLoader.propTypes = {
  active: PropTypes.bool,
  inline: PropTypes.bool,
  message: PropTypes.string,
};
