import React from "react";
import * as PropTypes from "prop-types";

import "./Avatar.scss";
import { ImageWithFallback, Tooltip } from "Legacy/components";
import sampleAvatar from "../../../assets/img/sampleAvatar.png";

export default function Avatar({ src, name, compact = false }) {
  const renderCompactAvatar = () => (
    <Tooltip shortHtmlContent={name}>
      <div className="image-container">
        <ImageWithFallback src={src} alt={name} fallbackSrc={sampleAvatar} />
      </div>
    </Tooltip>
  );

  const renderAvatar = () => (
    <>
      <div className="image-container">
        <ImageWithFallback src={src} alt={name} fallbackSrc={sampleAvatar} />
      </div>
      <div className="avatar-label">{name}</div>
    </>
  );

  return <div className="avatar-container">{compact ? renderCompactAvatar() : renderAvatar()}</div>;
}

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  compact: PropTypes.bool,
};
