import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Experience, Tag } from "@ogury/motionly-ws-api/ws";
import { Link, useHistory } from "react-router-dom";

import "./ExperienceDetailGeneralPage.scss";
import { Button } from "@ogury/design-system";
import {
  Attribute,
  Avatar,
  DetailPage,
  EditableText,
  LoadDots,
  SpacedContainer,
  SpacedUnits,
  ThumbnailContainer,
  WithCopyButton,
  TemplateLink,
} from "Legacy/components";
import {
  experiencePermissions,
  experienceService,
  getExperienceRatio,
  helpers,
  metaService,
  unitService,
} from "Legacy/services";
import { disableFeature, path } from "Legacy/utils";

import { dateFormatter } from "Legacy/utils/date";
import { ExperienceRatio, ExperienceStatusTag, ExperienceUpgradeModal } from "../../components";
import {
  ExperienceDetailTags,
  ExperiencePosterButton,
  ExperiencePrivacySwitcher,
  ExperiencePublishButton,
  ExperiencePublishOverButton,
} from "./components";

const formatTimestamp = timestamp => dateFormatter.toShortDateTime(timestamp);

export default function ExperienceDetailGeneralPage({
  experience,
  experienceSite,
  reloadExperience,
  showModalUpgrade,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const [canUpgrade, setCanUpgrade] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(showModalUpgrade);

  // Get template name via its tag with category "Template"
  const experienceTemplateTagId = useMemo(() => {
    // noinspection JSUnresolvedVariable
    const templateTags = experience.tags.filter(tag => tag.category === Tag.CategoryEnum.Template);

    return templateTags.length >= 1 ? templateTags[0].id : undefined;
  }, [experience]);

  useEffect(() => {
    const load = async () => {
      // We check whether the experience is template-based and may be upgraded
      setCanUpgrade(await metaService.canUpgrade(experience));
    };
    // noinspection JSIgnoredPromiseFromCall
    load();
  }, [experience]);

  useEffect(() => {
    setShowUpgradeModal(showModalUpgrade);
  }, [showModalUpgrade]);

  const onExperienceReplaced = _experience => {
    history.push(`${path.EXPERIENCES}/${_experience.id}`);
  };

  const experienceAdUnit = useMemo(() => {
    const results = unitService.getUnitsNamesFromUnits(experience.units);
    if (results) {
      return <span>{results.join(", ")}</span>;
    }
  }, [experience]);

  const renderNameAttribute = () => (
    <Attribute name={t("fields.name")} large>
      <EditableText
        disabled={!experiencePermissions.canEditName(experience)}
        text={experience?.name}
        large={false}
        maximumLength={helpers.constants.experienceNameLengthMaximum}
        update={async value => {
          await experienceService.updateName(experience.id, value);
          reloadExperience();
          return t("experiences.generalTab.updateNameSuccess");
        }}
      />
    </Attribute>
  );

  const onExperienceUpgraded = () => {
    setShowUpgradeModal(false);
    reloadExperience();
  };

  const renderCommentAttribute = () => (
    <Attribute
      name={t("fields.comment")}
      value={
        <EditableText
          text={experience?.comment}
          large
          disabled={experiencePermissions.canEditComment(experience) === false}
          maximumLength={helpers.constants.commentLengthMaximum}
          update={async value => {
            await experienceService.updateComment(experience.id, value);
            reloadExperience();
            return t("experiences.generalTab.updateCommentSuccess");
          }}
        />
      }
    />
  );

  function renderSiteAttribute() {
    function renderLink() {
      if (experienceSite) {
        return (
          <Link className="link" to={`${path.SITES}/${experienceSite.id}`}>
            {experienceSite.name}
          </Link>
        );
      }
      return <LoadDots />;
    }
    if (experienceService.isInSite(experience)) {
      return <Attribute name={t("fields.site")}>{renderLink()}</Attribute>;
    }
  }

  // noinspection JSUnresolvedVariable
  const ratio = getExperienceRatio(experience);
  return (
    <DetailPage className="page-detail-body">
      <ExperienceUpgradeModal
        open={canUpgrade === true && showUpgradeModal && experiencePermissions.canUpgrade(experience)}
        experience={experience}
        onClose={() => setShowUpgradeModal(false)}
        onSuccess={onExperienceUpgraded}
      />
      <SpacedContainer className="left-column" gap={SpacedUnits.Small}>
        <ThumbnailContainer experienceId={experience.id} url={experience.thumbnailUrl} />
        <ExperiencePosterButton experience={experience} onPosterSucceed={reloadExperience} />
      </SpacedContainer>
      <SpacedContainer className="right-column">
        {renderNameAttribute()}

        <SpacedContainer gap={SpacedUnits.Large} horizontal center>
          <Attribute>
            <ExperienceStatusTag status={experience.state} />
          </Attribute>
          {experiencePermissions.couldBePublished(experience) && (
            <>
              <ExperiencePublishButton
                experience={experience}
                disabled={experiencePermissions.canPublish(experience) === false}
                onExperiencePublished={reloadExperience}
              />
              <ExperiencePublishOverButton
                experience={experience}
                disabled={experiencePermissions.canPublishOver(experience) === false}
                onExperienceReplaced={onExperienceReplaced}
              />
            </>
          )}
          {/*    {experiencePermissions.couldBeArchived(experience) && (
            <ExperienceArchiveButton
              experience={experience}
              disabled={experiencePermissions.canArchive(experience) === false}
              onExperienceArchived={reloadExperience}
            />
          )} */}
        </SpacedContainer>
        {renderCommentAttribute()}
        <Attribute name={t("fields.owner")}>
          <Avatar src={experience.owner.avatarUrl} name={experience.owner.name ?? t("users.notPresentInCustomer")} />
        </Attribute>
        {disableFeature && (
          <Attribute name={t("experiences.generalTab.privacy")}>
            <ExperiencePrivacySwitcher experience={experience} onPrivacyChanged={reloadExperience} />
          </Attribute>
        )}
        <Attribute name={t("fields.id")} value={<WithCopyButton text={experience.id} />} />
        {renderSiteAttribute()}

        {experience.origin === Experience.OriginEnum.FromTemplate ||
        experience.origin === Experience.OriginEnum.Dynamic ? (
          <Attribute name={t("experiences.generalTab.template")}>
            <TemplateLink tagId={experienceTemplateTagId} />
          </Attribute>
        ) : (
          <Attribute
            name={t("experiences.generalTab.features")}
            value={experienceService.getOriginsLabels(t)[experience.origin]}
          />
        )}
        {experienceAdUnit !== undefined && (
          <Attribute name={t("experiences.properties.adUnit")} value={experienceAdUnit} />
        )}
        {ratio !== undefined && (
          <Attribute name={t("fields.ratio")} value={<ExperienceRatio ratio={ratio} multiline={false} />} />
        )}
        {(experience.origin === Experience.OriginEnum.FromTemplate ||
          experience.origin === Experience.OriginEnum.Dynamic) && (
          <Attribute name={t("experiences.generalTab.templateVersion")}>
            <span className="template-version">{experience.data?.templateVersion}</span>
            {canUpgrade === true && experiencePermissions.canUpgrade(experience) && (
              <Button onClick={() => setShowUpgradeModal(true)} type="primary" size="small">
                {t("actions.upgrade")}
              </Button>
            )}
          </Attribute>
        )}
        <Attribute name={t("fields.updateDate")} value={formatTimestamp(experience.updateDate)} />
        <Attribute name={t("fields.creationDate")} value={formatTimestamp(experience.creationDate)} />
        <hr />
        <ExperienceDetailTags experience={experience} tags={experience.tags} onTagsUpdated={reloadExperience} />
      </SpacedContainer>
    </DetailPage>
  );
}

ExperienceDetailGeneralPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  experienceSite: PropTypes.object,
  reloadExperience: PropTypes.func.isRequired,
};
