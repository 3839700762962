import React from "react";
import { useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "Legacy/components";
import { CustomerDetailPage } from "./pages";

export default function Customers() {
  const { url } = useRouteMatch();

  return (
    <div>
      <ProtectedRoute path={`${url}`}>
        <CustomerDetailPage />
      </ProtectedRoute>
    </div>
  );
}
