import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalClosableFooter, Typography } from "@ogury/design-system";

export default function LogoutConfirmationModal({ open, onCancel, onConfirm }) {
  const [t] = useTranslation();

  return (
    <Modal
      open={open}
      title={t("navbar.user.logoutConfirmationModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button type="primary" onClick={onConfirm}>
              {t("navbar.user.logoutConfirmationModal.action")}
            </Button>
          }
        />
      }
      onCancel={onCancel}
    >
      <Typography.P2Regular>{t("navbar.user.logoutConfirmationModal.explanation")}</Typography.P2Regular>
    </Modal>
  );
}

LogoutConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
