import React from "react";
import * as PropTypes from "prop-types";

import "./Attribute.scss";
import { SpacedContainer, SpacedUnits } from "../Layout";

export default function Attribute({ name = "", value = "", large = false, children }) {
  const renderValue = () => {
    if (value) {
      return <div className="attribute-value">{value}</div>;
    }
    if (large === true) {
      return <h1 className="h2">{children}</h1>;
    }
    return <div>{children}</div>;
  };
  return (
    <SpacedContainer className="attribute-container" gap={SpacedUnits.XXSmall}>
      {name && <div className="attribute-name">{name}</div>}
      {renderValue()}
    </SpacedContainer>
  );
}

Attribute.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
  large: PropTypes.bool,
  children: PropTypes.node,
};
