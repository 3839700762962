import { useReducer } from "react";
import { useTranslation } from "react-i18next";

/*
Events management :

- mergeFields : when the validation contract changes : which fields, which rules
  - extract data of state (errors, formValues, dirtyFields, fieldsValidity) only for the new fields list
  - Reset initial value for non-dirty fields (it sets initial value for new fields as well

- onChange :
  + change state :
    - set field value
    - set dirty = true
    - set submitted = false
    - [BLUR ONLY] remove validity on field
    - [BLUR ONLY] unset error on field

- onBlur [BLUR ONLY] :
  - if dirty : validate field -> errorMessage
  + change state :
    - set errorMessage
    - set validity

- onSubmit :
  - validate all fields -> error messages
  + change state :
    - set error messages on fields
    - set validity on fields
    - set submitted = true
*/

/**
 * Validates a field against several validators.
 * When at least one validator returns an error message, validation is stopped.
 * @param fieldValue
 * @param fieldValidatorsConfig
 * @returns {Promise<*>}
 */
function validateField(fieldValue, fieldValidatorsConfig) {
  // Validation runs until an error message is found or all validators are executed
  let message;
  if (fieldValidatorsConfig !== undefined) {
    let index = 0;
    while (!message && index < fieldValidatorsConfig.length) {
      const validatorConfig = fieldValidatorsConfig[index];
      const validator = validatorConfig.name;
      const configuredValidator = validator(validatorConfig);
      message = configuredValidator(fieldValue);
      // eslint-disable-next-line no-plusplus
      index++;
    }
  }
  return message;
}

/**
 * Validates all form fields against their registered validators.
 * @param fields
 * @param values
 * @returns {*}
 */
function validateFields(fields, values) {
  const errors = {};

  Object.keys(fields).forEach(fieldName => {
    const fieldValidatorsConfig = fields[fieldName];
    const fieldValue = values[fieldName];
    const message = validateField(fieldValue, fieldValidatorsConfig);
    if (message) {
      errors[fieldName] = message;
    }
  });
  return errors;
}

/**
 * Compute the form initial state
 * @param initialValue
 * @param fields
 * @param showErrors
 * @returns Object
 */
const initialState = ({ initialValue = {}, fields, showErrors }) => {
  // We must declare all fields into initialValue
  Object.keys(fields).forEach(field => {
    if (Object.keys(initialValue).indexOf(field) < 0) {
      // eslint-disable-next-line no-console
      console.error(`useFormValidation : the field '${field}' has not been declared into initialValue`);
    }
  });

  const values = {};
  const fieldsNames = Object.keys(fields);
  fieldsNames.forEach(fieldName => {
    values[fieldName] = initialValue[fieldName];
  });
  return {
    initialValue,
    showErrors,
    fieldsNames,
    values,
    errors: {},
    dirtyFields: {},
    fieldsValidity: {},
    submitted: false,
  };
};

/**
 * Reducer to mutate the form state
 * @param state
 * @param action
 * @returns Object
 */
function validationReducer(state, action) {
  switch (action.type) {
    case "mergeFields": {
      const { fields, initialValue } = action.payload;
      // sync these fields with old ones
      // keep values, blurred and dirty fields only among the new list
      const fieldsNames = Object.keys(fields);

      const errors = {};
      const formValues = {};
      const dirtyFields = {};
      const fieldsValidity = {};
      fieldsNames.forEach(field => {
        // Set initial value if field is not dirty
        formValues[field] = !state.dirtyFields[field] ? initialValue[field] : state.values[field];
        dirtyFields[field] = !!state.dirtyFields[field];

        if (state.errors[field]) {
          errors[field] = state.errors[field];
        }
        if (state.fieldsValidity[field]) {
          fieldsValidity[field] = state.fieldsValidity[field];
        }
      });
      return { ...state, fieldsNames, values: formValues, dirtyFields, errors, fieldsValidity, initialValue };
    }
    case "change": {
      const { fieldName, value } = action.payload;
      const result = {
        ...state,
        values: { ...state.values, [fieldName]: value },
        dirtyFields: { ...state.dirtyFields, [fieldName]: true },
        submitted: false,
      };

      // Remove validity and errors for the field if mode = blur
      if (state.showErrors === "blur") {
        const fieldsValidity = { ...state.fieldsValidity };
        delete fieldsValidity[fieldName];
        const errors = { ...state.errors };
        delete errors[fieldName];
        result.fieldsValidity = fieldsValidity;
        result.errors = errors;
      }
      return result;
    }
    case "blur": {
      // This case is used only if mode = blur and current field is dirty.
      const { fieldName, errorMessage } = action.payload;

      const errors = { ...state.errors };
      const fieldsValidity = { ...state.fieldsValidity };
      errors[fieldName] = errorMessage;
      fieldsValidity[fieldName] = errors[fieldName] ? "invalid" : "";
      return { ...state, fieldsValidity, errors };
    }
    case "submit": {
      const validationErrors = action.payload;
      const fieldsValidity = {};
      state.fieldsNames.forEach(field => {
        fieldsValidity[field] = validationErrors[field] ? "invalid" : "";
      });
      return { ...state, submitted: true, errors: validationErrors, fieldsValidity };
    }
    case "reset": {
      return initialState(action.payload);
    }
    default:
      throw new Error("Unknown action type");
  }
}

/**
 * Trigger a merge only the fields have changed
 * !! Assumes that the fields keep their order
 * !! Does not check if validators change
 * @param oldFields
 * @param newFields
 * @returns {boolean}
 */
function fieldsHaveChanged(oldFields, newFields) {
  return JSON.stringify(Object.keys(newFields)) !== JSON.stringify(oldFields);
}

/**
 * If initialValue has changed, it means we want to set a value to the form from outside
 * So we need to reinit the state with the new value
 * @param oldValue
 * @param newValue
 * @returns {boolean}
 */
function initialValueHasChanged(oldValue, newValue) {
  const oldFieldsNames = Object.keys(oldValue).sort();
  const newFieldsNames = Object.keys(newValue).sort();
  if (JSON.stringify(oldFieldsNames) === JSON.stringify(newFieldsNames)) {
    return newFieldsNames.some(fieldName => oldValue[fieldName] !== newValue[fieldName]);
  }
  return true;
}

/**
 * The useValidation hook can be used to setup forms validation.
 * Given a fields configuration, it returns the onSubmit callback
 * and form fields props such as onChange, name, value, isValid
 * @param config The form configuration must follow the following format:
 *
 * const formConfig = {
    fields: {
      field1: [
        { name: validators.IS_REQUIRED, message: 'A name is required' }
      ],
      field2: [],
      field3: []
    },
    showErrors: 'blur',
    onSubmit: saveModavalidateFieldl
  };
 *
 * Built-in validators and custom can be used.
 * To define a custom validator, implements this function signature:
 * const myValidator = value => {
 *   // Validate something...
 *   return message
 * };
 *
 * @returns Object
 */
const useFormValidation = ({ initialValue = {}, fields = {}, showErrors = "blur", onSubmit = () => {}, onChange }) => {
  const [state, dispatch] = useReducer(
    validationReducer,
    initialState({ initialValue, fields, showErrors }),
    undefined
  );
  const [t] = useTranslation();

  // Trigger a merge if the contract does not have the same fields as before
  if (fieldsHaveChanged(state.fieldsNames, fields)) {
    dispatch({ type: "mergeFields", payload: { fields, initialValue } });
  }

  // Trigger a form reset if the initial value is a new one
  if (initialValueHasChanged(state.initialValue, initialValue)) {
    dispatch({ type: "reset", payload: { initialValue, fields, showErrors } });
  }

  return {
    state,
    getFormProps: () => ({
      onSubmit: (...args) => {
        const errors = validateFields(fields, state.values);

        dispatch({ type: "submit", payload: errors });

        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          onSubmit(state.values, ...args);
        }
      },
    }),
    validate: () => {
      const errors = validateFields(fields, state.values);
      dispatch({ type: "submit", payload: errors });
    },
    setFieldValue: (fieldName, value) => {
      dispatch({ type: "change", payload: { fieldName, value } });
    },
    getFieldProps: fieldName => ({
      onChange: value => {
        if (fields[fieldName]) {
          dispatch({ type: "change", payload: { fieldName, value } });
          if (onChange !== undefined) {
            onChange({ ...state.values, [fieldName]: value }, fieldName, value);
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`[onChange] The field ${fieldName} is not registered into the form`);
        }
      },
      onBlur: () => {
        if (fields[fieldName]) {
          if (state.showErrors === "blur" && state.dirtyFields[fieldName]) {
            // In mode = blur, validate field only if dirty
            const errorMessage = validateField(state.values[fieldName], fields[fieldName]);
            dispatch({ type: "blur", payload: { fieldName, errorMessage } });
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`[onBlur] The field ${fieldName} is not registered into the form`);
        }
      },
      name: fieldName,
      value: state.values[fieldName],
      error: t(state.errors[fieldName]),
      validity: state.fieldsValidity[fieldName],
    }),
    resetForm: () => {
      dispatch({ type: "reset", payload: { initialValue, fields, showErrors } });
    },
  };
};

export default useFormValidation;
