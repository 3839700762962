import React from "react";
import * as PropTypes from "prop-types";

import "./PreviewPlaceholder.scss";

export default function PreviewPlaceholder({ disabled, children }) {
  return <div className={`preview-placeholder${disabled === true ? "" : " hover"}`}>{children}</div>;
}

PreviewPlaceholder.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
