import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "utils";
import { helpers, siteService } from "services";
import { AutoComplete, Form, Input } from "@ogury/design-system";

const useDebouncedSearch = (serviceMethod, setOptions) => {
  const debouncedCallback = useDebouncedCallback(async searchText => {
    const entities = await serviceMethod(searchText);
    setOptions(entities);
  }, 300);

  return async searchText => {
    if (searchText?.length >= 1) {
      debouncedCallback(searchText);
    }
  };
};

export default function SiteInformationForm({ site, isValid, onChange }) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  const [accountOptions, setAccountOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [accountSelected, setAccountSelected] = useState();
  const [brandSelected, setBrandSelected] = useState();

  const handleOnSearchAccount = useDebouncedSearch(siteService.listAccounts, setAccountOptions);
  const handleOnSearchBrand = useDebouncedSearch(siteService.listBrands, setBrandOptions);

  useEffect(() => {
    isValid(submittable);
  }, [submittable]);

  useEffect(() => {
    if (values && Object.keys(values)?.length > 0) {
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          setSubmittable(true);
        })
        .catch(() => setSubmittable(false));
    }
  }, [form, values]);

  useEffect(() => {
    if (accountSelected && brandSelected && values?.name) {
      onChange({ name: values.name, brand: brandSelected, account: accountSelected });
    }
  }, [values?.name, accountSelected, brandSelected]);

  useEffect(() => {
    if (site && form) {
      form.setFieldValue("name", site.name);
      setAccountSelected(site.account);
      setBrandSelected(site.brand);
    }
  }, [site, form]);

  return (
    <Form form={form}>
      <Form.Item
        rules={[
          { required: true },
          {
            pattern: new RegExp(`^.{0,${helpers.constants.siteNameLengthMaximum}}$`),
            message: t("fields.formValidationErrors.name", { length: helpers.constants.siteNameLengthMaximum }),
          },
        ]}
        validateTrigger="onBlur"
        required
        name="name"
        label={t("fields.name")}
        defaultValue={""}
      >
        <Input placeholder={t("fields.namePlaceholder")} />
      </Form.Item>
      <Form.Item
        label={t("fields.account")}
        required
        name="account"
        normalize={value => accountOptions.find(option => option.id === value)?.name}
        rules={[{ required: true }]}
        initialValue={site?.account?.name}
      >
        <AutoComplete
          placeholder={t("fields.accountPlaceholder")}
          onSearch={handleOnSearchAccount}
          onSelect={(value, option) => setAccountSelected({ id: option.value, name: option.label })}
          onClear={() => {
            form.setFieldValue("account", undefined);
            setAccountOptions([]);
          }}
          options={accountOptions.map(option => ({ value: option.id, label: option.name }))}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={t("fields.brand")}
        required
        name="brand"
        normalize={value => brandOptions.find(option => option.id === value)?.name}
        rules={[{ required: true }]}
        initialValue={site?.brand?.name}
      >
        <AutoComplete
          placeholder={t("fields.brandPlaceholder")}
          onSearch={handleOnSearchBrand}
          onSelect={(value, option) => setBrandSelected({ id: option.value, name: option.label })}
          onClear={() => {
            form.setFieldValue("brand", undefined);
            setBrandOptions([]);
          }}
          options={brandOptions.map(option => ({ value: option.id, label: option.name }))}
          allowClear
        />
      </Form.Item>
    </Form>
  );
}
