import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";

import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";
import { helpers } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function CallbacksModal({ title, explanation, javaScriptCode, fileName, onClose, open }) {
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const onSubmit = async () => {
    try {
      setWorking(true);
      FileSaver.saveAs(new Blob([javaScriptCode], { type: helpers.javaScriptMimeType }), fileName);
      onClose();
    } finally {
      setWorking(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      open={open}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button loading={working} onClick={onSubmit}>
              {t("actions.download")}
            </Button>
          }
        />
      }
      title={title}
      locked={working}
    >
      {explanation}
    </Modal>
  );
}

CallbacksModal.propTypes = {
  title: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  javaScriptCode: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
