import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./TemplateCreateDynamicModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { experienceService, helpers } from "Legacy/services";
import { path, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function TemplateCreateDynamicModal({ open, template, onCancel, onCreateSucceed }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const history = useHistory();
  const [working, setWorking] = useState(false);

  const onCreateExperience = async formValue => {
    setWorking(true);
    try {
      const experience = await experienceService.createDynamic(
        formValue.name,
        formValue.comment,
        template.id,
        template.version,
        parseFloat(formValue.ratio),
        formValue.inputsUrl
      );
      onCreateSucceed();
      notificationService.notifySuccess(t("templates.createDynamicModal.creationSuccess"));
      history.push(`${path.EXPERIENCES}/${experience.id}`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(
    () => ({
      name: "",
      comment: "",
      ratio: 1,
      inputsUrl: "",
    }),
    []
  );

  const nameMaxLength = helpers.constants.experienceNameLengthMaximum;
  const commentMaxLength = helpers.constants.commentLengthMaximum;

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.IS_MAX_LENGTH,
          length: nameMaxLength,
          message: t("experiences.formValidationErrors.name", { length: nameMaxLength }),
        },
      ],
      comment: [
        {
          name: validators.IS_MAX_LENGTH,
          length: commentMaxLength,
          message: t("experiences.formValidationErrors.comment", { length: commentMaxLength }),
        },
      ],
      ratio: [
        { name: validators.IS_REQUIRED },
        {
          name: validators.CUSTOM,
          validator: value =>
            parseFloat(value) <= 0 ? t("templates.createDynamicModal.formValidationErrors.ratioError") : "",
        },
      ],
      inputsUrl: [{ name: validators.IS_REQUIRED }, { name: validators.IS_URL, t }],
    },
    onSubmit: onCreateExperience,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const nameFieldProps = getFieldProps("name");
  const commentFieldProps = getFieldProps("comment");
  const ratioFieldProps = getFieldProps("ratio");
  const inputsUrlFieldProps = getFieldProps("inputsUrl");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("templates.createDynamicModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" onClick={() => getFormProps().onSubmit()} loading={working}>
              {t("actions.create")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        nameFieldProps.value !== initialFormValue.name ||
        commentFieldProps.value !== initialFormValue.comment ||
        ratioFieldProps.value !== initialFormValue.ratio ||
        inputsUrlFieldProps.value !== initialFormValue.inputsUrl
          ? t("components.guardedModal.closeQuestion")
          : undefined
      }
      onClose={onCancel}
    >
      <Typography.P2Regular as="p">{t("templates.createDynamicModal.explanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <fieldset disabled={working}>
          <FormControl
            id="name"
            label={t("fields.name")}
            placeholder={t("fields.namePlaceholder")}
            type="text"
            required
            {...nameFieldProps}
          />
          <FormControl
            id="comment"
            label={t("fields.comment")}
            placeholder={t("fields.commentPlaceholder")}
            type="textarea"
            {...commentFieldProps}
          />
          <FormControl
            id="ratio"
            type="number"
            label={t("fields.ratio")}
            placeholder={t("fields.ratioPlaceholder")}
            required
            {...ratioFieldProps}
          />
          <FormControl
            id="inputsUrl"
            label={t("fields.inputsUrl")}
            placeholder={t("fields.urlPlaceholder")}
            type="text"
            required
            {...inputsUrlFieldProps}
          />
        </fieldset>
      </Form>
    </GuardedModal>
  );
}

TemplateCreateDynamicModal.propTypes = {
  template: PropTypes.object,
  onCancel: PropTypes.func,
  onCreateSucceed: PropTypes.func,
  open: PropTypes.bool,
};
