import React from "react";
import * as PropTypes from "prop-types";
import { Pagination } from "@ogury/design-system";

export default function Pager({
  displaySizeSelector = true,
  displayResultsNumber = true,
  pageNumber = 1,
  total,
  pageSize,
  onPagerChange = () => {},
}) {
  return (
    <Pagination
      pageSize={pageSize}
      currentPage={pageNumber}
      total={total}
      onChange={(pageNumber, pageSize) => onPagerChange(pageSize, pageNumber)}
      showSizeChanger={displaySizeSelector}
      showTotal={displayResultsNumber}
    />
  );
}

Pager.propTypes = {
  displaySizeSelector: PropTypes.bool,
  displayResultsNumber: PropTypes.bool,
  pageNumber: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  onPagerChange: PropTypes.func,
};
