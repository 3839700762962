import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import { ProtectedRoute } from "Legacy/components";
import { SitesDetailPage, SitesPage } from "./pages";

export default function Sites() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={url} exact>
        <SitesPage />
      </ProtectedRoute>
      <ProtectedRoute path={`${url}/:siteId`}>
        <SitesDetailPage />
      </ProtectedRoute>
    </Switch>
  );
}
