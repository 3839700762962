import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ModalClosableFooter } from "@ogury/design-system";
import { ExperienceThumbnail, Form, FormControl, GuardedModal, SpacedContainer, SpacedUnits } from "Legacy/components";
import { useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { experienceService, siteService, unitService } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const inputsWidth = "100%";

export default function ExperienceAssignAdUnitModal({ open, experience, onClose, onSuccess }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [adUnitsOptions, setAdUnitsOptions] = useState(
    unitService.list().map(unit => ({ value: unit.technicalId, label: unit.name }))
  );
  const [containerPolicyOptions, setContainerPolicyOptions] = useState([]);

  const onSubmit = async formData => {
    try {
      setWorking(true);
      await experienceService.assignAdUnit(experience.id, formData.units, formData.containerPolicy);
      notificationService.notifySuccess(t("experiences.assignAdUnitModal.success"));
      onSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("experiences.assignAdUnitModal.errorTitle"));
    } finally {
      setWorking(false);
    }
  };

  const initialFormValue = useMemo(() => {
    if (!open) {
      return { units: undefined, containerPolicy: undefined };
    }

    if (experience?.units?.length >= 1) {
      return { units: experience.units[0].technicalId, containerPolicy: experience.units[0].containerPolicy };
    }
    return { units: undefined, containerPolicy: undefined };
  }, [experience?.units, open]);

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      units: [{ name: validators.IS_REQUIRED }],
      containerPolicy: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const unitsFieldProps = getFieldProps("units");
  const containerPolicyFieldProps = getFieldProps("containerPolicy");

  async function load() {
    const isInSite = experienceService.isInSite(experience);
    const experienceUnit = experience.units ? experience.units[0] : null;

    let existingUnitsInSiteTechnicalIds = [];

    if (isInSite) {
      const site = await siteService.getExperienceSite(experience.id);
      existingUnitsInSiteTechnicalIds = site.units?.map(unit => unit.technicalId);
    }
    let availableUnits = await unitService.getUnitsForSpecificRatio(experience.ratio);
    if (!availableUnits?.length) {
      availableUnits = unitService.list();
    }
    const options = availableUnits
      .filter(unit => {
        if (experienceUnit?.technicalId === unit.technicalId) {
          return true;
        }
        return !existingUnitsInSiteTechnicalIds.includes(unit.technicalId);
      })
      .map(unit => ({
        label: unit.name,
        value: unit.technicalId,
      }));
    setAdUnitsOptions(options);
    const allAdUnits = unitService.list();
    const policies = new Set();

    allAdUnits.forEach(adUnit => {
      adUnit.properties.forEach(property => {
        policies.add(property.containerPolicy);
      });
    });

    const policyOptions = Array.from(policies).map(policy => ({
      label: policy,
      value: policy,
    }));

    setContainerPolicyOptions(policyOptions);
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    load();
  }, [open]); // eslint-disable-line

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  const renderForm = () => (
    <Form {...getFormProps()}>
      <fieldset disabled={working}>
        <FormControl
          id="name"
          type="select"
          label={t("fields.adUnit")}
          placeholder={t("fields.adUnitPlaceholder")}
          options={adUnitsOptions}
          width={inputsWidth}
          required
          {...unitsFieldProps}
        />
        <FormControl
          type="select"
          label={t("fields.containerPolicy")}
          placeholder={t("fields.containerPolicyPlaceholder")}
          options={containerPolicyOptions}
          width={inputsWidth}
          required
          {...containerPolicyFieldProps}
        />
      </fieldset>
    </Form>
  );

  return (
    <GuardedModal
      open={open}
      title={t("experiences.assignAdUnitModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={working}
              disabled={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.assignAdUnit")}
            </Button>
          }
        />
      }
      canBeClosed={() =>
        unitsFieldProps.value !== initialFormValue.units ? t("components.guardedModal.closeQuestion") : undefined
      }
      onClose={onClose}
      width={ModalWidths.Medium}
    >
      <SpacedContainer gap={SpacedUnits.Small}>
        <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
        {renderForm()}
      </SpacedContainer>
    </GuardedModal>
  );
}

ExperienceAssignAdUnitModal.propTypes = {
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
