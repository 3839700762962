// TODO replace with the API Experience.IntegrationEnum
export const Environment = {
  Web: "Web",
  ECommerce: "ECommerce",
  HTML5Bundle: "HTML5Bundle",
  ThirdParty: "ThirdParty",
  VPAID: "Vpaid",
};

// TODO replace with the API ExperienceCodeGenerator.SolutionVendor
// import BundleType from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/SolutionVendor";
export const SolutionVendor = {
  // SolutionVendor.Default
  Default: "Default",
  // TODO: add the "Ogury" enumerated value
  // SolutionVendor.Ogury
  Ogury: "Ogury",
  // SolutionVendor.GoogleDV360
  GoogleDV360: "GoogleDV360",
  // SolutionVendor.Xandr
  Xandr: "Xandr",
  // SolutionVendor.TheTradeDesk
  TheTradeDesk: "TheTradeDesk",
  // SolutionVendor.AdForm
  AdForm: "AdForm",
  // SolutionVendor.Adelphic
  Adelphic: "Adelphic",
  // SolutionVendor.Amobee
  Amobee: "Amobee",
  // SolutionVendor.Avocet
  Avocet: "Avocet",
  // SolutionVendor.Beeswax
  Beeswax: "Beeswax",
  // SolutionVendor.Dataxu
  Dataxu: "Dataxu",
  // SolutionVendor.MediaMath
  MediaMath: "MediaMath",
  // SolutionVendor.OathBrightRoll
  OathBrightRoll: "OathBrightRoll",
  // SolutionVendor.Smadex
  Smadex: "Smadex",
  // SolutionVendor.Stackadapt
  Stackadapt: "Stackadapt",
  // SolutionVendor.TaptapSonata
  TaptapSonata: "TaptapSonata",
};

export function computeValue(value, lowerCaseKey) {
  return lowerCaseKey === true ? value.toLowerCase() : value;
}

export function computeValueLabel(enumType, key, lowerCaseKey, labelComputer) {
  const value = computeValue(enumType[key], lowerCaseKey);
  return {
    key,
    label: labelComputer === undefined ? enumType[key] : labelComputer(value),
    value,
  };
}

export function computeValueLabelWithLabels(enumType, labels, key) {
  return {
    key,
    label: labels[enumType[key]],
    value: enumType[key],
  };
}

export function computeValueLabels(enumType, lowerCaseKey, labelComputer) {
  return Object.keys(enumType).map(key => computeValueLabel(enumType, key, lowerCaseKey, labelComputer));
}
