import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { AddExperienceDrawer, Builder, UploadExperienceBundleModal } from "Legacy/components";
import { experiencePermissions, experienceService, siteService } from "Legacy/services";
import { SiteEditionModal, SitePublishModal } from "../../components";
import { SiteDetailHeader, SitesExperiencesTable } from "./components";
import style from "./SitesDetailPage.module.scss";

export default function SitesDetailPage() {
  const { siteId } = useParams();

  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState({});
  const [experiences, setExperiences] = useState([]);
  const [showAddExperienceDrawer, setShowAddExperienceDrawer] = useState(false);
  const [showUploadExperienceBundleModal, setShowUploadExperienceBundleModal] = useState(false);
  const [showSitePublishModal, setShowSitePublishModal] = useState(false);
  const [showEditSiteModal, setShowEditSiteModal] = useState(false);
  const [builderData, setBuilderData] = useState();
  const metaData = useRef({ site: undefined });

  const clickAddExperience = () => {
    if (experiencePermissions.canCreate()) {
      setShowAddExperienceDrawer(true);
    }
  };

  const handleOnDuplicateExperience = async (experience, destination) => {
    const { site, adUnit } = destination;
    metaData.current = {
      site,
    };

    setBuilderData({ fromExperienceId: experience.id, adUnitTechnicalId: adUnit, experienceName: experience.name });
  };

  async function load() {
    try {
      setLoading(true);
      const siteResponse = await siteService.get(siteId);
      const experienceResponse = await experienceService.getExperiencesSummaryList(siteResponse.experienceIds, {
        hydrateTags: true,
      });
      setSite(siteResponse);
      metaData.current.site = siteResponse;
      setExperiences(experienceResponse);
    } catch (error) {
      // TODO display error
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    load();
    document.getElementById("root").classList.add("hide-navbar");
    return () => document.getElementById("root").classList.remove("hide-navbar");
    // Trace to remember that there is a class "hide-navbar" that once applied to DOM element with id="root", hides the main navbar.
  }, [siteId]); //eslint-disable-line

  function renderPageBody() {
    return (
      <SitesExperiencesTable
        loading={loading}
        site={site}
        experiences={experiences}
        onRefresh={load}
        onAdd={clickAddExperience}
        onDuplicateExperience={handleOnDuplicateExperience}
      />
    );
  }

  const handleOnUploadBundle = async () => {
    setShowAddExperienceDrawer(false);
    await load();
  };

  const handleOnAddExperience = (templateId, adUnitTechnicalId, experienceName) => {
    setBuilderData({ templateId, adUnitTechnicalId, experienceName });
    setShowAddExperienceDrawer(false);
  };

  const handleOnCloseBuilder = () => {
    setBuilderData();
    metaData.current = {};
    load();
  };

  return (
    <>
      {builderData && <Builder {...builderData} siteId={site?.id} onClose={handleOnCloseBuilder} />}
      <AddExperienceDrawer
        open={showAddExperienceDrawer}
        onClose={() => setShowAddExperienceDrawer(false)}
        siteData={site}
        withAdunit
        onSuccessTemplate={handleOnAddExperience}
        onSuccessImport={handleOnUploadBundle}
      />
      <SiteEditionModal
        open={showEditSiteModal}
        site={site}
        onClose={() => setShowEditSiteModal(false)}
        onSuccess={load}
      />
      <UploadExperienceBundleModal
        open={showUploadExperienceBundleModal}
        onClose={() => setShowUploadExperienceBundleModal(false)}
        attachToSite={site}
        onSuccess={handleOnUploadBundle}
      />
      <SitePublishModal
        open={showSitePublishModal}
        onClose={() => setShowSitePublishModal(false)}
        site={site}
        onSuccess={load}
      />
      <SiteDetailHeader
        site={site}
        loading={loading}
        onClickAddExperience={clickAddExperience}
        onClickEditSite={() => setShowEditSiteModal(true)}
        onClickPublishSite={() => setShowSitePublishModal(true)}
      />
      <div className={style.container}>{renderPageBody()}</div>
    </>
  );
}
