import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import "./PasswordForgottenPage.scss";
import { Button } from "@ogury/design-system";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import { path, useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { Form, FormControl } from "Legacy/components";
import { authenticationService } from "Legacy/services";
import { AuthFormContainer, PublicLayout } from "../../components";

export default function PasswordForgottenPage() {
  const { state } = useLocation();
  const history = useHistory();
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const initialFormValue = {
    email: state?.email || "",
  };

  const redirectUser = emailValue => {
    const to = history.location?.state?.from;
    history.push({
      pathname: to || path.LOGIN,
      state: {
        email: emailValue,
      },
    });
  };

  const sendResetLink = async formValue => {
    setWorking(true);
    try {
      await authenticationService.forgotPassword(formValue.email);
      notificationService.notifySuccess(t("auth.passwordForgotten.success"));
      redirectUser(formValue.email);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      email: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: sendResetLink,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);

  const handleOnClickGoBack = () => {
    redirectUser(getFieldProps("email").value);
  };

  return (
    <PublicLayout>
      <div className="password-forgotten-page">
        <AuthFormContainer>
          <div className="auth-form-header">
            <div className="back-button">
              <Button iconPosition="iconOnly" icon={<ArrowLeftSLineIcon />} onClick={handleOnClickGoBack} />
            </div>
            <h1>Forgotten password</h1>
          </div>

          <Form {...getFormProps()}>
            <FormControl
              id="email"
              label={t("fields.email")}
              placeholder={t("fields.emailPlaceholder")}
              required
              {...getFieldProps("email")}
            />

            <div className="auth-form-actions">
              <Button block type="primary" loading={working} submit>
                {t("auth.passwordForgotten.send")}
              </Button>
            </div>
          </Form>
        </AuthFormContainer>
      </div>
    </PublicLayout>
  );
}
