import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ModalClosableFooter } from "@ogury/design-system";

import { Form, FormControl, GuardedModal, ModalHeights } from "Legacy/components";
import { useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { experienceService } from "Legacy/services";
import { controlTypes } from "Legacy/components/Form/FormControl/FormControl";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import "./ExperienceExternalTrackingModal.scss";

const initialFormData = {
  default: "",
};

export default function ExperienceExternalTrackingModal({ open, experience, onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [isRequesting, setIsRequesting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (!open) {
      return;
    }

    setIsRequesting(true);
    experienceService
      .getTracking(experience.id)
      .then(response => {
        if (response?.clicks && response.clicks.length > 0) {
          setFormData(
            response.clicks?.reduce((acc, { id, url }) => {
              acc[id] = url;
              return acc;
            }, {})
          );
        }
      })
      .catch(error => notificationService.notifyError(error))
      .finally(() => setIsRequesting(false));
  }, [open, experience]);

  const handleOnFormSubmit = form => {
    setIsRequesting(true);
    experienceService
      .setMultipleTrackingClickURL(
        experience.id,
        Object.entries(form).map(([id, url]) => ({ id, url }))
      )
      .then(() => {
        notificationService.notifySuccess(t("experiences.externalTrackingModal.success"));
        onClose();
      })
      .catch(error => notificationService.notifyError(error))
      .finally(() => setIsRequesting(false));
  };

  const formConfig = {
    initialValue: formData,
    fields: (() => {
      const result = {};
      Object.keys(formData).forEach(key => {
        result[key] = [{ name: validators.IS_URL }];
      });
      return result;
    })(),
    onSubmit: handleOnFormSubmit,
  };
  const { getFieldProps, getFormProps, state: formState, resetForm } = useFormValidation(formConfig);

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  function renderContent() {
    return (
      <div className="external-tracking-modal">
        <div className="description">
          <strong>{t("experiences.externalTrackingModal.creativeNameLabel")}</strong>
          {experience?.name}
        </div>
        <Form {...getFormProps()}>
          {Object.keys(formData).map(key => {
            return (
              <FormControl
                label={key === "default" ? t("experiences.externalTrackingModal.urlInputLabel") : key}
                placeholder={t("experiences.externalTrackingModal.urlInputPlaceholder")}
                width="100%"
                disabled={isRequesting}
                type={controlTypes.TEXTAREA}
                rows={4}
                required
                {...getFieldProps(key)}
              />
            );
          })}
        </Form>
      </div>
    );
  }
  return (
    <GuardedModal
      width={ModalWidths.Medium}
      open={open}
      title={t("experiences.externalTrackingModal.title")}
      height={ModalHeights.Small}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              type="primary"
              loading={isRequesting}
              disabled={formState?.initialValues === formState?.values}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.save")}
            </Button>
          }
        />
      }
      onClose={onClose}
      locked={isRequesting}
      canBeClosed={() =>
        formState.initialValue?.url !== formState.values?.url ? t("components.guardedModal.closeQuestion") : undefined
      }
    >
      {renderContent()}
    </GuardedModal>
  );
}

ExperienceExternalTrackingModal.propTypes = {
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
