/*
 * Copyright (C) 2016-2022 KoppaSoft.
 *
 * The code hereby is the private full property of the KoppaSoft company, Paris, France.
 *
 * You have no right to re-use or modify it. There are no open-source, nor free license
 * attached to it!
 */

import io from "socket.io-client";

import { authenticationService, customerService, overridingManager } from "Legacy/services";
import { path } from "Legacy/utils";
import { checkCredentials, renewCredentials } from "../../services/MotionlyApiService";

const debug = true;

const Socket = {
  open(studioCompanion, callbacks) {
    if (debug === true) {
      // eslint-disable-next-line no-console
      console.debug("opening the socket");
    }
    const socket = io(studioCompanion.getStudioBaseUrl());

    socket.on("connect", () => {
      if (debug === true) {
        // eslint-disable-next-line no-console
        console.debug("The application has been connected to the socket server");
      }
    });

    socket.on("message", async message => {
      if (debug === true) {
        // eslint-disable-next-line no-console
        console.debug(`Received from the socket server the message '${JSON.stringify(message)}'`);
      }
      const { command, parameters, acknowledgmentId } = message;
      let commandError;
      let commandResult;
      try {
        switch (command) {
          case "accessToken":
            await studioCompanion.renewAccessToken(false);
            break;
          case "credentials":
            await studioCompanion.sendCredentials();
            break;
          case "urls":
            commandResult = {
              applicationBaseUrl: studioCompanion.getApplicationBaseUrl(),
              studioBaseUrl: studioCompanion.getStudioBaseUrl(),
              formBaseUrl: studioCompanion.getFormBaseUrl(),
              assetsBaseUrl: studioCompanion.getAssetsBaseUrl(),
            };
            break;
          case "showExperience":
            callbacks.showExperience(parameters.experienceId);
            break;
          case "logOut":
            await studioCompanion.forget(true);
            break;
          default:
            if (debug === true) {
              // eslint-disable-next-line no-console
              console.error(`Cannot handle the command '${command}'`);
            }
            commandError = new Error(`Cannot handle the command '${command}'`);
            break;
        }
      } catch (error) {
        commandError = error;
      }
      if (acknowledgmentId !== undefined) {
        // We indicate that the command has been executed
        const acknowledgmentMessage = { id: acknowledgmentId, result: commandResult };
        if (commandError !== undefined) {
          // We do not send the error, just its message, because the marshalling does not work with the "Error" type
          acknowledgmentMessage.error = commandError.message;
        }
        socket.emit("acknowledgment", acknowledgmentMessage);
      }
    });
    socket.on("disconnect", () => {
      if (debug === true) {
        // eslint-disable-next-line no-console
        console.debug("The application has been disconnected from the socket server");
      }
    });
  },
};

class StudioCompanion {
  studioBaseUrl;

  serverBaseUrl;

  formBaseUrl;

  initialize(motionlyService) {
    this.studioBaseUrl = overridingManager.getStudioBaseUrl();
    if (this.withStudio() === true) {
      this.serverBaseUrl = overridingManager.getServerBaseUrl();
      this.formBaseUrl = overridingManager.getFormBaseUrl();
      Socket.open(
        {
          getStudioBaseUrl: () => this.studioBaseUrl,
          getApplicationBaseUrl: () => motionlyService.getWebServicesHostName(),
          getFormBaseUrl: () => this.formBaseUrl,
          getAssetsBaseUrl: () => motionlyService.getAssetsBaseUrl(),
          renewAccessToken: async doNotSendCommand => {
            const credentials = await renewCredentials();
            const accessToken = credentials?.accessToken;
            if (doNotSendCommand !== true) {
              // We send the new access token to the Studio
              await this.runCommand("authenticationSuccess", {
                customerPublicId: customerService.getCurrentCustomerPublicId(),
                customerPrivateId: customerService.getCurrentCustomerSummaryId(),
                accessToken,
              });
            }
            return accessToken;
          },
          sendCredentials: async () => {
            const credentials = await checkCredentials();
            const accessToken = credentials?.accessToken;
            // We send the new credentials to the Studio
            await this.runCommand("credentials", {
              customerPublicId: customerService.getCurrentCustomerPublicId(),
              customerPrivateId: customerService.getCurrentCustomerSummaryId(),
              accessToken,
            });
          },
          forget: async () => {
            authenticationService.logout();
            await this.runCommand("loggedOut", undefined);
          },
        },
        {
          showExperience: experienceId => {
            path.set(`${path.EXPERIENCES}/${experienceId}`);
          },
        }
      );
    }
  }

  withStudio() {
    return this.studioBaseUrl !== undefined;
  }

  getServerBaseUrl() {
    return this.serverBaseUrl;
  }

  getFormBaseUrl() {
    return this.formBaseUrl;
  }

  runCommand(command, parameters) {
    if (this.withStudio() === true) {
      return fetch(`${this.studioBaseUrl}/commands`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          software: "backoffice",
          command,
          parameters,
        }),
      });
    }
    return Promise.resolve();
  }
}

export default new StudioCompanion();
