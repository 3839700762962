// eslint-disable-next-line import/prefer-default-export
export const deepCopy = object => JSON.parse(JSON.stringify(object));

export function areAllValuesSame(obj) {
  const values = Object.values(obj);

  if (values.length === 0) return true;

  const firstValue = values[0];
  return values.every(value => value === firstValue);
}

export function areObjectsEqual(obj1, obj2) {
  if (!obj1 || !obj2) {
    return false;
  }
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i] || obj1[keys1[i]] !== obj2[keys2[i]]) {
      return false;
    }
  }

  return true;
}
