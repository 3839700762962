import React, { createContext, useContext, useState } from "react";
import * as PropTypes from "prop-types";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { motionlyService, authenticationService, customerService, userService } from "Legacy/services";

import StudioCompanion from "Legacy/app/studio/StudioCompanion";
import { path } from "../index";

const authContext = createContext(undefined);

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  // The current user object
  const [currentUser, setCurrentUser] = useState(userService.getCurrentUser());

  // The list of customers summary object == abstract of each customer related to the current user
  const [customersSummaryList, setCustomersSummaryList] = useState(customerService.getCustomersSummaryList());

  // The currently selected customer summary object.
  const [currentCustomerSummary, setCurrentCustomerSummary] = useState(customerService.getCurrentCustomerSummary());

  const updateCachedUser = freshUser => {
    userService.updateCachedUserData(freshUser);
    setCurrentUser(userService.getCurrentUser());
  };

  const updateCachedCustomer = freshCustomer => {
    customerService.updateCachedCustomerData(freshCustomer);
    setCurrentCustomerSummary(customerService.getCurrentCustomerSummary());
    setCustomersSummaryList(customerService.getCustomersSummaryList());
  };

  const authenticateAndLogin = async (formData, source, authenticateResult, from = "/") => {
    const { accessToken } = await authenticationService.authenticateAndLogin(formData, source, authenticateResult);
    await StudioCompanion.runCommand("authenticationSuccess", { accessToken });
    await StudioCompanion.runCommand("loggedIn", undefined);
    path.set(from, true);
  };

  const logout = async () => {
    authenticationService.logout();
    await StudioCompanion.runCommand("loggedOut", undefined);
    path.set("/", true);
  };

  const isAuthenticated = () => currentUser !== undefined && currentUser.id !== undefined;

  return {
    currentUser,
    currentCustomerSummary,
    customersSummaryList,
    isAuthenticated,
    authenticateAndLogin,
    logout,
    updateCachedUser,
    updateCachedCustomer,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  const googleClientId = motionlyService.getGoogleClientId();
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <authContext.Provider value={auth}>{children}</authContext.Provider>
    </GoogleOAuthProvider>
  );
}

ProvideAuth.propTypes = {
  children: PropTypes.node,
};
