import { ApplicationData } from "@ogury/motionly-ws-api/ws";

import MotionlyApiService from "./MotionlyApiService";
import { customerService, userService } from "./index";

const hydrateApplication = async (application, usersById) => {
  const actualUsersById = usersById !== undefined ? usersById : await customerService.listCurrentCustomerUsersById();
  return {
    ...application,
    // If the user has left the organization, it is not in the list
    owner: actualUsersById[application.ownerId?.toString()] || userService.computeNotReachableUser(application.ownerId),
  };
};

class ApplicationService {
  allApplications = undefined;

  async list(forceFetch = false) {
    const applications =
      forceFetch === true || this.allApplications === undefined
        ? await MotionlyApiService.applicationApi().list({
            customerId: customerService.getCurrentCustomerSummaryId(),
          })
        : this.allApplications;
    this.allApplications = applications;
    const usersById = await customerService.listCurrentCustomerUsersById();
    return Promise.all(
      applications
        .sort((object1, object2) => object1.name.localeCompare(object2.name))
        .map(application => hydrateApplication(application, usersById))
    );
  }

  async get(applicationId) {
    const application = await MotionlyApiService.applicationApi().get(
      customerService.getCurrentCustomerSummaryId(),
      applicationId
    );
    return hydrateApplication(application);
  }

  async create(publicId, name, comment) {
    const application = await MotionlyApiService.applicationApi().create(
      customerService.getCurrentCustomerSummaryId(),
      publicId,
      name,
      new ApplicationData(),
      { comment }
    );
    return hydrateApplication(application);
  }

  async delete(id) {
    await MotionlyApiService.applicationApi().callDelete(customerService.getCurrentCustomerSummaryId(), id);
  }

  async updateName(applicationId, name) {
    return hydrateApplication(
      await MotionlyApiService.applicationApi().rename(customerService.getCurrentCustomerSummaryId(), applicationId, {
        name,
      })
    );
  }

  async updateComment(applicationId, comment) {
    return hydrateApplication(
      await MotionlyApiService.applicationApi().rename(customerService.getCurrentCustomerSummaryId(), applicationId, {
        comment,
      })
    );
  }

  async update(applicationId, applicationUpdateInputs) {
    return hydrateApplication(
      await MotionlyApiService.applicationApi().update(
        customerService.getCurrentCustomerSummaryId(),
        applicationId,
        applicationUpdateInputs
      )
    );
  }

  async getCallbacks(applicationId) {
    return MotionlyApiService.applicationApi().getCallbacks(
      customerService.getCurrentCustomerSummaryId(),
      applicationId
    );
  }

  async setCallbacks(applicationId, process, callbacks) {
    return MotionlyApiService.applicationApi().setCallbacks(
      customerService.getCurrentCustomerSummaryId(),
      applicationId,
      process,
      callbacks === undefined ? undefined : { body: callbacks }
    );
  }
}

export default new ApplicationService();
