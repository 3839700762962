/**
 * The various modal heights.
 *
 * @type {Object<String, String>}
 */
export const ModalHeights = {
  Small: "small",
  Medium: "medium",
  Large: "large",
  Unspecified: "",
};

export const ModalWidths = {
  Small: 300,
  Medium: 600,
  Large: 900,
  XLarge: 1024,
  XXLarge: 1920,
};
