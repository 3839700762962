import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";

import FileSettingsLineIcon from "remixicon-react/FileSettingsLineIcon";
import { Button } from "@ogury/design-system";
import { bufferToString, useFormValidation, useNotificationService, validators } from "Legacy/utils";
import { experienceService, helpers } from "Legacy/services";
import {
  CallbacksModal,
  DetailPage,
  Form,
  FormControl,
  JavaScriptCode,
  PageHeader,
  SpacedContainer,
  Tooltip,
} from "../../../../components";

const inputsWidth = "100%";
const functionNameRegex = /^[a-zA-Z_$][0-9a-zA-Z_$]*$/;
const javaScriptCode = `function callbacks(callbacksContext)
{

  var logPrefix = "[MTLY] > ";

  return {
    version: 2,
    initialize: function (experienceIds, sharedContext, superCallbacks)
    {
      console.info(logPrefix + "Initializing the experience callbacks for the experience with id '" + experienceIds.id + "', belonging to the customer with public id '" + experienceIds.customerId + "', with a session id set to '" + experienceIds.sessionId + "'");
    },
    overrideInputs: function (experienceIds, inputs)
    {
      console.info(logPrefix + "Asking for the inputs overriding for the experience with id '" + experienceIds.id + "' bound to the HTML element '" + experienceIds.placeholderElement + "' belonging to the customer with id '" + experienceIds.customerId + "'");
      return inputs;
    },
    overrideDescription: function (experienceIds, description)
    {
      // This is the ideal place where to tune the experience description
      console.info(logPrefix + "Asking for the description overriding for the experience with id '" + experienceIds.id + "' belonging to the customer with id '" + experienceIds.customerId + "'");
      return description;
    },
    onTechnicalEvent: function (experienceIds, experienceContext, event)
    {
      console.info(logPrefix + "The experience with id '" + experienceIds.id + "' belonging to the customer with id '" + experienceIds.customerId + "' has issued the '" + event + "' lifecycle event");
    },
    onMetricEvent: function (experienceIds, experienceContext, event, scoreId, level)
    {
      console.info(logPrefix + "The experience with id '" + experienceIds.id + "' belonging to the customer with id '" + experienceIds.customerId + "' has triggered the '" + event + "' metric event with level '" + level + "' on the score with id '" + scoreId + "'");
      return true;
    },
    onAction: function (experienceIds, experienceContext, action)
    {
      console.info(logPrefix + "The end-user clicked/tapped on the experience with id '" + experienceIds.id + "' to the action with id '" + action.id + "' width mode '" + action.mode + "', with original URL '" + action.url + "' and macro URL set to '" + action.urlMacro + "' on the experience with id '" + experienceIds.id + "'");
      return undefined;
    },
    externalSprite: function (experienceIds, experienceContext, spriteId, dimensions, externals, controller, resolve, reject)
    {
      console.info(logPrefix + "Asking for the specifications of the sprite with id '" + spriteId + "'");
    }
  };

}
`;

export default function ExperienceDetailCallbacksPage({ experience, reloadExperience }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const [initialCallbacks, setInitialCallbacks] = useState("");
  const [callbacksFunctionName, setCallbacksFunctionName] = useState("");
  const [callbacksCode, setCallbacksCode] = useState("");
  const [callbacksModal, setCallbacksModal] = useState(false);

  const viaFunctionFieldName = "viaFunction";
  const formConfig = useMemo(
    () => ({
      initialValue: {
        override: initialCallbacks !== "",
        viaFunction: callbacksFunctionName !== "",
        functionName: callbacksFunctionName,
        file: null,
        process: true,
      },
      fields: {
        override: [{ name: validators.IS_REQUIRED }],
        viaFunction: [{ name: validators.IS_REQUIRED }],
        functionName: [
          {
            name: validators.CUSTOM,
            validator: value => {
              // eslint-disable-next-line no-use-before-define
              if (getFieldProps(viaFunctionFieldName).value === true) {
                if (value === "") {
                  return "form.notEmpty";
                }
                return functionNameRegex.test(value) === false
                  ? "experiences.callbacksTab.formValidationErrors.functionName"
                  : "";
              }
              return "";
            },
          },
        ],
        file: undefined,
        process: [
          {
            name: validators.IS_REQUIRED,
          },
        ],
      },
      onSubmit: async formValue => {
        setWorking(true);
        try {
          let parametersCallbacks;
          if (formValue.override === true) {
            parametersCallbacks =
              // eslint-disable-next-line no-use-before-define
              formValue.viaFunction === true ? formValue.functionName : callbacksCode;
          } else {
            parametersCallbacks = "";
          }
          const newExperience = await experienceService.setCallbacks(
            experience.id,
            formValue.process,
            parametersCallbacks
          );
          reloadExperience(newExperience);
          notificationService.notifySuccess(undefined, t("experiences.callbacksTab.submitSuccess"));
        } catch (error) {
          notificationService.notifyError(error, t("experiences.callbacksTab.title"));
        } finally {
          setWorking(false);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, experience, reloadExperience, initialCallbacks, callbacksCode]
  );

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);
  const overrideFieldProps = getFieldProps("override");
  const viaFunctionFieldProps = getFieldProps(viaFunctionFieldName);
  const functionNameFieldProps = getFieldProps("functionName");
  const fileFieldProps = getFieldProps("file");
  const overrideValue = overrideFieldProps.value;
  const viaFunctionValue = viaFunctionFieldProps.value;
  const viaFunctionNameValue = viaFunctionValue;

  useEffect(() => {
    const experienceCallbacks = experience.data.parameters.callbacks ?? "";
    setInitialCallbacks(experienceCallbacks);
    if (functionNameRegex.test(experienceCallbacks) === true) {
      setCallbacksFunctionName(experienceCallbacks);
      setCallbacksCode("");
    } else {
      setCallbacksFunctionName("");
      setCallbacksCode(experienceCallbacks);
    }
  }, [experience]);

  const isNotDraft = experience.state !== Experience.StateEnum.Draft;
  return (
    <>
      <DetailPage className="page-callbacks-container">
        <PageHeader
          title={
            <>
              {t("experiences.callbacksTab.name")}
              <Tooltip
                shortHtmlContent={t("experiences.callbacksTab.shortTooltip")}
                longHtmlContent={t("experiences.callbacksTab.longTooltip")}
              >
                <Icon path={mdiInformation} size={0.6} />
              </Tooltip>
            </>
          }
        >
          <Button
            type="secondary"
            iconPosition="iconOnly"
            icon={<FileSettingsLineIcon />}
            tooltipText={t("applications.callbacksTab.modalTooltip")}
            onClick={() => {
              setCallbacksModal(true);
            }}
          />
        </PageHeader>
        <Form {...getFormProps()}>
          <SpacedContainer withBottom>
            <FormControl
              id="override"
              type="toggle"
              label={t("experiences.callbacksTab.enable")}
              tooltipText={t("experiences.callbacksTab.enableTooltip")}
              width={inputsWidth}
              disabled={working || isNotDraft}
              required
              {...overrideFieldProps}
            />
            {overrideValue === true && (
              <>
                <FormControl
                  id="viaFunction"
                  type="toggle"
                  label={t("experiences.callbacksTab.viaFunction")}
                  tooltipText={t("experiences.callbacksTab.viaFunctionTooltip")}
                  width={inputsWidth}
                  disabled={working || isNotDraft || overrideValue === false}
                  required
                  {...viaFunctionFieldProps}
                />
                {viaFunctionNameValue === true && (
                  <FormControl
                    id="functionName"
                    type="text"
                    label={t("experiences.callbacksTab.functionName")}
                    width={inputsWidth}
                    disabled={working || isNotDraft || overrideValue === false}
                    required
                    {...functionNameFieldProps}
                  />
                )}
                {viaFunctionNameValue === false && (
                  <>
                    <FormControl
                      id="file"
                      type="file"
                      accept="text/javascript"
                      width={inputsWidth}
                      disabled={working || isNotDraft || overrideValue === false}
                      required
                      {...fileFieldProps}
                      onChange={value => {
                        setCallbacksCode(bufferToString(value.buffer));
                      }}
                    />
                    {fileFieldProps.value && (
                      <div>
                        <span className="selected-file-name">{fileFieldProps.value?.selectedFile?.name}</span>
                      </div>
                    )}
                    {callbacksCode !== "" && (
                      <>
                        <FormControl
                          id="callbacks"
                          label={t("fields.javascriptSource")}
                          width={inputsWidth}
                          disabled={working || overrideValue === false}
                          required
                        >
                          {() => <JavaScriptCode code={callbacksCode} fileName={helpers.experienceCallbacksFileName} />}
                        </FormControl>
                        {callbacksCode !== initialCallbacks && (
                          <FormControl
                            id="process"
                            type="checkbox"
                            label={t("fields.process")}
                            tooltipText={t("fields.processTooltip")}
                            width={inputsWidth}
                            disabled={working}
                            required
                            {...getFieldProps("process")}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </SpacedContainer>
          <Button
            submit
            type="primary"
            loading={working}
            disabled={
              working ||
              isNotDraft ||
              (overrideValue === false && initialCallbacks === "") ||
              (overrideValue === true &&
                viaFunctionValue === true &&
                functionNameFieldProps.value === callbacksFunctionName) ||
              (overrideValue === true &&
                viaFunctionValue === false &&
                (callbacksCode === "" || callbacksCode === initialCallbacks))
            }
          >
            {t("actions.save")}
          </Button>
        </Form>
      </DetailPage>
      <CallbacksModal
        open={callbacksModal}
        title={t("experiences.callbacksTab.modal.title")}
        explanation={t("experiences.callbacksTab.modal.explanation")}
        fileName={helpers.experienceCallbacksFileName}
        javaScriptCode={javaScriptCode}
        onClose={() => {
          setCallbacksModal(false);
        }}
      />
    </>
  );
}

ExperienceDetailCallbacksPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object.isRequired,
  reloadExperience: PropTypes.func.isRequired,
};
