import { path } from "Legacy/utils";
import Customers from "./Customers";

export default [
  {
    path: path.CUSTOMERS,
    key: "customers",
    component: Customers,
  },
];
