import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./CustomerDetailSettingsPage.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import {
  Attribute,
  Form,
  FormControl,
  PageHeader,
  PageLoader,
  selectEntry,
  SpacedContainer,
  SpacedUnits,
  ThumbnailContainer,
} from "Legacy/components";
import { customerPermissions, customerService } from "Legacy/services";
import {
  disableFeature,
  randomString,
  useAuth,
  useFormValidation,
  validators,
  useNotificationService,
} from "Legacy/utils";
import DetailPage from "Legacy/components/Layout/DetailPage/DetailPage";
import { UploadImageModal } from "./components";

const inputsWidth = "400px";

const countryOptions = [selectEntry(1, "France")];
const companySizeOptions = [selectEntry(1, "50-100 employees")];

export default function CustomerDetailSettingsPage({ customer }) {
  const [working, setWorking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const auth = useAuth();

  const initialFormValue = {
    name: customer.name,
    websiteUrl: customer.data?.websiteUrl,
    country: 1,
    industry: 1,
    companySize: 1,
  };

  const onClickRefresh = async () => {
    setLoading(true);
    try {
      const freshCustomer = await customerService.getCurrentCustomerData(true);
      auth.updateCachedCustomer(freshCustomer);
      notificationService.notifySuccess(t("messages.refreshSuccess"));
      setLoading(false);
    } catch (error) {
      notificationService.notifyError(error);
      setLoading(false);
    }
  };

  const onChangeInformations = async formValue => {
    setWorking(true);
    try {
      const freshCustomer = await customerService.update(customer.id, formValue.name, formValue.websiteUrl);
      auth.updateCachedCustomer(freshCustomer);
      notificationService.notifySuccess(t("messages.saveSuccess"));
      setWorking(false);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      name: [{ name: validators.IS_REQUIRED }],
      websiteUrl: [{ name: validators.IS_REQUIRED }, { name: validators.IS_URL, t }],
      country: [{ name: validators.IS_REQUIRED }],
      industry: [{ name: validators.IS_REQUIRED }],
      companySize: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onChangeInformations,
  };

  const { getFormProps, getFieldProps } = useFormValidation(formConfig);
  const disabled = working || !customerPermissions.canModifySettings();

  return (
    <>
      <div className="detail-settings-body">
        <PageHeader title={t("customers.settings.title")}>
          <PageLoader inline active={loading} />
          <Button iconPosition="iconOnly" onClick={onClickRefresh} type="secondary" icon={<RefreshLineIcon />} />
        </PageHeader>
      </div>
      <DetailPage className="customer-page-detail-body">
        <SpacedContainer className="left-column" gap={SpacedUnits.Small}>
          <ThumbnailContainer key={randomString(6)} url={auth.currentCustomerSummary?.logoImageUrl} />
          {customerPermissions.canModifyLogo() && (
            <Button type="secondary" onClick={() => setShowUploadImageModal(true)}>
              {t("customers.settings.uploadLogo")}
            </Button>
          )}
        </SpacedContainer>
        <Form {...getFormProps()}>
          <SpacedContainer className="right-column">
            <FormControl
              id="name"
              label={t("fields.name")}
              placeholder={t("fields.namePlaceholder")}
              required
              disabled={disabled}
              width={inputsWidth}
              {...getFieldProps("name")}
            />
            <div>
              <FormControl
                id="websiteUrl"
                label={t("customers.settings.websiteUrl")}
                placeholder={t("customers.settings.websiteUrlPlaceholder")}
                required
                disabled={disabled}
                width={inputsWidth}
                {...getFieldProps("websiteUrl")}
              />
              <div className="hint">{t("customers.settings.websiteUrlDescription")}</div>
            </div>
            <hr />
            <Attribute name={t("fields.publicId")} value={customer.publicId} />
            <Attribute name={t("customers.settings.privateId")} value={customer.id} />
            {disableFeature && (
              <>
                <hr />
                <FormControl
                  required
                  id="country"
                  type="select"
                  disabled={!customerPermissions.canModifySettings()}
                  label={t("customers.settings.country")}
                  options={countryOptions}
                  width={inputsWidth}
                  {...getFieldProps("country")}
                />
                <FormControl
                  required
                  id="industry"
                  type="select"
                  disabled={!customerPermissions.canModifySettings()}
                  label={t("customers.settings.industry")}
                  options={[selectEntry("Web", 1), selectEntry("ECommerce", 2)]}
                  width={inputsWidth}
                  {...getFieldProps("industry")}
                />
                <FormControl
                  required
                  id="companySize"
                  type="select"
                  disabled={!customerPermissions.canModifySettings()}
                  label={t("customers.settings.companySize")}
                  options={companySizeOptions}
                  width={inputsWidth}
                  {...getFieldProps("companySize")}
                />
              </>
            )}
            <hr />
            {customerPermissions.canModifySettings() && (
              <Button submit type="primary" loading={working}>
                {t("actions.saveChanges")}
              </Button>
            )}
          </SpacedContainer>
        </Form>
      </DetailPage>
      <UploadImageModal
        open={showUploadImageModal}
        customer={customer}
        onClose={() => setShowUploadImageModal(false)}
      />
    </>
  );
}
CustomerDetailSettingsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customer: PropTypes.object,
};
