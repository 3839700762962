import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { disableFeature, path, useAuth, useNotificationService } from "Legacy/utils";
import { AuthFormContainer, CredentialsForm, PublicLayout } from "../../components";

export default function LoginPage() {
  const { state } = useLocation();
  const [t] = useTranslation();
  const notificationService = useNotificationService();

  const initialFormValue = {
    email: state?.email || "",
    password: "",
  };

  const location = useLocation();
  const auth = useAuth();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (auth.isAuthenticated() === true) {
      // If the user is already authenticated, we redirect her to the default page
      path.set("/", true);
    }
  }, [auth, location]);

  // We navigate to DEFAULT_HOME after login unless a path exists in the state
  const from = state?.from || path.DEFAULT_HOME;

  const [working, setWorking] = useState(false);
  const [inputEmailValue, setInputEmailValue] = useState(state?.email || "");

  const onEmailAuth = async (formData, source) => {
    setWorking(true);
    try {
      await auth.authenticateAndLogin(formData, source, undefined, from);
    } catch (error) {
      notificationService.notifyError(error, t("auth.login.authenticationError"));
      setWorking(false);
    }
  };

  async function onGoogleAuth(googleResponse, source) {
    setWorking(true);
    try {
      await auth.authenticateAndLogin(googleResponse, source, undefined, from);
    } catch (error) {
      notificationService.notifyError(error, t("auth.login.authenticationError"));
      setWorking(false);
    }
  }

  return (
    <PublicLayout>
      <div className="auth-page">
        {disableFeature && (
          <div className="auth-page-header">
            {t("auth.login.dontHaveAnAccount")}
            <Link
              to={{
                pathname: path.SIGNUP,
                state: {
                  email: inputEmailValue,
                },
              }}
              className="typo-link"
            >
              {t("auth.signup.title")}
            </Link>
          </div>
        )}
        <div className="auth-form">
          <AuthFormContainer>
            <div className="auth-form-header">
              <h1>{t("auth.login.title")}</h1>
            </div>

            <CredentialsForm
              initialFormValue={initialFormValue}
              working={working}
              onEmailAuth={onEmailAuth}
              onGoogleAuth={onGoogleAuth}
              setEmail={setInputEmailValue}
            />
          </AuthFormContainer>
        </div>
      </div>
    </PublicLayout>
  );
}
