// eslint-disable-next-line import/prefer-default-export
export const tagTypes = {
  NEUTRAL: "neutral",
  PROCESSING: "processing",
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  GEEKBLUE: "geekblue",
  DEFAULT: "default",
};

export const TAG_IN_SITE = "in-site";
export const TAG_STANDALONE = "standalone";
