import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { siteService } from "Legacy/services";

import { Attribute, ExperienceThumbnail, GuardedModal, SpacedContainer, SpacedUnits } from "Legacy/components";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { DESTINATION_DETACH_FROM_SITES } from "Legacy/utils/constants";
import { DestinationSelector } from "./components";

export default function ExperienceMoveToModal({ open, fromSiteId, experience, onSuccess, onClose }) {
  const [working, setWorking] = useState(false);
  const [destinationId, setDestinationId] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [t] = useTranslation();
  const notificationService = useNotificationService();

  // eslint-disable-next-line consistent-return
  function handleOnChangeDestination(site) {
    if (!site) {
      return;
    }
    setErrorMessage(undefined);
    const unitAlreadyExistsInDestination = site.units?.some(
      siteUnit => siteUnit.technicalId === experience.units[0].technicalId
    );
    if (unitAlreadyExistsInDestination) {
      setErrorMessage(t("experiences.moveToModal.adUnitAlreadyExistsMessage"));
      return setDestinationId(undefined);
    }
    setDestinationId(site);
  }

  async function handleMoveTo() {
    setWorking(true);
    try {
      // fromSiteId could be undefined (if modal opened from the experiences list). So we call this WS to fill the site ID.
      // fromSiteId is directly passed through the site's detail page to avoid a unnecessary API call.
      if (fromSiteId === undefined) {
        const site = await siteService.getExperienceSite(experience.id);
        // eslint-disable-next-line no-param-reassign
        fromSiteId = site?.id;
      }

      if (fromSiteId !== undefined) {
        await siteService.detachExperience(fromSiteId, experience.id);
      }

      if (destinationId !== DESTINATION_DETACH_FROM_SITES) {
        try {
          await siteService.attachExperience(destinationId, experience.id);

          // Rollback if the attachement didn't work.
        } catch (error) {
          if (fromSiteId !== undefined) {
            await siteService.attachExperience(fromSiteId, experience.id);
          }
          // eslint-disable-next-line no-throw-literal
          throw {
            message: error?.message,
          };
        }
      }
      notificationService.notifySuccess(t("experiences.moveToModal.successMessage"));
      onSuccess();
      onClose();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("experiences.moveToModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={handleMoveTo} type="primary" loading={working} disabled={destinationId === undefined}>
              {t("actions.moveTo")}
            </Button>
          }
        />
      }
      canBeClosed={() => {
        if (working === true) {
          return false;
        }
        return destinationId !== undefined ? t("components.guardedModal.closeQuestion") : undefined;
      }}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("experiences.moveToModal.explanation")}</Typography.P2Regular>
      <Attribute name={t("experiences.moveToModal.experienceToMove")} />
      <SpacedContainer gap={SpacedUnits.Small}>
        <ExperienceThumbnail experience={experience} isFull={false} withLink={false} />
        <Attribute name={t("experiences.moveToModal.destinationLabel")} />
      </SpacedContainer>

      <SpacedContainer gap={SpacedUnits.Small} withBottom>
        <DestinationSelector onChange={handleOnChangeDestination} sourceExperience={experience} />
        {errorMessage && <div className="typo-warning typo-semi-bold">{errorMessage}</div>}
      </SpacedContainer>
    </GuardedModal>
  );
}

ExperienceMoveToModal.propTypes = {
  // TODO add details for experience object
  experience: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  fromSiteId: PropTypes.string,
  open: PropTypes.bool,
};
