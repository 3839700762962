import { ChangePasswordInputs, CustomerCreationInputs, UserUpdateInputs } from "@ogury/motionly-ws-api/ws";
import { encryptPassword } from "Legacy/utils";
import {
  getObject,
  LOCALSTORAGE_CREDENTIALS,
  LOCALSTORAGE_CURRENT_USER,
  LOCALSTORAGE_CURRENT_USER_PREFERENCES,
  persistObject,
} from "Legacy/utils/storage";
import { customerService } from "Legacy/services/index";
import MotionlyApiService from "./MotionlyApiService";

class UserService {
  // Cache of current user
  currentUser = {
    avatarUrl: undefined,
    email: undefined,
    id: undefined,
    name: undefined,
    roles: {
      list: [],
    },
  };

  constructor() {
    const credentials = getObject(LOCALSTORAGE_CREDENTIALS);
    const user = getObject(LOCALSTORAGE_CURRENT_USER);

    if (credentials && user) {
      this.initialize(user);
    }
  }

  initialize(user) {
    this.currentUser = user;
  }

  updateCachedUserData(freshUser) {
    this.currentUser = freshUser;

    persistObject(LOCALSTORAGE_CURRENT_USER, freshUser);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  getCurrentUserId() {
    return this.getCurrentUser().id;
  }

  getCurrentUserEmail() {
    return this.getCurrentUser().email;
  }

  computeNotReachableUser(userId) {
    return { id: userId.toString() };
  }

  getRoleForCustomer(customerId) {
    const currentRoleObj = this.getCurrentUser().roles.list.find(
      role => role.customerId.toString() === customerId.toString()
    );
    return currentRoleObj?.role;
  }

  getUsersFromCustomersId(customerId) {
    return MotionlyApiService.userApi().list({ customerId });
  }

  async setRole(userId, customerId, roleString) {
    return MotionlyApiService.userApi().setRole(userId, customerId, roleString);
  }

  async removeRole(userId, customerId) {
    return MotionlyApiService.userApi().removeRole(userId, customerId);
  }

  async transferOwnership(sourceUserId, customerId, targetUserId) {
    return MotionlyApiService.userApi().transferOwnership(sourceUserId, customerId, targetUserId);
  }

  async setAvatar(userId, requestBody) {
    return MotionlyApiService.userApi().setAvatar(userId, { requestBody });
  }

  async changeEmail(userId, email) {
    return MotionlyApiService.userApi().changeEmail(userId, email);
  }

  async changePassword(userId, currentPassword, newPassword) {
    const changePasswordInputs = new ChangePasswordInputs(
      encryptPassword(currentPassword),
      encryptPassword(newPassword)
    );
    return MotionlyApiService.userApi().changePassword(userId, changePasswordInputs);
  }

  async get(userId) {
    return MotionlyApiService.userApi().get(userId);
  }

  async update(userId, name) {
    const userUpdateInputs = new UserUpdateInputs(name);
    return MotionlyApiService.userApi().update(userId, userUpdateInputs);
  }

  async createCustomer(userId, customerPublicId, customerName) {
    const customerCreationInputs = new CustomerCreationInputs(customerPublicId, customerName);
    return MotionlyApiService.userApi().createCustomer(userId, customerCreationInputs);
  }

  getUserPreferences(field) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const preferences = getObject(LOCALSTORAGE_CURRENT_USER_PREFERENCES);
    const customerPreferences = preferences?.[customerId] || {};

    return field ? customerPreferences?.[field] : customerPreferences;
  }

  setUserPreferences(field, value) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const preferences = getObject(LOCALSTORAGE_CURRENT_USER_PREFERENCES) || {};

    const customerPreferences = preferences[customerId] || {};

    customerPreferences[field] = value;
    preferences[customerId] = customerPreferences;

    persistObject(LOCALSTORAGE_CURRENT_USER_PREFERENCES, preferences);

    return preferences;
  }
}

export default new UserService();
