import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { Experience, Tag as TagModel } from "@ogury/motionly-ws-api/ws";
import { FilterBar, FormControl } from "Legacy/components";

import "./ExperiencesFilters.scss";
import {
  computeValueLabels,
  computeValueLabelWithLabels,
  customerService,
  experienceFilters,
  experienceService,
  unitService,
  userService,
} from "Legacy/services";
import { applyGuestSpecificities, applyOwningCustomerTweak, Filters, useNotificationService } from "Legacy/utils";
import { TAG_IN_SITE, TAG_STANDALONE } from "Legacy/utils/tag";
import { ViewType } from "Legacy/propTypes";

const { StateEnum, OriginEnum } = Experience;
function computeLabel(key) {
  if (key === StateEnum.Published) {
    return i18n.t("experiences.status.published");
  }
  return key;
}
const statusOptions = computeValueLabels(StateEnum, null, computeLabel).sort((a, b) => (a.label > b.label ? 1 : -1));

// noinspection JSUnresolvedVariable
const originOptions = labels =>
  Object.keys(OriginEnum)
    .filter(
      origin => applyOwningCustomerTweak(userService.getCurrentUserEmail()) === true || origin !== OriginEnum.Managed
    )
    .map(key => computeValueLabelWithLabels(OriginEnum, labels, key))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

/* eslint-disable no-unused-vars */
export default function ExperiencesFilters({
  filtersValue = [],
  onChange,
  searchText,
  onSearchChange,
  onCleared,
  onSearch,
  viewManagerConfig,
  tags,
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [usersOptions, setUsersOptions] = useState([]);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  // noinspection JSUnresolvedVariable,JSUnusedLocalSymbols
  const [uxOptions, setUxOptions] = useState([]);
  // noinspection JSUnresolvedVariable,JSUnusedLocalSymbols
  const [industryOptions, setIndustryOptions] = useState([]);
  const [usageOptions, setUsageOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  // noinspection JSUnresolvedVariable,JSUnusedLocalSymbols
  const [favouritesOptions, setFavouritesOptions] = useState([]);
  const [adUnitsOptions, setAdUnitsOptions] = useState([]);

  useEffect(() => {
    if (!tags) {
      return;
    }
    const loadOptions = async () => {
      // TODO remove this function when API returns the right labels.
      function renameUsageTag(tagName) {
        if (tagName === TAG_IN_SITE) {
          return "From Multi ad-units creatives";
        }
        if (tagName === TAG_STANDALONE) {
          return "Standalone";
        }
        return tagName;
      }

      try {
        const users = await customerService.listCurrentCustomerUsers().then(customerService.filterPendingUsers);
        setUsersOptions(Filters.buildOptions(users, user => `(${user.email})`));
        setTemplatesOptions(Filters.buildOptions(tags.filter(tag => tag.category === TagModel.CategoryEnum.Template)));
        setUsageOptions(
          Filters.buildOptions(
            tags
              .filter(tag => tag.category === TagModel.CategoryEnum.Usage)
              .map(tag => ({
                ...tag,
                name: renameUsageTag(tag.name),
              }))
          )
        );
        setUxOptions(Filters.buildOptions(tags.filter(tag => tag.category === TagModel.CategoryEnum.Ux)));
        setIndustryOptions(
          Filters.buildOptions(tags.filter(tag => tag.category === TagModel.CategoryEnum.Application))
        );
        // noinspection JSUnresolvedVariable
        setTagsOptions(Filters.buildOptions(tags.filter(tag => tag.category === TagModel.CategoryEnum.Customer)));

        // noinspection JSUnresolvedVariable
        setFavouritesOptions(Filters.buildOptions(tags.filter(tag => tag.category === TagModel.CategoryEnum.Favorite)));

        setAdUnitsOptions(unitService.list().map(unit => ({ value: unit.technicalId, label: unit.name })));
      } catch (error) {
        notificationService.notifyError(error, "The filters data could not be loaded");
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadOptions();
  }, [tags]);

  const defaultFilters = [
    experienceFilters.USAGE,
    experienceFilters.TEMPLATE,
    experienceFilters.AD_UNIT,
    experienceFilters.OWNER,
  ];
  const filterBarConfig = [
    {
      filterKey: experienceFilters.USAGE,
      width: "200px",
      label: "filters.usage",
      type: "select",
      allowClear: true,
      options: usageOptions,
      placeholder: t("filters.usagePlaceholder"),
    },
    {
      filterKey: experienceFilters.TEMPLATE,
      label: "filters.template",
      type: "multiselect",
      options: templatesOptions,
      allowClear: true,
      placeholder: t("filters.templatePlaceholder"),
    },
    {
      filterKey: experienceFilters.AD_UNIT,
      label: "filters.adUnit",
      type: "select",
      allowClear: true,
      options: adUnitsOptions,
      placeholder: t("filters.adUnitPlaceholder"),
    },
    {
      filterKey: experienceFilters.STATUS,
      label: "filters.status",
      type: "select",
      allowClear: true,
      options: statusOptions,
      placeholder: t("filters.statusPlaceholder"),
    },
    {
      filterKey: experienceFilters.CUSTOMER_TAGS,
      label: "filters.customerTags",
      type: "multiselect",
      options: tagsOptions,
      placeholder: t("filters.tagsPlaceholder"),
    },
  ];
  if (!applyGuestSpecificities(customerService.getCurrentCustomerSummaryId(), userService.getCurrentUserEmail())) {
    filterBarConfig.push({
      filterKey: experienceFilters.OWNER,
      label: "filters.owner",
      type: "select",
      allowClear: true,
      options: usersOptions,
      placeholder: t("filters.ownerPlaceholder"),
    });
  }
  filterBarConfig.push({
    filterKey: experienceFilters.ORIGIN,
    label: "filters.origin",
    type: "select",
    allowClear: true,
    options: originOptions(experienceService.getOriginsLabels(t)),
    placeholder: t("filters.originPlaceholder"),
  });

  return (
    <FilterBar
      searchText={searchText}
      preFilter={
        <div className="filter-control" style={{ position: "relative", top: -2 }}>
          {/* styling quick fix until filters refactoring */}
          <div className="form-label" style={{ marginBottom: 7 }}>
            {t("actions.search")}
          </div>
          <FormControl
            type="search"
            value={searchText}
            onChange={onSearchChange}
            placeholder={t("filters.quickSearchPlaceholder")}
            withPadding={false}
            allowClear={false}
          />
        </div>
      }
      filtersConfig={filterBarConfig}
      filtersEnum={experienceFilters}
      defaultFilters={defaultFilters}
      value={filtersValue}
      onChange={onChange}
      onCleared={onCleared}
      onSearch={onSearch}
      viewManagerConfig={viewManagerConfig}
    />
  );
}

ExperiencesFilters.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  filtersValue: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onCleared: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  viewManagerConfig: PropTypes.shape({
    disabled: PropTypes.bool,
    currentView: ViewType,
    callCreate: PropTypes.func,
    callSave: PropTypes.func,
    callRename: PropTypes.func,
    callDelete: PropTypes.func,
    onViewCreated: PropTypes.func,
    onViewSaved: PropTypes.func,
    onViewRenamed: PropTypes.func,
    onViewDeleted: PropTypes.func,
  }),
};
