import { Alert, Button, Modal, ModalClosableFooter } from "@ogury/design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { experienceService } from "Legacy/services";
import { useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";

export default function ExternalSyncFailedModal({ experienceId, open, onCancel, onSuccess }) {
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);
  const notificationService = useNotificationService();

  async function handleOnSynchronize() {
    setWorking(true);
    try {
      const experience = await experienceService.synchronizeWithCreativeConsole(experienceId);
      notificationService.notifySuccess(t("experiences.externalSyncFailedModal.success"));
      onSuccess(experience);
    } catch (error) {
      notificationService.notifyError(error, t("experiences.externalSyncFailedModal.error"));
    } finally {
      setWorking(false);
    }
  }

  return (
    <Modal
      open={open}
      title={t("experiences.externalSyncFailedModal.title")}
      onCancel={onCancel}
      width={ModalWidths.Medium}
      footer={
        <ModalClosableFooter
          actions={
            <Button onClick={handleOnSynchronize} disabled={working}>
              {t("experiences.externalSyncFailedModal.synchronize")}
            </Button>
          }
        />
      }
    >
      <Alert type="warning" closable={false} description={t("experiences.externalSyncFailedModal.description")} />
    </Modal>
  );
}
