import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import Tag from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/Tag";
import { useTranslation } from "react-i18next";
import { Environment, sitePermissions, siteSortings, tagService, unitService } from "Legacy/services";
import { Avatar, SiteThumbnail, ThirdPartyTagDrawer } from "Legacy/components";
import { dateFormatter, randomString, sortOrder, useNotificationService } from "Legacy/utils";
import {
  SiteDeleteModal,
  SiteDuplicateModal,
  SiteExternalTrackingDrawer,
  SitePublishModal,
  SiteStatusTag,
} from "Legacy/app/sites/components";
import { Button, Dropdown, Select, Space, Table, Tooltip, Typography } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import ThirdPartyTagBulkDrawer from "Legacy/app/sites/pages/SitesPage/components/SitesTable/components/ThirdPartyTagBulkDrawer/ThirdPartyTagBulkDrawer";
import SitesDuplicateDrawer from "Legacy/app/sites/pages/SitesPage/components/SitesTable/components/SitesDuplicateDrawer/SitesDuplicateDrawer";
import SiteBulkPublishModal from "Legacy/app/sites/components/SiteBulkPublishModal/SiteBulkPublishModal";
import style from "./SitesTable.module.scss";

const ACTIONS = {
  THIRD_PARTY_TAG: "third-party-tag",
  DUPLICATE: "duplicate",
  PUBLISH: "publish",
};

export default function SitesTable({
  emptyState,
  sites = [],
  pagination,
  onChange = () => {},
  onRefresh = () => {},
  loading,
  sorting,
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [siteThirdPartyModal, setSiteThirdPartyModal] = useState(null);
  const [sitePublishModal, setSitePublishModal] = useState(null);
  const [siteDuplicateModal, setSiteDuplicateModal] = useState(null);
  const [siteDeleteModal, setSiteDeleteModal] = useState(null);
  const [siteExternalTrackingDrawer, setSiteExternalTrackingDrawer] = useState(null);
  const [selectedSites, setSelectedSites] = useState([]);
  const [showThirdPartyTagBulkDrawer, setShowThirdPartyTagBulkDrawer] = useState(false);
  const [showDuplicateBulkDrawer, setShowDuplicateBulkDrawer] = useState(false);
  const [showBulkPublishModal, setShowBulkPublishModal] = useState(false);
  const [selectedBulkAction, setSelectedBulkAction] = useState();

  function onClickThirdParty(row) {
    setSiteThirdPartyModal(row);
  }
  function onClickDuplicate(row) {
    setSiteDuplicateModal(row);
  }
  function onClickPublish(row) {
    setSitePublishModal(row);
  }
  function onClickExternalTracking(row) {
    setSiteExternalTrackingDrawer(row);
  }
  function onClickDelete(row) {
    setSiteDeleteModal(row);
  }

  function renderThumbnailCell(row) {
    return <SiteThumbnail site={row} editable />;
  }
  function renderAccountCell(row) {
    return <Typography.P2Regular>{row.account?.name}</Typography.P2Regular>;
  }
  function renderBrandCell(row) {
    return <Typography.P2Regular>{row.brand?.name}</Typography.P2Regular>;
  }
  function renderAdUnitCell(units) {
    const results = unitService.getUnitsNamesFromUnits(units);
    if (results?.length) {
      return (
        <Tooltip title={results.join(", ")}>
          <span>{results.join(", ").substring(0, 25) + "..."}</span>
        </Tooltip>
      );
    }
    return <></>;
  }

  function renderTemplateCell(tags) {
    if (tags?.length) {
      // eslint-disable-next-line array-callback-return,consistent-return
      const results = tagService.filterByCategory(tags, Tag.CategoryEnum.Template).map(tag => tag.name);
      if (results) {
        return <span>{results.join(", ")}</span>;
      }
    }
    return <></>;
  }

  const renderOwnerCell = owner => (
    <Avatar key={randomString(8)} src={owner.avatarUrl} name={owner.name ?? t("users.notPresentInCustomer")} compact />
  );

  const renderDateCell = date => {
    return dateFormatter.toShortDate(date);
  };
  const renderStatusCell = state => <SiteStatusTag status={state} />;

  // Edit button + dropdown menu More actions
  const renderActionCell = row => {
    const menuItems = [
      sitePermissions.canPublish(row) === true && {
        key: "publish",
        onClick: () => onClickPublish(row),
        label: t("actions.publishForReview"),
      },
      sitePermissions.canDuplicate(row) === true && {
        key: "duplicate",
        onClick: () => onClickDuplicate(row),
        label: t("actions.duplicate"),
      },
      sitePermissions.canEmbed(row, Environment.ThirdParty) === true && {
        key: "thirdParty",
        onClick: () => onClickThirdParty(row),
        label: t("sites.actionsMenu.thirdParty"),
      },
      {
        key: "externalTracking",
        onClick: () => onClickExternalTracking(row),
        label: t("actions.externalTracking"),
      },

      sitePermissions.canDelete(row) && { type: "divider" },
      sitePermissions.canDelete(row) && {
        key: "delete",
        onClick: () => onClickDelete(row),
        label: t("actions.delete"),
      },
    ];

    return (
      <div className="action-button">
        <Dropdown menu={{ items: menuItems }} trigger="click">
          <Tooltip title={t("actions.more")} placement="top">
            <Button iconPosition="iconOnly" icon={<More2FillIcon />} type="tertiary" />
          </Tooltip>
        </Dropdown>
      </div>
    );
  };

  const onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    // First, removing from the selected sites, all the sites from the current selection, then adding the selectedRows.
    const updatedSelectedSites = selectedSites.filter(
      selectedSite => !sites.some(site => site.id?.toString() === selectedSite.id?.toString())
    );
    const sitesList = selectedRows.map(selectedRow => selectedRow.site) || [];
    setSelectedSites([...updatedSelectedSites, ...sitesList]);
  };

  const columns = useMemo(() => {
    const value = [
      {
        title: t("fields.name"),
        dataIndex: "name",
        sortKey: siteSortings.NAME,
        sorter: true,
        width: "30%",
        render: renderThumbnailCell,
      },
      {
        title: t("fields.account"),
        dataIndex: "site",
        width: "30%",
        render: renderAccountCell,
      },
      {
        title: t("fields.brand"),
        dataIndex: "site",
        width: "30%",
        render: renderBrandCell,
      },
      {
        title: t("fields.adUnit"),
        dataIndex: "adUnit",
        width: 140,
        render: renderAdUnitCell,
      },
      {
        title: t("experiences.properties.template"),
        dataIndex: "template",
        render: renderTemplateCell,
      },
      {
        title: t("fields.owner"),
        dataIndex: "owner",
        sorter: true,
        sortKey: siteSortings.OWNER_EMAIL,
        width: 100,
        render: renderOwnerCell,
      },
      {
        title: t("fields.updateDate"),
        dataIndex: "updated",
        render: renderDateCell,
        sorter: true,
        width: 100,
        sortKey: siteSortings.UPDATE_DATE,
      },
      {
        title: t("experiences.properties.status"),
        dataIndex: "status",
        sorter: true,
        sortKey: siteSortings.STATE,
        width: 120,
        render: renderStatusCell,
      },
      {
        title: t("experiences.properties.actions"),
        dataIndex: "actions",
        key: "actions",
        align: "right",
        width: 150,
        render: renderActionCell,
      },
    ];

    if (!sorting) {
      return value;
    }

    // apply sorting
    value.forEach((item, index) => {
      if (item.sortKey === sorting.sortBy && sorting.sortOrder === -1) {
        value[index].defaultSortOrder = "descend";
      } else if (item.sortKey === sorting.sortBy && sorting.sortOrder === 1) {
        value[index].defaultSortOrder = "ascend";
      }
    });

    return value;
  }, [sorting]);

  const dataSource = sites.map(site => ({
    key: site.id,
    site,
    name: site,
    owner: site.owner,
    status: site.state,
    template: site.tags,
    adUnit: site.units,
    updated: site.updateDate,
    actions: site,
  }));
  function handleOnTableChange(pagination, filters, sorter) {
    const sortBy = sorter.column?.sortKey || sorter.column?.dataIndex || siteSortings.UPDATE_DATE;
    const _sortOrder = sorter.order !== "ascend" ? sortOrder.DESC : sortOrder.ASC;
    onChange({ sortBy, sortOrder: _sortOrder, pagination });
  }

  const handleThirdPartyTagDrawerClose = () => {
    setSelectedBulkAction(undefined);
    setShowThirdPartyTagBulkDrawer(false);
  };

  const handleBulkActionChange = value => {
    setSelectedBulkAction(value);
  };

  const handleDuplicateDrawerClose = () => {
    setSelectedBulkAction(undefined);
    setShowDuplicateBulkDrawer(false);
  };

  const handleDuplicateDrawerSuccess = () => {
    notificationService.notifySuccess(t("sites.duplicateDrawer.successMessage"));
    setSelectedBulkAction(undefined);
    setShowDuplicateBulkDrawer(false);
    onRefresh();
  };

  const handleBulkPublishClose = () => {
    setSelectedBulkAction(undefined);
    setShowBulkPublishModal(false);
  };

  const handleBulkPublishSuccess = () => {
    notificationService.notifySuccess(t("sites.bulkPublishModal.publishSuccessMessage"));
    setSelectedBulkAction(undefined);
    setShowBulkPublishModal(false);
    onRefresh();
  };

  const executeAction = () => {
    // eslint-disable-next-line default-case
    switch (selectedBulkAction) {
      case ACTIONS.THIRD_PARTY_TAG:
        setShowThirdPartyTagBulkDrawer(true);
        break;
      case ACTIONS.DUPLICATE:
        setShowDuplicateBulkDrawer(true);
        break;
      case ACTIONS.PUBLISH:
        setShowBulkPublishModal(true);
        break;
    }
  };

  const actionOptions = [
    {
      label: t("sites.actionsMenu.thirdParty"),
      value: ACTIONS.THIRD_PARTY_TAG,
    },
    {
      label: t("sites.actionsMenu.duplicate"),
      value: ACTIONS.DUPLICATE,
    },
    {
      label: t("sites.actionsMenu.publish"),
      value: ACTIONS.PUBLISH,
    },
  ];

  return (
    <>
      <SitePublishModal
        open={sitePublishModal}
        onClose={() => setSitePublishModal(null)}
        site={sitePublishModal}
        onSuccess={onRefresh}
      />
      <SiteBulkPublishModal
        open={showBulkPublishModal}
        sites={selectedSites}
        onClose={handleBulkPublishClose}
        onSuccess={handleBulkPublishSuccess}
      />
      <SiteExternalTrackingDrawer
        open={!!siteExternalTrackingDrawer}
        onClose={() => setSiteExternalTrackingDrawer(null)}
        site={siteExternalTrackingDrawer}
      />
      <SiteDuplicateModal
        open={siteDuplicateModal}
        onClose={() => setSiteDuplicateModal(null)}
        site={siteDuplicateModal}
        siteName={siteDuplicateModal?.name}
        onSuccess={onRefresh}
      />
      <ThirdPartyTagDrawer
        open={siteThirdPartyModal}
        onClose={() => setSiteThirdPartyModal(null)}
        site={siteThirdPartyModal}
      />
      <SiteDeleteModal
        open={siteDeleteModal}
        onClose={() => setSiteDeleteModal(null)}
        site={siteDeleteModal}
        onSuccess={onRefresh}
      />
      <ThirdPartyTagBulkDrawer
        open={showThirdPartyTagBulkDrawer}
        sites={selectedSites}
        onClose={handleThirdPartyTagDrawerClose}
      />
      <SitesDuplicateDrawer
        open={showDuplicateBulkDrawer}
        onClose={handleDuplicateDrawerClose}
        onSuccess={handleDuplicateDrawerSuccess}
        sites={selectedSites}
      />
      {selectedSites?.length > 1 && (
        <Space size="xs" className={style.buttonContainer}>
          <Select
            data-testid="select-bulk-actions"
            value={selectedBulkAction}
            options={actionOptions}
            placeholder={t("sites.actionsMenu.placeholder")}
            width={217}
            onChange={handleBulkActionChange}
          />
          <Button disabled={!selectedBulkAction} type="secondary" onClick={executeAction}>
            {t("experiences.actionsMenu.apply")}
          </Button>
        </Space>
      )}
      <Table
        key={JSON.stringify(columns)}
        loading={loading}
        rowSelection={{
          selectedRowKeys: selectedSites.map(site => site.id),
          onChange: onRowSelectionChange,
        }}
        columns={columns}
        dataSource={dataSource}
        onChange={handleOnTableChange}
        emptyState={emptyState}
        pagination={{ current: pagination.pageNumber, pageSize: pagination.pageSize, total: pagination.total }}
      />
    </>
  );
}

SitesTable.propTypes = {
  sites: PropTypes.array.isRequired,
  emptyState: PropTypes.any,
  loading: PropTypes.bool,
  onRefresh: PropTypes.func,
  onChange: PropTypes.func,
  pagination: PropTypes.shape({ pageNumber: PropTypes.number, pageSize: PropTypes.number }),
};
