import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Attribute, Avatar, DetailPage, EditableText, SpacedContainer, WithCopyButton } from "Legacy/components";
import { applicationService, customerPermissions, helpers, motionlyService } from "Legacy/services";
import { dateFormatter } from "Legacy/utils/date";

export default function ApplicationDetailGeneralPage({ application, onUpdatedApplication }) {
  const [t] = useTranslation();
  return (
    <DetailPage>
      <SpacedContainer>
        <Attribute name={t("fields.name")} large>
          <EditableText
            text={application.name}
            large={false}
            disabled={customerPermissions.canRenameApplication(application) === false}
            maximumLength={helpers.constants.nameLengthMaximum}
            update={async value => {
              const updatedApplication = await applicationService.updateName(application.id, value);
              onUpdatedApplication(updatedApplication);
            }}
          />
        </Attribute>
        <Attribute
          name={t("fields.comment")}
          value={
            <EditableText
              text={application.comment}
              large
              disabled={customerPermissions.canRenameApplication(application) === false}
              maximumLength={helpers.constants.commentLengthMaximum}
              update={async value => {
                const updatedApplication = await applicationService.updateComment(application.id, value);
                onUpdatedApplication(updatedApplication);
              }}
            />
          }
        />
        <Attribute name={t("fields.owner")}>
          <Avatar src={application.owner.avatarUrl} name={application.owner.name ?? t("users.notPresentInCustomer")} />
        </Attribute>
        <Attribute name={t("fields.id")} value={application.id} />
        <Attribute name={t("fields.publicId")} value={application.publicId} />
        <Attribute name={t("fields.updateDate")} value={dateFormatter.toShortDateTime(application.updateDate)} />
        <Attribute name={t("fields.creationDate")} value={dateFormatter.toShortDateTime(application.creationDate)} />
        <Attribute
          name={t("applications.generalTab.sdkUrl")}
          value={<WithCopyButton text={motionlyService.getSdkUrl(application.publicId)} />}
        />
      </SpacedContainer>
    </DetailPage>
  );
}

ApplicationDetailGeneralPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object.isRequired,
  onUpdatedApplication: PropTypes.func.isRequired,
};
