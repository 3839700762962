import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import Site from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/Site";

import { Badge } from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { tagTypes } from "Legacy/utils";

export default function SiteStatusTag({ status = "" }) {
  const [t] = useTranslation();

  const tagInfo = useMemo(() => {
    switch (status) {
      case Site.StateEnum.Draft:
        return { label: Site.StateEnum.Draft, status: tagTypes.DEFAULT };
      case Site.StateEnum.Idle:
        return { label: Site.StateEnum.Idle, status: tagTypes.PROCESSING };
      case Site.StateEnum.Live:
        return { label: Site.StateEnum.Live, status: tagTypes.SUCCESS };
      case Site.StateEnum.Published:
        return { label: t("sites.status.published"), status: tagTypes.WARNING };
      default:
        return { label: "Unknown status", status: tagTypes.ERROR };
    }
  }, [status]);

  return <Badge status={tagInfo.status} text={tagInfo.label} />;
}

SiteStatusTag.propTypes = {
  status: PropTypes.string,
};
