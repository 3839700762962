import React from "react";
import * as PropTypes from "prop-types";

import "./ColorInput.scss";

export default function ColorInput({ id = "color", value = "", onChange = () => {}, width, disabled = false }) {
  return (
    <div className="color-input-container">
      <input id={id} type="color" value={value} onChange={event => onChange(event.target.value)} disabled={disabled} />
      <label htmlFor={id} className="color-input-label" style={{ width, backgroundColor: value }}>
        <span className="input-color-value">{value.toUpperCase()}</span>
      </label>
    </div>
  );
}

ColorInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};
