import sitePermissions from "../services/sitePermissions";
import { disableSites } from "./constants";
import { getObject, LOCALSTORAGE_SETTINGS, SETTINGS_HOME } from "./storage";

const hashType = "slash";
const set = (urlPath, forceReload) => {
  if (urlPath !== undefined) {
    if (hashType === "slash") {
      window.location.hash = urlPath;
    } else {
      window.location = `${window.location.origin}/${urlPath}`;
    }
    if (forceReload === true) {
      window.location.reload();
    }
  }
};

export function computeDefaultHome() {
  if (disableSites === true && sitePermissions.canAccess() === false) {
    return "/experiences";
  }
  const settings = getObject(LOCALSTORAGE_SETTINGS) || {};
  return settings[SETTINGS_HOME] || "/sites";
}

export default {
  set,
  HASH_TYPE: hashType,
  ROOT_CUSTOMER_PREFIX: "c",
  DEFAULT_HOME: computeDefaultHome(),
  DASHBOARD: "/dashboard",
  EXPERIENCES: "/experiences",
  TEMPLATES: "/templates",
  ANALYTICS: "/analytics",
  ASSETS: "/assets",
  SITES: "/sites",
  CUSTOMERS: "/settings",
  APPLICATIONS: "/applications",
  USERS: "/users",
  NOT_FOUND: "/notfound",
  LOGIN: "/login",
  SIGNUP: "/signup",
  JOIN: "/join",
  UNAUTHORIZED: "/unauthorized",
  PASSWORD_FORGOTTEN: "/password-forgotten",
};
