import React from "react";
import * as PropTypes from "prop-types";

import "./DetailPage.scss";

export default function DetailPage({ className, children }) {
  return <div className={`detail-page${className === undefined ? "" : ` ${className}`}`}>{children}</div>;
}

DetailPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
