/* eslint-disable */
import { templateFilters } from "Legacy/services/templateFilterHelper";
import comparators from "./Comparators";
// Lookup in tags to find target tag name with category "Industry"
const industryFilter = comparator => template => comparator(template?.tags.some(tag => comparator(tag)));

/**
 * Searches a text string in several template attributes
 * @param comparator
 * @returns {function(*): boolean}
 */
const fullTextFilter = comparator => template => comparator(template.name || "");

const templateFiltersConfig = {
  [templateFilters.FULLTEXT]: { filterFn: fullTextFilter, comparator: comparators.containsIgnoreCase },
  [templateFilters.INDUSTRY]: { filterFn: industryFilter, comparator: comparators.equals },
};

class TemplateFilterService {
  // You may pass values to the function to preprocess the config before returning it
  getFilterConfig() {
    return { ...templateFiltersConfig };
  }
}

export default new TemplateFilterService();
