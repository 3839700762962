import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

import "./TemplatesCard.scss";
import { Button, Dropdown, Tag, Tooltip } from "@ogury/design-system";
import AddLineIcon from "remixicon-react/AddLineIcon";
import PlayLineIcon from "remixicon-react/PlayLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";
import CodeLineIcon from "remixicon-react/CodeLineIcon";
import { templatePermissions } from "Legacy/services";
import { path, tagTypes } from "Legacy/utils";
import { TemplateCreateDynamicModal, TemplateCreateFromRevisionModal } from "Legacy/app/templates/components";
import { Avatar, Builder } from "Legacy/components/index";
import motionlyAuthor from "Legacy/assets/img/motionlyAuthor.png";

export default function TemplatesCard({ isCreate = false, template, loadPreview, onClick = () => {} }) {
  const history = useHistory();
  const [showBuilder, setShowBuilder] = useState(false);
  const [showCreateDynamicModal, setShowCreateDynamicModal] = useState(false);
  const [showBuilderWithTemplateRevision, setShowBuilderWithTemplateRevision] = useState();
  const [showCreateFromRevisionModal, setShowCreateFromRevisionModal] = useState(false);

  const [t] = useTranslation();

  const handleOnClick = () => {
    if (isCreate) {
      onClick(template.id);
    } else {
      history.push(`${path.TEMPLATES}/${template.id}`);
    }
  };
  const handleLoadPreview = () => loadPreview();

  const handleCreateWithBuilder = () => {
    setShowBuilder(true);
  };
  const handleCreateFromRevision = () => {
    setShowCreateFromRevisionModal(true);
  };

  const handleCreateWithInput = () => {
    setShowCreateDynamicModal(true);
  };

  return (
    <>
      <div className="template-card">
        <div className="template-card-header" />
        <div className="template-card-container">
          <div
            className="container"
            role="presentation"
            onClick={handleOnClick}
            style={{ backgroundColor: template.identity.backgroundColor }}
          >
            <div className="illustration-container">
              <img className="illustration" src={template.illustrationUrl} alt="thumbnail" />
            </div>
            <div className="template-card-footer" style={{ backgroundColor: template.identity.backgroundColor }}>
              {template.identity.status !== undefined && (
                <Tag color={tagTypes.GEEKBLUE}>{template.identity.status}</Tag>
              )}
              <Avatar src={motionlyAuthor} />
              <div
                className="template-card-footer-foreground-color"
                style={{ backgroundColor: template.identity.foregroundColor }}
              />
            </div>
          </div>
          {!isCreate && (
            <div className="template-card-actions">
              <div role="presentation" onClick={handleOnClick} />
              {template?.demos.length > 0 && (
                <Tooltip title={t("templates.card.demos")}>
                  <Button
                    type="secondary"
                    icon={<PlayLineIcon />}
                    iconPosition="iconOnly"
                    onClick={handleLoadPreview}
                  />
                </Tooltip>
              )}
              {(templatePermissions.canCreateWithBuilder() || templatePermissions.canCreateWithInput()) && (
                <Dropdown
                  trigger="click"
                  menu={{
                    items: [
                      templatePermissions.canCreateWithBuilder() && {
                        key: "static",
                        icon: <PencilLineIcon size={16} />,
                        onClick: handleCreateWithBuilder,
                        label: t("templates.card.static"),
                      },
                      templatePermissions.canCreateWithBuilderFromRevision() && {
                        key: "revision",
                        icon: <TimeLineIcon size={16} />,
                        onClick: handleCreateFromRevision,
                        label: t("templates.card.revision"),
                      },
                      templatePermissions.canCreateWithInput() && {
                        key: "dynamic",
                        icon: <CodeLineIcon size={16} />,
                        onClick: handleCreateWithInput,
                        label: t("templates.card.dynamic"),
                      },
                    ],
                  }}
                >
                  <Tooltip title={t("actions.create")} placement="top">
                    <Button type="secondary" icon={<AddLineIcon />} iconPosition="iconOnly" />
                  </Tooltip>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      </div>

      {templatePermissions.canCreateWithBuilder() && showBuilder && (
        <Builder templateId={template.id} onClose={() => setShowBuilder(false)} />
      )}

      {templatePermissions.canCreateWithBuilderFromRevision() && showBuilderWithTemplateRevision && (
        <Builder
          templateId={template.id}
          templateRevision={showBuilderWithTemplateRevision}
          onClose={() => setShowBuilderWithTemplateRevision(undefined)}
        />
      )}

      <TemplateCreateDynamicModal
        open={templatePermissions.canCreateWithInput() && showCreateDynamicModal}
        template={template}
        onCancel={() => setShowCreateDynamicModal(false)}
        onCreateSucceed={() => {}}
      />
      <TemplateCreateFromRevisionModal
        open={templatePermissions.canCreateWithBuilder() && showCreateFromRevisionModal}
        template={template}
        onCancel={() => setShowCreateFromRevisionModal(false)}
        onSuccess={setShowBuilderWithTemplateRevision}
      />
    </>
  );
}

TemplatesCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.any,
  isCreate: PropTypes.bool,
  loadPreview: PropTypes.func,
  onClick: PropTypes.func,
};
