import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./CreateViewModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";
import { ModalWidths } from "Legacy/components/Modal/Modal";
import { Form, FormControl, GuardedModal } from "../../../../..";

const inputsWidth = "100%";

export default function CreateViewModal({
  open,
  onCancel = () => {},
  callAction = () => {},
  onActionPerformed = () => {},
}) {
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [working, setWorking] = useState(false);

  const onCreate = async formData => {
    setWorking(true);
    try {
      const view = await callAction(formData.name.trim());
      notificationService.notifySuccess(t("viewManagement.createSuccess"));
      onActionPerformed(view);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: { name: "" },
    fields: { name: [{ name: validators.IS_REQUIRED }] },
    onSubmit: onCreate,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const nameFieldProps = getFieldProps("name");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      width={ModalWidths.Medium}
      title={t("viewManagement.createModalTitle")}
      footer={
        <ModalClosableFooter
          actions={
            <Button
              submit
              loading={working}
              onClick={() => {
                getFormProps().onSubmit();
              }}
            >
              {t("actions.create")}
            </Button>
          }
        />
      }
      canBeClosed={() => (nameFieldProps.value !== "" ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onCancel}
    >
      <Typography.P2Regular as="p">{t("viewManagement.createModalExplanation")} </Typography.P2Regular>
      <Form {...getFormProps()}>
        <FormControl
          label={t("fields.name")}
          placeholder={t("fields.namePlaceholder")}
          width={inputsWidth}
          required
          {...nameFieldProps}
        />
      </Form>
    </GuardedModal>
  );
}

CreateViewModal.propTypes = {
  onCancel: PropTypes.func,
  callAction: PropTypes.func,
  onActionPerformed: PropTypes.func,
  open: PropTypes.bool,
};
