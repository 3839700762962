import MotionlyApiService from "./MotionlyApiService";
import { customerService } from ".";

class SharingService {
  async getExperienceSharing(experienceId) {
    const sharings = await MotionlyApiService.shareApi().getExperience(
      customerService.getCurrentCustomerSummaryId(),
      experienceId
    );
    // We can have only one sharing even if the api allows more
    if (sharings && sharings.length > 0) {
      return sharings[0];
    }
    return null;
  }

  async create(experienceId, settings) {
    return MotionlyApiService.shareApi().create(customerService.getCurrentCustomerSummaryId(), experienceId, settings);
  }

  async update(sharingId, settings) {
    return MotionlyApiService.shareApi().update(customerService.getCurrentCustomerSummaryId(), sharingId, settings);
  }

  async setCapsule(sharingId, capsuleSettings) {
    return MotionlyApiService.shareApi().setCapsule(
      customerService.getCurrentCustomerSummaryId(),
      sharingId,
      capsuleSettings
    );
  }

  async delete(sharingId) {
    return MotionlyApiService.shareApi().callDelete(customerService.getCurrentCustomerSummaryId(), sharingId);
  }
}

export default new SharingService();
