import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ViewManager.scss";
import { Button, Dropdown } from "@ogury/design-system";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import { useNotificationService } from "Legacy/utils";
import { ViewType } from "Legacy/propTypes";
import { ButtonGroup } from "../../..";
import { CreateViewModal, RenameViewModal, DeleteViewModal } from "./components";
export default function ViewManager({
  criteriaChanged = false,
  currentView,
  callCreate = () => {},
  callSave = () => {},
  callRename = () => {},
  callDelete = () => {},
  onViewCreated = () => {},
  onViewSaved = () => {},
  onViewRenamed = () => {},
  onViewDeleted = () => {},
}) {
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [working, setWorking] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const clickSave = async () => {
    setWorking(true);
    try {
      const view = await callSave(currentView.id);
      setWorking(false);
      notificationService.notifySuccess(t("viewManagement.saveSuccess"));
      onViewSaved(view);
    } catch (error) {
      notificationService.notifyError(error);
      setWorking(false);
    }
  };

  const viewCreated = view => {
    setShowCreateModal(false);
    onViewCreated(view);
  };
  const viewRenamed = view => {
    setShowRenameModal(false);
    onViewRenamed(view);
  };
  const viewDeleted = () => {
    setShowDeleteModal(false);
    onViewDeleted();
  };

  const menuItems = [
    {
      key: "saveAs",
      label: t("viewManagement.saveAsNew"),
      onClick: () => setShowCreateModal(true),
    },
    {
      key: "rename",
      label: t("actions.rename"),
      onClick: () => setShowRenameModal(true),
      disabled: !currentView.id || currentView.default,
    },
    {
      key: "delete",
      label: t("actions.delete"),
      onClick: () => setShowDeleteModal(true),
      disabled: !currentView.id || currentView.default,
    },
  ];
  return (
    <>
      <ButtonGroup>
        <Button
          type="secondary"
          onClick={clickSave}
          disabled={working || !currentView.id || currentView.default || !criteriaChanged}
          loading={working}
        >
          {t("actions.save")}
        </Button>
        <Dropdown trigger="click" menu={{ items: menuItems }}>
          <Button iconPosition="iconOnly" icon={<ArrowDownSLineIcon />} type="secondary" disabled={working} />
        </Dropdown>
      </ButtonGroup>
      <CreateViewModal
        open={showCreateModal}
        currentView={currentView}
        onCancel={() => setShowCreateModal(false)}
        callAction={callCreate}
        onActionPerformed={viewCreated}
      />
      <RenameViewModal
        open={showRenameModal}
        currentView={currentView}
        onCancel={() => setShowRenameModal(false)}
        callAction={callRename}
        onActionPerformed={viewRenamed}
      />
      <DeleteViewModal
        open={showDeleteModal}
        currentView={currentView}
        onCancel={() => setShowDeleteModal(false)}
        callAction={callDelete}
        onActionPerformed={viewDeleted}
      />
    </>
  );
}

ViewManager.propTypes = {
  criteriaChanged: PropTypes.bool,
  currentView: ViewType,
  callCreate: PropTypes.func,
  callSave: PropTypes.func,
  callRename: PropTypes.func,
  callDelete: PropTypes.func,
  onViewCreated: PropTypes.func,
  onViewSaved: PropTypes.func,
  onViewRenamed: PropTypes.func,
  onViewDeleted: PropTypes.func,
};
