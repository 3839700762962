import buildUrl from "build-url";
import GenerationHtmlParameters from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/GenerationHtmlParameters";
import ExternalLink from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/ExternalLink";
import { DynamicInputs, Experience, FromTemplateInputs, Settings, Sorting, Tag } from "@ogury/motionly-ws-api/ws";

import { experienceDescription } from "Legacy/utils";
import { getObject, LOCALSTORAGE_CUSTOMER_VIEW, persistObject } from "Legacy/utils/storage";
import { sortOrder } from "Legacy/utils/sort";
import { DEFAULT_PAGE_SIZE } from "Legacy/utils/pagination";
import { TAG_IN_SITE } from "Legacy/utils/tag";
import MotionlyApiService from "./MotionlyApiService";
import {
  customerService,
  experienceFilterHelper,
  experienceSortingHelper,
  experienceSortings,
  tagService,
  templateService,
  userService,
} from ".";

export const experienceDownloadCommands = {
  DESCRIPTION: Symbol("description"),
  INPUTS: Symbol("inputs"),
  BUNDLE: Symbol("bundle"),
  HTML: Symbol("html"),
};

export const experienceBucketDimensions = {
  Default: { value: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000], label: "Default" },
  Small: { value: [50, 100, 200, 300, 400, 500, 600], label: "Small" },
  Medium: { value: [400, 500, 600, 700, 800, 900, 1000], label: "Medium" },
  Intermediate: { value: [700, 800, 900, 1000, 1100, 1200, 1300], label: "Intermediate" },
  Large: { value: [800, 1000, 1200, 1400, 1600, 1800, 2000], label: "Large" },
  SparseNarrow: { value: [400, 600, 800, 1000, 1200], label: "Sparse Narrow" },
  SparseLarge: { value: [500, 800, 1100, 1400, 1700, 2000], label: "Sparse Large" },
};

export const experienceBuiltInRatios = {
  Custom: { name: undefined, label: "Custom", value: undefined, dimensions: undefined },
  IAB120x600: {
    name: "120x600",
    label: 'IAB "SKYSCRAPER" 120x600',
    value: 0.2,
    dimensions: { width: 120, height: 600 },
  },
  IAB160x600: {
    name: "160x600",
    label: 'IAB "MEGASKYSCRAPER" 160x600',
    value: 0.2666,
    dimensions: { width: 160, height: 600 },
  },
  IAB300x1050: {
    name: "300x1050",
    label: 'IAB "PORTRAIT" 300x1050',
    value: 0.2857,
    dimensions: { width: 300, height: 1050 },
  },
  IAB300x600: {
    name: "300x600",
    label: 'IAB "GRAND ANGLE" 300x600',
    value: 0.5,
    dimensions: { width: 300, height: 600 },
  },
  IAB750x1334: {
    name: "750x1334",
    label: 'IAB "Mobile Phone Interstitial" 750x1334',
    value: 0.5622,
    dimensions: { width: 750, height: 1334 },
  },
  Instagram: {
    name: "Instagram",
    label: "Instagram 1080x1920",
    value: 0.5625,
    dimensions: { width: 1080, height: 1920 },
  },
  IAB640x1136: {
    name: "640x1136",
    label: 'IAB "Mobile Phone Interstitial" 640x1136',
    value: 0.5633,
    dimensions: { width: 640, height: 1136 },
  },
  IAB320x480: {
    name: "320x480",
    label: "IAB 320x480 and 640x960",
    value: 0.6666,
    dimensions: { width: 320, height: 480 },
  },
  Square: { name: "Square", label: "Square", value: 1, dimensions: undefined },
  IAB300x250: { name: "300x250", label: 'IAB "PAVE" 300x250', value: 1.2, dimensions: { width: 300, height: 255 } },
  Facebook1200x628: {
    name: "1200x628",
    label: "Facebook 1200x628",
    value: 1.9108,
    dimensions: { width: 1200, height: 628 },
  },
  IAB970x250: {
    name: "970x250",
    label: 'IAB "BILLBOARD" 970x250',
    value: 3.88,
    dimensions: { width: 970, height: 250 },
  },
  IAB994x250: {
    name: "994x250",
    label: 'IAB "WIDEBOARD" 994x250',
    value: 3.976,
    dimensions: { width: 994, height: 250 },
  },
  IAB300x50: {
    name: "300x50",
    label: 'IAB "SMARTPHONE BANNER" 300x50',
    value: 6,
    dimensions: { width: 300, height: 50 },
  },
  IAB320x50: {
    name: "320x50",
    label: 'IAB "SMARTPHONE BANNER" 320x50',
    value: 6.4,
    dimensions: { width: 320, height: 50 },
  },
  IAB728x90: {
    name: "728x90",
    label: 'IAB "LEADERBOARD" 728x90',
    value: 8.0888,
    dimensions: { width: 728, height: 90 },
  },
  IAB970x90: {
    name: "970x90",
    label: 'IAB "SUPER LEADERBOARD" 970x90',
    value: 10.7777,
    dimensions: { width: 970, height: 90 },
  },
};

export const areRatioEquals = (ratio, value, isVeryStrict) =>
  value !== undefined && Math.abs(ratio - value) / value <= (isVeryStrict === true ? 0.0001 : 0.01);

export const findBestRatioItem = ratio => {
  const keys = Object.keys(experienceBuiltInRatios);
  // We first search strictly
  const strictKeys = keys.filter(aKey => areRatioEquals(ratio, experienceBuiltInRatios[aKey].value, true));
  if (strictKeys.length >= 1) {
    return experienceBuiltInRatios[strictKeys[0]];
  }
  // Then, we widen the search to non-strict ratio equality
  const notStrictKeys = keys.filter(aKey => areRatioEquals(ratio, experienceBuiltInRatios[aKey].value, false));
  if (notStrictKeys.length >= 1) {
    return experienceBuiltInRatios[notStrictKeys[0]];
  }
  return undefined;
};

export const getExperienceRatio = experience => experience?.ratio;

export const guessExperienceWidth = experience =>
  findBestRatioItem(getExperienceRatio(experience))?.dimensions?.width || 300;

export const convertSearchDataUiToApi = criteria => {
  try {
    const sorting = experienceSortingHelper.uiToApi(criteria.sort);
    const filters = experienceFilterHelper.uiToApi(criteria.filters || []);
    return {
      sorting,
      filters,
      pagination: criteria.pagination,
      intervalValue: criteria.intervalValue,
      searchText: criteria.searchText,
      viewId: criteria.viewId,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(criteria),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

export const convertSearchDataApiToUi = (parameters, allTags) => {
  try {
    const sorting = experienceSortingHelper.apiToUi(parameters?.sorting);
    const filters = experienceFilterHelper.apiToUi(parameters?.filters, allTags);
    return { sorting, filters, pagination: parameters?.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(parameters),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return {
      filters: [],
      sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false },
      pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    };
  }
};

export const convertViewDataUiToApi = viewData => {
  try {
    const sorting = experienceSortingHelper.uiToApi(viewData.sorting);
    const filters = experienceFilterHelper.uiToApi(viewData.filters);
    return { sorting, filters };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted ui -> api with this value :",
      JSON.stringify(viewData),
      "Reason :",
      error
    );
    // noinspection JSUnresolvedVariable
    return { filters: [], sorting: { field: Sorting.FieldEnum.UpdateDate, ascending: false } };
  }
};

export const convertViewDataApiToUi = (viewData, allTags) => {
  try {
    const sorting = experienceSortingHelper.apiToUi(viewData.sorting);
    const filters = experienceFilterHelper.apiToUi(viewData.filters, allTags);
    return { sorting, filters };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "The view data could not be converted api -> ui with this value :",
      JSON.stringify(viewData),
      "Reason :",
      error
    );
    return { filters: [], sorting: { sortBy: experienceSortings.UPDATE_DATE, sortOrder: sortOrder.DESC } };
  }
};

const buildSearchOptions = ({ sort, pagination, filters, searchText } = {}) => {
  const criteria = {};
  const options = {};

  if (sort) {
    criteria.sorting = experienceSortingHelper.uiToApi(sort);
  }
  if (pagination) {
    options.range = { start: pagination.offset, end: pagination.offset + pagination.limit - 1 };
  }
  if (searchText) {
    options.text = searchText;
  }
  if (filters) {
    criteria.filters = experienceFilterHelper.uiToApi(filters);
  }
  return {
    criteria,
    options,
  };
};

const hydrateExperience = (experience, usersById = {}, tagsById = {}) => {
  // get all tags and put them into a tags property
  const experienceTags = experience.tagIds.map(id => tagsById[id] || { id, name: "Unknown tag" });

  // If the user has left the organization, it is not in the list
  return {
    ...experience,
    owner: usersById[experience.ownerId?.toString()] || userService.computeNotReachableUser(experience.ownerId),
    tags: experienceTags,
  };
};

const calculateTotalExperiences = (experiences, { pagination }) => {
  if (!pagination) {
    return experiences.length;
  }

  return pagination.limit === experiences.length
    ? pagination.offset + pagination.limit + 1
    : pagination.offset + experiences.length;
};

class ExperienceService {
  async list(searchCriteria) {
    // eslint-disable-next-line no-param-reassign
    delete searchCriteria.filters;
    const { criteria, options } = buildSearchOptions(searchCriteria);

    const experiencesList = await MotionlyApiService.experienceApi().search(
      customerService.getCurrentCustomerSummaryId(),
      searchCriteria.searchText || "",
      { ...options, sorting: criteria.sorting }
    );

    const usersById = await customerService.listCurrentCustomerUsersById();
    const tagsById = await tagService.listTagsById();
    const builtExperiences = experiencesList.experiences.map(experience =>
      hydrateExperience(experience, usersById, tagsById)
    );
    return { experiences: builtExperiences, total: calculateTotalExperiences(builtExperiences, searchCriteria) };
  }

  async listByFilter(searchCriteria) {
    const { criteria, options } = buildSearchOptions(searchCriteria);
    const experiences = await MotionlyApiService.experienceApi().listByFilter(
      customerService.getCurrentCustomerSummaryId(),
      criteria,
      options
    );

    const usersById = await customerService.listCurrentCustomerUsersById();
    const tagsById = await tagService.listTagsById();
    const builtExperiences = experiences.experiences.map(experience =>
      hydrateExperience(experience, usersById, tagsById)
    );

    return { experiences: builtExperiences, total: experiences.total };
  }

  async get(experienceId, providedExperience) {
    if (!experienceId) {
      return undefined;
    }

    const experience =
      providedExperience !== undefined
        ? providedExperience
        : await MotionlyApiService.experienceApi().get(customerService.getCurrentCustomerSummaryId(), experienceId);
    return hydrateExperience(
      experience,
      await customerService.listCurrentCustomerUsersById(),
      await tagService.listTagsById()
    );
  }

  async getPreview({ experienceId, descriptionUrl }) {
    // noinspection JSUnresolvedVariable
    return MotionlyApiService.experienceApi().html(customerService.getCurrentCustomerSummaryId(), {
      id: experienceId,
      applicationId: "preview",
      sdkVersion: undefined,
      descriptionUrl,
      navigationBar: GenerationHtmlParameters.NavigationBarEnum.None,
      theme: GenerationHtmlParameters.ThemeEnum.LightMode,
      bodyWidthPercentage: 100,
      // experienceWidthPercentage: 100,
      poweredBy: false,
      inIframe: true,
    });
  }

  async createDynamic(name, comment, templateId, templateVersion, ratio, inputsUrl) {
    return MotionlyApiService.experienceApi().create(
      customerService.getCurrentCustomerSummaryId(),
      name,
      { ratio, data: { templateId, templateVersion, inputsUrl } },
      {
        comment,
      }
    );
  }

  async duplicate(experienceId, { name, comment }) {
    return MotionlyApiService.experienceApi().duplicate(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      name,
      {
        comment,
      }
    );
  }

  async setOwner(experienceId, userId) {
    return MotionlyApiService.experienceApi().setOwner(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      userId
    );
  }

  async deleteExperience(experienceId) {
    return MotionlyApiService.experienceApi().callDelete(customerService.getCurrentCustomerSummaryId(), experienceId);
  }

  async setParameters(experienceId, motionlyInputParams) {
    return MotionlyApiService.experienceApi().setParameters(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      motionlyInputParams
    );
  }

  async setCallbacks(experienceId, process, callbacks) {
    return MotionlyApiService.experienceApi().setCallbacks(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      process,
      { body: callbacks }
    );
  }

  async upgrade(experience, templateVersion) {
    // noinspection JSUnresolvedVariable
    return experience.origin === Experience.OriginEnum.FromTemplate
      ? this.setFromTemplateSettings(experience.id, templateVersion)
      : this.setDynamicSettings(experience.id, templateVersion, experience.data.inputsUrl);
  }

  async setFromTemplateSettings(experienceId, templateVersion) {
    return MotionlyApiService.experienceApi().fromTemplateSet(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      new FromTemplateInputs(templateVersion)
    );
  }

  getTemplate(experience) {
    const templateTagIds = tagService.filterByCategory(experience.tags, Tag.CategoryEnum.Template).map(tag => tag.id);
    if (templateTagIds && templateTagIds.length === 1) {
      return templateService.getByTagId(templateTagIds[0]);
    }
    return undefined;
  }
  isInSite(experience) {
    if (experience?.tags?.length) {
      const usageTagArray = tagService.filterByCategory(experience.tags, Tag.CategoryEnum.Usage);
      if (usageTagArray?.length === 1) {
        return usageTagArray[0].name === TAG_IN_SITE;
      }
    }
    return false;
  }

  async setDynamicSettings(experienceId, templateVersion, inputsUrl) {
    return MotionlyApiService.experienceApi().dynamicSet(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      new DynamicInputs(templateVersion, inputsUrl)
    );
  }

  async setPoster(experienceId, posterData) {
    return MotionlyApiService.experienceApi().poster(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      posterData
    );
  }

  async getDescription(experienceId) {
    return MotionlyApiService.experienceApi().getDescription(
      customerService.getCurrentCustomerSummaryId(),
      experienceId
    );
  }

  async getBundle(experienceId) {
    return MotionlyApiService.experienceApi().download(customerService.getCurrentCustomerSummaryId(), experienceId);
  }

  async getInputs(experienceId) {
    return MotionlyApiService.experienceApi().getInputs(customerService.getCurrentCustomerSummaryId(), experienceId);
  }

  async getHtml(settings) {
    return MotionlyApiService.experienceApi().html(customerService.getCurrentCustomerSummaryId(), settings);
  }

  async getTracking(experienceId) {
    return MotionlyApiService.experienceApi().getTracking(customerService.getCurrentCustomerSummaryId(), experienceId);
  }

  async getThirdPartyTagsByExperienceIdsToExcel(experienceIds, dsps) {
    return MotionlyApiService.experienceApi().getThirdPartyTagsByExperienceIdsToExcel(
      customerService.getCurrentCustomerSummaryId(),
      experienceIds,
      dsps
    );
  }

  /*
*
* "clicks" is an array of objects with id and url as properties.
*
* clicks = [
        {
          id: "default",
          url,
        },
      ]
*
* */
  async setMultipleTrackingClickURL(experienceId, clicks) {
    return MotionlyApiService.experienceApi().setTracking(customerService.getCurrentCustomerSummaryId(), experienceId, {
      clicks,
    });
  }

  async setDefaultTrackingClickURL(experienceId, url) {
    return MotionlyApiService.experienceApi().setTracking(customerService.getCurrentCustomerSummaryId(), experienceId, {
      clicks: [
        {
          id: "default",
          url,
        },
      ],
    });
  }

  async exportMedia(experienceId, type, trackId, width, mediaInputs) {
    return MotionlyApiService.experienceApi().media(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      trackId,
      width,
      type,
      mediaInputs
    );
  }

  async exportScore(experienceId, scoreId, width, concatenated, exportInputs) {
    return MotionlyApiService.experienceApi().exportScore(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      scoreId,
      width,
      concatenated,
      exportInputs
    );
  }

  async publish(experienceId) {
    // noinspection JSUnresolvedVariable
    return MotionlyApiService.experienceApi().state(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      Experience.StateEnum.Published
    );
  }

  async archive(experienceId) {
    // noinspection JSUnresolvedVariable
    return MotionlyApiService.experienceApi().state(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      Experience.StateEnum.Archived
    );
  }

  async setPrivacy(experienceId, privacyValue) {
    return MotionlyApiService.experienceApi().setPrivacy(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      privacyValue
    );
  }

  async replace(experienceId, experienceToReplaceId) {
    return MotionlyApiService.experienceApi().replace(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      experienceToReplaceId
    );
  }

  async getIntegrationData(experienceId, environment) {
    return MotionlyApiService.experienceApi().showIntegrationCode(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      { environment }
    );
  }

  async getPosterWithHttpInfo(experienceId) {
    return MotionlyApiService.experienceApi().getPosterWithHttpInfo(
      customerService.getCurrentCustomerSummaryId(),
      experienceId
    );
  }

  async html5Bundle(experienceId, vendor, optimize, width, dimensionsBuckets) {
    return MotionlyApiService.experienceApi().html5Bundle(
      customerService.getCurrentCustomerSummaryId(),
      experienceId,
      vendor,
      width,
      { sdkVersion: undefined, dimensionsBuckets, assetsBaseUrl: undefined, optimize }
    );
  }

  async capsule(experienceId, width, isPortrait, poweredBy) {
    return MotionlyApiService.experienceApi().capsule(customerService.getCurrentCustomerSummaryId(), experienceId, {
      width,
      isPortrait,
      poweredBy,
    });
  }

  async upload(buffer, units = []) {
    // noinspection JSUnresolvedVariable
    return MotionlyApiService.experienceApi().upload(
      customerService.getCurrentCustomerSummaryId(),
      Experience.OriginEnum.Synchronized,
      buffer,
      { units }
    );
  }

  async updateComment(experienceId, comment) {
    return MotionlyApiService.experienceApi().rename(customerService.getCurrentCustomerSummaryId(), experienceId, {
      comment,
    });
  }

  async updateName(experienceId, name) {
    return MotionlyApiService.experienceApi().rename(customerService.getCurrentCustomerSummaryId(), experienceId, {
      name,
    });
  }

  async setTags(experienceId, tagIds) {
    return MotionlyApiService.experienceApi().setTags(customerService.getCurrentCustomerSummaryId(), experienceId, {
      tagIds,
    });
  }

  setLastClientView(view) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_VIEW);

    if (view) {
      // We associate the current view to the current customer
      // Need to convert the view into api format before storage
      const dataToStore = { ...storedView, [customerId]: { ...view, data: convertViewDataUiToApi(view.data) } };
      persistObject(LOCALSTORAGE_CUSTOMER_VIEW, dataToStore);
    } else {
      // Erase client view for current customer
      delete storedView[customerId];
      persistObject(LOCALSTORAGE_CUSTOMER_VIEW, storedView);
    }
  }

  // Get last view used by this client, for the current customer
  async getLastClientView() {
    const allTags = await tagService.listTags();
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_VIEW);
    const customerId = customerService.getCurrentCustomerSummaryId();

    if (storedView && storedView[customerId]) {
      // Need to convert the view into UI format
      return { ...storedView[customerId], data: convertViewDataApiToUi(storedView[customerId].data, allTags) };
    }
    return null;
  }

  async listViews() {
    const allTags = await tagService.listTags();
    const views = await MotionlyApiService.backofficeApi().experiencesViewList(
      customerService.getCurrentCustomerSummaryId()
    );
    return views
      .map(view => ({ ...view, data: convertViewDataApiToUi(view.data, allTags) }))
      .sort((a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1));
  }

  async getView(viewId) {
    const allTags = await tagService.listTags();
    const view = await MotionlyApiService.backofficeApi().experiencesViewGet(
      customerService.getCurrentCustomerSummaryId(),
      viewId
    );
    return { ...view, data: convertViewDataApiToUi(view.data, allTags) };
  }

  async createView(name, filters, sorting) {
    const allTags = await tagService.listTags();
    const experiencesViewData = convertViewDataUiToApi({ filters, sorting });
    const view = await MotionlyApiService.backofficeApi().experiencesViewCreate(
      customerService.getCurrentCustomerSummaryId(),
      name,
      experiencesViewData
    );
    return { ...view, data: convertViewDataApiToUi(view.data, allTags) };
  }

  async renameView(viewId, newName) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const allTags = await tagService.listTags();
    const view = await MotionlyApiService.backofficeApi().experiencesViewRename(customerId, viewId, newName);

    // update view in localStorage if present
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_VIEW);
    if (storedView[customerId]) {
      persistObject(LOCALSTORAGE_CUSTOMER_VIEW, { ...storedView, [customerId]: view });
    }
    return { ...view, data: convertViewDataApiToUi(view.data, allTags) };
  }

  async updateView(viewId, viewData) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    const allTags = await tagService.listTags();
    const convertedData = convertViewDataUiToApi(viewData);
    const view = await MotionlyApiService.backofficeApi().experiencesViewUpdate(customerId, viewId, convertedData);

    // update view in localStorage if present
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_VIEW);
    if (storedView[customerId]) {
      persistObject(LOCALSTORAGE_CUSTOMER_VIEW, { ...storedView, [customerId]: view });
    }
    return { ...view, data: convertViewDataApiToUi(view.data, allTags) };
  }

  async deleteView(viewId) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    await MotionlyApiService.backofficeApi().experiencesViewDelete(customerId, viewId);

    // delete view in localStorage if set
    const storedView = getObject(LOCALSTORAGE_CUSTOMER_VIEW);
    if (storedView[customerId]) {
      delete storedView[customerId];
      persistObject(LOCALSTORAGE_CUSTOMER_VIEW, { ...storedView });
    }
  }

  async getExperiencesSummaryList(arrayIds = [], options = { hydrateTags: false }) {
    if (arrayIds.length > 0) {
      const customerId = customerService.getCurrentCustomerSummaryId();
      const apiResponse = await MotionlyApiService.experienceApi().listByIds(customerId, arrayIds);
      const experiencesList = apiResponse?.experiences;
      if (experiencesList?.length && options.hydrateTags) {
        const tagsById = await tagService.listTagsById();
        return experiencesList.map(experience => hydrateExperience(experience, {}, tagsById));
      }
      return experiencesList;
    }
    return [];
  }

  openExperienceViaStudio(experience) {
    const queryParams = {};
    queryParams.experienceId = experience.id;
    const targetUrl = buildUrl(`mtly://open`, {
      path: "experience",
      queryParams,
    });
    window.open(targetUrl, "_blank");
  }

  computeSharingProperties(experience) {
    const description = experience?.description;
    // noinspection JSUnresolvedVariable
    return {
      introduction: experience === undefined ? "" : experience.name,
      title: experience === undefined ? "" : experience.comment,
      chapeau: "",
      footer: "",
      brandLogoImageUrl: "",
      context: Settings.ContextEnum.Article.toLowerCase(),
      theme: Settings.ThemeEnum.LightMode.toLowerCase(),
      wrapping:
        description === undefined ||
        experienceDescription.hasScore(description, experienceDescription.SCORE_MODES.scrolling) === true
          ? Settings.WrappingEnum.Short.toLowerCase()
          : Settings.WrappingEnum.None.toLowerCase(),
      backgroundColor: "#FFFFFF",
      bodyWidthPercentage: 50,
      experienceWidthPercentage:
        description === undefined ? 25 : experienceDescription.computeWidthPercentage(description),
    };
  }

  getOriginsLabels(t) {
    // noinspection JSUnresolvedVariable
    return {
      [Experience.OriginEnum.Managed]: t("experiences.origins.managed"),
      [Experience.OriginEnum.Synchronized]: t("experiences.origins.custom"),
      [Experience.OriginEnum.FromTemplate]: t("experiences.origins.fromTemplate"),
      [Experience.OriginEnum.Dynamic]: t("experiences.origins.dynamic"),
    };
  }

  async assignAdUnit(experienceId, technicalId, containerPolicy) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    await MotionlyApiService.experienceApi().setUnits(customerId, experienceId, {
      units: [{ technicalId, containerPolicy }],
    });
  }

  async getThirdPartyTag(experienceId, minified = false, applicationPublicId, dsp) {
    const customerId = customerService.getCurrentCustomerSummaryId();

    return await MotionlyApiService.experienceApi().getThirdPartyTagByExperienceId(customerId, experienceId, {
      dsp,
      applicationPublicId,
      minified,
    });
  }
  async getVpaidUrl(experienceId) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return await MotionlyApiService.experienceApi().getVastUrlByExperienceId(customerId, experienceId);
  }

  experienceIsPublished(experience) {
    return (
      experience.state === Experience.StateEnum.Published ||
      experience.state === Experience.StateEnum.Live ||
      experience.state === Experience.StateEnum.Idle
    );
  }

  async synchronizeWithCreativeConsole(experienceId) {
    const customerId = customerService.getCurrentCustomerSummaryId();
    return MotionlyApiService.experienceApi().synchronizeWithCreativeConsole(customerId, experienceId);
  }

  getExternalLinkByType(experience, externalLinkType) {
    if (this.experienceIsPublished(experience)) {
      const externalLinks = experience.units && experience.units[0]?.externalLinks;
      if (externalLinks?.length) {
        return externalLinks.find(
          externalLink =>
            externalLink.status === ExternalLink.StatusEnum.SUCCESS && externalLink.type === externalLinkType
        );
      }
    }
  }
  doesCreativeConsoleSyncFailed(experience) {
    if (this.experienceIsPublished(experience)) {
      const externalLinks = experience.units && experience.units[0]?.externalLinks;
      if (externalLinks?.length) {
        return externalLinks.find(externalLink => externalLink.status === ExternalLink.StatusEnum.FAILED);
      }
    }
  }
}

export default new ExperienceService();
