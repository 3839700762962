import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";
import FileSaver from "file-saver";

import Tag from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/Tag";
import { Button, Select, Space, Table } from "@ogury/design-system";
import { Avatar, ExperienceThumbnail, TemplateLink } from "Legacy/components";
import { experienceService, experienceSortings, SolutionVendor, unitService } from "Legacy/services";
import { randomString, sortOrder, useNotificationService } from "Legacy/utils";
import { ExperienceInlineActions, ExperienceStatusTag } from "Legacy/app/experiences/components";
import { dateFormatter, getTodayDate } from "Legacy/utils/date";
import style from "./ExperiencesTable.module.scss";

const ACTIONS = {
  THIRD_PARTY_TAG: "third-party-tag",
};

export default function ExperiencesTable({
  experiences = [],
  loading,
  sort,
  pagination,
  emptyState,
  onChange = () => {},
  onRefresh = () => {},
}) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState();
  const [requesting, setRequesting] = useState(false);

  const renderThumbnailNameCell = cellData => <ExperienceThumbnail experience={cellData} isFull={false} editable />;

  const renderDateCell = date => dateFormatter.toShortDate(date);

  const renderStatusCell = state => <ExperienceStatusTag status={state} />;

  const renderActionCell = row => {
    return (
      <ExperienceInlineActions key={"experience-table-menu-options-" + row.id} experience={row} onRefresh={onRefresh} />
    );
  };

  const renderOwnerCell = owner => (
    <Avatar key={randomString(8)} src={owner.avatarUrl} name={owner.name ?? t("users.notPresentInCustomer")} compact />
  );

  function renderAdUnitCell(units) {
    const results = unitService.getUnitsNamesFromUnits(units);
    if (results) {
      return <span>{results.join(", ")}</span>;
    }

    return <></>;
  }

  function renderFeatureCell(row) {
    if (row.origin === Experience.OriginEnum.FromTemplate || row.origin === Experience.OriginEnum.Dynamic) {
      const tag = row.tags.find(tag => tag.category === Tag.CategoryEnum.Template);
      if (tag) {
        return <TemplateLink tagId={tag.id} />;
      }
    }
    return experienceService.getOriginsLabels(t)[row.origin];
  }
  const onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    // First, removing from the selected experiences, all the experiences from the current selection, then adding the selectedRows.
    const updatedSelectedExperiences = selectedExperiences.filter(
      selectedExperience =>
        !experiences.some(experience => experience.id?.toString() === selectedExperience.id?.toString())
    );
    const experiencesList = selectedRows.map(selectedRow => selectedRow.experience) || [];
    setSelectedExperiences([...updatedSelectedExperiences, ...experiencesList]);
  };

  function handleOnTableChange(pagination, filters, sorter) {
    const sortBy = sorter.column?.sortKey || sorter.column?.dataIndex || experienceSortings.UPDATE_DATE;
    const _sortOrder = sorter.order !== "ascend" ? sortOrder.DESC : sortOrder.ASC;
    onChange({ sortBy, sortOrder: _sortOrder, pagination });
  }
  const dataSource = experiences.map(experience => ({
    key: experience.id,
    experience,
    name: experience,
    owner: experience.owner,
    status: experience.state,
    template: experience,
    adUnit: experience.units,
    ratio: experience.ratio,
    updated: experience.updateDate,
    actions: experience,
  }));

  const executeAction = () => {
    // eslint-disable-next-line default-case
    switch (selectedBulkAction) {
      case ACTIONS.THIRD_PARTY_TAG:
        downloadThirdPartyTag();
        break;
    }
  };

  const downloadThirdPartyTag = async () => {
    try {
      setRequesting(true);
      const experienceIds = selectedExperiences.map(experience => experience.id);
      const dsps = selectedExperiences.map(() => SolutionVendor.Default);
      const response = await experienceService.getThirdPartyTagsByExperienceIdsToExcel(experienceIds, dsps);
      const blob = new Blob([response], { type: "application/vnd.ms-excel" });
      FileSaver.saveAs(blob, "export-third-party-tag-experiences-" + getTodayDate() + ".xls");
    } catch (error) {
      console.warn(error);
      notificationService.notifyError(t("experiences.thirdPartyTagBulkDrawer.downloadError"));
    } finally {
      setRequesting(false);
    }
  };

  const columns = useMemo(() => {
    const value = [
      {
        title: t("fields.name"),
        dataIndex: "name",
        sorter: true,
        sortKey: experienceSortings.NAME,
        width: "30%",
        render: renderThumbnailNameCell,
      },
      {
        title: t("fields.adUnit"),
        dataIndex: "adUnit",
        width: "10%",
        render: renderAdUnitCell,
      },
      {
        title: t("fields.ratio"),
        width: 100,
        dataIndex: "ratio",
      },
      {
        title: t("experiences.properties.template"),
        dataIndex: "template",
        width: 100,
        render: renderFeatureCell,
      },
      {
        title: t("fields.owner"),
        dataIndex: "owner",
        sorter: true,
        width: 80,
        sortKey: experienceSortings.OWNER_EMAIL,
        render: renderOwnerCell,
      },
      {
        title: t("fields.updateDate"),
        dataIndex: "updated",
        sorter: true,
        width: 120,
        render: renderDateCell,
        sortKey: experienceSortings.UPDATE_DATE,
      },
      {
        title: t("experiences.properties.status"),
        dataIndex: "status",
        sorter: true,
        width: 120,
        sortKey: experienceSortings.STATE,
        render: renderStatusCell,
      },

      {
        title: t("experiences.properties.actions"),
        dataIndex: "actions",
        align: "right",
        width: 150,
        render: renderActionCell,
      },
    ];

    if (!sort) {
      return value;
    }

    // apply sorting
    value.forEach((item, index) => {
      if (item.sortKey === sort.sortBy && sort.sortOrder === -1) {
        value[index].defaultSortOrder = "descend";
      } else if (item.sortKey === sort.sortBy && sort.sortOrder === 1) {
        value[index].defaultSortOrder = "ascend";
      }
    });

    return value;
  }, [sort]);

  const actionOptions = [
    {
      label: t("experiences.actionsMenu.thirdParty"),
      value: ACTIONS.THIRD_PARTY_TAG,
    },
    // {
    //   label: t("experiences.actionsMenu.duplicate"),
    //   value: ACTIONS.DUPLICATE,
    // },
  ];

  const handleBulkActionChange = value => {
    setSelectedBulkAction(value);
  };

  return (
    <>
      {selectedExperiences?.length > 1 && (
        <Space size="xs" className={style.buttonContainer}>
          <Select
            value={selectedBulkAction}
            options={actionOptions}
            disabled={requesting}
            placeholder={t("experiences.actionsMenu.placeholder")}
            width={217}
            onChange={handleBulkActionChange}
          />
          <Button
            loading={requesting}
            disabled={!selectedBulkAction || requesting}
            type="secondary"
            onClick={executeAction}
          >
            {t("experiences.actionsMenu.apply")}
          </Button>
        </Space>
      )}
      <Table
        key={JSON.stringify(columns)}
        loading={loading}
        emptyState={emptyState}
        rowSelection={{
          selectedRowKeys: selectedExperiences.map(experience => experience.id),
          onChange: onRowSelectionChange,
        }}
        columns={columns}
        dataSource={dataSource}
        onChange={handleOnTableChange}
        pagination={{ current: pagination.pageNumber, pageSize: pagination.pageSize, total: pagination.total }}
      />
    </>
  );
}

ExperiencesTable.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onRefresh: PropTypes.func,
  onChange: PropTypes.func,
  pagination: PropTypes.shape({ pageNumber: PropTypes.number, pageSize: PropTypes.number }),
  emptyState: PropTypes.any,
};
