import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperiencePublishButton.scss";
import { Button } from "@ogury/design-system";
import { experiencePermissions } from "Legacy/services";
import { ExperiencePublishModal } from "./components";

export default function ExperiencePublishButton({ experience, disabled, onExperiencePublished }) {
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const onClickPublish = () => {
    setShowModal(true);
  };
  const onPublicationSuccess = () => {
    onExperiencePublished();
  };

  return (
    <>
      <Button type="primary" disabled={disabled} onClick={onClickPublish}>
        {t("actions.publish")}
      </Button>
      <ExperiencePublishModal
        open={showModal && experiencePermissions.canPublish(experience)}
        experience={experience}
        onClose={() => setShowModal(false)}
        onSuccess={onPublicationSuccess}
      />
    </>
  );
}

ExperiencePublishButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experience: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onExperiencePublished: PropTypes.func.isRequired,
};
