import React, { useState } from "react";
import * as PropTypes from "prop-types";

import "./ApplicationDetailHeader.scss";
import { Button } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import { customerPermissions } from "Legacy/services";
import { DetailPageHeader } from "Legacy/components";
import { path } from "Legacy/utils";
import DeleteApplicationModal from "../../../ApplicationsPage/components/DeleteApplicationModal/DeleteApplicationModal";

export default function ApplicationDetailHeader({ application, loading, onReload, onDeleted, showName }) {
  const [deleteModal, setDeleteModal] = useState();

  return (
    <>
      <DetailPageHeader
        backPath={path.APPLICATIONS}
        title={showName === false ? undefined : application.name}
        loading={loading}
      >
        <Button onClick={onReload} type="secondary" iconPosition="iconOnly" icon={<RefreshLineIcon />} />
        <Button
          onClick={() => {
            setDeleteModal(application);
          }}
          type="secondary"
          iconPosition="iconOnly"
          icon={<DeleteBinLineIcon />}
          disabled={customerPermissions.canDeleteApplication(application) === false}
        />
      </DetailPageHeader>
      <DeleteApplicationModal
        open={deleteModal !== undefined}
        application={deleteModal}
        onClose={() => setDeleteModal(undefined)}
        onSuccess={() => {
          setDeleteModal(undefined);
          onDeleted();
        }}
      />
    </>
  );
}

ApplicationDetailHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  showName: PropTypes.bool.isRequired,
};
