import React, { useEffect, useState } from "react";
import CustomerRole from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/CustomerRole";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./InviteMemberModal.scss";
import { Button, ModalClosableFooter, Typography } from "@ogury/design-system";
import { Form, FormControl, GuardedModal } from "Legacy/components";
import { computeValueLabels, customerService } from "Legacy/services";
import { useFormValidation, validators, useNotificationService } from "Legacy/utils";

const inputsWidth = "100%";

const { RoleEnum } = CustomerRole;
const initialFormValue = {
  email: "",
  role: RoleEnum.Viewer,
};

const rolesOptions = computeValueLabels(RoleEnum).filter(object => object.value !== RoleEnum.Owner);

export default function InviteMemberModal({ open, customer, onClose = () => {}, onSendInvitationSuccess = () => {} }) {
  const [t] = useTranslation();
  const notificationService = useNotificationService();
  const [working, setWorking] = useState(false);

  const onSendInvitation = async formValue => {
    setWorking(true);
    try {
      await customerService.sendInvitation(customer.id, formValue.email, formValue.role);
      notificationService.notifySuccess(t("customers.members.inviteMemberModal.success"));
      setWorking(false);
      onSendInvitationSuccess();
    } catch (error) {
      notificationService.notifyError(error, t("messages.errorTitle"));
      setWorking(false);
    }
  };

  const formConfig = {
    initialValue: initialFormValue,
    fields: {
      email: [{ name: validators.IS_REQUIRED }, { name: validators.IS_EMAIL, t }],
      role: [{ name: validators.IS_REQUIRED }],
    },
    onSubmit: onSendInvitation,
  };

  const { getFormProps, getFieldProps, resetForm } = useFormValidation(formConfig);
  const emailFieldProps = getFieldProps("email");

  useEffect(() => {
    resetForm();
  }, [open]); // eslint-disable-line

  return (
    <GuardedModal
      open={open}
      title={t("customers.members.inviteMemberModal.title")}
      footer={
        <ModalClosableFooter
          actions={
            <Button submit type="primary" loading={working} onClick={() => getFormProps().onSubmit()}>
              {t("customers.members.inviteMemberModal.sendInvitation")}
            </Button>
          }
        />
      }
      canBeClosed={() => (emailFieldProps.value !== "" ? t("components.guardedModal.closeQuestion") : undefined)}
      onClose={onClose}
    >
      <Typography.P2Regular as="p">{t("customers.members.inviteMemberModal.explanation")}</Typography.P2Regular>
      <Form {...getFormProps()}>
        <FormControl
          id="email"
          label={t("fields.email")}
          placeholder={t("fields.emailPlaceholder")}
          required
          width={inputsWidth}
          {...emailFieldProps}
        />
        <FormControl
          id="role"
          type="select"
          label={t("customers.members.inviteMemberModal.role")}
          options={rolesOptions}
          required
          width={inputsWidth}
          {...getFieldProps("role")}
        />
      </Form>
    </GuardedModal>
  );
}

InviteMemberModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool,
  customer: PropTypes.object,
  onClose: PropTypes.func,
  onSendInvitationSuccess: PropTypes.func,
};
