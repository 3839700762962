import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FilterBar } from "Legacy/components";
import { templateFilters } from "Legacy/services";

export default function TemplatesFilters({ filtersValue = [], onChange, onSearch, onCleared }) {
  const [t] = useTranslation();
  const defaultFilters = [templateFilters.FULLTEXT];

  const filterBarConfig = [
    {
      filterKey: templateFilters.FULLTEXT,
      label: "templates.filters.textSearch",
      type: "searchinput",
      placeholder: t("fields.namePlaceholder"),
    } /* ,
    {
      filterKey: templateFilters.INDUSTRY,
      label: "templates.filters.industry",
      type: "select",
      options: industryOptions,
    }, */,
  ];

  return (
    <FilterBar
      filtersConfig={filterBarConfig}
      filtersEnum={templateFilters}
      defaultFilters={defaultFilters}
      value={filtersValue}
      onChange={onChange}
      onSearch={onSearch}
      onCleared={onCleared}
    />
  );
}

TemplatesFilters.propTypes = {
  filtersValue: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCleared: PropTypes.func.isRequired,
};
