/**
 * Helper functions to convert filter values from/to api/ui
 */

import { Filter } from "@ogury/motionly-ws-api/ws";
import { Filters } from "Legacy/utils";

export const templateFilters = {
  FULLTEXT: Symbol("fulltext"),
  INDUSTRY: Symbol("industry"),
};

const filterToAttribute = filter => {
  switch (filter) {
    case templateFilters.FULLTEXT:
      return Filter.FieldEnum.Name;
    default:
      return "";
  }
};

const attributeToFilter = attribute => {
  switch (attribute) {
    case Filter.FieldEnum.Name:
      return templateFilters.FULLTEXT;
    default:
      return "";
  }
};

const tagsFiltersOperator = filter => {
  switch (filter) {
    default:
      return Filters.operators.OR;
  }
};

// Convert filters (UI value) into Filters (API value)
export const uiToApi = filters => Filters.uiToApi(filters, filterToAttribute, tagsFiltersOperator);

// Convert Filters (API value) into filters (UI value)
export const apiToUi = (filters = [], tags = []) => {
  const uiFilters = [];
  const tagsByCategories = {};

  // We need tags to separate tag filters by category
  tags.forEach(tag => {
    tagsByCategories[tag.id] = tag.category;
  });

  filters.forEach(filter => {
    uiFilters.push({ filter: attributeToFilter(filter.field), values: [filter.value] });
  });

  return uiFilters;
};

export default { uiToApi, apiToUi };
