import React, { createContext, useEffect, useState } from "react";
import { userService } from "Legacy/services";
import { checkAndResetUserSettings } from "Legacy/utils/hooks/useUserSettings";

export const UserSettingsContext = createContext();

export function UserSettingsProvider({ children }) {
  const [userSettings, setUserSettings] = useState(userService.getUserPreferences());

  const updateUserSettings = (field, value) => {
    userService.setUserPreferences(field, value);
    const newSettings = {
      [field]: value,
    };
    setUserSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
  };

  useEffect(() => {
    checkAndResetUserSettings();
  }, []);

  return (
    <UserSettingsContext.Provider value={{ userSettings, updateUserSettings }}>{children}</UserSettingsContext.Provider>
  );
}
