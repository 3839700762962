import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { experiencePermissions, sitePermissions } from "Legacy/services";
import { disableSites } from "Legacy/utils/constants";
import customerService from "../../services/CustomerService";
import { SETTINGS_HOME } from "../../utils/storage";
import style from "./CreativesTypeSwitch.module.scss";

export default function CreativesTypeSwitch() {
  const [t] = useTranslation();
  const params = useRouteMatch();

  const TABS = useMemo(
    () => ({
      SITES: {
        PATH: "/sites",
        NAME: t("components.creativesTypeSwitch.sites"),
      },
      EXPERIENCES: {
        PATH: "/experiences",
        NAME: t("components.creativesTypeSwitch.experiences"),
      },
    }),
    [t]
  );

  // eslint-disable-next-line consistent-return
  const selected = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in TABS) {
      if (TABS[key].PATH === params.path) {
        return TABS[key];
      }
    }
  }, [params, TABS]);

  useEffect(() => {
    if (selected) {
      customerService.updateLocalSettings(SETTINGS_HOME, selected.PATH);
    }
  }, [selected]);

  if (
    (disableSites === true && sitePermissions.canAccess() === false) ||
    experiencePermissions.canViewList() === false
  ) {
    return <></>;
  }

  return (
    <div className={style.container}>
      {Object.keys(TABS).map(key => {
        const path = TABS[key].PATH;
        return (
          <Link key={path} to={path}>
            <div className={`${style.tab} ${selected === TABS[key] ? style.active : {}}`}>{TABS[key].NAME} </div>
          </Link>
        );
      })}
    </div>
  );
}
